import React, { useEffect, useMemo, useRef } from "react";
import { OrgChart } from "d3-org-chart";
import { memo } from "react";
import moment from "moment";
import { Button } from "reactstrap";
import {
  capitalize,
  formatCurrencyValue,
  formatDate,
  formatNumberInShort,
} from "../helper-methods";
import { APP_LOGO } from "../config";
import { useSelector } from "react-redux";

const OrgChartComponent = ({
  data = [],
  filters = {},
  reset = () => {},
  setIsErrorInOrgChart = () => {},
}) => {
  const userCredential = useSelector((state) => state?.userCredential);

  const userType = useMemo(
    () => userCredential?.user?.userType?.toLowerCase(),
    [userCredential]
  );

  const d3Container = useRef({ current: null });
  const chartRef = useRef({ current: null });

  const _onNodeClick = (node) => {
    console.log("onNodeClick", node);
  };

  const _onExpandOrCollapse = (node) => {
    try {
      if (!node.parent) return;

      // Clear previous higlighting
      chartRef.current.clearHighlighting();

      if (node?.children?.length) {
        node?.children?.forEach((d) => {
          // children, mark node as highlighted
          chartRef.current.setHighlighted(d.id).render();
        });
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const _zoomIn = () => {
    try {
      if (!chartRef?.current?.zoomIn) {
        return;
      }

      chartRef.current.zoomIn();
    } catch (error) {
      console.log({ error });
    }
  };

  const _zoomOut = () => {
    try {
      if (!chartRef?.current?.zoomOut) {
        return;
      }

      chartRef.current.zoomOut();
    } catch (error) {
      console.log({ error });
    }
  };

  const _reset = () => {
    try {
      if (!chartRef?.current?.fit) {
        return;
      }

      chartRef.current.fit();

      reset();
    } catch (error) {
      console.log({ error });
    }
  };

  const _onChangeFilters = () => {
    try {
      if (!chartRef?.current?.container) {
        return;
      }

      // Clear previous higlighting
      chartRef.current.clearHighlighting();

      // Get chart nodes
      const data = chartRef.current.data();

      // Mark all previously expanded nodes for collapse
      data.forEach((d) => (d._expanded = false));

      let filteredData = [...data];

      let isFilterExist = false;

      // Loop over data and check if input value matches any name
      if (filters?.search) {
        isFilterExist = true;

        filteredData = filteredData.filter((d) =>
          d.name?.toLowerCase().includes(filters?.search.toLowerCase())
        );
      }

      if (filters?.startDate && filters?.endDate) {
        isFilterExist = true;

        filteredData = filteredData.filter((d) => {
          const createdAt = moment(d.createdAt);

          if (
            createdAt.isAfter(filters?.startDate) &&
            createdAt.isBefore(filters?.endDate)
          ) {
            return true;
          }

          return false;
        });
      }

      if (filters?.referLeadCount) {
        isFilterExist = true;

        filteredData = filteredData.filter((d) => {
          // clientCount
          try {
            const referLeadCount = JSON.parse(filters?.referLeadCount);

            if (
              referLeadCount.min <= d.clientCount &&
              referLeadCount.max >= d.clientCount
            ) {
              return true;
            }
          } catch (error) {
            console.log({ error });
          }

          return false;
        });
      }

      if (filters?.refereAffiliateCount) {
        isFilterExist = true;

        filteredData = filteredData.filter((d) => {
          // affiliateCount
          try {
            const refereAffiliateCount = JSON.parse(
              filters?.refereAffiliateCount
            );

            if (
              refereAffiliateCount.min <= d.affiliateCount &&
              refereAffiliateCount.max >= d.affiliateCount
            ) {
              return true;
            }
          } catch (error) {
            console.log({ error });
          }

          return false;
        });
      }

      if (isFilterExist) {
        filteredData.forEach((d) => {
          // If matches, mark node as highlighted
          if (d.parentId) {
            d._highlighted = true;
            d._expanded = true;
          }
        });
      }

      // Update data and rerender graph
      chartRef.current.data(data).render().fit();
    } catch (error) {
      console.log({ error });
    }
  };

  const _initializeChart = async () => {
    try {
      if (!data || !d3Container?.current) {
        return;
      }

      if (!chartRef?.current?.container) {
        chartRef.current = new OrgChart();
      }

      chartRef.current
        .container(d3Container.current)
        .data(data)
        .pagingStep((d) => 19)
        .minPagingVisibleNodes((d) => 19)
        .pagingButton((d, i, arr, state) => {
          const step = state.pagingStep(d.parent);
          const currentIndex = d.parent.data._pagingStep;
          const diff = d.parent.data._directSubordinatesPaging - currentIndex;
          const min = Math.min(diff, step);
          return `
              <div style="margin-top:50px;">
                <div style="display:flex;width:170px;border-radius:20px;padding:5px 15px; padding-bottom:4px; color:white; background-color:#015898">
                <div>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.59 7.41L10.18 12L5.59 16.59L7 18L13 12L7 6L5.59 7.41ZM16 6H18V18H16V6Z" fill="#fff" stroke="#015898"/>
                </svg>
                </div>
                <div style="line-height:2"> Show next ${min}  nodes </div></div>
              </div>
          `;
        })
        .nodeHeight((d) => 210)
        .nodeWidth((d) => 220)
        .childrenMargin((d) => 50)
        .compactMarginBetween((d) => 35)
        .compactMarginPair((d) => 30)
        .neighbourMargin((a, b) => 20)
        .nodeContent((d, i, arr, state) => {
          if (d.data.parentId === "") {
            return `
              
    
                <div  style="width:${d.width}px; height:${
              d.height
            }px; border-radius:10px; display:flex; align-items:center; justify-content:center; border: ${
              d.data._highlighted || d.data._upToTheRootHighlighted
                ? "5px solid #31b27c"
                : "1px solid #E4E2E9"
            }">
                        
                    <img src=${APP_LOGO} alt="Logo" width="130" height="24" />
    
                  </div>
                
              `;
          }

          return `
            <div style='width:${d.width}px; height:${d.height}px;'>

              <div style="width:${
                d.width
              }px; background-color: #FFFFFF; height:${
            d.height
          }px; border-radius:10px; border: ${
            d.data._highlighted || d.data._upToTheRootHighlighted
              ? "5px solid #31b27c"
              : "1px solid #E4E2E9"
          }">
                    
                <div class="empName">
                  ${
                    d.data.id && d.data.name
                      ? `<a href="/${userType}/affiliate/${
                          d.data.id
                        }" target="_blank">${capitalize(d.data.name)}</a>`
                      : `<span>N/A</span>`
                  }
                </div>

                <ul class="empInfo">
                <li>
                <span>Joined:</span> ${formatDate(d.data.createdAt)}
                </li>
                <li>
                <span>Referred By:</span> ${
                  d.data.referId && d.data.referBy?.full
                    ? `<a href="/${userType}/affiliate/${
                        d.data.referId
                      }" target="_blank">${capitalize(
                        d.data.referBy?.full
                      )}</a>`
                    : `N/A`
                }
                </li>
                <li>
                <span>Org:</span> ${
                  d.data.organizationId && d.data.organizationName
                    ? `<a href="/${userType}/affiliate/${
                        d.data.organizationId
                      }" target="_blank">${capitalize(
                        d.data.organizationName
                      )}</a>`
                    : `N/A`
                }
                </li>
                <li>
                <span>Revenue Shared:</span> ${formatCurrencyValue(
                  d.data.revenueShared || 0
                )}
                </li>
                <li>
                <span>Employees:</span> ${formatNumberInShort(
                  d.data.employeesCount || 0
                )}
                </li>
                <li>
                <span>Clients:</span> ${formatNumberInShort(
                  d.data.clientCount || 0
                )}
                </li>
                <li>
                <span>Affiliates:</span> ${formatNumberInShort(
                  d.data.affiliateCount || 0
                )}
                </li>
                </ul>
                
                

              </div>
            </div>
          `;
        })
        .onNodeClick((node) => {
          _onNodeClick(node);
        })
        .onExpandOrCollapse((node) => {
          _onExpandOrCollapse(node);
        })
        .render();

      try {
        const zoomBehavior = new chartRef.current.zoomBehavior();

        zoomBehavior.wheelDelta((event) => {
          return (
            -event.deltaY *
            (event.deltaMode === 1 ? 0.05 : event.deltaMode ? 1 : 0.002) *
            (event.ctrlKey ? 5 : 0)
          );
        });
        // error handler for zoomBehavior
      } catch (error) {
        console.log("zoomBehavior error>>", { error });
      }

      setIsErrorInOrgChart(false);

      // error handler for overall method
    } catch (error) {
      console.log({ error });
      setIsErrorInOrgChart(true);
    }
  };

  useEffect(() => {
    _onChangeFilters();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    _initializeChart();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, d3Container]);

  return (
    <>
      <div className="text-end">
        <Button
          color="link"
          className="m-2 p-2"
          title="Zoom In"
          onClick={() => _zoomIn()}
        >
          <i className="fa fa-search-plus" />
        </Button>

        <Button
          color="link"
          className="m-2 p-2"
          title="Zoom Out"
          onClick={() => _zoomOut()}
        >
          <i className="fa fa-search-minus" />
        </Button>

        <Button
          color="link"
          className="m-2 p-2"
          title="Reset"
          onClick={() => _reset()}
        >
          Reset
        </Button>
      </div>

      <div ref={d3Container} />
    </>
  );
};

export default memo(OrgChartComponent);
