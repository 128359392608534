import OneSignal from "react-onesignal";

export { default as OneSignalHelper } from "./onesignalHelper";

export default async function runOneSignal() {
  await OneSignal.init({
    appId: "5a1032e0-02e4-4188-82ff-22187f82558c",
    safari_web_id: "web.onesignal.auto.4058b815-86ea-44b3-9131-733c406f5a63",
    notifyButton: {
      enable: false,
    },
    allowLocalhostAsSecureOrigin: true,
  });
}
