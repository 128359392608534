import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  Table,
  TabPane,
} from "reactstrap";
import AgreementModal from "../../components/modals/AgreementModal";
import CustomPagination from "../../components/Table/CustomPagination";
import {
  dateConfig,
  documentTemplateStatusConfig,
} from "../../config/helper-config";
import {
  errorHandler,
  formatDate,
  openUrlOnNewTab,
  showToast,
} from "../../helper-methods";
import {
  getAllAgreementDetail,
  updateLead,
  updateUser,
} from "../../http/http-calls";

const AgreementDocuments = () => {
  const searchInterval = useRef({ current: null });

  const [data, setData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [dataPayload, setDataPayload] = useState({
    filters: {},
    skip: 0,
    pageNumber: 0,
    page: 1,
    pageSize: 10,
    search: "",
    userType: "User",
  });
  const [loading, setLoading] = useState(false);
  const [filtersLoading, setFiltersLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [filters, setFilters] = useState({
    status: "",
    addedDate: "",
  });

  const [activeTab, setActiveTab] = useState("User");

  const [agreementModal, setAgreementModal] = useState({
    isOpen: false,
    data: null,
    templateType: null,
  });

  const _toggleTab = (newTab = "User") => {
    if (activeTab !== newTab) {
      setActiveTab(newTab);
      _clearFilters(newTab);
    }
  };

  const _toggleAgreementModal = (
    isOpen = false,
    data = null,
    templateType = null
  ) => {
    setAgreementModal({ isOpen, data, templateType });
  };

  const _getAllAgreementDetail = (payload) => {
    setLoading(true);

    getAllAgreementDetail(payload)
      .then((res) => {
        setData(res?.users?.length ? res.users : []);
        setDataCount(res?.totalCount || 0);
        setLoading(false);
        setFiltersLoading(false);
        setSearchLoading(false);
        setStatusLoading(false);
      })
      .catch((error) => {
        errorHandler(error);
        setLoading(false);
        setFiltersLoading(false);
        setSearchLoading(false);
        setStatusLoading(false);
      });
  };

  const _onPageChange = (pageNumber = 0) => {
    const newDataPayload = { ...dataPayload };
    newDataPayload["skip"] = pageNumber * newDataPayload["pageSize"];
    newDataPayload["pageNumber"] = pageNumber;
    newDataPayload["page"] = pageNumber + 1;
    setDataPayload(newDataPayload);
    _getAllAgreementDetail(newDataPayload);
  };

  const _onChangeFilters = (key, value) => {
    setFiltersLoading(true);

    const newFilters = { ...filters };
    newFilters[key] = value;
    setFilters(newFilters);

    const newDataPayload = { ...dataPayload };
    newDataPayload["skip"] = 0;
    newDataPayload["pageNumber"] = 0;
    newDataPayload["page"] = 1;
    newDataPayload["filters"] = {};

    Object.keys(newFilters).forEach((eachFilterKey) => {
      if (newFilters[eachFilterKey]) {
        newDataPayload.filters[eachFilterKey] = newFilters[eachFilterKey];
      }
    });

    setDataPayload(newDataPayload);

    _getAllAgreementDetail(newDataPayload);
  };

  const _onChangeSearch = (searchValue) => {
    if (searchInterval?.current) clearInterval(searchInterval.current);
    setSearchLoading(true);
    const newDataPayload = { ...dataPayload };
    newDataPayload["search"] = searchValue;
    newDataPayload["skip"] = 0;
    newDataPayload["pageNumber"] = 0;
    newDataPayload["page"] = 1;
    setDataPayload(newDataPayload);
    searchInterval.current = setTimeout(() => {
      _getAllAgreementDetail(newDataPayload);
    }, 1000);
  };

  const _clearFilters = (userType = "User") => {
    setFiltersLoading(true);

    const newFilters = { ...filters };
    Object.keys(newFilters).forEach((key) => {
      newFilters[key] = "";
    });
    setFilters(newFilters);

    const newDataPayload = { ...dataPayload };
    newDataPayload["search"] = "";
    newDataPayload["skip"] = 0;
    newDataPayload["pageNumber"] = 0;
    newDataPayload["page"] = 1;
    newDataPayload["filters"] = {};
    newDataPayload["userType"] = userType;
    setDataPayload(newDataPayload);

    _getAllAgreementDetail(newDataPayload);
  };

  const _onChangeStatus = async (user, status, userType) => {
    try {
      if (!user?._id || !userType) {
        errorHandler({ reason: "User data not found" });
        return;
      }

      setStatusLoading(true);

      const payload = {
        agreement: { status },
      };

      const newData = [...data];
      const findData = newData.find((each) => each._id === user._id);
      if (findData) {
        findData.agreement.status = status;
        setData(newData);
      }

      if (userType === "User") {
        await updateUser({ id: user._id, payload });
      } else {
        await updateLead({ id: user._id, payload });
      }

      setStatusLoading(false);
      showToast("Status has been updated", "success");
    } catch (error) {
      errorHandler(error);
      setStatusLoading(false);
      _getAllAgreementDetail(dataPayload);
    }
  };

  useEffect(() => {
    _getAllAgreementDetail(dataPayload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="pgTitleWrap">
        <h2>Agreement Documents {filtersLoading ? <Spinner /> : null}</h2>
      </div>

      <div className="cardWrap main_content_wrapper">
        <Nav pills>
          <NavItem>
            <NavLink
              className={activeTab === "User" ? "active" : ""}
              onClick={() => _toggleTab("User")}
            >
              Affiliate
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "Lead" ? "active" : ""}
              onClick={() => _toggleTab("Lead")}
            >
              Client
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="User">
            {/* filter */}
            <div className="filterWrapper">
              <i className="fas fa-filter filterIcon" />

              <div className="filterForm">
                <div className="d-flex flex-wrap">
                  <div className="formGroup">
                    <Label>Added Date</Label>
                    <Input
                      type="select"
                      value={filters.addedDate}
                      name="addedDate"
                      onChange={(e) =>
                        _onChangeFilters("addedDate", e.target.value)
                      }
                    >
                      <option value="">All Time</option>
                      {dateConfig.map((each) => (
                        <option key={each.value} value={each.value}>
                          {each.label}
                        </option>
                      ))}
                    </Input>
                  </div>

                  <div className="formGroup">
                    <Label>Status</Label>
                    <Input
                      type="select"
                      value={filters.status}
                      name="status"
                      onChange={(e) =>
                        _onChangeFilters("status", e.target.value)
                      }
                    >
                      <option value="">All</option>
                      {documentTemplateStatusConfig.map((each) => (
                        <option key={each.value} value={each.value}>
                          {each.label}
                        </option>
                      ))}
                    </Input>
                  </div>
                </div>

                <div className="searchBoxContainer">
                  <div className="formGroup searchBar">
                    <Label>Search</Label>
                    <InputGroup className="searchTable">
                      <Input
                        type="text"
                        name="search"
                        placeholder="Search"
                        autoComplete="off"
                        value={dataPayload.search}
                        onChange={(e) => _onChangeSearch(e.target.value)}
                      />
                      <InputGroupText>
                        {searchLoading ? (
                          <i className="fa fa-spinner fa-spin" />
                        ) : dataPayload.search ? (
                          <i
                            className="fa fa-times"
                            onClick={() => _onChangeSearch("")}
                          />
                        ) : (
                          <i className="fa fa-search" />
                        )}
                      </InputGroupText>
                    </InputGroup>
                  </div>

                  <Button
                    className="btn-eraser"
                    onClick={() => _clearFilters("User")}
                  >
                    <i className="fa fa-eraser" />
                  </Button>
                </div>
              </div>
            </div>

            <div className="tableWrapper mb-0">
              <Table responsive className="table-fixed">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Added On</th>
                    <th>Draft</th>
                    <th>Status</th>
                    {/* <th>Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {data?.length ? (
                    data.map((each) => (
                      <tr key={each?._id}>
                        <td>{each.name?.full || "N/A"}</td>
                        <td>{formatDate(each.createdAt)}</td>
                        <td>
                          {each?.agreement?.url ? (
                            <Button
                              color="link"
                              onClick={() =>
                                openUrlOnNewTab(each.agreement.url)
                              }
                            >
                              View
                            </Button>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td>
                          <Input
                            type="select"
                            disabled={statusLoading ? true : false}
                            value={each.agreement?.status}
                            onChange={(e) =>
                              _onChangeStatus(each, e.target.value, "User")
                            }
                          >
                            {documentTemplateStatusConfig.map((each) => (
                              <option key={each.value} value={each.value}>
                                {each.label}
                              </option>
                            ))}
                          </Input>
                        </td>
                        {/* <td>
                          {each?.agreement?.status === "Pending" ? ( // enum: ["Signed", "Pending"]
                            <Button
                              color="link"
                              className="ms-1"
                              onClick={() =>
                                _toggleAgreementModal(
                                  true,
                                  each,
                                  "Affiliate Agreement Reminder"
                                )
                              }
                            >
                              Send Agreement Reminder
                            </Button>
                          ) : (
                            "-"
                          )}
                        </td> */}
                      </tr>
                    ))
                  ) : (
                    <tr className="text-center">
                      <td colSpan="4">
                        {loading ? (
                          <Spinner />
                        ) : (
                          <div className="nodata">
                            <img
                              src={
                                require("../../assets/img/noData.svg").default
                              }
                              alt="no data"
                            />
                            <p>There is no data to display</p>
                          </div>
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>

              <CustomPagination
                data={data}
                dataCount={dataCount}
                dataPayload={dataPayload}
                onPageChange={_onPageChange}
              />
            </div>
          </TabPane>

          <TabPane tabId="Lead">
            <div className="filterWrapper">
              <i className="fas fa-filter filterIcon" />

              <div className="filterForm">
                <div className="d-flex flex-wrap">
                  <div className="formGroup">
                    <Label>Added Date</Label>
                    <Input
                      type="select"
                      value={filters.addedDate}
                      name="addedDate"
                      onChange={(e) =>
                        _onChangeFilters("addedDate", e.target.value)
                      }
                    >
                      <option value="">All Time</option>
                      {dateConfig.map((each) => (
                        <option key={each.value} value={each.value}>
                          {each.label}
                        </option>
                      ))}
                    </Input>
                  </div>

                  <div className="formGroup">
                    <Label>Status</Label>
                    <Input
                      type="select"
                      value={filters.status}
                      name="status"
                      onChange={(e) =>
                        _onChangeFilters("status", e.target.value)
                      }
                    >
                      <option value="">All</option>
                      {documentTemplateStatusConfig.map((each) => (
                        <option key={each.value} value={each.value}>
                          {each.label}
                        </option>
                      ))}
                    </Input>
                  </div>
                </div>

                <div className="searchBoxContainer">
                  <div className="formGroup searchBar">
                    <Label>Search</Label>
                    <InputGroup className="searchTable">
                      <Input
                        type="text"
                        name="search"
                        placeholder="Search"
                        autoComplete="off"
                        value={dataPayload.search}
                        onChange={(e) => _onChangeSearch(e.target.value)}
                      />
                      <InputGroupText>
                        {searchLoading ? (
                          <i className="fa fa-spinner fa-spin" />
                        ) : dataPayload.search ? (
                          <i
                            className="fa fa-times"
                            onClick={() => _onChangeSearch("")}
                          />
                        ) : (
                          <i className="fa fa-search" />
                        )}
                      </InputGroupText>
                    </InputGroup>
                  </div>

                  <Button
                    className="btn-eraser"
                    onClick={() => _clearFilters("Lead")}
                  >
                    <i className="fa fa-eraser" />
                  </Button>
                </div>
              </div>
            </div>

            <div className="tableWrapper mb-0">
              <Table responsive className="table-fixed">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Added On</th>
                    <th>Draft</th>
                    <th>Status</th>
                    {/* <th>Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {data?.length ? (
                    data.map((each) => (
                      <tr key={each?._id}>
                        <td>{each.name?.full || "N/A"}</td>
                        <td>{formatDate(each.createdAt)}</td>
                        <td>
                          {each?.agreement?.url ? (
                            <Button
                              color="link"
                              onClick={() =>
                                openUrlOnNewTab(each.agreement.url)
                              }
                            >
                              View
                            </Button>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td>
                          <Input
                            type="select"
                            disabled={statusLoading ? true : false}
                            value={each.agreement?.status}
                            onChange={(e) =>
                              _onChangeStatus(each, e.target.value, "Lead")
                            }
                          >
                            {documentTemplateStatusConfig.map((each) => (
                              <option key={each.value} value={each.value}>
                                {each.label}
                              </option>
                            ))}
                          </Input>
                        </td>
                        {/* <td>
                          {each?.agreement?.status === "Pending" ? ( // enum: ["Signed", "Pending"]
                            <Button
                              color="link"
                              className="ms-1"
                              onClick={() =>
                                _toggleAgreementModal(
                                  true,
                                  each,
                                  "Client Agreement Reminder"
                                )
                              }
                            >
                              Send Agreement Reminder
                            </Button>
                          ) : (
                            "-"
                          )}
                        </td> */}
                      </tr>
                    ))
                  ) : (
                    <tr className="text-center">
                      <td colSpan="4">
                        {loading ? (
                          <Spinner />
                        ) : (
                          <div className="nodata">
                            <img
                              src={
                                require("../../assets/img/noData.svg").default
                              }
                              alt="no data"
                            />
                            <p>There is no data to display</p>
                          </div>
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>

              <CustomPagination
                data={data}
                dataCount={dataCount}
                dataPayload={dataPayload}
                onPageChange={_onPageChange}
              />
            </div>
          </TabPane>
        </TabContent>
      </div>

      <AgreementModal
        isOpen={agreementModal.isOpen}
        data={agreementModal.data}
        templateType={agreementModal.templateType}
        toggle={() => _toggleAgreementModal()}
        onSuccess={() => _getAllAgreementDetail(dataPayload)}
      />
    </>
  );
};

export default AgreementDocuments;
