import React, { useState } from "react";
import { useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  FormGroup,
  Label,
  Form,
} from "reactstrap";
import { RegexConfig } from "../../config/RegexConfig";
import { copyToClipboard, errorHandler, showToast } from "../../helper-methods";
import { resetUserPassword } from "../../http/http-calls";

const ResetUserPasswordModal = ({ isOpen, data, toggle }) => {
  const [formFields, setFormFields] = useState({
    newPassword: "",
  });
  // eslint-disable-next-line no-unused-vars
  const [isDirty, setIsDirty] = useState({});
  const [errors, setErrors] = useState({});

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const _resetState = () => {
    setFormFields({
      newPassword: "",
    });
    setIsDirty({});
    setErrors({});
    setShowPassword(false);
    setLoading(false);
  };

  const _closeModal = () => {
    _resetState();
    toggle();
  };

  const _validateFormFields = ({ newFormFields, newIsDirty }) => {
    return new Promise((resolve) => {
      const newErrors = {};
      let isFormValid = true;

      Object.keys(newFormFields).forEach((key) => {
        if (newIsDirty[key]) {
          switch (key) {
            case "newPassword": {
              if (newFormFields[key]?.trim().length) {
                if (
                  RegexConfig.password.test(
                    String(newFormFields[key]).toLowerCase()
                  )
                ) {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] =
                    "*Minimum eight characters, at least one letter, one number and one special character";
                  isFormValid = false;
                }
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            default:
          }
        }
      });

      setErrors((prev) => ({
        ...prev,
        ...newErrors,
      }));

      setIsDirty((prev) => ({
        ...prev,
        ...newIsDirty,
      }));

      resolve(isFormValid);
    });
  };

  const _onChangeFormFields = (key, value) => {
    const newFormFields = { ...formFields };
    newFormFields[key] = value;
    setFormFields(newFormFields);
  };

  const _onBlurFormFields = (key) => {
    const newFormFields = { ...formFields };
    const newIsDirty = {
      [key]: true,
    };
    _validateFormFields({ newFormFields, newIsDirty });
  };

  const _resetUserPassword = async (e) => {
    try {
      if (e) e.preventDefault();

      if (!data?._id) {
        showToast("User not found", "error");
        _closeModal();
        return;
      }

      const newFormFields = { ...formFields };

      const newIsDirty = {
        newPassword: true,
      };

      const isFormValid = await _validateFormFields({
        newFormFields,
        newIsDirty,
      });

      if (!isFormValid) return;

      setLoading(true);

      const { newPassword } = await resetUserPassword({
        id: data._id,
        payload: { newPassword: formFields.newPassword },
      });

      showToast("Password has been reset", "success");

      if (newPassword) {
        setTimeout(() => {
          copyToClipboard(newPassword);
        }, 1000);
      }

      _closeModal();
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      _resetState();
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => _closeModal()}
      scrollable
      centered
      backdrop="static"
    >
      <ModalHeader toggle={() => _closeModal()}>Reset Password</ModalHeader>

      <ModalBody className="pb-0">
        <Form onSubmit={(e) => _resetUserPassword(e)}>
          <div>
            <FormGroup>
              <Label>Password</Label>
              <div className="d-flex">
                <Input
                  type={showPassword ? "text" : "password"}
                  autoComplete="new-password"
                  name="password"
                  value={formFields.newPassword}
                  onChange={(e) =>
                    _onChangeFormFields("newPassword", e.target.value)
                  }
                  onBlur={() => _onBlurFormFields("newPassword")}
                />

                <Button
                  type="button"
                  color="link"
                  className="showHideBtn"
                  onClick={() => setShowPassword((prev) => !prev)}
                >
                  {showPassword ? "Hide" : "Show"}
                </Button>
              </div>
              {errors.newPassword && (
                <div className="form-error">{errors.newPassword}</div>
              )}
            </FormGroup>
          </div>

          <div className="text-end border-top py-3">
            <Button
              color="link"
              className="closeBtn"
              onClick={() => _closeModal()}
            >
              Cancel
            </Button>
            <Button type="submit" color="primary" disabled={loading}>
              {loading ? <i className="fa fa-spinner fa-spin mr-2" /> : null}{" "}
              Reset Password
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ResetUserPasswordModal;
