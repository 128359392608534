import React from "react";
import { Button } from "reactstrap";
import {
  formatCurrencyValue,
  formatNumberInComma,
  formatNumberInShort,
} from "../helper-methods";
import CustomTooltip from "./custom/CustomTooltip";
import SvgIcons from "./SvgIcons";
import { APP_NAME } from "../config";

const LeadEstimatedEarning = ({
  data,
  _toggleEditEarningsModal,
  _sendEmailPayout,
  sendEmailPayoutLoading,
}) => {
  return (
    <div className="billingSummaryWrap">
      <div className="cardHeader d-flex justify-content-between">
        Estimated Earnings
        <Button
          className="editBtn"
          color="link"
          onClick={() => _toggleEditEarningsModal(true, data)}
        >
          <i className="fas fa-pencil-alt" />
        </Button>
      </div>

      <div className="billingSummary">
        <ul>
          <li>
            <div className="content">
              <h3>Qualified Employees</h3>
              <h2 className="mb-0" id="lead_detail_page_qualified_employees">
                {formatNumberInShort(data?.qualifiedEmployees || 0)}
              </h2>
            </div>
            {data?.qualifiedEmployees ? (
              <CustomTooltip
                text={formatNumberInComma(data?.qualifiedEmployees || 0)}
                target="lead_detail_page_qualified_employees"
              />
            ) : null}
            <div className="empIcon">
              <SvgIcons type={"Qualified"} />
            </div>
          </li>

          <li>
            <div className="content">
              <h3>{APP_NAME} Comission</h3>
              <h2 className="mb-0 text-success">
                {formatCurrencyValue(
                  data?.estimateEarning?.companyComission || 0
                )}{" "}
                PEPM
              </h2>
            </div>
            <div className="empIcon">
              <SvgIcons type={"Xbenefits"} />
            </div>
          </li>

          {data?.estimateEarning?.leadReferComission?._affiliate?._id ? (
            <>
              <li>
                <div className="content">
                  <h3>
                    Affiliate Level 1{" "}
                    {data?.estimateEarning?.leadReferComission?._affiliate?.name
                      ?.full
                      ? `(${data?.estimateEarning?.leadReferComission?._affiliate?.name?.full?.trim()})`
                      : null}
                  </h3>
                  <h2 className="mb-0 text-warning">
                    {formatCurrencyValue(
                      data?.estimateEarning?.leadReferComission?.comission || 0
                    )}{" "}
                    PEPM
                  </h2>
                </div>
                <div className="empIcon">
                  <SvgIcons type={"Affiliate"} />
                </div>
              </li>
            </>
          ) : null}

          {data?.estimateEarning?.affiliatesReferComission?._affiliate?._id ? (
            <>
              <li>
                <div className="content">
                  <h3>
                    Affiliate Level 2{" "}
                    {data?.estimateEarning?.affiliatesReferComission?._affiliate
                      ?.name?.full
                      ? `(${data?.estimateEarning?.affiliatesReferComission?._affiliate?.name?.full?.trim()})`
                      : null}
                  </h3>
                  <h2 className="mb-0 text-warning">
                    {formatCurrencyValue(
                      data?.estimateEarning?.affiliatesReferComission
                        ?.comission || 0
                    )}{" "}
                    PEPM
                  </h2>
                </div>
                <div className="empIcon">
                  <SvgIcons type={"Affiliate"} />
                </div>
              </li>
            </>
          ) : null}

          {data?.estimateEarning?.firstLeaderComission?._affiliate?._id ? (
            <>
              <li>
                <div className="content">
                  <h3>
                    Primary Leader <br />
                    {data?.estimateEarning?.firstLeaderComission?._affiliate
                      ?.name?.full
                      ? `(${data?.estimateEarning?.firstLeaderComission?._affiliate?.name?.full?.trim()})`
                      : null}
                  </h3>
                  <h2 className="mb-0 text-warning">
                    {" "}
                    {formatCurrencyValue(
                      data?.estimateEarning?.firstLeaderComission?.comission ||
                        0
                    )}{" "}
                    PEPM
                  </h2>
                </div>
                <div className="empIcon">
                  <SvgIcons type={"Organization"} />
                </div>
              </li>
            </>
          ) : null}

          {data?.estimateEarning?.secondLeaderComission?._affiliate?._id ? (
            <>
              <li>
                <div className="content">
                  <h3>
                    Secondary Leader <br />
                    {data?.estimateEarning?.secondLeaderComission?._affiliate
                      ?.name?.full
                      ? `(${data?.estimateEarning?.secondLeaderComission?._affiliate?.name?.full?.trim()})`
                      : null}
                  </h3>
                  <h2 className="text-warning">
                    {" "}
                    {formatCurrencyValue(
                      data?.estimateEarning?.secondLeaderComission?.comission ||
                        0
                    )}{" "}
                    PEPM
                  </h2>
                </div>
                <div className="empIcon">
                  <SvgIcons type={"Organization"} />
                </div>
              </li>
            </>
          ) : null}
        </ul>
      </div>

      {data?.estimateEarning?.leadReferComission?._affiliate?._id ||
      data?.estimateEarning?.affiliatesReferComission?._affiliate?._id ? (
        <div className="text-center pt-3 pb-2">
          <Button
            style={{ whiteSpace: "nowrap" }}
            color="primary"
            onClick={() => _sendEmailPayout()}
            disabled={sendEmailPayoutLoading}
          >
            {sendEmailPayoutLoading ? (
              <i className="fa fa-spinner fa-spin" />
            ) : null}{" "}
            Send Email
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default LeadEstimatedEarning;
