import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Input,
  Collapse,
  Label,
  FormGroup,
  // InputGroupText,
  // InputGroup,
} from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import LeadPersonalInfoModal from "../../components/modals/LeadPersonalInfoModal";
import EditEarningsModal from "../../components/modals/EditEarningsModal";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, showLoader } from "../../redux/actions/loader-data";
import {
  getLeadById,
  updateLead,
  getAllActivites,
  // getAllDocuments,
  sendEmailPayout,
  generateLeadToken,
} from "../../http/http-calls";
import {
  copyToClipboard,
  errorHandler,
  // openUrlOnNewTab,
  showToast,
} from "../../helper-methods";
import { leadStatusConfig } from "../../config/helper-config";
// import LeadAddDocumentsModal from "../../components/modals/LeadAddDocumentsModal";
import AgreementModal from "../../components/modals/AgreementModal";
// import LeadDocuments from "../../components/LeadDocuments";
import { FRONTEND_BASE_URL } from "../../config";
import StatusUpdate from "../../components/StatusUpdate";
import { useMemo } from "react";
import LeadStatusNotes from "../../components/modals/LeadStatusNotes";
import LeadPersonalInfo from "../../components/LeadPersonalInfo";
import LeadQuestionnaires from "../../components/LeadQuestionnaires";
import LeadEstimatedEarning from "../../components/LeadEstimatedEarning";
import LeadMessagesTable from "../../components/LeadMessagesTable";
import SvgIcons from "../../components/SvgIcons";
import CustomTooltip from "../../components/custom/CustomTooltip";

const LeadDetailsPage = () => {
  const navigate = useNavigate();

  const params = useParams();

  const dispatch = useDispatch();

  const userCredential = useSelector((state) => state?.userCredential);

  const userType = useMemo(
    () => userCredential?.user?.userType?.toLowerCase(),
    [userCredential]
  );

  const affiliateType = useMemo(
    () => userCredential?.user?.affiliateType?.toLowerCase(),
    [userCredential]
  );

  const [data, setData] = useState(null);
  const [activities, setActivities] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [documents, setDocuments] = useState([]);

  // const [leadPayoutLoading, setLeadPayoutLoading] = useState(false);
  const [sendEmailPayoutLoading, setSendEmailPayoutLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);

  const [isOpenCollapse, setIsOpenCollapse] = useState(false);
  const [showStatus, setShowStatus] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [generateTokenLoading, setGenerateTokenLoading] = useState(false);

  const [editEarningsModal, setEditEarningsModal] = useState({ isOpen: false });
  const [leadPersonalInfoModal, setLeadPersonalInfoModal] = useState({
    isOpen: false,
  });

  const [agreementModal, setAgreementModal] = useState({
    isOpen: false,
    data: null,
    templateType: null,
  });
  const [leadStatusNotes, setLeadStatusNotes] = useState({
    isOpen: false,
    data: null,
  });
  // const [leadAddDocumentsModal, setLeadAddDocumentsModal] = useState({
  //   isOpen: false,
  // });

  const statusTimeLineFiltered = useMemo(() => {
    if (!data?.statusTimeLine?.length) return [];

    let statusTimeLine = data.statusTimeLine.reduce((acc, each) => {
      const findIndex = acc.findIndex((e) => e.status === each.status);

      if (findIndex > -1) {
        acc.splice(findIndex, 1);
      }

      acc.push(each);

      return acc;
    }, []);

    statusTimeLine = statusTimeLine.sort((a, b) =>
      new Date(a.updateAt) < new Date(b.updateAt) ? -1 : 1
    );

    const getCurrentIndex = (status) => {
      return leadStatusConfig.findIndex((each) => each.value === status);
    };

    const currentIndex = getCurrentIndex(data?.leadsProjectStatus);

    statusTimeLine = statusTimeLine.filter((each, index) => {
      const statusIndex = getCurrentIndex(each?.status);

      if (statusIndex <= currentIndex) return true;

      return false;
    });

    return statusTimeLine;
  }, [data?.leadsProjectStatus, data?.statusTimeLine]);

  const _toggleEditEarningsModal = (isOpen = false, data = null) => {
    setEditEarningsModal({ isOpen, data });
  };

  const _toggleLeadPersonalInfoModal = (isOpen = false, data = null) => {
    setLeadPersonalInfoModal({ isOpen, data });
  };

  const _toggleLeadStatusNotes = (
    isOpen = false,
    data = null,
    isUpdateNote = false
  ) => {
    setLeadStatusNotes({ isOpen, data, isUpdateNote });
  };

  // const _toggleLeadAddDocumentsModal = (isOpen = false, data = null) => {
  //   setLeadAddDocumentsModal({ isOpen, data });
  // };

  const _toggleAgreementModal = (
    isOpen = false,
    data = null,
    templateType = null
  ) => {
    setAgreementModal({ isOpen, data, templateType });
  };
  const _toggleCollapse = () => {
    setIsOpenCollapse((prev) => !prev);
  };
  const _toggleStatus = () => {
    setShowStatus((prev) => !prev);
  };

  // const _onChangeLeadPayout = (value) => {
  //   if (
  //     isNaN(value) ||
  //     (value.includes(".") && value.split(".")[1].length > 2) ||
  //     value.length > 16 ||
  //     Number(value) < 0
  //   )
  //     return;

  //   const newData = { ...data };
  //   newData.leadPayout = value;
  //   setData(newData);
  // };

  // const _updateLeadPayout = async () => {
  //   try {
  //     setLeadPayoutLoading(true);
  //     const { lead } = await updateLead({
  //       id: data?._id,
  //       payload: { leadPayout: Number(data?.leadPayout) || "" },
  //     });
  //     setData(lead);

  //     showToast("Payout has been updated", "success");
  //     setLeadPayoutLoading(false);
  //   } catch (error) {
  //     setLeadPayoutLoading(false);
  //     errorHandler(error);
  //   }
  // };

  // const _getAllDocuments = () => {
  //   getAllDocuments(params?.id)
  //     .then((res) => {
  //       setDocuments(res.documents?.length ? res.documents : []);
  //     })
  //     .catch((error) => {
  //       errorHandler(error);
  //     });
  // };

  const _getAllActivites = async () => {
    try {
      const payload = {
        userId: params?.id,
        userType: "Lead", // enum=["Lead", "Affiliate"]
      };

      const res = await getAllActivites(payload);

      setActivities(res.activities?.length ? res.activities : []);
    } catch (error) {
      errorHandler(error);
    }
  };

  const _getLeadById = async () => {
    try {
      dispatch(showLoader("Fetching..."));

      const res = await getLeadById(params?.id);

      setData(res.lead);

      dispatch(hideLoader());
    } catch (error) {
      errorHandler(error);
      dispatch(hideLoader());
      // navigate(-1);
    }
  };

  const _onChangeStatus = async (user, status, key) => {
    try {
      if (!user?._id) {
        errorHandler({ reason: "User data not found" });
        return;
      }

      // if (key === "leadsProjectStatus") {
      //   _toggleLeadStatusNotes(true, { ...user, leadsProjectStatus: status });
      //   return;
      // }

      setStatusLoading(true);

      const payload = {
        [key]: status,
      };

      if (key === "isStucked") {
        payload.isStucked = status === "Yes" ? true : false;
      }

      const newData = { ...data };
      newData[key] = payload[key];
      setData(newData);

      await updateLead({ id: user._id, payload });

      setStatusLoading(false);
      showToast("Status has been updated", "success");
      _getLeadById();
    } catch (error) {
      errorHandler(error);
      setStatusLoading(false);
      _getLeadById();
    }
  };

  // eslint-disable-next-line no-unused-vars
  const _generateLeadToken = async (id) => {
    try {
      setGenerateTokenLoading(true);
      const { token } = await generateLeadToken(id);
      if (!token) {
        errorHandler();
        setGenerateTokenLoading(false);
        return;
      }

      copyToClipboard(
        `${FRONTEND_BASE_URL}/client-document-upload?token=${token}`
      );

      setGenerateTokenLoading(false);
    } catch (error) {
      errorHandler(error);
      setGenerateTokenLoading(false);
    }
  };

  const _sendEmailPayout = async () => {
    try {
      setSendEmailPayoutLoading(true);
      await sendEmailPayout(params?.id);

      showToast("Email has been sent", "success");
      setSendEmailPayoutLoading(false);
    } catch (error) {
      setSendEmailPayoutLoading(false);
      errorHandler(error);
    }
  };

  useEffect(() => {
    _getLeadById();
    _getAllActivites();
    // _getAllDocuments();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="pgTitleWrap align-items-center">
        <div className="d-flex align-items-center">
          <Button color="link" className="backBtn" onClick={() => navigate(-1)}>
            <i className="fas fa-chevron-left" />
          </Button>
          <h2>Client Details</h2>
        </div>

        <div className="d-flex align-items-end">
          {(userType === "admin" || affiliateType === "leader") && (
            <>
              <FormGroup className="me-2">
                <Label>Stuck</Label>
                <Input
                  type="select"
                  style={{ width: 150, height: "auto" }}
                  disabled={statusLoading ? true : false}
                  value={data?.isStucked ? "Yes" : "No"}
                  onChange={(e) =>
                    _onChangeStatus(data, e.target.value, "isStucked")
                  }
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Input>
              </FormGroup>

              <div className="d-flex">
                <FormGroup>
                  <Label>
                    Status{" "}
                    {leadStatusConfig.find(
                      (each) => each.value === data?.leadsProjectStatus
                    )?.description ? (
                      <>
                        <sup>
                          <SvgIcons
                            type={"info"}
                            id={`status_description_lead_details_page`}
                          />
                        </sup>
                        <CustomTooltip
                          text={
                            leadStatusConfig.find(
                              (each) => each.value === data?.leadsProjectStatus
                            )?.description
                          }
                          target={`status_description_lead_details_page`}
                        />
                      </>
                    ) : null}
                  </Label>
                  <Input
                    type="select"
                    style={{ width: 150, height: "auto" }}
                    disabled={statusLoading ? true : false}
                    value={data?.leadsProjectStatus || ""}
                    onChange={(e) =>
                      _onChangeStatus(
                        data,
                        e.target.value,
                        "leadsProjectStatus"
                      )
                    }
                  >
                    {leadStatusConfig.map((each) => (
                      <>
                        <option key={each.value} value={each.value}>
                          {each.label}
                        </option>
                      </>
                    ))}
                  </Input>
                </FormGroup>

                <Button
                  color="link"
                  onClick={() => _toggleLeadStatusNotes(true, data, true)}
                >
                  <i className="fas fa-pencil-alt" />
                </Button>
              </div>
            </>
          )}
          {/* {data?.agreement?.status === "Pending" ||
          data?.agreement?.status === "Not Sent" ? ( // enum: ["Signed", "Pending", "Not Sent"]
            <Button
              color="primary"
              className="text-nowrap"
              onClick={() =>
                _toggleAgreementModal(
                  true,
                  data,
                  data?.agreement?.status === "Pending"
                    ? "Client Agreement Reminder"
                    : "Client Agreement"
                )
              }
            >
              Send Agreement{" "}
              {data?.agreement?.status === "Pending" ? "Reminder" : ""}
            </Button>
          ) : null} */}
          {/* {data?.docStatus !== "Completed" ? (
            <Button
              color="primary"
              className="text-nowrap"
              onClick={() =>
                _toggleAgreementModal(
                  true,
                  data,
                  data?.docStatus !== "Pending"
                    ? "Documents Reminder"
                    : "Documents"
                )
              }
            >
              {data?.docStatus !== "Pending"
                ? "Send Documents Reminder"
                : "Request Documents"}
            </Button>
          ) : null} */}
        </div>
      </div>

      <Row>
        <Col lg="6">
          <LeadPersonalInfo
            data={data}
            userType={userType}
            affiliateType={affiliateType}
            _toggleLeadPersonalInfoModal={_toggleLeadPersonalInfoModal}
          />
        </Col>

        <Col lg={6}>
          <LeadQuestionnaires
            data={data}
            isOpenCollapse={isOpenCollapse}
            _toggleCollapse={_toggleCollapse}
          />
        </Col>

        <Col lg={12}>
          <div className="cardWrap questionnaire">
            <div
              className={`customAccordionHead ${
                showStatus ? "customAccordionHeadActive" : ""
              }`}
              onClick={() => _toggleStatus()}
            >
              <div className="px-1">Status</div>
              <i className="fas fa-chevron-down toggleAccordion" />
            </div>

            <Collapse isOpen={showStatus} className="customAccordionBody">
              <StatusUpdate
                leadsProjectStatus={data?.leadsProjectStatus}
                statusTimeLine={statusTimeLineFiltered}
              />
            </Collapse>
          </div>
        </Col>

        {(userType === "admin" || affiliateType === "leader") && (
          <Col lg="12">
            <LeadEstimatedEarning
              data={data}
              _toggleEditEarningsModal={_toggleEditEarningsModal}
              _sendEmailPayout={_sendEmailPayout}
              sendEmailPayoutLoading={sendEmailPayoutLoading}
            />

            {/* <div className="billingSummaryWrap">
            <div className="cardHeader d-flex justify-content-between">
              Actual Earnings
            </div>
            <div className="billingSummary">
              <div>
                <p>Payout Amount</p>
                <InputGroup className="searchTable">
                  <InputGroupText>$</InputGroupText>
                  <Input
                    type="text"
                    bsSize="sm"
                    value={data?.leadPayout ? data.leadPayout.toString() : ""}
                    onChange={(e) => _onChangeLeadPayout(e.target.value)}
                  />
                </InputGroup>

                <Button
                  color="link"
                  className="px-0 fs-12 mt-2"
                  disabled={leadPayoutLoading}
                  onClick={() => _updateLeadPayout()}
                >
                  {leadPayoutLoading ? (
                    <i className="fa fa-spinner fa-spin" />
                  ) : null}{" "}
                  Update
                </Button>
              </div>

              <div>
                <p>ERC Comission</p>
                <h6 className="mb-0 text-success">
                  {formatCurrencyValue(data?.ercPayout || 0)}
                </h6>
              </div>

              {data?.estimateEarning?.leadReferComission?._affiliate?._id ? (
                <div>
                  <p>
                    Affiliate Level 1{" "}
                    {data?.estimateEarning?.leadReferComission?._affiliate?.name
                      ?.full
                      ? `(${data?.estimateEarning?.leadReferComission?._affiliate?.name?.full?.trim()})`
                      : null}
                  </p>
                  <h6 className="mb-0 text-warning">
                    {formatCurrencyValue(data?.leadReferPayout || 0)}
                  </h6>
                </div>
              ) : null}

              {data?.estimateEarning?.affiliatesReferComission?._affiliate
                ?._id ? (
                <div>
                  <p>
                    Affiliate Level 2{" "}
                    {data?.estimateEarning?.affiliatesReferComission?._affiliate
                      ?.name?.full
                      ? `(${data?.estimateEarning?.affiliatesReferComission?._affiliate?.name?.full?.trim()})`
                      : null}
                  </p>
                  <h6 className="mb-0 text-warning">
                    {formatCurrencyValue(data?.affiliateReferPayout || 0)}
                  </h6>
                </div>
              ) : null}

              {data?.estimateEarning?.leadReferComission?._affiliate?._id ||
              data?.estimateEarning?.affiliatesReferComission?._affiliate
                ?._id ? (
                <div className="justify-content-center">
                  <Button
                    color="primary"
                    onClick={() => _sendEmailPayout()}
                    disabled={sendEmailPayoutLoading}
                  >
                    {sendEmailPayoutLoading ? (
                      <i className="fa fa-spinner fa-spin" />
                    ) : null}{" "}
                    Send Email
                  </Button>
                </div>
              ) : null}
            </div>
          </div> */}

            {/* <div className="tableInCardWrap lead-documents">
            <div className="tableCardHeader">
              Documents
              <Button onClick={() => _toggleLeadAddDocumentsModal(true, data)}>
                Add New
              </Button>
            </div>
            <div className="tableWrapper">
              <Table responsive>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th className="text-end">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <LeadDocuments
                    documents={documents}
                    getAllDocuments={() => _getAllDocuments()}
                  />
                </tbody>
              </Table>
            </div>
          </div> */}
          </Col>
        )}

        {(userType === "admin" || affiliateType === "leader") && (
          <Col lg={12}>
            <LeadMessagesTable
              activities={activities}
              userType={userType}
              data={data}
            />
          </Col>
        )}
      </Row>

      <EditEarningsModal
        isOpen={editEarningsModal.isOpen}
        data={editEarningsModal.data}
        toggle={() => _toggleEditEarningsModal()}
        onSuccess={() => _getLeadById()}
      />
      <LeadPersonalInfoModal
        isOpen={leadPersonalInfoModal.isOpen}
        data={leadPersonalInfoModal.data}
        toggle={() => _toggleLeadPersonalInfoModal()}
        onSuccess={() => _getLeadById()}
      />
      {/* <LeadAddDocumentsModal
        isOpen={leadAddDocumentsModal.isOpen}
        data={leadAddDocumentsModal.data}
        toggle={() => _toggleLeadAddDocumentsModal()}
        onSuccess={() => _getAllDocuments()}
      /> */}
      <AgreementModal
        isOpen={agreementModal.isOpen}
        data={agreementModal.data}
        templateType={agreementModal.templateType}
        toggle={() => _toggleAgreementModal()}
        onSuccess={() => _getAllActivites()}
      />
      <LeadStatusNotes
        isOpen={leadStatusNotes.isOpen}
        data={leadStatusNotes.data}
        isUpdateNote={leadStatusNotes.isUpdateNote}
        toggle={() => _toggleLeadStatusNotes()}
        onSuccess={() => _getLeadById()}
      />
    </>
  );
};

export default LeadDetailsPage;
