import React, { Fragment, useEffect, useState, useMemo } from "react";
import {
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  Button,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Badge,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { getAffiliateInfo } from "../../http/http-calls";
import {
  capitalize,
  copyToClipboard,
  errorHandler,
  formatCurrencyValue,
  formatDate,
  formatDateMonthYear,
  getLeadsProjectStatusLabel,
  openUrlInSameTab,
  showToast,
} from "../../helper-methods";
import { useDispatch } from "react-redux";
import { showLoader, hideLoader } from "../../redux/actions/loader-data";
import AffiliatesClientsModal from "../../components/modals/AffiliatesClientsModal";
import LeadNotesModal from "../../components/modals/LeadNotesModal";
import AddUsersModal from "../../components/modals/AddUsersModal";
import { affiliateTypeConfig } from "../../config/helper-config";
import { useSelector } from "react-redux";
import ReferralCodeAndLinkList from "../../components/ReferralCodeAndLinkList";
import QuizTestModal from "../../components/modals/QuizTestModal";
import CustomTooltip from "../../components/custom/CustomTooltip";
import SvgIcons from "../../components/SvgIcons";
import ResubmitSignatureComponent from "../../components/ResubmitSignatureComponent";
import { updateUserData } from "../../redux/actions/user-credential";

const AffiliateHomePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [openSecondaryAccordian, setOpenSecondaryAccordian] = useState("");

  const _toggleSecondaryAccordian = (id = "") => {
    if (openSecondaryAccordian === id) {
      setOpenSecondaryAccordian("");
    } else {
      setOpenSecondaryAccordian(id);
    }
  };

  const userCredential = useSelector((state) => state?.userCredential);

  const userType = useMemo(
    () => userCredential?.user?.userType?.toLowerCase(),
    [userCredential]
  );

  const [data, setData] = useState(null);
  const [leadRefer, setLeadRefer] = useState([]);
  const [affiliateRefer, setAffiliateRefer] = useState([]);
  const [affiliatesClientsModal, setAffiliatesClientsModal] = useState({
    isOpen: false,
    data: null,
  });

  const [leadNotesModal, setLeadNotesModal] = useState({
    isOpen: false,
    data: null,
  });
  const [addUsersModal, setAddUsersModal] = useState({
    isOpen: false,
    data: null,
    heading: null,
    primaryLeaderData: null,
  });

  const [quizTestModal, setQuizTestModal] = useState({
    isOpen: false,
    data: null,
  });

  const isPrimaryLeader = useMemo(() => {
    return userCredential?.user?.id === data?._organization?._primaryLeader?.id;
  }, [userCredential, data]);

  const _toggleQuizTestModal = (isOpen = false, data = null) => {
    setQuizTestModal({ isOpen, data });
  };

  const _toggleAffiliatesClientsModal = (isOpen = false, data = null) => {
    setAffiliatesClientsModal({ isOpen, data });
  };

  const _toggleLeadNotesModal = (isOpen = false, data = null) => {
    setLeadNotesModal({ isOpen, data });
  };

  const _toggleAddUsersModal = (
    isOpen = false,
    data = null,
    heading = null,
    primaryLeaderData = null
  ) => {
    setAddUsersModal({ isOpen, data, heading, primaryLeaderData });
  };

  const _getAffiliateInfo = async () => {
    try {
      dispatch(showLoader("Fetching..."));

      const res = await getAffiliateInfo();

      setData(res.user);
      setLeadRefer(res.user?.referredClients);
      setAffiliateRefer(res.user?.referredAffiliate);
      dispatch(
        updateUserData({
          user: userCredential?.user,
          notificationCount: userCredential?.notificationCount,
          showHeader: res.user?.isVerified || res?.user?.completedAllTopic,
        })
      );

      if (
        res?.user?.isVerified &&
        // res?.user?.isAddedByForm &&
        res?.user?.isAcceptForSignature &&
        res?.user?.affiliateType !== "Leader" &&
        res?.user?.isVerifiedForDocuSign
      ) {
        if (res?.user?.stageOfSign === "noSigned") {
          openUrlInSameTab(
            `/docu-sign-accept-agreement/${userCredential?.token}`
          );
        } else if (res?.user?.stageOfSign !== "combineAchw9Signed") {
          localStorage.temp_token = userCredential?.token;
          openUrlInSameTab(
            `/agreementsigned/${userCredential?.user?._id}?event=signing_complete`
          );
        }
      } else {
        if (
          res?.user?.isVerified &&
          res?.user?.stageOfSign !== "combineAchw9Signed" &&
          res?.user?.affiliateType === "Leader" &&
          res?.user?.isVerifiedForDocuSign
        ) {
          localStorage.temp_token = userCredential?.token;
          openUrlInSameTab(
            `/agreementsigned/${userCredential?.user?._id}?event=signing_complete`
          );
        }
      }

      if (res?.user?.isVerified && !res?.user?.isAddedByForm) {
        dispatch(
          updateUserData({
            user: userCredential?.user,
            notificationCount: userCredential?.notificationCount,
            showHeader: true,
          })
        );
      }

      dispatch(hideLoader());
    } catch (error) {
      errorHandler(error);
      dispatch(hideLoader());
    }
  };

  const _checkLeaderTypeCommission = (each) => {
    switch (data?.leaderType) {
      case "Primary": {
        return (
          (each?._client?.estimateEarning?.firstLeaderComission?.comission ||
            0) * (each?._client?.qualifiedEmployees || 0)
        );
      }
      case "Secondary": {
        return (
          (each?._client?.estimateEarning?.secondLeaderComission?.comission ||
            0) * (each?._client?.qualifiedEmployees || 0)
        );
      }
      default: {
        return (
          (each?._client?.estimateEarning?.leadReferComission?.comission || 0) *
          (each?._client?.qualifiedEmployees || 0)
        );
      }
    }
  };

  const _calculateTotalCommission = (array = [], key = "") => {
    if (!array?.length || !key) return 0;

    return array.reduce((acc, each) => {
      if (
        each?._client?.estimateEarning?.[key]?.comission &&
        +each?._client?.estimateEarning?.[key]?.comission
      )
        acc += +each?._client?.estimateEarning?.[key]?.comission;

      return acc;
    }, 0);
  };

  const _calculateTotalAffiliatesPayout = (array = [], key = "") => {
    key =
      data?.leaderType === "Primary"
        ? "firstLeaderComission"
        : data?.leaderType === "Secondary"
        ? "secondLeaderComission"
        : key;

    if (!array?.length || !key) return 0;

    return array.reduce((acc, each) => {
      if (each?._affiliate?.referredClients?.length)
        acc += _calculateTotalClientsPayout(
          each?._affiliate?.referredClients,
          key
        );

      return acc;
    }, 0);
  };

  const _calculateTotalClientsPayout = (array = [], key = "") => {
    key =
      data?.leaderType === "Primary"
        ? "firstLeaderComission"
        : data?.leaderType === "Secondary"
        ? "secondLeaderComission"
        : key;

    if (!array?.length || !key) return 0;

    return array.reduce((acc, each) => {
      if (
        each?._client?.estimateEarning?.[key]?.comission &&
        +each?._client?.estimateEarning?.[key]?.comission
      )
        acc +=
          +each?._client?.estimateEarning?.[key]?.comission *
          +each?._client?.qualifiedEmployees;

      return acc;
    }, 0);
  };

  useEffect(() => {
    _getAffiliateInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="pgTitleWrap">
        <h2>Affiliate Home</h2>
      </div>

      <Row>
        <Col lg="6">
          <div className="cardWrap personalInfo">
            <div className="cardHeader d-flex justify-content-between">
              Personal Info
              <Button
                className="editBtn"
                color="link"
                onClick={() =>
                  _toggleAddUsersModal(true, data, "Update Personal Info")
                }
              >
                <i className="fas fa-pencil-alt" />
              </Button>
            </div>

            <div className="cardBody">
              <h6 className="heading">{data?.name?.full || "N/A"}</h6>
              <div className="contactInfo">
                <ul>
                  <li>
                    Verified :{" "}
                    <div className="ms-1">
                      {data?.isVerified ? "Yes" : "No"}
                    </div>
                  </li>
                  <li>
                    Referred By :{" "}
                    {data?.isAddedByAdmin
                      ? "Admin"
                      : data?._referBy?.name?.full
                      ? capitalize(data?._referBy?.name?.full)
                      : "N/A"}
                  </li>
                  {userCredential?.user?.affiliateType === "Leader" ? (
                    <>
                      <li>
                        {data?.affiliateType === "Leader"
                          ? "Organization Commission"
                          : "PEPM Commission"}{" "}
                        : {formatCurrencyValue(data?.commissionLevel1 || 0)}
                      </li>
                      {data?.affiliateType !== "Leader" ? (
                        <li>
                          Override :{" "}
                          {formatCurrencyValue(data?.commissionLevel2 || 0)}
                        </li>
                      ) : null}
                      <li>
                        Organization Name : {data?.organizationName || "N/A"}
                      </li>
                      <li>
                        Type :{" "}
                        {affiliateTypeConfig.find(
                          (each) => each.value === data?.affiliateType
                        )?.label || "N/A"}
                      </li>
                    </>
                  ) : (
                    <>
                      {/* <li>
                        Primary Leader :{" "}
                        {data?._organization?._primaryLeader?.name?.full ||
                          "N/A"}
                      </li>
                      {data?._organization?._secondaryLeader?.length && (
                        <li>
                          Secondary Leaders:{" "}
                          {Array.from(
                            new Set(
                              data?._organization?._secondaryLeader.map(
                                (data) => data?.name?.full
                              )
                            )
                          ).join(", ")}
                        </li>
                      )} */}
                    </>
                  )}
                  {/* <li>
                    <img
                      className="telegramIcon"
                      src={require("../../assets/img/telegram.png")}
                      alt="icon"
                      onClick={() =>
                        window.open("https://t.me/+97lWkZe6IfJhOWQx")
                      }
                    />{" "}
                    <span
                      className="text-primary cursorPointer"
                      onClick={() =>
                        window.open("https://t.me/+97lWkZe6IfJhOWQx")
                      }
                    >
                      Join Community
                    </span>
                  </li> */}
                </ul>
                <ul className="right">
                  <li>
                    <i className="fas fa-phone-alt" />
                    <span
                      className="text-overflow"
                      onClick={() =>
                        copyToClipboard(
                          `${data?.countryCode || ""}${data?.phone}`
                        )
                      }
                    >
                      {data?.countryCode} {data?.phone || "N/A"}
                    </span>
                  </li>
                  <li>
                    <i className="far fa-envelope" />
                    <span
                      className="value"
                      onClick={() => copyToClipboard(data.email)}
                    >
                      {data?.email || "N/A"}
                    </span>
                  </li>
                  <li>
                    <i className="fas fa-map-marker-alt" />
                    <span>{data?.location || "N/A"}</span>
                  </li>

                  <ReferralCodeAndLinkList data={data} />
                </ul>
              </div>

              {/* <div className="d-flex">
                  <p className="mb-0 fs-14">
                    Agreement :{" "}
                    {data?.agreement?.isSigned ? "Signed" : "Pending"}
                  </p>
                  {data?.agreement?.url && (
                    <Button
                      color="link"
                      className="ms-2 px-1"
                      onClick={() => openUrlOnNewTab(data.agreement.url)}
                    >
                      View
                    </Button>
                  )}
                </div> */}
            </div>
          </div>
          {userCredential?.user?.affiliateType === "Leader" ? null : (
            <div className="cardWrap quizTestCard">
              <div className="cardHeader">Quiz</div>
              <div className="cardBody ">
                <>
                  {data?.hasClearedQuiz ? (
                    <>
                      <SvgIcons type={"success"} id="success" />
                      <h3>Quiz Score</h3>

                      <div className="percentage">
                        <span>{data?.quizPercentageScored}%</span>
                        <Badge color="success">Pass</Badge>
                      </div>
                    </>
                  ) : (
                    <>
                      {data?.failedInQuiz ? (
                        <>
                          <SvgIcons type={"filed"} id="filed" />
                          <h3>Quiz Score</h3>
                          <div className="percentage">
                            <span>{data?.quizPercentageScored}%</span>{" "}
                            <Badge color="danger">Fail</Badge>
                          </div>
                          {data?.failedInQuiz < 2 ? (
                            <div>1 Attempt left</div>
                          ) : null}
                        </>
                      ) : null}

                      {data?.failedInQuiz < 2 ? (
                        <>
                          <Button
                            color="primary"
                            id="user_home_page_take_test_button"
                            onClick={() => {
                              data?.completedAllTopic
                                ? _toggleQuizTestModal(true, data)
                                : showToast(
                                    "Before proceeding to the test, ensure the completion of your training.",
                                    "error"
                                  );
                            }}
                          >
                            {data?.failedInQuiz ? "Reattempt" : "Start quiz"}
                          </Button>

                          <CustomTooltip
                            target="user_home_page_take_test_button"
                            text={
                              data?.completedAllTopic
                                ? `You are now eligible to proceed with the Quiz. ${
                                    data?.failedInQuiz
                                      ? "Please note that this is your final attempt."
                                      : ""
                                  }`
                                : "Before proceeding to the test, ensure the completion of your training."
                            }
                          />
                        </>
                      ) : null}
                    </>
                  )}
                </>
              </div>
            </div>
          )}
        </Col>

        {userCredential?.user?.affiliateType === "Leader" &&
          (isPrimaryLeader ? (
            <>
              <Col lg="6">
                <div className="cardWrap personalInfo">
                  <div className="cardHeader d-flex justify-content-between">
                    Secondary Leader
                    <Button
                      className="editBtn"
                      color="link"
                      // onClick={() =>
                      //   _toggleAddUsersModal(
                      //     true,
                      //     data?._organization?._secondaryLeader?.email
                      //       ? data?._organization?._secondaryLeader
                      //       : null,
                      //     data?._organization?._secondaryLeader?.email
                      //       ? "Update Secondary Leader"
                      //       : "Add Secondary Leader",
                      //     data?._organization?._secondaryLeader?.email
                      //       ? null
                      //       : data
                      //   )
                      // }
                      onClick={() =>
                        _toggleAddUsersModal(
                          true,
                          null,
                          "Add Secondary Leader",
                          data
                        )
                      }
                    >
                      {/* {data?._organization?._secondaryLeader?.email ? (
                        <i className="fas fa-pencil-alt" />
                      ) : ( */}
                      <i className="fas fa-plus" />
                      {/* )} */}
                    </Button>
                  </div>

                  <div className="cardBody">
                    {data?._organization?._secondaryLeader?.length ? (
                      data?._organization?._secondaryLeader?.map(
                        (each, index) => (
                          <Fragment key={each?._id + index}>
                            <Accordion
                              open={openSecondaryAccordian}
                              toggle={_toggleSecondaryAccordian}
                              key={index}
                            >
                              <AccordionItem>
                                <AccordionHeader targetId={`${each?.id}`}>
                                  {each?.name?.full || "N/A"}
                                </AccordionHeader>
                                <AccordionBody accordionId={`${each?.id}`}>
                                  <div className="text-end">
                                    <Button
                                      color="link"
                                      className="editBtn"
                                      onClick={() =>
                                        _toggleAddUsersModal(
                                          true,
                                          each,
                                          "Update Secondary Leader",
                                          null
                                        )
                                      }
                                    >
                                      <i className="fas fa-pencil-alt"></i>
                                    </Button>
                                  </div>
                                  <div className="contactInfo">
                                    <ul>
                                      <li>
                                        Referred By :{" "}
                                        {each?.isAddedByAdmin
                                          ? "Admin"
                                          : each?._referBy?.name?.full
                                          ? capitalize(
                                              each?._referBy?.name?.full
                                            )
                                          : "N/A"}
                                      </li>
                                      <li>
                                        Organization Name :{" "}
                                        {each?.organizationName || "N/A"}
                                      </li>
                                      <li>
                                        Type :{" "}
                                        {affiliateTypeConfig.find(
                                          (e) => e.value === each?.affiliateType
                                        )?.label || "N/A"}
                                      </li>
                                    </ul>
                                    <ul className="right">
                                      <li>
                                        <i className="fas fa-phone-alt" />
                                        <span
                                          className="text-overflow"
                                          onClick={() =>
                                            copyToClipboard(each?.phone)
                                          }
                                        >
                                          {each?.countryCode}{" "}
                                          {each?.phone || "N/A"}
                                        </span>
                                      </li>
                                      <li>
                                        <i className="far fa-envelope" />
                                        <span
                                          className="value"
                                          onClick={() =>
                                            copyToClipboard(each?.email)
                                          }
                                        >
                                          {each?.email || "N/A"}
                                        </span>
                                      </li>
                                      <li>
                                        <i className="fas fa-map-marker-alt" />
                                        <span>{each?.location || "N/A"}</span>
                                      </li>

                                      <ReferralCodeAndLinkList data={each} />
                                    </ul>
                                  </div>
                                </AccordionBody>
                              </AccordionItem>
                            </Accordion>
                            {/* <h6 className="heading">
                          {data?._organization?._secondaryLeader?.name?.full ||
                            "N/A"}
                        </h6> */}

                            {/* <div className="d-flex">
                          <p className="mb-0 fs-14">
                            Agreement :{" "}
                            {data?.agreement?.isSigned ? "Signed" : "Pending"}
                          </p>
                          {data?.agreement?.url && (
                            <Button
                              color="link"
                              className="ms-2 px-1"
                              onClick={() =>
                                openUrlOnNewTab(data.agreement.url)
                              }
                            >
                              View
                            </Button>
                          )}
                        </div> */}
                          </Fragment>
                        )
                      )
                    ) : (
                      <div className="nodata">
                        <img
                          src={require("../../assets/img/noData.svg").default}
                          alt="no data"
                        />
                        <p>There is no data to display</p>
                      </div>
                    )}
                  </div>
                </div>
              </Col>
            </>
          ) : (
            <>
              <Col lg="6">
                <div className="cardWrap personalInfo">
                  <div className="cardHeader d-flex justify-content-between">
                    Primary Leader
                  </div>

                  <div className="cardBody">
                    <h6 className="heading">
                      {data?._organization?._primaryLeader?.name?.full || "N/A"}
                    </h6>
                    <div className="contactInfo">
                      <ul>
                        <li>
                          Referred By :{" "}
                          {data?._organization?._primaryLeader?.isAddedByAdmin
                            ? "Admin"
                            : data?._organization?._primaryLeader?._referBy
                                ?.name?.full
                            ? capitalize(
                                data?._organization?._primaryLeader?._referBy
                                  ?.name?.full
                              )
                            : "N/A"}
                        </li>
                        <li>
                          Organization Name :{" "}
                          {data?._organization?._primaryLeader
                            ?.organizationName || "N/A"}
                        </li>
                        <li>
                          Type :{" "}
                          {affiliateTypeConfig.find(
                            (each) =>
                              each.value ===
                              data?._organization?._primaryLeader?.affiliateType
                          )?.label || "N/A"}
                        </li>
                      </ul>
                      <ul className="right">
                        <li>
                          <i className="fas fa-phone-alt" />
                          <span
                            className="text-overflow"
                            onClick={() =>
                              copyToClipboard(
                                `${
                                  data?._organization?._primaryLeader
                                    ?.countryCode || ""
                                }${data?._organization?._primaryLeader?.phone}`
                              )
                            }
                          >
                            {data?._organization?._primaryLeader?.countryCode}{" "}
                            {data?._organization?._primaryLeader?.phone ||
                              "N/A"}
                          </span>
                        </li>
                        <li>
                          <i className="far fa-envelope" />
                          <span
                            className="value"
                            onClick={() =>
                              copyToClipboard(
                                data?._organization?._primaryLeader?.email
                              )
                            }
                          >
                            {data?._organization?._primaryLeader?.email ||
                              "N/A"}
                          </span>
                        </li>
                        <li>
                          <i className="fas fa-map-marker-alt" />
                          <span>
                            {data?._organization?._primaryLeader?.location ||
                              "N/A"}
                          </span>
                        </li>

                        <ReferralCodeAndLinkList
                          data={data?._organization?._primaryLeader}
                        />
                      </ul>
                    </div>

                    {/* <div className="d-flex">
                  <p className="mb-0 fs-14">
                    Agreement :{" "}
                    {data?.agreement?.isSigned ? "Signed" : "Pending"}
                  </p>
                  {data?.agreement?.url && (
                    <Button
                      color="link"
                      className="ms-2 px-1"
                      onClick={() => openUrlOnNewTab(data.agreement.url)}
                    >
                      View
                    </Button>
                  )}
                </div> */}
                  </div>
                </div>
              </Col>
            </>
          ))}

        <Col lg="6">
          <div className="cardWrap questionnaire">
            <div className="cardHeader">
              <div>
                Questionnaire{" "}
                <img
                  src={`/assets/img/questionnaire.png`}
                  alt=""
                  width="18x"
                  className="ms-1"
                />
              </div>
            </div>
            <div className="cardBody quesAnsWrap">
              {data?.responses?.length
                ? data.responses.map((each, index) => (
                    <Fragment key={`responses_${index}`}>
                      <h5>
                        {index + 1}. {each.questionText}
                      </h5>
                      <p>{each.response}</p>
                    </Fragment>
                  ))
                : "There is no data to display."}
            </div>
          </div>
          {data?.achSignedPdf ||
          data?.agreementSignedPdf ||
          data?.w9SignedPdf ? (
            <ResubmitSignatureComponent data={data} pageName="home" />
          ) : null}
        </Col>

        <Col md={12}>
          <div className="tableInCardWrap">
            <div className="tableCardHeader">
              <h3>
                Referred Clients{" "}
                <img
                  src={`/assets/img/referred_clients.png`}
                  alt=""
                  width="16"
                  className="ms-1"
                />
              </h3>
              <h6>
                Estimated Earnings
                <span className="text-success">
                  {formatCurrencyValue(
                    _calculateTotalClientsPayout(
                      leadRefer,
                      "leadReferComission"
                    )
                  )}
                </span>
              </h6>
            </div>

            <div className="tableWrapper">
              <Table responsive>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Company Name</th>
                    <th>Date</th>
                    <th>Number of Employees</th>
                    <th>Qualified Month</th>
                    <th>Estimated Commissions</th>
                    {/* hide this for super affiliate */}
                    {userCredential?.user?.affiliateType === "Leader" && (
                      <>
                        <th>Leader 1 Comm.</th>
                        <th>Leader 2 Comm.</th>
                        <th>Level 1 Comm.</th>
                        <th>Level 2 Comm.</th>
                      </>
                    )}
                    <th>Notes</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {leadRefer?.length ? (
                    leadRefer.map((each) => (
                      <tr key={each?._id}>
                        <td className="text-capitalize">
                          {each?._client?.name?.full ? (
                            <div
                              className="themeColor cursorPointer"
                              onClick={() =>
                                navigate(
                                  `/${userType}/client/${each?._client?._id}`
                                )
                              }
                            >
                              {capitalize(each?._client?.name?.full)}
                            </div>
                          ) : (
                            "N/A"
                          )}

                          <div
                            className="d-flex text-dark cursorPointer"
                            style={{ marginTop: 7 }}
                          >
                            {each?._client?.phone && (
                              <>
                                <i
                                  className="fas fa-phone-alt fs-12"
                                  id={`user_phone_${each?._client?._id}`}
                                  onClick={() =>
                                    copyToClipboard(
                                      `${each?._client?.countryCode || ""}${
                                        each?._client?.phone
                                      }`
                                    )
                                  }
                                />
                                <UncontrolledTooltip
                                  target={`user_phone_${each?._client?._id}`}
                                  placement="bottom"
                                >
                                  {each?._client?.countryCode}{" "}
                                  {each?._client?.phone}
                                </UncontrolledTooltip>
                              </>
                            )}
                            {each?._client?.email && (
                              <>
                                <i
                                  className="far fa-envelope mx-3"
                                  id={`user_email_${each?._client?._id}`}
                                  onClick={() =>
                                    copyToClipboard(each?._client?.email)
                                  }
                                />
                                <UncontrolledTooltip
                                  target={`user_email_${each?._client?._id}`}
                                  placement="bottom"
                                >
                                  {each?._client?.email}
                                </UncontrolledTooltip>
                              </>
                            )}
                          </div>
                        </td>
                        <td>{each?._client?.companyName || "N/A"}</td>
                        <td>{formatDate(each?._client?.createdAt) || "N/A"}</td>
                        <td>{each?._client?.qualifiedEmployees}</td>
                        <td>
                          {formatDateMonthYear(each?._client?.qualifiedDate) ||
                            "N/A"}
                        </td>
                        <td>
                          {formatCurrencyValue(
                            _checkLeaderTypeCommission(each)
                          )}
                        </td>
                        {/* hide this for super affiliate */}
                        {userCredential?.user?.affiliateType === "Leader" && (
                          <>
                            <td>
                              {formatCurrencyValue(
                                each?._client?.estimateEarning
                                  ?.firstLeaderComission?.comission
                              )}{" "}
                              (PEPM)
                            </td>
                            <td>
                              {formatCurrencyValue(
                                each?._client?.estimateEarning
                                  ?.secondLeaderComission?.comission
                              )}{" "}
                              (PEPM)
                            </td>
                            <td>
                              {formatCurrencyValue(
                                each?._client?.estimateEarning
                                  ?.leadReferComission?.comission
                              )}{" "}
                              (PEPM)
                            </td>
                            <td>
                              {formatCurrencyValue(
                                each?._client?.estimateEarning
                                  ?.affiliatesReferComission?.comission
                              )}{" "}
                              (PEPM)
                            </td>
                          </>
                        )}
                        <td>
                          <Button
                            color="link"
                            className="me-1"
                            onClick={() => _toggleLeadNotesModal(true, each)}
                          >
                            <i
                              className="far fa-sticky-note me-1"
                              style={{ verticalAlign: -0.5 }}
                            />
                            {each._client?.notesCount || 0}
                          </Button>
                        </td>
                        <td>
                          {getLeadsProjectStatusLabel(
                            each?._client?.leadsProjectStatus
                          ) || "N/A"}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="text-center">
                      <td colSpan="8">
                        <div className="nodata">
                          <img
                            src={require("../../assets/img/noData.svg").default}
                            alt="no data"
                          />
                          <p>There is no data to display</p>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </Col>
        <Col md={12}>
          <div className="tableInCardWrap">
            <div className="tableCardHeader">
              <h3>
                Referred Affiliates{" "}
                <img
                  src={`/assets/img/people.png`}
                  alt=""
                  width="16"
                  className="ms-1"
                />
              </h3>
              <h6>
                Estimated Earnings
                <span className="text-success">
                  {formatCurrencyValue(
                    _calculateTotalAffiliatesPayout(
                      affiliateRefer,
                      "affiliatesReferComission"
                    )
                  )}
                </span>
              </h6>
            </div>

            <div className="tableWrapper">
              <Table responsive>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Date</th>
                    <th>Type</th>
                    <th>Estimated Commissions</th>
                    {/* hide this for super affiliate */}
                    {userCredential?.user?.affiliateType === "Leader" && (
                      <>
                        <th>Leader 1 Comm.</th>
                        <th>Leader 2 Comm.</th>
                        <th>Level 1 Comm.</th>
                        <th>Level 2 Comm.</th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {affiliateRefer?.length ? (
                    affiliateRefer.map((each) => (
                      <tr key={each?._id}>
                        <td className="text-capitalize">
                          {each?._affiliate?.referredClients?.length ? (
                            <div
                              className="themeColor cursorPointer"
                              onClick={() =>
                                _toggleAffiliatesClientsModal(
                                  true,
                                  each?._affiliate?.referredClients
                                )
                              }
                            >
                              {capitalize(each?._affiliate?.name?.full)}
                            </div>
                          ) : (
                            capitalize(each?._affiliate?.name?.full) || "N/A"
                          )}

                          <div
                            className="d-flex text-dark cursorPointer"
                            style={{ marginTop: 7 }}
                          >
                            {each._affiliate?.phone && (
                              <>
                                <i
                                  className="fas fa-phone-alt fs-12"
                                  id={`affiliate_home_page_user_phone_${each._id}`}
                                  onClick={() =>
                                    copyToClipboard(
                                      `${each._affiliate?.countryCode || ""}${
                                        each._affiliate?.phone
                                      }`
                                    )
                                  }
                                />
                                <UncontrolledTooltip
                                  target={`affiliate_home_page_user_phone_${each._id}`}
                                  placement="bottom"
                                >
                                  {each._affiliate?.countryCode}{" "}
                                  {each._affiliate?.phone}
                                </UncontrolledTooltip>
                              </>
                            )}
                            {each._affiliate?.email && (
                              <>
                                <i
                                  className="far fa-envelope mx-3"
                                  id={`affiliate_home_page_user_email_${each._id}`}
                                  onClick={() =>
                                    copyToClipboard(each._affiliate?.email)
                                  }
                                />
                                <UncontrolledTooltip
                                  target={`affiliate_home_page_user_email_${each._id}`}
                                  placement="bottom"
                                >
                                  {each._affiliate?.email}
                                </UncontrolledTooltip>
                              </>
                            )}
                          </div>
                        </td>
                        <td>{formatDate(each?._affiliate?.createdAt)}</td>
                        <td>
                          {affiliateTypeConfig.find(
                            (obj) =>
                              obj.value === each?._affiliate?.affiliateType
                          )?.label || "N/A"}
                        </td>
                        <td>
                          {formatCurrencyValue(
                            _calculateTotalClientsPayout(
                              each?._affiliate?.referredClients,
                              "affiliatesReferComission"
                            )
                          )}
                        </td>
                        {/* hide this for super affiliate */}
                        {userCredential?.user?.affiliateType === "Leader" && (
                          <>
                            <td>
                              {formatCurrencyValue(
                                _calculateTotalCommission(
                                  each?._affiliate?.referredClients,
                                  "firstLeaderComission"
                                )
                              )}{" "}
                              (PEPM)
                            </td>
                            <td>
                              {formatCurrencyValue(
                                _calculateTotalCommission(
                                  each?._affiliate?.referredClients,
                                  "secondLeaderComission"
                                )
                              )}{" "}
                              (PEPM)
                            </td>
                            <td>
                              {formatCurrencyValue(
                                _calculateTotalCommission(
                                  each?._affiliate?.referredClients,
                                  "leadReferComission"
                                )
                              )}{" "}
                              (PEPM)
                            </td>
                            <td>
                              {formatCurrencyValue(
                                _calculateTotalCommission(
                                  each?._affiliate?.referredClients,
                                  "affiliatesReferComission"
                                )
                              )}{" "}
                              (PEPM)
                            </td>
                          </>
                        )}
                      </tr>
                    ))
                  ) : (
                    <tr className="text-center">
                      <td colSpan="7">
                        <div className="nodata">
                          <img
                            src={require("../../assets/img/noData.svg").default}
                            alt="no data"
                          />
                          <p>There is no data to display</p>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </Col>
      </Row>

      <AddUsersModal
        isOpen={addUsersModal.isOpen}
        data={addUsersModal.data}
        heading={addUsersModal.heading}
        primaryLeaderData={addUsersModal.primaryLeaderData}
        toggle={() => _toggleAddUsersModal()}
        onSuccess={() => _getAffiliateInfo()}
        isUpdateUser={true}
      />

      <AffiliatesClientsModal
        isOpen={affiliatesClientsModal.isOpen}
        data={affiliatesClientsModal.data}
        isHideClientName={true}
        toggle={() => _toggleAffiliatesClientsModal()}
      />

      <LeadNotesModal
        isOpen={leadNotesModal.isOpen}
        data={leadNotesModal.data}
        toggle={() => _toggleLeadNotesModal()}
        onSuccess={() => _getAffiliateInfo()}
      />

      <QuizTestModal
        isOpen={quizTestModal.isOpen}
        data={quizTestModal.data}
        toggle={() => _toggleQuizTestModal()}
        onSuccess={() => _getAffiliateInfo()}
      />
    </>
  );
};

export default AffiliateHomePage;
