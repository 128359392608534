import React, { Fragment } from "react";
import { Collapse } from "reactstrap";

const LeadQuestionnaires = ({ data, isOpenCollapse, _toggleCollapse }) => {
  return (
    <div className="cardWrap questionnaire">
      <div
        className={`customAccordionHead ${
          isOpenCollapse ? "customAccordionHeadActive" : ""
        }`}
        onClick={() => _toggleCollapse()}
      >
        <div id="toggler" className="px-1">
          Questionnaire
        </div>
        <i className="fas fa-chevron-down toggleAccordion" id="toggler" />
      </div>
      <Collapse isOpen={isOpenCollapse} className="customAccordionBody">
        <div className="quesAnsWrap">
          {data?.leadQuestionResponse?.length
            ? data.leadQuestionResponse.map((each, index) => (
                <Fragment key={`leadQuestionResponse_${index}`}>
                  <h5>
                    {index + 1}. {each.questionText}
                  </h5>
                  <p>{each.response}</p>
                </Fragment>
              ))
            : "There is no data to display."}
        </div>
      </Collapse>
    </div>
  );
};

export default LeadQuestionnaires;
