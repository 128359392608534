import React, { useMemo } from "react";
import { ListGroup, ListGroupItem } from "reactstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
const DashboardSidebar = ({ isShow, setIsShow }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const userCredential = useSelector((state) => state?.userCredential);

  const userType = useMemo(
    () => userCredential?.user?.userType?.toLowerCase(),
    [userCredential]
  );

  const affiliateType = useMemo(
    () => userCredential?.user?.affiliateType?.toLowerCase(),
    [userCredential]
  );

  const _isActiveTab = (route) => {
    return location?.pathname === route ? true : false;
  };

  const _toggleSidebar = () => {
    setIsShow(!isShow);
  };
  return (
    <>
      {/* add show class after click on bar icon  */}
      <div
        className={`sidebarWrapper ${isShow ? "show" : ""}`}
        onClick={() => _toggleSidebar()}
      >
        <div className="sidebarInner">
          {/* <div className="userInfo">
            <div className="userAvatar">
              <img
                src={require("../../assets/img/default-profile.svg").default}
                alt="Profile"
              />
            </div>
            <div className="userName" onClick={() => navigate(`/${userType}/profile`)}>
              <span>John Doe</span>
              <i className="fas fa-pencil-alt" />
            </div>
          </div> */}
          <div className="sidebarMenu">
            <ListGroup>
              {userCredential?.showHeader || affiliateType === "leader" || userType === "admin" ? 
              <ListGroupItem
                className={
                  _isActiveTab(`/${userType}/dashboard`) ? "active" : ""
                }
                onClick={() => navigate(`/${userType}/dashboard`)}
              >
                Dashboard
              </ListGroupItem> : null}

              {userType === "affiliate" ? (
                <>
                  {userCredential?.showHeader || affiliateType === "leader" || userCredential?.isVerified ? 
                  <ListGroupItem
                    className={
                      _isActiveTab(`/${userType}/home`) ? "active" : ""
                    }
                    onClick={() => navigate(`/${userType}/home`)}
                  >
                    Home
                  </ListGroupItem> : null}

                  <ListGroupItem
                    className={
                      _isActiveTab(`/${userType}/training-user`) ? "active" : ""
                    }
                    onClick={() => navigate(`/${userType}/training-user`)}
                  >
                    Training
                  </ListGroupItem>
                </>
              ) : null}

              {userType === "admin" ||
              (userType === "affiliate" && affiliateType === "leader") ? (
                <>
                  <ListGroupItem
                    className={
                      _isActiveTab(`/${userType}/clients`) ? "active" : ""
                    }
                    onClick={() => navigate(`/${userType}/clients`)}
                  >
                    Clients
                  </ListGroupItem>
                  <ListGroupItem
                    className={
                      _isActiveTab(`/${userType}/affiliates`) ? "active" : ""
                    }
                    onClick={() => navigate(`/${userType}/affiliates`)}
                  >
                    Affiliates
                  </ListGroupItem>
                  <ListGroupItem
                    className={
                      _isActiveTab(`/${userType}/message-templates`)
                        ? "active"
                        : ""
                    }
                    onClick={() => navigate(`/${userType}/message-templates`)}
                  >
                    Message Templates
                  </ListGroupItem>

                  {userType === "admin" && (
                    <>
                      <ListGroupItem
                        className={
                          _isActiveTab(`/${userType}/training`) ? "active" : ""
                        }
                        onClick={() => navigate(`/${userType}/training`)}
                      >
                        Training
                      </ListGroupItem>

                      <ListGroupItem
                        className={
                          _isActiveTab(`/${userType}/quiz-bank`) ? "active" : ""
                        }
                        onClick={() => navigate(`/${userType}/quiz-bank`)}
                      >
                        Quiz Bank
                      </ListGroupItem>
                    </>
                  )}

                  {/* <ListGroupItem
                    className={
                      _isActiveTab(`/${userType}/agreement-documents`) ? "active" : ""
                    }
                    onClick={() => navigate(`/${userType}/agreement-documents`)}
                  >
                    Agreement Documents
                  </ListGroupItem> */}
                </>
              ) : null}
            </ListGroup>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardSidebar;
