import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Navbar, NavbarBrand, NavItem, NavLink, Nav, Button } from "reactstrap";
import { APP_LOGO } from "../../config";
import { errorHandler, logout } from "../../helper-methods";
import { fetchAllNotifications, removeDeviceId } from "../../http/http-calls";
import { updateUserData } from "../../redux/actions/user-credential";
import NotificationViewModal from "../../components/modals/NotificationViewModal";

const DashboardHeader = ({ isShow, setIsShow }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const userCredential = useSelector((state) => state?.userCredential);

  const userType = useMemo(
    () => userCredential?.user?.userType?.toLowerCase(),
    [userCredential]
  );

  const affiliateType = useMemo(
    () => userCredential?.user?.affiliateType?.toLowerCase(),
    [userCredential]
  );

  // eslint-disable-next-line no-unused-vars
  const [payload, setPayload] = useState({
    pageNumber: 0,
    limit: 10,
    page: 1,
    userType: userType,
  });

  const [notificationViewModal, setNotificationViewModal] = useState({
    isOpen: false,
    data: null,
  });

  const _logout = async () => {
    if (window.location.origin !== "http://localhost:3000") {
      const deviceIdData = JSON.parse(localStorage.getItem("users"));

      const { deviceId } = deviceIdData?.[deviceIdData?.length - 1] || {};
      await removeDeviceId({ deviceId: deviceId });
    }

    logout(navigate);
  };
  // menu
  // const [isOpen, setIsOpen] = useState(false);
  // const toggle = () => setIsOpen(!isOpen);

  // menu
  const _isActiveTab = (route) => {
    return location?.pathname === route ? true : false;
  };

  const _toggleSidebar = () => {
    setIsShow(!isShow);
  };

  const _toggleNotificationModal = (isOpen = false, data = null) => {
    setNotificationViewModal({ isOpen, data });
  };

  const _fetchNotifications = async () => {
    try {
      const res = await fetchAllNotifications(payload);

      if (res?.totalUnreadCount !== 0) {
        _toggleNotificationModal(true, res?.unreadNotifications);
      }
      dispatch(
        updateUserData({
          ...userCredential,
          user: userCredential?.user,
          notificationCount: res?.totalUnreadCount,
        })
      );
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    if (userType === "affiliate") {
      _fetchNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Navbar expand="lg" className="projectHeader">
        <NavbarBrand onClick={() => navigate("/")} className="order-1">
          <img src={APP_LOGO} alt="Logo" />
        </NavbarBrand>

        {/* DO NOT REOMVE, ICON FOR SIDEBAE */}
        <Button
          onClick={() => _toggleSidebar()}
          className="order-lg-1 toggleIcon"
        >
          <span></span>
        </Button>

        <Nav className="m-auto order-md-2 d-lg-flex d-none" navbar>
        {userCredential?.showHeader || affiliateType === "leader" || userType === "admin" ? 
          <NavItem>
            <NavLink
              className={_isActiveTab(`/${userType}/dashboard`) ? "active" : ""}
              onClick={() => navigate(`/${userType}/dashboard`)}
            >
              Dashboard
            </NavLink>
          </NavItem> : null}

          {userType === "affiliate" ? (
            <>
              {userCredential?.showHeader || affiliateType === "leader" || userCredential?.isVerified ? (
                <NavItem>
                  <NavLink
                    className={
                      _isActiveTab(`/${userType}/home`) ? "active" : ""
                    }
                    onClick={() => navigate(`/${userType}/home`)}
                  >
                    Home
                  </NavLink>
                </NavItem>
              ) : null}

              <NavItem>
                <NavLink
                  className={
                    _isActiveTab(`/${userType}/training-user`) ? "active" : ""
                  }
                  onClick={() => navigate(`/${userType}/training-user`)}
                >
                  Training
                </NavLink>
              </NavItem>

              {/* <NavLink onClick={() => navigate(`/${userType}/notification`)}>
                <div className="bellIcon">
                  <i className="fa fa-bell"></i>
                  <span>{userCredential?.notificationCount}</span>
                </div>
              </NavLink> */}
            </>
          ) : null}

          {userType === "admin" ||
          (userType === "affiliate" && affiliateType === "leader") ? (
            <>
              {userCredential?.showHeader || affiliateType === "leader" || userCredential?.isVerified || userType === "admin"  ? (
                <>
                  <NavItem>
                    <NavLink
                      className={
                        _isActiveTab(`/${userType}/clients`) ? "active" : ""
                      }
                      onClick={() => navigate(`/${userType}/clients`)}
                    >
                      Clients
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={
                        _isActiveTab(`/${userType}/affiliates`) ? "active" : ""
                      }
                      onClick={() => navigate(`/${userType}/affiliates`)}
                    >
                      Affiliates
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={
                        _isActiveTab(`/${userType}/message-templates`)
                          ? "active"
                          : ""
                      }
                      onClick={() => navigate(`/${userType}/message-templates`)}
                    >
                      Message Templates
                    </NavLink>
                  </NavItem>
                </>
              ) : null}

              {userType === "admin" && (
                <>
                  <NavItem>
                    <NavLink
                      className={
                        _isActiveTab(`/${userType}/training`) ? "active" : ""
                      }
                      onClick={() => navigate(`/${userType}/training`)}
                    >
                      Training
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={
                        _isActiveTab(`/${userType}/quiz-bank`) ? "active" : ""
                      }
                      onClick={() => navigate(`/${userType}/quiz-bank`)}
                    >
                      Quiz Bank
                    </NavLink>
                  </NavItem>
                </>
              )}

              {/* <NavItem>
              <NavLink
                className={
                  _isActiveTab(`/${userType}/agreement-documents`) ? "active" : ""
                }
                onClick={() => navigate(`/${userType}/agreement-documents`)}
              >
                Agreement Documents
              </NavLink>
            </NavItem> */}
              {/* <NavItem></NavItem> */}
            </>
          ) : null}
        </Nav>
        <div className="order-3 d-flex align-items">
          {userType === "affiliate" && (
            <Link to={`/${userType}/notification`} className="bellIcon">
              <i className="fa fa-bell"></i>
              {userCredential?.notificationCount ? (
                <span>{userCredential?.notificationCount}</span>
              ) : null}
            </Link>
          )}
          <Button onClick={() => _logout()} color="link" className="logout p-0">
            <i className="fas fa-power-off" />
          </Button>
        </div>
      </Navbar>

      {notificationViewModal.isOpen && notificationViewModal?.data && (
        <NotificationViewModal
          isOpen={notificationViewModal.isOpen}
          toggle={() => _toggleNotificationModal()}
          data={notificationViewModal?.data}
        />
      )}
    </>
  );
};

export default DashboardHeader;
