import React from "react";
import { PopoverBody, Table, UncontrolledPopover } from "reactstrap";
import { formatDate, formatTime } from "../helper-methods";
import ReadMoreReadLess from "./ReadMoreReadLess";

const AffiliateMessagesTable = ({ activities }) => (
  <div className="tableInCardWrap">
    <div className="tableCardHeader">
      <h3>Messages</h3>
    </div>

    <div className="tableWrapper">
      <Table responsive>
        <thead>
          <tr>
            <th>Type</th>
            <th>Time</th>
            <th>Message</th>
            <th>By</th>
          </tr>
        </thead>
        <tbody>
          {activities?.length ? (
            activities.map((each) => (
              <tr key={each?._id}>
                <td>
                  <div style={{ width: 60 }}>{each.activityType || "N/A"}</div>
                </td>
                <td>
                  <div style={{ width: 100 }}>
                    {formatDate(each.createdAt)} <br />
                    {formatTime(each.createdAt)}
                  </div>
                </td>
                <td>
                  {each.message ? (
                    <>
                      <div
                        id={`newMessageText_${each._id}`}
                        className="themeColor"
                        style={{ width: 120 }}
                      >
                        <ReadMoreReadLess
                          text={
                            each.activityType === "Email"
                              ? each.subject
                              : each.message
                          }
                          count={80}
                          isHideMoreButton={true}
                        />
                      </div>
                      <UncontrolledPopover
                        target={`newMessageText_${each._id}`}
                        trigger="legacy"
                      >
                        {each.activityType === "Email" ? (
                          <PopoverBody>
                            <div>
                              Subject:
                              <p>{each.subject}</p>
                            </div>
                            <hr />
                            <div>
                              Body:
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: each.message,
                                }}
                              />
                            </div>
                          </PopoverBody>
                        ) : (
                          <PopoverBody>{each.message}</PopoverBody>
                        )}
                      </UncontrolledPopover>
                    </>
                  ) : (
                    "N/A"
                  )}
                </td>
                <td>
                  <div style={{ width: 100 }}>
                    {each._createdBy?.name?.full || "N/A"}
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr className="text-center">
              <td colSpan="4">
                <div className="nodata">
                  <img
                    src={require("../assets/img/noData.svg").default}
                    alt="no data"
                  />
                  <p>There is no data to display</p>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  </div>
);

export default AffiliateMessagesTable;
