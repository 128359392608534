import React from 'react'
import { Input, InputGroup, InputGroupText, Label } from 'reactstrap'

const FilterTrainingVideo = ({ fields, payload, onFilterChange }) =>  {
  return (
    <div className="filterForm">
    {fields.map((field, index) => (
      <div key={index} className="formGroup">
        <Label>{field.label}</Label>

        {field.type === 'text' ? (
          <InputGroup className="searchTable">
            <Input
              type="text"
              name={field.name}
              placeholder={field.placeholder}
              autoComplete="off"
              value={payload[field.name]}
              onChange={(event) => onFilterChange(field.name, event)}
            />
            <InputGroupText>
              <i className="fa fa-search" />
            </InputGroupText>
          </InputGroup>
        ) : (
          <Input
            type="select"
            disabled={field.disabled}
            value={payload[field.name]}
            name={field.name}
            onChange={(event) => onFilterChange(field.name, event)}
          >
            <option value="">{field.defaultOption}</option>
            {field.options.map((option, optionIndex) => (
              <option key={optionIndex} value={option.value}>
                {option.label}
              </option>
            ))}
          </Input>
        )}
      </div>
    ))}
  </div>
  )
}

export default FilterTrainingVideo