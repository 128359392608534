import React, { useState, forwardRef, useImperativeHandle } from "react";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import { Input } from "reactstrap";
import { dateConfig } from "../config/helper-config";

const CustomDateRangePicker = forwardRef(
  (
    {
      startDate,
      startDateId,
      endDate,
      endDateId,
      maxDate,
      isDisabledOutsideRange = false,
      isMaxDateValidation = false,
      disabled = false,
      onDatesChange = () => {},
    },
    ref
  ) => {
    const [focusedInput, setFocusedInput] = useState(null);
    const [dateType, setDateType] = useState("");

    const _maxDateValidation = () => {
      if (isMaxDateValidation && maxDate) {
        return moment(maxDate);
      }
      return moment();
    };

    const _onDatesChange = ({ startDate, endDate }) => {
      if (startDate) {
        startDate = moment(startDate).utcOffset(0);
        startDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      }

      if (endDate) {
        endDate = moment(endDate).utcOffset(0);
        endDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      }

      onDatesChange({ startDate, endDate });
    };

    const _onChangeDateType = (value) => {
      setDateType(value);

      switch (value) {
        case "last7days": {
          const startDate = moment().subtract(6, "d");
          const endDate = moment();

          _onDatesChange({ startDate, endDate });
          break;
        }
        case "last4weeks": {
          const startDate = moment().subtract(3, "w");
          const endDate = moment();

          _onDatesChange({ startDate, endDate });
          break;
        }
        case "lastmonth": {
          const startDate = moment().subtract(1, "month").startOf("month");
          const endDate = moment().subtract(1, "month").endOf("month");

          _onDatesChange({ startDate, endDate });
          break;
        }
        case "last3months": {
          const startDate = moment().subtract(2, "month").startOf("month");
          const endDate = moment();

          _onDatesChange({ startDate, endDate });
          break;
        }
        case "last12months": {
          const startDate = moment().subtract(11, "month").startOf("month");
          const endDate = moment();

          _onDatesChange({ startDate, endDate });
          break;
        }
        case "lastyear": {
          const startDate = moment().subtract(1, "year").startOf("year");
          const endDate = moment().subtract(1, "year").endOf("year");

          _onDatesChange({ startDate, endDate });
          break;
        }
        case "custom": {
          console.log({ value });
          setFocusedInput("startDate");
          break;
        }
        default: {
          console.log({ value });
          _onDatesChange({ startDate: null, endDate: null });
          setDateType("");
        }
      }
    };

    useImperativeHandle(ref, () => ({
      resetState() {
        setDateType("");
      },
    }));

    return (
      <>
        <div className="dateRangeWithDropDown">
          <Input
            type="select"
            value={dateType}
            name="dateType"
            disabled={disabled}
            onChange={(e) => _onChangeDateType(e.target.value)}
          >
            <option value="">All Time</option>
            {dateConfig.map((each) => (
              <option key={each.value} value={each.value}>
                {each.label}
              </option>
            ))}
            <option value="custom">Custom</option>
          </Input>

          {dateType === "custom" && (
            <DateRangePicker
              startDate={startDate || null} // momentPropTypes.momentObj or null,
              startDateId={`startDateId_${startDateId}`} // PropTypes.string.isRequired,
              endDate={endDate || null} // momentPropTypes.momentObj or null,
              endDateId={`endDateId_${endDateId}`} // PropTypes.string.isRequired,
              onDatesChange={({ startDate, endDate }) => {
                onDatesChange({ startDate, endDate });
              }} // PropTypes.func.isRequired,
              focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
              onFocusChange={(newFocusInput) => setFocusedInput(newFocusInput)}
              isOutsideRange={(day) =>
                isDisabledOutsideRange || (isMaxDateValidation && maxDate)
                  ? moment().endOf("day").diff(day) <= 0 ||
                    (isMaxDateValidation &&
                      maxDate &&
                      moment(day) > _maxDateValidation())
                  : null
              }
              maxDate={isDisabledOutsideRange ? _maxDateValidation() : null}
              numberOfMonths={1}
              hideKeyboardShortcutsPanel={true}
              disabled={disabled}
            />
          )}
        </div>
      </>
    );
  }
);

export default CustomDateRangePicker;
