import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  LoadCanvasTemplateNoReload,
  loadCaptchaEnginge,
  validateCaptcha,
} from "react-simple-captcha";
import {
  Button,
  Form,
  Input,
  Label,
  FormGroup,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { APP_LOGO } from "../../config";
import { countryCodes } from "../../config/country-codes";
import { questionnaireConfig } from "../../config/questionnaireConfig";
import { RegexConfig } from "../../config/RegexConfig";
import PublicFooter from "../../containers/Public/PublicFooter";
import {
  errorHandler,
  extractQueryParams,
  showToast,
  splitFullName,
} from "../../helper-methods";
import { createLead } from "../../http/http-calls";
import { hideLoader, showLoader } from "../../redux/actions/loader-data";

const Questionnaire = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formFields, setFormFields] = useState({
    countryCode: "+1",
  });
  // eslint-disable-next-line no-unused-vars
  const [isDirty, setIsDirty] = useState({});
  const [errors, setErrors] = useState({});
  const [referCode, setReferCode] = useState("");

  useEffect(() => {
    const newFormFields = { ...formFields };

    questionnaireConfig.forEach((each) => {
      newFormFields[each.key] = "";
    });
    newFormFields.captchaValue = "";

    setFormFields(newFormFields);

    const { referCode } = extractQueryParams();
    if (referCode) setReferCode(referCode);

    loadCaptchaEnginge(4, "grey");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _validateFormFields = ({ newFormFields, newIsDirty }) => {
    return new Promise((resolve) => {
      const newErrors = {};
      let isFormValid = true;

      if (newFormFields) {
        Object.keys(newFormFields).forEach((key) => {
          if (newIsDirty[key]) {
            switch (key) {
              case "fullName": {
                if (newFormFields[key]?.trim().length) {
                  if (
                    RegexConfig.name.test(
                      String(newFormFields[key]).toLowerCase()
                    )
                  ) {
                    newErrors[key] = null;
                    newIsDirty[key] = false;
                  } else {
                    newErrors[key] = "*Invalid name";
                    isFormValid = false;
                  }
                } else {
                  newErrors[key] = "*Required";
                  isFormValid = false;
                }
                break;
              }
              case "companyName": {
                if (newFormFields[key]?.trim().length) {
                  if (
                    RegexConfig.alphanumericMultiWord.test(
                      String(newFormFields[key]).toLowerCase()
                    ) &&
                    newFormFields[key].length >= 2 &&
                    newFormFields[key].length <= 250
                  ) {
                    newErrors[key] = null;
                    newIsDirty[key] = false;
                  } else {
                    newErrors[key] =
                      "*Invalid name, it should be between 2 to 250 alphanumeric value";
                    isFormValid = false;
                  }
                } else {
                  newErrors[key] = "*Required";
                  isFormValid = false;
                }
                break;
              }
              case "How_did_you_hear_about_us?": {
                if (newFormFields[key]?.trim().length) {
                  if (
                    RegexConfig.alphanumericMultiWord.test(
                      String(newFormFields[key]).toLowerCase()
                    ) &&
                    newFormFields[key].length >= 2 &&
                    newFormFields[key].length <= 50
                  ) {
                    newErrors[key] = null;
                    newIsDirty[key] = false;
                  } else {
                    newErrors[key] =
                      "*Invalid name, it should be between 2 to 50 alphanumeric value";
                    isFormValid = false;
                  }
                } else {
                  newErrors[key] = "*Required";
                  isFormValid = false;
                }
                break;
              }
              case "email": {
                if (newFormFields[key]?.trim().length) {
                  if (
                    RegexConfig.email.test(
                      String(newFormFields[key]).toLowerCase()
                    )
                  ) {
                    newErrors[key] = null;
                    newIsDirty[key] = false;
                  } else {
                    newErrors[key] = "*Invalid email";
                    isFormValid = false;
                  }
                } else {
                  newErrors[key] = "*Required";
                  isFormValid = false;
                }
                break;
              }
              case "phone": {
                if (newFormFields[key]?.trim().length) {
                  if (
                    RegexConfig.phone.test(
                      String(newFormFields[key]).toLowerCase()
                    )
                  ) {
                    newErrors[key] = null;
                    newIsDirty[key] = false;
                  } else {
                    newErrors[key] = "*Invalid phone number";
                    isFormValid = false;
                  }
                } else {
                  newErrors[key] = "*Required";
                  isFormValid = false;
                }
                break;
              }
              default: {
                if (newFormFields[key]?.trim().length) {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] = "*Required";
                  isFormValid = false;
                }
                break;
              }
            }
          }
        });
      }

      setErrors((prev) => ({
        ...prev,
        ...newErrors,
      }));

      setIsDirty((prev) => ({
        ...prev,
        ...newIsDirty,
      }));

      resolve(isFormValid);
    });
  };

  const _onChangeFormFields = (key, value) => {
    if (
      (key === "phone" ||
        key === "How_many_W2_employees_do_you_currently_have_on_payroll?") &&
      (isNaN(value) || value.includes(".") || Number(value) < 0)
    )
      return;

    const newFormFields = { ...formFields };
    newFormFields[key] = value;
    setFormFields(newFormFields);
  };

  const _onBlurFormFields = (key) => {
    const newFormFields = { ...formFields };
    const newIsDirty = {
      [key]: true,
    };
    _validateFormFields({ newFormFields, newIsDirty });
  };

  const _createLead = async (payload) => {
    try {
      dispatch(showLoader("Loading..."));

      const res = await createLead(payload);

      dispatch(hideLoader());

      localStorage.questionnaire_next_step_token = res?.token;
      navigate(`/questionnaire-next-step`);
    } catch (error) {
      errorHandler(error);
      dispatch(hideLoader());
    }
  };

  const _onSaveDetails = async (e) => {
    try {
      if (e) e.preventDefault();

      const newFormFields = { ...formFields };

      const newIsDirty = {
        captchaValue: true,
      };

      questionnaireConfig.forEach((each) => {
        newIsDirty[each.key] = true;
      });

      const isFormValid = await _validateFormFields({
        newFormFields,
        newIsDirty,
      });

      if (!isFormValid) return;

      if (validateCaptcha(formFields.captchaValue) !== true) {
        showToast("Captcha Does Not Match", "error");
        return;
      }

      const { firstName, lastName } = splitFullName(formFields.fullName.trim());

      const payload = {
        name: {
          first: firstName,
          last: lastName,
        },
        email: formFields.email.trim(),
        phone: formFields.phone.trim(),
        countryCode: formFields.countryCode.trim(),
        companyName: formFields.companyName.trim(),
        // _referBy: formFields._referBy?.value,
        leadQuestionResponse: [], // { questionText: String, response: String }
      };

      questionnaireConfig.forEach((each) => {
        if (each.isQuestion) {
          payload.leadQuestionResponse.push({
            questionText: each.label,
            response: formFields[each.key],
          });
        }
      });

      const employees = +payload?.leadQuestionResponse?.find(
        (each) =>
          each.questionText ===
          "How many W2 employees do you currently have on payroll?"
      )?.response;
      payload.questionnaireEmployeeCount = employees;

      if (referCode) {
        payload.referCode = referCode;
      }

      _createLead(payload);
    } catch (error) {
      errorHandler(error);
    }
  };

  const _renderFormFields = () => {
    return questionnaireConfig.map((each) => {
      switch (each.type) {
        case "input": {
          if (each.key === "phone") {
            return (
              <div className="mb-3" key={each.key}>
                <Label for="exampleText">{each.label}</Label>
                <InputGroup>
                  <InputGroupText className="p-0 bg-transparent border-0 border-end">
                    <Input
                      type="select"
                      className="pl-0 bg-transparent pe-0 border-0"
                      value={formFields.countryCode || ""}
                      name="countryCode"
                      onChange={(e) =>
                        _onChangeFormFields("countryCode", e.target.value)
                      }
                      onBlur={() => _onBlurFormFields("countryCode")}
                    >
                      {countryCodes.map((each) => (
                        <option key={each.code} value={each.dial_code}>
                          {each.code} ({each.dial_code})
                        </option>
                      ))}
                    </Input>
                  </InputGroupText>
                  <Input
                    type="text"
                    value={formFields.phone || ""}
                    name="phone"
                    onChange={(e) =>
                      _onChangeFormFields("phone", e.target.value)
                    }
                    onBlur={() => _onBlurFormFields("phone")}
                  />
                </InputGroup>
                {errors["phone"] ? (
                  <div className="form-error">{errors["phone"]}</div>
                ) : null}
              </div>
            );
          }
          return (
            <div className="mb-3" key={each.key}>
              <Label for="exampleText">{each.label}</Label>
              <Input
                type="text"
                value={formFields[each.key] || ""}
                name={each.key}
                onChange={(e) => _onChangeFormFields(each.key, e.target.value)}
                onBlur={() => _onBlurFormFields(each.key)}
              />
              {errors[each.key] ? (
                <div className="form-error">{errors[each.key]}</div>
              ) : null}
            </div>
          );
        }
        case "radio": {
          return (
            <div className="mb-3" key={each.key}>
              <Label>{each.label}</Label>
              {each.options.map((option) => (
                <FormGroup check key={`${each.key}_${option}`}>
                  <Input
                    name={each.key}
                    id={`${each.key}_${option}`}
                    type="radio"
                    checked={formFields[each.key] === option ? true : false}
                    value={option}
                    onChange={(e) =>
                      _onChangeFormFields(each.key, e.target.value)
                    }
                    onBlur={() => _onBlurFormFields(each.key)}
                  />
                  <Label check for={`${each.key}_${option}`}>
                    {option}
                  </Label>
                </FormGroup>
              ))}
              {errors[each.key] ? (
                <div className="form-error">{errors[each.key]}</div>
              ) : null}
            </div>
          );
        }
        default:
          return null;
      }
    });
  };

  return (
    <>
      <div className="authPgWrapper affiliate-questionnaire">
        <div className="authFormWrap">
          <img
            src={APP_LOGO}
            alt="Project Logo"
            className="companyLogo"
            role="button"
            onClick={() => navigate("/")}
          />
          <div className="authForm">
            <h2 className="form-title">Questionnaire</h2>

            <Form onSubmit={(e) => _onSaveDetails(e)}>
              {_renderFormFields()}

              <div className="mt-3">
                <div className="d-flex align-items-center">
                  <Input
                    type="text"
                    placeholder="Enter Captcha Value"
                    value={formFields?.captchaValue || ""}
                    name={"captchaValue"}
                    onChange={(e) =>
                      _onChangeFormFields("captchaValue", e.target.value)
                    }
                    onBlur={() => _onBlurFormFields("captchaValue")}
                  />

                  <LoadCanvasTemplateNoReload />
                </div>
                {errors?.captchaValue ? (
                  <div className="form-error">{errors?.captchaValue}</div>
                ) : null}
              </div>

              <Button type="submit" color="primary" className="my-3">
                Submit
              </Button>
            </Form>
          </div>
        </div>
      </div>

      <PublicFooter />
    </>
  );
};

export default Questionnaire;
