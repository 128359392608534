import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Label,
  Input,
  InputGroup,
  InputGroupText,
  UncontrolledTooltip,
  Spinner,
  FormGroup,
} from "reactstrap";
import AddUsersModal from "../../components/modals/AddUsersModal";
import {
  affiliateTypeConfig,
  refereAffiliateConfig,
  referredLeadsConfig,
} from "../../config/helper-config";
import {
  capitalize,
  copyToClipboard,
  errorHandler,
  formatCurrencyValue,
  formatDate,
  showToast,
} from "../../helper-methods";
import {
  deleteUserById,
  getAffiliateList,
  getAllUsers,
  getOrganizationsList,
  // sendAgreementAffiliate,
  updateUser,
} from "../../http/http-calls";
import SearchableInput from "../../components/SearchableInput";
import AgreementModal from "../../components/modals/AgreementModal";
import SvgIcons from "../../components/SvgIcons";
import SendEmailTextComponent from "../../components/SendEmailTextComponent";
import { useSelector } from "react-redux";
import CustomDateRangePicker from "../../components/CustomDateRangePicker";
import Swal from "sweetalert2";
import CustomTable from "../../components/Table/CustomTable";
import StatusChangeComponent from "../../components/StatusChangeComponent";

const headerKeys = [
  { label: "_id", id: "_id" },
  { label: "Name", id: "name", isSort: true },
  { label: "Added On", id: "createdAt", isSort: true },
  { label: "Referred By", id: "referredBy" },
  { label: "Organization Name", id: "organizationName", isSort: true },
  { label: "Type", id: "type" },
  { label: "Clients", id: "referedLeadCount" },
  { label: "Referred Affiliates", id: "refereAffliateCount" },
  { label: "Estimated Earnings", id: "estimatedEarnings" },
  { label: "PEPM Commission", id: "commissionLevel1", isSort: true },
  { label: "Override", id: "commissionLevel2", isSort: true },
  { label: "Attempted Date", id: "Attempted Date" },
  { label: "Verification", id: "verification" },
  { label: "Register Docs", id: "Register Docs" },
  { label: "Status", id: "status" },
  // { label: "Action", id: "action" },
];

const AffiliatesPage = () => {
  const navigate = useNavigate();

  const userCredential = useSelector((state) => state?.userCredential);

  const userType = useMemo(
    () => userCredential?.user?.userType?.toLowerCase(),
    [userCredential]
  );

  const searchInterval = useRef({ current: null });

  const customDateRangePickerRef = useRef({ current: null });

  const [data, setData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [dataPayload, setDataPayload] = useState({
    filters: {},
    skip: 0,
    pageNumber: 0,
    page: 1,
    limit: 10,
    search: "",
    sortBy: "createdAt",
    orderBy: -1,
  });
  const [loading, setLoading] = useState(false);
  const [filtersLoading, setFiltersLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [verificationLoading, setVerificationLoading] = useState(false);
  const [affiliateTypeLoading, setAffiliateTypeLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [regDocsLoading, setRegDocsLoading] = useState(false);
  const [filters, setFilters] = useState({
    status: "",
    isVerified: "",
    startDate: null,
    endDate: null,
    refereAffiliateCount: "",
    organizationId: "",
    referLeadCount: "",
    isAgreementSign: false,
    referBy: "",
  });
  const [usersList, setUsersList] = useState([]);
  const [organizationsList, setOrganizationsList] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  // const [sendAgreementLoading, setSendAgreementLoading] = useState(false);

  const [isSelectAll, setIsSelectAll] = useState(false);
  const [selectedDataIds, setSelectedDataIds] = useState([]);

  const [addUsersModal, setAddUsersModal] = useState({ isOpen: false });

  const [agreementModal, setAgreementModal] = useState({
    isOpen: false,
    data: null,
    templateType: null,
  });

  const _toggleAddUsersModal = (isOpen = false) => {
    setAddUsersModal({ isOpen });
  };

  const _toggleAgreementModal = (
    isOpen = false,
    data = null,
    templateType = null
  ) => {
    setAgreementModal({ isOpen, data, templateType });
  };

  const _getAffiliateList = async () => {
    try {
      const res = await getAffiliateList();

      setUsersList(
        res?.user?.length
          ? res.user.map((each) => ({
              label: each.name?.full,
              value: each._id,
            }))
          : []
      );
    } catch (error) {
      errorHandler(error);
    }
  };

  const _getOrganizationsList = async () => {
    try {
      const res = await getOrganizationsList();

      setOrganizationsList(
        res?.organizations?.length
          ? res.organizations.map((each) => ({
              label: each.name,
              value: each._id,
            }))
          : []
      );
    } catch (error) {
      errorHandler(error);
    }
  };

  const _getAllUsers = async (payload) => {
    try {
      setLoading(true);

      const res = await getAllUsers(payload);
      setData(res?.users?.length ? res.users : []);
      setDataCount(res?.totalCount || 0);
      setLoading(false);
      setFiltersLoading(false);
      setSearchLoading(false);
      setStatusLoading(false);
      setAffiliateTypeLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
      setFiltersLoading(false);
      setSearchLoading(false);
      setStatusLoading(false);
      setAffiliateTypeLoading(false);
    }
  };

  const _onSizeChange = (limit = 10) => {
    const newDataPayload = { ...dataPayload };
    newDataPayload["skip"] = 0;
    newDataPayload["pageNumber"] = 0;
    newDataPayload["page"] = 1;
    newDataPayload["limit"] = limit;
    setDataPayload(newDataPayload);
    _getAllUsers(newDataPayload);
  };

  const _onPageChange = (pageNumber = 0) => {
    const newDataPayload = { ...dataPayload };
    newDataPayload["skip"] = pageNumber * newDataPayload["limit"];
    newDataPayload["pageNumber"] = pageNumber;
    localStorage.affiliatesPageNumber = pageNumber;
    newDataPayload["page"] = pageNumber + 1;
    setDataPayload(newDataPayload);
    _getAllUsers(newDataPayload);
  };

  const _toggleSortBy = (key) => {
    const newDataPayload = { ...dataPayload };
    if (newDataPayload.sortBy === key) {
      newDataPayload.orderBy = newDataPayload.orderBy === 1 ? -1 : 1;
    } else {
      newDataPayload.sortBy = key;
      newDataPayload.orderBy = 1;
    }
    setDataPayload(newDataPayload);
    _getAllUsers(newDataPayload);
  };

  const _onFiltersUpdated = (newFilters) => {
    const newDataPayload = { ...dataPayload };
    newDataPayload["skip"] = 0;
    newDataPayload["pageNumber"] = 0;
    newDataPayload["page"] = 1;
    newDataPayload["filters"] = {};

    Object.keys(newFilters).forEach((eachFilterKey) => {
      if (newFilters[eachFilterKey]) {
        if (
          eachFilterKey === "referLeadCount" ||
          eachFilterKey === "refereAffiliateCount"
        ) {
          try {
            newDataPayload.filters[eachFilterKey] = JSON.parse(
              newFilters[eachFilterKey]
            );
          } catch (e) {}
        } else if (
          eachFilterKey === "referBy" ||
          eachFilterKey === "organizationId"
        ) {
          newDataPayload.filters[eachFilterKey] =
            newFilters[eachFilterKey]?.value;
        } else if (eachFilterKey === "isVerified") {
          newDataPayload.filters[eachFilterKey] =
            newFilters[eachFilterKey] === "verified" ? true : false;
        } else if (eachFilterKey === "hasClearedQuiz") {
          newDataPayload.filters[eachFilterKey] = newFilters[eachFilterKey];
        } else {
          newDataPayload.filters[eachFilterKey] = newFilters[eachFilterKey];
        }
      }
    });

    setDataPayload(newDataPayload);

    _getAllUsers(newDataPayload);
  };

  const _onChangeFilters = (key, value) => {
    setFiltersLoading(true);

    const newFilters = { ...filters };
    newFilters[key] = value;
    setFilters(newFilters);

    _onFiltersUpdated(newFilters);
  };

  const _onDatesChange = ({ startDate, endDate }) => {
    setFiltersLoading(true);

    const newFilters = { ...filters };
    newFilters.startDate = startDate;
    newFilters.endDate = endDate;
    setFilters(newFilters);

    if ((!startDate && !endDate) || (startDate && endDate)) {
      _onFiltersUpdated(newFilters);
    }
  };

  const _onChangeSearch = (searchValue) => {
    if (searchInterval?.current) clearInterval(searchInterval.current);
    setSearchLoading(true);
    const newDataPayload = { ...dataPayload };
    newDataPayload["search"] = searchValue;
    newDataPayload["skip"] = 0;
    newDataPayload["pageNumber"] = 0;
    newDataPayload["page"] = 1;
    setDataPayload(newDataPayload);
    searchInterval.current = setTimeout(() => {
      _getAllUsers(newDataPayload);
    }, 1000);
  };

  const _clearFilters = () => {
    if (customDateRangePickerRef?.current?.resetState) {
      customDateRangePickerRef.current.resetState();
    }

    setFiltersLoading(true);

    const newFilters = { ...filters };
    Object.keys(newFilters).forEach((key) => {
      newFilters[key] = "";
    });
    setFilters(newFilters);

    const newDataPayload = { ...dataPayload };
    newDataPayload["search"] = "";
    newDataPayload["skip"] = 0;
    newDataPayload["pageNumber"] = 0;
    newDataPayload["page"] = 1;
    newDataPayload.sortBy = "createdAt";
    newDataPayload.orderBy = -1;
    newDataPayload["filters"] = {};
    setDataPayload(newDataPayload);

    _getAllUsers(newDataPayload);
  };

  const _onChangeSelectedData = (value = "all", checked = false) => {
    if (value === "every") {
      setIsSelectAll(checked);
    } else {
      setIsSelectAll(false);
      let newSelectedDataIds = [...selectedDataIds];
      if (value === "all") {
        if (checked) {
          const currentPageIds = data
            .filter((each) => !newSelectedDataIds?.includes(each._id))
            .map((each) => each._id);
          newSelectedDataIds = [...newSelectedDataIds, ...currentPageIds];
        } else {
          const currentPageIds = data.map((each) => each._id);
          newSelectedDataIds = newSelectedDataIds.filter(
            (sid) => !currentPageIds.includes(sid)
          );
        }
      } else {
        if (checked) {
          newSelectedDataIds.push(value);
        } else {
          const findIndex = newSelectedDataIds.findIndex(
            (each) => each === value
          );
          if (findIndex >= 0) newSelectedDataIds.splice(findIndex, 1);
        }
      }
      setSelectedDataIds(newSelectedDataIds);
    }
  };

  const _onChangeAffiliateType = async (user, status) => {
    try {
      if (!user?._id) {
        errorHandler({ reason: "User data not found" });
        return;
      }

      setAffiliateTypeLoading(true);

      const payload = {
        affiliateType: status,
      };

      const newData = [...data];
      const findData = newData.find((each) => each._id === user._id);
      if (findData) {
        findData.affiliateType = payload.affiliateType;
        setData(newData);
      }

      const res = await updateUser({ id: user._id, payload });

      if (res?.user?.affiliateType) {
        findData.affiliateType = res.user.affiliateType;
        setData(newData);
      }

      setAffiliateTypeLoading(false);
      showToast("Status has been updated", "success");
    } catch (error) {
      errorHandler(error);
      setAffiliateTypeLoading(false);
      _getAllUsers(dataPayload);
    }
  };

  const _onChangeVerification = async (user, status) => {
    try {
      if (!user?._id) {
        errorHandler({ reason: "User data not found" });
        return;
      }

      setVerificationLoading(true);

      const payload = {
        isVerified: status === "verified" ? true : false,
      };

      const newData = [...data];
      const findData = newData.find((each) => each._id === user._id);
      if (findData) {
        findData.isVerified = payload.isVerified;
        setData(newData);
      }

      // await updateUserVerification(user._id);
      await updateUser({ id: user._id, payload });

      setVerificationLoading(false);

      showToast("Verification has been updated", "success");
    } catch (error) {
      errorHandler(error);
      setVerificationLoading(false);
      _getAllUsers(dataPayload);
    }
  };

  const _onChangeStatus = async (user, status) => {
    try {
      if (!user?._id) {
        errorHandler({ reason: "User data not found" });
        return;
      }

      setStatusLoading(true);

      const payload = {
        isActive: status === "active" ? true : false,
      };

      const newData = [...data];
      const findData = newData.find((each) => each._id === user._id);
      if (findData) {
        findData.isActive = payload.isActive;
        setData(newData);
      }

      await updateUser({ id: user._id, payload });

      setStatusLoading(false);
      showToast("Status has been updated", "success");
    } catch (error) {
      errorHandler(error);
      setStatusLoading(false);
      _getAllUsers(dataPayload);
    }
  };

  const _onChangeRegisterDocs = async (user, status) => {
    console.log("status", status);
    try {
      if (!user?._id) {
        errorHandler({ reason: "User data not found" });
        return;
      }

      setRegDocsLoading(true);

      const payload = {
        isVerifiedForDocuSign: status,
      };

      const newData = [...data];
      const findData = newData.find((each) => each._id === user._id);
      if (findData) {
        findData.isVerifiedForDocuSign = payload.isVerifiedForDocuSign;
        setData(newData);
      }

      await updateUser({ id: user._id, payload });

      setRegDocsLoading(false);
      showToast("Status has been updated", "success");
    } catch (error) {
      errorHandler(error);
      setRegDocsLoading(false);
      _getAllUsers(dataPayload);
    }
  };

  const _deleteData = async (id) => {
    try {
      setDeleteLoading(id);

      const newData = [...data];
      let newDataCount = dataCount;
      const findDataIndex = newData.findIndex((each) => each._id === id);
      if (findDataIndex > -1) {
        newData.splice(findDataIndex, 1);
        setData(newData);
        newDataCount--;
        setDataCount(newDataCount);
      }

      await deleteUserById(id);

      Swal.fire("Deleted!", "User has been deleted.", "success");
      if (data.length === 0 || newDataCount <= 10) {
        _onPageChange();
      }
      setDeleteLoading(false);
    } catch (error) {
      errorHandler(error);
      setDeleteLoading(false);
      _getAllUsers(dataPayload);
    }
  };

  const _deleteDataAlert = (data) => {
    if (!data?._id) {
      errorHandler();
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete this (Name: ${data?.name?.full}) User.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#31c5c7",
      cancelButtonColor: "#F16667",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        _deleteData(data._id);
      }
    });
  };

  // const _sendAgreementAffiliate = async (id) => {
  //   try {
  //     setSendAgreementLoading(id);
  //     await sendAgreementAffiliate(id);
  //     setSendAgreementLoading(false);

  //     _getAllUsers(dataPayload);
  //   } catch (error) {
  //     errorHandler(error);
  //     setSendAgreementLoading(false);
  //   }
  // };

  // eslint-disable-next-line no-unused-vars
  const _estimatedEarning = (data) => {
    const referAffiliatesCount = data?._referAffiliates?.length
      ? data._referAffiliates.reduce(
          (acc, each) => acc + (Number(each.earningForWhoReferMe) || 0),
          0
        )
      : 0;
    const referLeadsCount = data?._referLeads?.length
      ? data._referLeads.reduce(
          (acc, each) => acc + (Number(each.leadReferEstimateEarning) || 0),
          0
        )
      : 0;

    return referAffiliatesCount + referLeadsCount;
  };

  const _calculateTotalAffiliatesPayout = (array = [], key = "", each) => {
    key =
      each?.leaderType === "Primary"
        ? "firstLeaderComission"
        : each?.leaderType === "Secondary"
        ? "secondLeaderComission"
        : key;

    if (!array?.length || !key) return 0;

    return array.reduce((acc, each) => {
      if (each?._affiliate?.referredClients?.length)
        acc += _calculateTotalClientsPayout(
          each?._affiliate?.referredClients,
          key,
          each
        );

      return acc;
    }, 0);
  };

  const _calculateTotalClientsPayout = (array = [], key = "", each) => {
    key =
      each?.leaderType === "Primary"
        ? "firstLeaderComission"
        : each?.leaderType === "Secondary"
        ? "secondLeaderComission"
        : key;

    if (!array?.length || !key) return 0;

    return array.reduce((acc, each) => {
      if (
        each?._client?.estimateEarning?.[key]?.comission &&
        +each?._client?.estimateEarning?.[key]?.comission
      )
        acc +=
          +each?._client?.estimateEarning?.[key]?.comission *
          +each?._client?.qualifiedEmployees;

      return acc;
    }, 0);
  };

  // const _calculateTotalCommission = (array = [], key = "") => {
  //   if (!array?.length || !key) return 0;

  //   return array.reduce((acc, each) => {
  //     if (
  //       each?._client?.estimateEarning?.[key]?.comission &&
  //       Number(each?._client?.estimateEarning?.[key]?.comission)
  //     )
  //       acc += Number(each?._client?.estimateEarning?.[key]?.comission);

  //     return acc;
  //   }, 0);
  // };

  const _dataFormat = (cell, row, header) => {
    switch (header) {
      case "name": {
        return (
          <>
            <div className="tableUserInfo">
              <h2
                style={{
                  color:
                    row?.isActive === false
                      ? "#dc3545"
                      : row?.affiliateType === "Super"
                      ? "#FFA500" // ORANGE
                      : row?.affiliateType === "Regular"
                      ? "#13e513" // GREEN
                      : "#339dff", // BLUE
                }}
                onClick={() => navigate(`/${userType}/affiliate/${row?._id}`)}
              >
                {row?.name?.full ? capitalize(row?.name?.full) : "N/A"}
                {row?.hasClearedQuiz ? (
                  <div className="checkIcon">
                    <SvgIcons type="check" />
                  </div>
                ) : // <img
                //   src={require("../../assets/img/checked.png")}
                //   width="16px"
                //   className="ms-2"
                //   alt=""
                // />
                null}
              </h2>
              <div className="userContact">
                {row?.phone && (
                  <>
                    <i
                      className="fas fa-phone-alt fs-12"
                      id={`user_phone_${row?._id}`}
                      onClick={() =>
                        copyToClipboard(
                          `${row?.countryCode || ""}${row?.phone}`
                        )
                      }
                    />
                    <UncontrolledTooltip
                      target={`user_phone_${row?._id}`}
                      placement="bottom"
                    >
                      {row?.countryCode} {row?.phone}
                    </UncontrolledTooltip>
                  </>
                )}
                {row?.email && (
                  <>
                    <i
                      className="far fa-envelope "
                      id={`user_email_${row?._id}`}
                      onClick={() => copyToClipboard(row?.email)}
                    />
                    <UncontrolledTooltip
                      target={`user_email_${row?._id}`}
                      placement="bottom"
                    >
                      {row?.email}
                    </UncontrolledTooltip>
                  </>
                )}
                {row?.referCode && (
                  <>
                    <img
                      src={require("../../assets/img/refer.png")}
                      alt=""
                      width="14px"
                      id={`user_website_${row?._id}`}
                      onClick={() => copyToClipboard(row?.referCode)}
                    />
                    <UncontrolledTooltip
                      target={`user_website_${row?._id}`}
                      placement="bottom"
                    >
                      {row?.referCode}
                    </UncontrolledTooltip>
                  </>
                )}
              </div>
            </div>
          </>
        );
      }
      case "createdAt": {
        return (
          <>
            <div style={{ width: 110 }}>{formatDate(row?.createdAt)}</div>
          </>
        );
      }
      case "referredBy": {
        return (
          <>
            {row?.isAddedByAdmin ? (
              "Admin"
            ) : row?._referBy?.name?.full ? (
              <div
                className="themeColor cursorPointer"
                onClick={() =>
                  navigate(`/${userType}/affiliate/${row?._referBy?._id}`)
                }
              >
                {capitalize(row?._referBy?.name?.full)}
              </div>
            ) : (
              "N/A"
            )}
          </>
        );
      }
      case "organizationName": {
        return <>{row?.organizationName || "N/A"}</>;
      }
      case "type": {
        return (
          <>
            <Input
              style={{ width: 100 }}
              type="select"
              disabled={affiliateTypeLoading ? true : false}
              value={row?.affiliateType}
              onChange={(e) => _onChangeAffiliateType(row, e.target.value)}
            >
              {affiliateTypeConfig.map((each) => (
                <option
                  key={each.value}
                  value={each.value}
                  disabled={each.value === "Leader"}
                >
                  {each.label}
                </option>
              ))}
            </Input>
          </>
        );
      }
      case "referedLeadCount": {
        return <>{row?.referedLeadCount || 0}</>;
      }
      case "refereAffliateCount": {
        return <>{row?.refereAffliateCount || 0}</>;
      }
      case "estimatedEarnings": {
        return (
          <>
            {formatCurrencyValue(
              _calculateTotalClientsPayout(
                row?.referredClients,
                "leadReferComission",
                row
              ) +
                _calculateTotalAffiliatesPayout(
                  row?.referredAffiliate,
                  "affiliatesReferComission",
                  row
                )
            )}
          </>
        );
      }
      case "commissionLevel1": {
        return <>{formatCurrencyValue(row?.commissionLevel1 || 0)}</>;
      }
      case "commissionLevel2": {
        return <>{formatCurrencyValue(row?.commissionLevel2 || 0)}</>;
      }
      case "Attempted Date": {
        return (
          <>
            {row?.quizAttempts?.length ? (
              !row?.quizAttempts[1]?.attemptDate ? (
                formatDate(row?.quizAttempts[0]?.attemptDate) || "-"
              ) : row?.quizAttempts[1]?.attemptDate ? (
                <>
                  {formatDate(row?.quizAttempts[0]?.attemptDate)}
                  <br />
                  {formatDate(row?.quizAttempts[1]?.attemptDate)}
                </>
              ) : (
                "-"
              )
            ) : (
              "-"
            )}
          </>
        );
      }
      case "verification": {
        return (
          <>
            {userType === "admin" ? (
              <Input
                style={{ width: 100 }}
                type="select"
                disabled={verificationLoading ? true : false}
                value={row?.isVerified ? "verified" : "notVerified"}
                onChange={(e) => _onChangeVerification(row, e.target.value)}
              >
                <option value="verified">Verified</option>
                <option value="notVerified">Not Verified</option>
              </Input>
            ) : (
              <>{row?.isVerified ? "Verified" : "Not Verified"}</>
            )}
          </>
        );
      }

      case "Register Docs": {
        return (
          <>
            <FormGroup switch>
              <StatusChangeComponent
                value={row?.isVerifiedForDocuSign}
                onChange={(e) => _onChangeRegisterDocs(row, e.target.checked)}
                disabled={regDocsLoading ? true : false}
                type="switch"
              />
            </FormGroup>
          </>
        );
      }

      case "status": {
        return (
          <>
            <Input
              style={{ width: 100 }}
              type="select"
              disabled={statusLoading ? true : false}
              value={row?.isActive ? "active" : "inactive"}
              onChange={(e) => _onChangeStatus(row, e.target.value)}
            >
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </Input>
          </>
        );
      }
      case "action": {
        return (
          <>
            <Button
              color="link"
              className="ms-1"
              disabled={deleteLoading ? true : false}
              onClick={() => _deleteDataAlert(row)}
            >
              {deleteLoading === row?._id ? (
                <i className="fa fa-spinner fa-spin text-danger" />
              ) : (
                <i className="fa fa-trash-alt text-danger" />
              )}
            </Button>
          </>
        );
      }
      default: {
        return cell;
      }
    }
  };

  useEffect(() => {
    _getAffiliateList();
    _getOrganizationsList();

    const pageNumber = localStorage?.affiliatesPageNumber;

    _onPageChange(+pageNumber ? +pageNumber : 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="pgTitleWrap">
        <h2>Affiliates {loading || filtersLoading ? <Spinner /> : null}</h2>

        <div className="right">
          {userType === "admin" && (
            <Button
              color="primary"
              className="floatingButton"
              onClick={() => _toggleAddUsersModal(true)}
            >
              <span>Add Affiliate</span>
              <div className="plusIcon">
                <SvgIcons type={"plus"} />
              </div>
            </Button>
          )}
          <div
            className="filterBarIcon"
            onClick={() => setShowFilter(!showFilter)}
          >
            <SvgIcons type={"filter"} />
          </div>
          <Button
            color="link"
            className="btn-reset"
            onClick={() => _clearFilters()}
          >
            <SvgIcons type={"reset"} />
          </Button>
        </div>
      </div>

      <div
        className={`filterWrapper ${showFilter === true ? "showFilter" : ""}`}
      >
        <i className="fas fa-filter filterIcon" />

        <div className="filterForm">
          <div className="formGroup searchBar">
            <Label>Search</Label>
            <InputGroup className="searchTable">
              <Input
                type="text"
                name="search"
                placeholder="Search"
                autoComplete="off"
                value={dataPayload.search}
                onChange={(e) => _onChangeSearch(e.target.value)}
              />
              <InputGroupText>
                {searchLoading ? (
                  <i className="fa fa-spinner fa-spin" />
                ) : dataPayload.search ? (
                  <i
                    className="fa fa-times"
                    onClick={() => _onChangeSearch("")}
                  />
                ) : (
                  <i className="fa fa-search" />
                )}
              </InputGroupText>
            </InputGroup>
          </div>
          <div className="formGroup">
            <Label>Added Date</Label>

            <CustomDateRangePicker
              ref={customDateRangePickerRef}
              startDate={filters?.startDate}
              endDate={filters?.endDate}
              startDateId={"startDateId_LeadPageFilter"}
              endDateId={"endDateId_LeadPageFilter"}
              onDatesChange={_onDatesChange}
            />
          </div>

          <div className="formGroup">
            <Label>Referred Clients</Label>
            <Input
              type="select"
              value={filters.referLeadCount}
              name="referLeadCount"
              onChange={(e) =>
                _onChangeFilters("referLeadCount", e.target.value)
              }
            >
              <option value="">All</option>
              {referredLeadsConfig.map((each, index) => (
                <option
                  key={`referLeadCount_${index}`}
                  value={JSON.stringify(each.value)}
                >
                  {each.label}
                </option>
              ))}
            </Input>
          </div>

          <div className="formGroup">
            <Label>Referred By</Label>
            <SearchableInput
              options={usersList}
              value={filters.referBy}
              onChange={(value) => _onChangeFilters("referBy", value)}
            />
          </div>

          <div className="formGroup">
            <Label>Referred Affiliates</Label>
            <Input
              type="select"
              value={filters.refereAffiliateCount}
              name="refereAffiliateCount"
              onChange={(e) =>
                _onChangeFilters("refereAffiliateCount", e.target.value)
              }
            >
              <option value="">All</option>
              {refereAffiliateConfig.map((each, index) => (
                <option
                  key={`refereAffiliateCount_${index}`}
                  value={JSON.stringify(each.value)}
                >
                  {each.label}
                </option>
              ))}
            </Input>
          </div>

          {userType === "admin" && (
            <div className="formGroup">
              <Label>Organization Name</Label>

              <SearchableInput
                options={organizationsList}
                value={filters.organizationId}
                onChange={(value) => _onChangeFilters("organizationId", value)}
              />
            </div>
          )}

          <div className="formGroup">
            <Label>Type</Label>
            <Input
              type="select"
              value={filters.affiliateType}
              name="type"
              onChange={(e) =>
                _onChangeFilters("affiliateType", e.target.value)
              }
            >
              <option value="">All</option>
              {affiliateTypeConfig.map((each) => (
                <option
                  key={each.value}
                  value={each.value}
                  disabled={userType === "affiliate" && each.value === "Leader"}
                >
                  {each.label}
                </option>
              ))}
            </Input>
          </div>

          <div className="formGroup">
            <Label>Verification</Label>
            <Input
              type="select"
              value={filters.isVerified}
              name="isVerified"
              onChange={(e) => _onChangeFilters("isVerified", e.target.value)}
            >
              <option value="">All</option>
              <option value="verified">Verified</option>
              <option value="notVerified">Not Verified</option>
            </Input>
          </div>

          <div className="formGroup">
            <Label>Status</Label>
            <Input
              type="select"
              value={filters.status}
              name="status"
              onChange={(e) => _onChangeFilters("status", e.target.value)}
            >
              <option value="">All</option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </Input>
          </div>

          <div className="form-check">
            <Input
              type="checkbox"
              value={filters.hasClearedQuiz}
              id="hasClearedQuiz"
              name="hasClearedQuiz"
              onChange={(e) =>
                _onChangeFilters("hasClearedQuiz", e.target.checked)
              }
            />

            <Label check for="hasClearedQuiz">
              Pending Verification
            </Label>
          </div>

          {/* <div className="formGroup d-flex flex-column mt-4">
              <Label style={{ opacity: 0 }}>.</Label>
              <div className="form-check">
                <Input
                  id="agreement"
                  type="checkbox"
                  role="button"
                  checked={filters.isAgreementSign}
                  onChange={(e) =>
                    _onChangeFilters("isAgreementSign", e.target.checked)
                  }
                />
                <Label check for="agreement" role="button">
                  Agreement
                </Label>
              </div>
            </div> */}
        </div>
      </div>

      {isSelectAll || selectedDataIds?.length ? (
        <>
          <div>
            {isSelectAll ? (
              <>All {dataCount} Affiliates are selected</>
            ) : (
              <>
                {selectedDataIds.length}{" "}
                {selectedDataIds.length > 1 ? "Affiliates are" : "Affiliate is"}{" "}
                selected
              </>
            )}
          </div>

          {selectedDataIds?.length >= data?.length &&
          dataCount > dataPayload.limit ? (
            <FormGroup check className="mb-0">
              <Label for="isSelectAll_Affiliates">
                Select All {dataCount} Affiliates
              </Label>
              <Input
                type="checkbox"
                id="isSelectAll_Affiliates"
                checked={isSelectAll}
                onChange={(e) =>
                  _onChangeSelectedData("every", e.target.checked)
                }
              />
            </FormGroup>
          ) : null}
        </>
      ) : null}

      <CustomTable
        data={data}
        dataCount={dataCount}
        dataPayload={dataPayload}
        onPageChange={_onPageChange}
        onSizeChange={_onSizeChange}
        loading={loading}
        isRowSelection={true}
        isSelectAll={isSelectAll}
        selectedDataIds={selectedDataIds}
        onChangeSelectedData={(each, checked) =>
          _onChangeSelectedData(each?._id, checked)
        }
        headerKeys={headerKeys}
        dataFormat={_dataFormat}
        toggleSortBy={_toggleSortBy}
      />

      {selectedDataIds?.length ? (
        <SendEmailTextComponent
          isSelectAll={isSelectAll}
          dataPayload={dataPayload}
          selectedDataIds={selectedDataIds}
          setSelectedDataIds={setSelectedDataIds}
          setIsSelectAll={setIsSelectAll}
          userType={`Affiliate`}
        />
      ) : null}

      <AddUsersModal
        isOpen={addUsersModal.isOpen}
        toggle={() => _toggleAddUsersModal()}
        onSuccess={() => {
          _getAffiliateList();
          _getOrganizationsList();
          _onPageChange();
        }}
      />

      <AgreementModal
        isOpen={agreementModal.isOpen}
        data={agreementModal.data}
        templateType={agreementModal.templateType}
        toggle={() => _toggleAgreementModal()}
        onSuccess={() => _onPageChange()}
      />
    </>
  );
};

export default AffiliatesPage;
