export const questionnaireConfig = [
  {
    label: "How many W2 employees do you currently have on payroll?",
    key: "How_many_W2_employees_do_you_currently_have_on_payroll?",
    type: "input",
    isQuestion: true,
  },
  // {
  //   label:
  //     "Are you interested in reducing your FICA spend, and increasing your employees take home pay?",
  //   key: "Are_you_interested_in_reducing_your_FICA_spend_and_increasing_your_employees_take_home_pay?",
  //   type: "radio",
  //   options: ["Yes", "No"],
  //   isQuestion: true,
  // },
  {
    label: "Full Name (First and Last)",
    key: "fullName",
    type: "input",
  },
  {
    label: "What is your best email?",
    key: "email",
    type: "input",
  },
  {
    label: "What is the best number to call you at?",
    key: "phone",
    type: "input",
  },
  {
    label: "What is your company name?",
    key: "companyName",
    type: "input",
  },
  {
    label: "How did you hear about us?",
    key: "How_did_you_hear_about_us?",
    type: "input",
    isQuestion: true,
  },
  // {
  //   label:
  //     "Would you like to jump on a call with a licensed professional to get a free savings calculation?",
  //   key: "Would_you_like_to_jump_on_a_call_with_a_licensed_professional_to_get_a_free_savings_calculation?",
  //   type: "radio",
  //   options: ["Yes", "No"],
  //   isQuestion: true,
  // },
];

export const questionnaireNextStepConfig = [
  {
    label: "How many people will you hire within the next 6-12 months?",
    key: "How_many_people_will_you_hire_within_the_next_6_12_months?",
    type: "input",
    isQuestion: true,
  },
  {
    label: "What payroll software do you use?",
    key: "What_payroll_software_do_you_use?",
    type: "input",
    isQuestion: true,
  },
  {
    label: "Do you have a designated payroll representative or team?",
    key: "Do_you_have_a_designated_payroll_representative_or_team?",
    type: "radio",
    options: ["Yes", "No"],
    isQuestion: true,
  },
];

export const affiliateApplicationConfig = [
  {
    label: "Full Name",
    key: "fullName",
    type: "input",
  },
  {
    label: "Phone Number",
    key: "phone",
    type: "input",
  },
  {
    label: "Email",
    key: "email",
    type: "input",
  },
  {
    label: "Password",
    key: "password",
    type: "input",
  },
  // {
  //   label: "How many business owners do you know with W2 employees?",
  //   key: "How_many_business_owners_do_you_know_with_W2_employees?",
  //   type: "radio",
  //   options: ["0-5", "5-10", "10+", "none"],
  //   isQuestion: true,
  // },
  {
    label:
      "How many years of experience do you have in the field of appointment setting or related sales roles?",
    key: "How_many_years_of_experience_do_you_have_in_the_field_of_appointment_setting_or_related_sales_roles?",
    type: "radio",
    options: ["Less than 1 year", "1-3 years", "4-6 years", "7+ years"],
    isQuestion: true,
  },
  {
    label:
      "Do you have a current book of business or a network of potential clients that know, like, and trust you?",
    key: "Do_you_have_a_current_book_of_business_or_a_network_of_potential_clients_that_know_like_and_trust_you?",
    type: "radio",
    options: [
      "No, I do not have a network or book of business",
      "Limited contacts and potential leads",
      "Moderate connections and potential leads",
      "Extensive network and a significant book of business",
    ],
    isQuestion: true,
  },
  {
    label:
      "In your previous roles, what was the average number of warm appointments or leads you generated per month?",
    key: "In_your_previous_roles_what_was_the_average_number_of_warm_appointments_or_leads_you_generated_per_month?",
    type: "radio",
    options: ["Less than 10", "10-30", "31-50", "Over 50+"],
    isQuestion: true,
  },
  {
    label:
      "What type of industry do you have the most knowledge of or connections with that might benefit from the serrvice?",
    key: "What_type_of_industry_do_you_have_the_most_knowledge_of_or_connections_with_that_might_benefit_from_the_serrvice?",
    type: "radio",
    options: [
      "Healthcare and medical",
      "Technology and software",
      "Finance and banking",
      "Real estate and property management",
      "Retail and e-commerce",
      "Manufacturing and industrial",
      "Other",
    ],
    isQuestion: true,
  },
  {
    label: "How many employers do you know with 10 or more W2 employees?",
    key: "How_many_employers_do_you_know_with_10_or_more_W2_employees?",
    type: "radio",
    options: ["None", "A few (1-5)", "Several (6-10)", "Many (25+)"],
    isQuestion: true,
  },
  {
    label:
      "What is your preferred approach to setting appointments: cold calling, email outreach, social media, or a combination of methods?",
    key: "What_is_your_preferred_approach_to_setting_appointments_cold_calling_email_outreach_social_media_or_a_combination_of_methods?",
    type: "radio",
    options: [
      "Cold calling only",
      "Email outreach primarily",
      "Social media primarily",
      "Combination of methods based on client needs",
      "Warm networking and outreach",
    ],
    isQuestion: true,
  },
  {
    label:
      "Do you have access to decision-makers or key contacts within the businesses or employers you are familiar with?",
    key: "Do_you_have_access_to_decision_makers_or_key_contacts_within_the_businesses_or_employers_you_are_familiar_with?",
    type: "radio",
    options: [
      "No access to decision-makers",
      "Limited access to a few decision-makers",
      "Moderate access to key contacts",
      "Extensive access to decision-makers and key contacts",
    ],
    isQuestion: true,
  },
  {
    label: "Where is your current location or place of residence?",
    key: "Where_is_your_current_location_or_place_of_residence?",
    type: "radio",
    options: ["North America", "Europe", "Asia", "Other"],
    isQuestion: true,
  },
  {
    label: "What is your native language or primary language of communication?",
    key: "What_is_your_native_language_or_primary_language_of_communication?",
    type: "radio",
    options: ["English", "Spanish", "Mandarin", "Hindi", "Other"],
    isQuestion: true,
  },
  // {
  //   label: "Why should we approve your application to become an affiliate?",
  //   key: "Why_should_we_approve_your_application_to_become_an_affiliate?",
  //   type: "textarea",
  //   isQuestion: true,
  // },
  {
    label: "Referred By",
    key: "referCode",
    type: "searchable_input",
  },
];

export const accepctAggrimentQuestions = [
  "1. Sign the affiliate agreement.",
  "2. Fill out the ACH and W9 forms.",
  "3. Complete the mandatory training.",
  "4. Successfully pass the associated quiz.",
];
