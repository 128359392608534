import OneSignal from "react-onesignal";

export default class OneSignalHelper {
  static showPrompt() {
    OneSignal.showSlidedownPrompt({ force: true });
  }

  static getNotificationPermission() {
    return new Promise((resolve) => {
      OneSignal.isPushNotificationsEnabled((isEnabled) => {
        resolve(isEnabled);
      });
    });
  }

  static onEnableSubscription(callback) {
    // Occurs when the user's subscription changes to a new value.
    OneSignal.on("subscriptionChange", function (isSubscribed) {
      if (isSubscribed) callback();
    });
  }

  static getDeviceId() {
    return new Promise((resolve) => {
      OneSignal.getUserId(function (userId) {
        resolve(userId);
      });
    });
  }
}