import React, { useState } from "react";
import { useEffect } from "react";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Container, Form, FormGroup, Input, Label } from "reactstrap";
import { questionnaireNextStepConfig } from "../../config/questionnaireConfig";
import { decodeToken, errorHandler } from "../../helper-methods";
import { updateLeadMeetBooking } from "../../http/http-calls";
import { hideLoader, showLoader } from "../../redux/actions/loader-data";

const links = [
  "https://calendly.com/d/3d6-fyb-rh6/fica-save-10-100",
  "https://calendly.com/d/5cf-tzg-x6q/fica-save-100-500",
  "https://calendly.com/d/5bn-wvf-md5/fica-save-500",
];

const QuestionnaireNextStep = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useCalendlyEventListener({
    onProfilePageViewed: () => console.log("onProfilePageViewed"),
    onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
    onEventTypeViewed: () => console.log("onEventTypeViewed"),

    onEventScheduled: (e) => {
      console.log("scheduled response", e);
      _updateLeadMeetBooking({ isMeetingBook: true });
      // finalPayloadWithMeetingUri["meetingUri"] = e.data.payload.event.uri || "";
    },
  });

  const [link, setLink] = useState("");
  const [isAskQuestion, setIsAskQuestion] = useState(false);
  const [isQuestionnaireDone, setIsQuestionnaireDone] = useState(false);
  const [formFields, setFormFields] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [isDirty, setIsDirty] = useState({});
  const [errors, setErrors] = useState({});

  const _validateFormFields = ({ newFormFields, newIsDirty }) => {
    return new Promise((resolve) => {
      const newErrors = {};
      let isFormValid = true;

      if (newFormFields) {
        Object.keys(newFormFields).forEach((key) => {
          if (newIsDirty[key]) {
            if (newFormFields[key]?.trim().length) {
              newErrors[key] = null;
              newIsDirty[key] = false;
            } else {
              newErrors[key] = "*Required";
              isFormValid = false;
            }
          }
        });
      }

      setErrors((prev) => ({
        ...prev,
        ...newErrors,
      }));

      setIsDirty((prev) => ({
        ...prev,
        ...newIsDirty,
      }));

      resolve(isFormValid);
    });
  };

  const _onChangeFormFields = (key, value) => {
    if (
      (key === "phone" ||
        key === "How_many_people_will_you_hire_within_the_next_6_12_months?") &&
      (isNaN(value) || value.includes(".") || Number(value) < 0)
    )
      return;

    const newFormFields = { ...formFields };
    newFormFields[key] = value;
    setFormFields(newFormFields);
  };

  const _onBlurFormFields = (key) => {
    const newFormFields = { ...formFields };
    const newIsDirty = {
      [key]: true,
    };
    _validateFormFields({ newFormFields, newIsDirty });
  };

  const _updateLeadMeetBooking = async (payload) => {
    try {
      dispatch(showLoader("Loading..."));

      payload = {
        ...payload,
        token: localStorage?.questionnaire_next_step_token,
      };

      await updateLeadMeetBooking(payload);

      delete localStorage?.questionnaire_next_step_token;

      dispatch(hideLoader());

      setIsQuestionnaireDone(true);
    } catch (error) {
      errorHandler(error);
      dispatch(hideLoader());
    }
  };

  const _onSaveDetails = async (e) => {
    try {
      if (e) e.preventDefault();

      const newFormFields = { ...formFields };

      const newIsDirty = {};

      questionnaireNextStepConfig.forEach((each) => {
        newIsDirty[each.key] = true;
      });

      const isFormValid = await _validateFormFields({
        newFormFields,
        newIsDirty,
      });

      if (!isFormValid) return;

      // isMeetingBook

      const payload = {
        // _referBy: formFields._referBy?.value,
        leadQuestionResponse: [], // { questionText: String, response: String }
      };

      questionnaireNextStepConfig.forEach((each) => {
        if (each.isQuestion) {
          payload.leadQuestionResponse.push({
            questionText: each.label,
            response: formFields[each.key],
          });
        }
      });

      _updateLeadMeetBooking(payload);
    } catch (error) {
      errorHandler(error);
    }
  };

  const _renderFormFields = () => {
    return questionnaireNextStepConfig.map((each) => {
      switch (each.type) {
        case "input": {
          return (
            <div className="mb-3" key={each.key}>
              <Label for="exampleText">{each.label}</Label>
              <Input
                type="text"
                value={formFields[each.key] || ""}
                name={each.key}
                onChange={(e) => _onChangeFormFields(each.key, e.target.value)}
                onBlur={() => _onBlurFormFields(each.key)}
              />
              {errors[each.key] ? (
                <div className="form-error">{errors[each.key]}</div>
              ) : null}
            </div>
          );
        }
        case "radio": {
          return (
            <div className="mb-3" key={each.key}>
              <Label>{each.label}</Label>
              {each.options.map((option) => (
                <FormGroup check key={`${each.key}_${option}`}>
                  <Input
                    name={each.key}
                    id={`${each.key}_${option}`}
                    type="radio"
                    checked={formFields[each.key] === option ? true : false}
                    value={option}
                    onChange={(e) =>
                      _onChangeFormFields(each.key, e.target.value)
                    }
                    onBlur={() => _onBlurFormFields(each.key)}
                  />
                  <Label check for={`${each.key}_${option}`}>
                    {option}
                  </Label>
                </FormGroup>
              ))}
              {errors[each.key] ? (
                <div className="form-error">{errors[each.key]}</div>
              ) : null}
            </div>
          );
        }
        default:
          return null;
      }
    });
  };

  const _initialize = () => {
    try {
      const token = localStorage?.questionnaire_next_step_token;

      const decodedTokenDetails = decodeToken(token);

      const employees = +decodedTokenDetails?.leadQuestionResponse?.find(
        (each) =>
          each.questionText ===
          "How many W2 employees do you currently have on payroll?"
      )?.response;

      const newFormFields = { ...formFields };
      questionnaireNextStepConfig.forEach((each) => {
        newFormFields[each.key] = "";
      });
      setFormFields(newFormFields);

      if (employees >= 0 && employees <= 9) {
        setIsAskQuestion(true);
      } else if (employees >= 10 && employees <= 100) {
        setLink(links[0]);
      } else if (employees >= 100 && employees <= 500) {
        setLink(links[1]);
      } else if (employees > 500) {
        setLink(links[2]);
      } else {
        navigate("/login");
      }
    } catch (error) {
      errorHandler(error);
      navigate("/login");
    }
  };

  useEffect(() => {
    _initialize();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {link ? (
        <>
          <Container>
            <div
              className="d-flex align-items-center justify-content-center flex-column"
              style={{ minHeight: "100vh" }}
            >
              <div className="questionnaireVideo">
                {/* <video
                  src={CLIENT_INTRO_VIDEO}
                  controls
                  // poster={require("../../assets/img/questionnaire.jpg")}
                  disablePictureInPicture
                  controlsList="nodownload"
                /> */}

                <p>
                  <img src={require("../../assets/img/checked.png")} alt="" />
                  Thank you for submitting your application! We will review it,
                  and contact you soon. <br /> Schedule a call with our team, so
                  we can help you save!
                </p>
              </div>

              <InlineWidget url={link} />
            </div>
          </Container>
        </>
      ) : isAskQuestion ? (
        <>
          <div className="authPgWrapper affiliate-questionnaire">
            <div className="authFormWrap">
              <div className="authForm">
                {isQuestionnaireDone ? (
                  <div className="questionnaireVideo">
                    <p>
                      {/* <img
                        src={require("../../assets/img/checked.png")}
                        alt=""
                      /> */}
                      Unfortunately you are not qualified for the program. Our
                      team will contact you when there is an update to the
                      program.
                    </p>
                  </div>
                ) : (
                  <>
                    <h2 className="form-title">Questionnaire</h2>

                    <Form onSubmit={(e) => _onSaveDetails(e)}>
                      {_renderFormFields()}

                      <Button type="submit" color="primary" className=" my-3">
                        Submit
                      </Button>
                    </Form>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default QuestionnaireNextStep;
