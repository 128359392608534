import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import PublicFooter from "../../containers/Public/PublicFooter";
import { decodeToken, errorHandler } from "../../helper-methods";
import { docuSignAcceptAgreement, getUserById } from "../../http/http-calls";
import { hideLoader, showLoader } from "../../redux/actions/loader-data";
// import CustomTrainingSteps from "../../components/CustomTrainingSteps";
import AccepctAggrimentModal from "../../components/modals/AccepctAggrimentModal";

const DocuSignAcceptAgreementPage = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const [isError, setIsError] = useState(false);
  const [agreementModal, setAgreementModal] = useState(false);

  const _docuSignAcceptAgreement = async () => {
    try {
      dispatch(showLoader("Loading..."));

      const {
        basicUrl: { redirectUrl },
      } = await docuSignAcceptAgreement({
        status: "Accept",
        isTempToken: true,
      });

      delete localStorage.temp_token;

      dispatch(hideLoader());
      window.open(redirectUrl, "_self");
    } catch (error) {
      errorHandler(error);
      setIsError(true);
      dispatch(hideLoader());

      delete localStorage.temp_token;
    }
  };

  const _checkAuthUser = async () => {
    try {
      const { token } = params;
      console.log({ token });

      const decodedToken = decodeToken(token);
      console.log({ decodedToken });

      localStorage.temp_token = token;
    } catch (error) {
      if (error?.message?.includes("Invalid token specified")) {
        errorHandler({ reason: "Unauthorized" });
      } else {
        errorHandler(error);
      }
      delete localStorage.temp_token;
      setIsError(true);
    }
  };

  const _getUserData = async () => {
    const { token } = params;
    console.log({ token });

    const decodedToken = decodeToken(token);

    try {
      const res = await getUserById(decodedToken?._id, { isTempToken: true });
      if (!res?.user?.isAcceptForSignature && res?.user?.isVerified) {
        setAgreementModal(true);
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    _checkAuthUser();
    _getUserData();
    return () => {
      delete localStorage.temp_token;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* <CustomTrainingSteps activeTabIndex={1} /> */}
      <div className="docSignStatus">
        {isError ? (
          <p className="text-danger">Unauthorized</p>
        ) : (
          <div className="acceptAgreementBox">
            {/* <h1 className="title mb-4 d-flex">
              Please{" "}
              <u
                className="cursorPointer mx-2 p-0 underline themeColor"
                onClick={() => _docuSignAcceptAgreement()}
              >
                Click Here
              </u>{" "}
              to Review & Sign the Agreement!
            </h1> */}

            <h3 className="title">
              <img src={require("../../assets/img/checked.png")} alt="" />
              Verified your account.
            </h3>
            <h5 className="sub-title">
              Please review and complete your account registration.
            </h5>
            <u
              className="cursorPointer mx-2 p-0 underline themeColor"
              style={{ fontSize: "22px" }}
              onClick={() => _docuSignAcceptAgreement()}
            >
              Begin Now
            </u>
          </div>
        )}
      </div>
      <AccepctAggrimentModal
        isOpen={agreementModal}
        toggle={() => setAgreementModal(false)}
      />

      <PublicFooter />
    </>
  );
};

export default DocuSignAcceptAgreementPage;
