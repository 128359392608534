import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  Button,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Spinner,
} from "reactstrap";
import Swal from "sweetalert2";
import { capitalize, errorHandler, formatDate } from "../../helper-methods";
import { deleteQuiz, getAllQuiz } from "../../http/http-calls";
import AddQuizModal from "../../components/modals/AddQuizModal";
import SvgIcons from "../../components/SvgIcons";
import CustomTable from "../../components/Table/CustomTable";
import { alphabetArray } from "../../config/helper-config";
import CustomDateRangePicker from "../../components/CustomDateRangePicker";
import SkeletonLoading from "../../components/SkeletonLoading";

const headerKeys = [
  { label: "_id", id: "_id" },
  { label: "Question #", id: "number" },
  { label: "Question", id: "question" },
  { label: "Added On", id: "createdAt" },
  // { label: "Type", id: "type" },
  { label: "Answer", id: "answer" },
  { label: "Action", id: "action" },
];

const QuizBankPage = ({ activeTab, tabId }) => {
  const searchInterval = useRef({ current: null });
  const customDateRangePickerRef = useRef({ current: null });

  const [data, setData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [dataPayload, setDataPayload] = useState({
    skip: 0,
    pageNumber: 0,
    page: 1,
    limit: 10,
    search: "",
    filters: {},
  });
  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
  });
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [filtersLoading, setFiltersLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  const [addQuizModal, setAddQuizModal] = useState({
    isOpen: false,
    data: null,
  });

  const _toggleAddQuizModal = (isOpen = false, data = null) => {
    setAddQuizModal({ isOpen, data });
  };

  const _dataFormat = (cell, row, header, index) => {
    switch (header) {
      case "number": {
        return (
          <>
            <div style={{ width: 50, fontWeight: 600 }}>
              {loading ? (
                <SkeletonLoading type="box" count={1} height={15} width={25} />
              ) : (
                index + 1 + (dataPayload?.page - 1) * dataPayload?.limit
              )}
            </div>
          </>
        );
      }
      case "question": {
        return (
          <>
            <div className="lineClimp">
              {row?.question ? capitalize(row?.question) : "N/A"}
            </div>
          </>
        );
      }
      case "createdAt": {
        return (
          <>
            <div style={{ width: 110 }}>
              {formatDate(row?.createdAt) || "-"}
            </div>
          </>
        );
      }
      case "type": {
        return (
          <>
            <div style={{ width: 100 }}>Multi Type</div>
          </>
        );
      }
      case "answer": {
        return (
          <>
            <div style={{ width: 50 }}>
              {alphabetArray[row?.correctOption - 1]}
            </div>
          </>
        );
      }
      case "action": {
        return (
          <>
            <div className="d-flex">
              <Button
                color="link"
                onClick={() => _toggleAddQuizModal(true, row)}
              >
                <SvgIcons type={"edit"} />
              </Button>

              <Button
                color="link"
                className="remove"
                onClick={() => _deleteQuizAlert(row)}
              >
                <SvgIcons type={"delete"} />
              </Button>
            </div>
          </>
        );
      }
      default: {
        return cell;
      }
    }
  };

  const _getAllQuiz = async (payload) => {
    try {
      setLoading(true);

      const res = await getAllQuiz(payload);

      setData(res.quizzes);
      setDataCount(res.quizzesCount);

      setLoading(false);
      setSearchLoading(false);
      setFiltersLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
      setSearchLoading(false);
      setFiltersLoading(false);
    }
  };

  const _onSizeChange = (limit = 10) => {
    const newDataPayload = { ...dataPayload };
    newDataPayload["skip"] = 0;
    newDataPayload["pageNumber"] = 0;
    newDataPayload["page"] = 1;
    newDataPayload["limit"] = limit;
    setDataPayload(newDataPayload);
    _getAllQuiz(newDataPayload);
  };

  const _onPageChange = (pageNumber = 0) => {
    const newDataPayload = { ...dataPayload };
    newDataPayload["skip"] = pageNumber * newDataPayload["limit"];
    newDataPayload["pageNumber"] = pageNumber;
    newDataPayload["page"] = pageNumber + 1;
    setDataPayload(newDataPayload);
    _getAllQuiz(newDataPayload);
  };

  const _onChangeSearch = (searchValue) => {
    if (searchInterval?.current) clearInterval(searchInterval.current);
    setSearchLoading(true);
    const newDataPayload = { ...dataPayload };
    newDataPayload["search"] = searchValue;
    newDataPayload["skip"] = 0;
    newDataPayload["pageNumber"] = 0;
    newDataPayload["page"] = 1;
    setDataPayload(newDataPayload);
    searchInterval.current = setTimeout(() => {
      _getAllQuiz(newDataPayload);
    }, 1000);
  };

  const _onFiltersUpdated = (newFilters) => {
    const newDataPayload = { ...dataPayload };
    newDataPayload["skip"] = 0;
    newDataPayload["pageNumber"] = 0;
    newDataPayload["page"] = 1;
    newDataPayload["filters"] = {};

    Object.keys(newFilters).forEach((eachFilterKey) => {
      if (newFilters[eachFilterKey]) {
        newDataPayload.filters[eachFilterKey] = newFilters[eachFilterKey];
      }
    });

    setDataPayload(newDataPayload);

    _getAllQuiz(newDataPayload);
  };

  const _onDatesChange = ({ startDate, endDate }) => {
    setFiltersLoading(true);

    const newFilters = { ...filters };
    newFilters.startDate = startDate;
    newFilters.endDate = endDate;
    setFilters(newFilters);

    if ((!startDate && !endDate) || (startDate && endDate)) {
      _onFiltersUpdated(newFilters);
    }
  };

  const _clearFilters = () => {
    if (customDateRangePickerRef?.current?.resetState) {
      customDateRangePickerRef.current.resetState();
    }

    setFiltersLoading(true);

    const newFilters = { ...filters };
    Object.keys(newFilters).forEach((key) => {
      newFilters[key] = "";
    });
    setFilters(newFilters);

    const newDataPayload = { ...dataPayload };
    newDataPayload["search"] = "";
    newDataPayload["skip"] = 0;
    newDataPayload["pageNumber"] = 0;
    newDataPayload["page"] = 1;
    newDataPayload["filters"] = {};
    setDataPayload(newDataPayload);

    _getAllQuiz(newDataPayload);
  };

  const _deleteQuiz = async (id) => {
    try {
      const newData = [...data];
      let newDataCount = dataCount;
      const findDataIndex = newData.findIndex((each) => each._id === id);
      if (findDataIndex > -1) {
        newData.splice(findDataIndex, 1);
        newDataCount--;
        setData(newData);
        setDataCount(newDataCount);
      }

      await deleteQuiz(id);

      if (newData.length === 0 || newDataCount <= 10) {
        _onPageChange();
      }

      Swal.fire("Deleted!", "Quiz has been deleted.", "success");
    } catch (error) {
      errorHandler(error);
      _getAllQuiz(dataPayload);
    }
  };

  const _deleteQuizAlert = (data) => {
    if (!data?._id) {
      errorHandler();
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete "${data?.question || ""}" Quiz.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#31c5c7",
      cancelButtonColor: "#F16667",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        _deleteQuiz(data._id);
      }
    });
  };

  useEffect(() => {
    if (activeTab === tabId) {
      _getAllQuiz(dataPayload);
    }

    // eslint-disable-next-line
  }, [activeTab, tabId]);

  return (
    <>
      <div className="pgTitleWrap">
        <h2>Quiz Bank {loading || filtersLoading ? <Spinner /> : null}</h2>
        <div className="right">
          <Button color="primary" onClick={() => _toggleAddQuizModal(true)}>
            <span> Add Quiz</span>
            <div className="plusIcon">
              <SvgIcons type={"plus"} />
            </div>
          </Button>

          <div
            className="filterBarIcon"
            onClick={() => setShowFilter(!showFilter)}
          >
            <SvgIcons type={"filter"} />
          </div>

          <Button
            color="link"
            className="btn-reset"
            onClick={() => _clearFilters()}
          >
            <SvgIcons type={"reset"} />
          </Button>
        </div>
      </div>

      <div
        className={`filterWrapper ${showFilter === true ? "showFilter" : ""}`}
      >
        <i className="fas fa-filter filterIcon" />

        <div className="filterForm">
          <div className="formGroup searchBar">
            <Label>Search</Label>
            <InputGroup className="searchTable">
              <Input
                type="text"
                name="search"
                placeholder="Search"
                autoComplete="off"
                value={dataPayload.search}
                onChange={(e) => _onChangeSearch(e.target.value)}
              />
              <InputGroupText>
                {searchLoading ? (
                  <i className="fa fa-spinner fa-spin" />
                ) : dataPayload.search ? (
                  <i
                    className="fa fa-times"
                    onClick={() => _onChangeSearch("")}
                  />
                ) : (
                  <i className="fa fa-search" />
                )}
              </InputGroupText>
            </InputGroup>
          </div>
          <div className="formGroup">
            <Label>Added Date</Label>

            <CustomDateRangePicker
              ref={customDateRangePickerRef}
              startDate={filters?.startDate}
              endDate={filters?.endDate}
              startDateId={"startDateId_QuizBankPageFilter"}
              endDateId={"endDateId_QuizBankPageFilter"}
              onDatesChange={_onDatesChange}
            />
          </div>

          {/* <div className="formGroup importIcon">
            <Button color="link">
              <SvgIcons type={"import"} />
              Import Question
            </Button>
          </div> */}
        </div>
      </div>

      <CustomTable
        data={data}
        dataCount={dataCount}
        dataPayload={dataPayload}
        onPageChange={_onPageChange}
        onSizeChange={_onSizeChange}
        loading={loading}
        isRowSelection={false}
        headerKeys={headerKeys}
        dataFormat={_dataFormat}
      />

      <AddQuizModal
        isOpen={addQuizModal.isOpen}
        data={addQuizModal.data}
        toggle={() => _toggleAddQuizModal()}
        onSuccess={() => _getAllQuiz(dataPayload)}
      />
    </>
  );
};

export default QuizBankPage;
