import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  FormGroup,
  Input,
  InputGroup,
} from "reactstrap";
import {
  fetchCategories,
  fetchresources,
  getAffiliateInfo,
} from "../../http/http-calls";
import {
  debounce,
  errorHandler,
  openUrlInSameTab,
  openUrlOnNewTab,
} from "../../helper-methods";
import SkeletonLoading from "../../components/SkeletonLoading";
import CustomPagination from "../../components/Table/CustomPagination";
import { REQUEST_INVITE_THUMBNAIL, REQUEST_INVITE_VIDEO } from "../../config";
import QuizTestModal from "../../components/modals/QuizTestModal";
import CustomTrainingSteps from "../../components/CustomTrainingSteps";
import { useDispatch, useSelector } from "react-redux";
import { updateUserData } from "../../redux/actions/user-credential";
import AccepctAggrimentModal from "../../components/modals/AccepctAggrimentModal";
// import CustomTrainingSteps from "../../components/CustomTrainingSteps";

const TrainingUser = () => {
  const dispatch = useDispatch();

  const userCredential = useSelector((state) => state?.userCredential);

  const [categoryPayload, setCategoryPayload] = useState({
    pageNumber: 0,
    limit: 10,
    page: 1,
    search: "",
  });

  const [resoucePayload, setResoucePayload] = useState({
    pageNumber: 0,
    limit: 10,
    page: 1,
    search: "",
  });

  const [categories, setCategories] = useState([]);
  const [categoryCount, setCategoryCount] = useState(0);

  const [resouces, setResouces] = useState([]);
  const [resouceCount, setResourceCount] = useState(0);

  const [searchLoading, setSearchLoading] = useState(false);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [resourceLoading, setResourceLoading] = useState(false);

  const [userDetails, setUserDetails] = useState(null);

  const [quizTestModal, setQuizTestModal] = useState({
    isOpen: false,
    data: null,
  });
  const [agreementModal, setAgreementModal] = useState(false);

  const _toggleQuizTestModal = (isOpen = false, data = null) => {
    setQuizTestModal({ isOpen, data });
  };

  const _getAffiliateInfo = async () => {
    try {
      const res = await getAffiliateInfo();
      dispatch(
        updateUserData({
          user: userCredential?.user,
          notificationCount: userCredential?.notificationCount,
          showHeader: res.user?.isVerified || res?.user?.completedAllTopic,
        })
      );
      setUserDetails(res.user);
      if (
        !res?.user?.isVerified &&
        // res?.user?.isAddedByForm &&
        !res?.user?.isAcceptForSignature &&
        res?.user?.affiliateType !== "Leader"
      ) {
        setAgreementModal(true);
      }
      if (
        res?.user?.isVerified &&
        // res?.user?.isAddedByForm &&
        res?.user?.isAcceptForSignature &&
        res?.user?.affiliateType !== "Leader" && res?.user?.isVerifiedForDocuSign
      ) {
        if (res?.user?.stageOfSign === "noSigned") {
          openUrlInSameTab(
            `/docu-sign-accept-agreement/${userCredential?.token}`
          );
        } else if (res?.user?.stageOfSign !== "combineAchw9Signed") {
          localStorage.temp_token = userCredential?.token;
          openUrlInSameTab(
            `/agreementsigned/${userCredential?.user?._id}?event=signing_complete`
          );
        }
      } else {
        if (
          res?.user?.isVerified &&
          res?.user?.stageOfSign !== "combineAchw9Signed" &&
          res?.user?.affiliateType === "Leader" && res?.user?.isVerifiedForDocuSign
        ) {
          localStorage.temp_token = userCredential?.token;
          openUrlInSameTab(
            `/agreementsigned/${userCredential?.user?._id}?event=signing_complete`
          );
        }
      }
      if (res?.user?.isVerified && !res?.user?.isAddedByForm) {
        dispatch(
          updateUserData({
            user: userCredential?.user,
            notificationCount: userCredential?.notificationCount,
            showHeader: true,
          })
        );
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const _fetchAllCategory = async (newPayload) => {
    setCategoryLoading(true);

    try {
      const res = await fetchCategories(
        newPayload ? newPayload : categoryPayload
      );

      setCategories(res?.faqCategories);
      setCategoryCount(res?.totalCount);
    } catch (error) {
      errorHandler(error);
    }

    setSearchLoading(false);
    setCategoryLoading(false);
  };

  const _fetchAllResources = async (newPayload) => {
    setResourceLoading(true);
    try {
      const res = await fetchresources(
        newPayload ? newPayload : resoucePayload
      );

      setResouces(res?.faqMiscellaneous);
      setResourceCount(res?.totalCount);
    } catch (error) {
      errorHandler(error);
    }
    setResourceLoading(false);
  };

  const _checkHandler = async (data) => {
    _fetchAllCategory(data);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const optimizedFn = useCallback(debounce(_checkHandler), []);

  const _searchFilterHandler = async (field, value) => {
    setSearchLoading(true);

    const newCategoryPayload = { ...categoryPayload };

    newCategoryPayload[field] = value;

    newCategoryPayload["pageNumber"] = 0;
    newCategoryPayload["page"] = 1;

    setCategoryPayload(newCategoryPayload);

    if (field === "search") {
      optimizedFn({
        ...newCategoryPayload,
        search: newCategoryPayload.search.trim(),
      });
    } else {
      _checkHandler(newCategoryPayload);
    }
  };

  const _onCategorySizeChange = (limit = 10) => {
    const newDataPayload = { ...categoryPayload };
    newDataPayload["skip"] = 0;
    newDataPayload["pageNumber"] = 0;
    newDataPayload["page"] = 1;
    newDataPayload["limit"] = limit;
    setCategoryPayload(newDataPayload);
    _fetchAllCategory(newDataPayload);
  };

  const _onCategoryPageChange = (pageNumber = 0) => {
    const newDataPayload = { ...categoryPayload };
    newDataPayload["pageNumber"] = pageNumber;
    newDataPayload["page"] = pageNumber + 1;
    setCategoryPayload(newDataPayload);
    _fetchAllCategory(newDataPayload);
  };

  const _onResourceSizeChange = (limit = 10) => {
    const newDataPayload = { ...resoucePayload };
    newDataPayload["skip"] = 0;
    newDataPayload["pageNumber"] = 0;
    newDataPayload["page"] = 1;
    newDataPayload["limit"] = limit;
    setResoucePayload(newDataPayload);
    _fetchAllResources(newDataPayload);
  };

  const _onResourcePageChange = (pageNumber = 0) => {
    const newDataPayload = { ...resoucePayload };
    newDataPayload["pageNumber"] = pageNumber;
    newDataPayload["page"] = pageNumber + 1;
    setResoucePayload(newDataPayload);
    _fetchAllResources(newDataPayload);
  };

  const _onGoingTopic = useCallback((topics = []) => {
    if (!topics?.length) return;

    const totalTopicsCount = topics.length;

    const watchedTopicsCount = topics.reduce((acc, each) => {
      if (
        each?.onGoingTopic?.[0]?._topic === each._id &&
        each?.onGoingTopic?.[0]?.isCompleted
      ) {
        return acc + 1;
      }
      return acc;
    }, 0);

    return watchedTopicsCount === totalTopicsCount
      ? `Completed`
      : `${watchedTopicsCount} out of ${totalTopicsCount} topics watched`;
  }, []);

  useEffect(() => {
    _getAffiliateInfo();
    _fetchAllCategory();
    _fetchAllResources();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="pgTitleWrap">
        <h2>Training</h2>
      </div>

      {userDetails?.stageOfSign === "combineAchw9Signed" || userDetails?.affiliateType === "Leader" ? null : (
        <CustomTrainingSteps
          activeTabIndex={
            !userDetails?.completedAllTopic
              ? 1
              : userDetails?.quizAttempt === 0
              ? 2
              : !userDetails?.isVerified
              ? 3
              : 4
          }
        />
      )}

      {userDetails?.completedAllTopic &&
      !userDetails?.hasClearedQuiz &&
      !userDetails?.failedInQuiz && userDetails?.affiliateType !== "Leader" ? (
        <Alert color="warning" className="text-center">
          You are now eligible to proceed with the Quiz
          <Button
            color="link"
            onClick={() => _toggleQuizTestModal(true, userDetails)}
          >
            Start Quiz
          </Button>
        </Alert>
      ) : null}
      <div className="TrainingWrap">
        <div className="helpSearch">
          <FormGroup>
            <InputGroup>
              <Input
                type="text"
                id="input1-group2"
                name="input1-group2"
                placeholder="Search"
                value={categoryPayload.search}
                onChange={(e) => _searchFilterHandler("search", e.target.value)}
              />

              <Button type="button" color="primary">
                {searchLoading ? (
                  <i className="fa fa-spinner fa-spin" />
                ) : categoryPayload.search ? (
                  <i
                    className="fa fa-times"
                    onClick={() => _searchFilterHandler("search", "")}
                  />
                ) : (
                  <i className="fa fa-search" />
                )}
              </Button>
            </InputGroup>
          </FormGroup>

          {/* {userDetails?.isVerified && REQUEST_INVITE_VIDEO ? (
            <>
              <div className="mediaPhotoWrap-Feed mt-0">
                <video
                  poster={REQUEST_INVITE_THUMBNAIL}
                  src={REQUEST_INVITE_VIDEO}
                  controls
                  controlsList="nodownload"
                  disablePictureInPicture
                />
              </div>

              <hr />
            </>
          ) : null} */}
        </div>

        <div className="categoriesWrap">
          {categories?.length ? (
            categories?.map((category, index) => (
              <Link
                key={index}
                to={`/affiliate/training-list/${category?._id}?categoryName=${category?.category}&description=${category?.description}`}
              >
                {category?.isMandatoryToSee ? <p>Mandatory To Watch</p> : null}
                <h5>{category?.category}</h5>
                <p>{category?.description}</p>

                {category?.topics?.length ? (
                  <div className="mt-2">{_onGoingTopic(category?.topics)}</div>
                ) : null}
              </Link>
            ))
          ) : categoryLoading ? (
            <SkeletonLoading type="card" count={3} />
          ) : (
            <>
              <div className="nodata">
                <img
                  src={require("../../assets/img/noData.svg").default}
                  alt="no data"
                />
                <p>There is no data to display</p>
              </div>
            </>
          )}
        </div>

        <CustomPagination
          data={categories}
          dataCount={categoryCount}
          dataPayload={categoryPayload}
          onPageChange={_onCategoryPageChange}
          onSizeChange={_onCategorySizeChange}
        />

        {resouces?.length ? (
          <Card className="cardDesign mt-3">
            <CardHeader>
              <CardTitle>Additional Resources</CardTitle>
            </CardHeader>

            <CardBody style={{ padding: "10px" }}>
              <div className="categoriesWrap">
                {resouces?.length ? (
                  resouces?.map((resouce, index) => (
                    <Link tag="a" key={index}>
                      <h5 onClick={() => openUrlOnNewTab(resouce?.url)}>
                        {resouce?.title}
                      </h5>
                    </Link>
                  ))
                ) : resourceLoading ? (
                  <SkeletonLoading type="card" count={3} />
                ) : (
                  <>
                    <div className="nodata">
                      <img
                        src={require("../../assets/img/noData.svg").default}
                        alt="no data"
                      />
                      <p>There is no data to display</p>
                    </div>
                  </>
                )}
              </div>
              <CustomPagination
                data={resouces}
                dataCount={resouceCount}
                dataPayload={resoucePayload}
                onPageChange={_onResourcePageChange}
                onSizeChange={_onResourceSizeChange}
              />
            </CardBody>
          </Card>
        ) : null}
      </div>

      <QuizTestModal
        isOpen={quizTestModal.isOpen}
        data={quizTestModal.data}
        toggle={() => _toggleQuizTestModal()}
        onSuccess={() => _getAffiliateInfo()}
      />

      <AccepctAggrimentModal
        isOpen={agreementModal}
        toggle={() => setAgreementModal(false)}
        affiliateType={userDetails?.affiliateType}
      />
    </>
  );
};

export default TrainingUser;
