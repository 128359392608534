import React from "react";
import { FormGroup, Input } from "reactstrap";

const StatusChangeComponent = ({
  value,
  onChange,
  options,
  disabled,
  type,
}) => {
  return type ? (
    <FormGroup switch>
      <Input
        type="switch"
        checked={value}
        onChange={(e) => onChange(e)}
        disabled={disabled}
      />
    </FormGroup>
  ) : (
    <Input
      type="select"
      value={value}
      onChange={(e) => onChange(e)}
      disabled={disabled}
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Input>
  );
};

export default StatusChangeComponent;
