import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, CardBody, Col, Progress, Row } from "reactstrap";
import { getTopicFromCategoryId } from "../../http/http-calls";
import { errorHandler, extractQueryParams } from "../../helper-methods";
import SkeletonLoading from "../../components/SkeletonLoading";
import CustomPagination from "../../components/Table/CustomPagination";
import SvgIcons from "../../components/SvgIcons";

const TrainingUserList = () => {
  const navigate = useNavigate();

  const params = useParams();

  const query = extractQueryParams();

  const [payload, setPayload] = useState({
    pageNumber: 0,
    limit: 10,
    page: 1,
    categoryId: params?.id,
  });

  const [topics, setTopics] = useState([]);
  const [loading, setLoading] = useState(false);
  const [topicCount, setTopicCount] = useState(0);

  const _fetchAllTopics = async (newPayload) => {
    setLoading(true);
    try {
      const res = await getTopicFromCategoryId(
        newPayload ? newPayload : payload
      );

      const newTopics = res?.topics?.length ? res.topics : [];

      if (res.onGoingTopic?.length) {
        newTopics.forEach((each, index) => {
          const findTracking = res.onGoingTopic.find(
            (subEach) => subEach._topic === each._id
          );
          if (findTracking) {
            newTopics[index].isStarted = findTracking.isStarted;
            newTopics[index].isCompleted = findTracking.isCompleted;
            newTopics[index].watchedTill = findTracking.watchedTill;
          }
        });
      }

      setTopics(newTopics);

      setTopicCount(res?.totalCount);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const _onSizeChange = (limit = 10) => {
    const newDataPayload = { ...payload };
    newDataPayload["skip"] = 0;
    newDataPayload["pageNumber"] = 0;
    newDataPayload["page"] = 1;
    newDataPayload["limit"] = limit;
    setPayload(newDataPayload);
    _fetchAllTopics(newDataPayload);
  };

  const _onPageChange = (pageNumber = 0) => {
    const newDataPayload = { ...payload };
    newDataPayload["pageNumber"] = pageNumber;
    newDataPayload["page"] = pageNumber + 1;
    setPayload(newDataPayload);
    _fetchAllTopics(newDataPayload);
  };

  useEffect(() => {
    _fetchAllTopics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="pgTitleWrap">
        <h2>
          <Button
            color="link"
            className="backBtn"
            onClick={() => navigate(`/affiliate/training-user`)}
          >
            <i className="fas fa-chevron-left" />
          </Button>
          Training List
        </h2>
      </div>
      <div className="trainingWrap">
        <h2>{query?.categoryName}</h2>
        <div className="trainingCard">
          <Row>
            {topics?.length ? (
              topics?.map((each, index) => (
                <Col
                  xxl={3}
                  lg={4}
                  md={6}
                  sm={12}
                  className="mb-4"
                  key={each?._id + index}
                >
                  <Card>
                    <CardBody
                      className="cursorPointer"
                      onClick={() =>
                        navigate(`/affiliate/training-detail/${each?._id}`)
                      }
                    >
                      <h2>{each?.topicName}</h2>
                      <p>{each?.content}</p>
                      <div className="progressWrap">
                        <Progress
                          color="success"
                          style={{
                            height: "5px",
                          }}
                          value={
                            each?.isCompleted
                              ? 100
                              : each?.watchedTill
                              ? (each?.watchedTill /
                                  each.videoFiles?.[0]?.duration) *
                                100
                              : 0
                          }
                        />
                        {each?.isCompleted ? (
                          <div className="iconCheck">
                            <SvgIcons type="check" />
                          </div>
                        ) : (
                          <>
                            <Button color="primary" className="h-auto">
                              {each?.watchedTill ? "Resume" : "Play"}
                            </Button>
                          </>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))
            ) : loading ? (
              <SkeletonLoading type="card" height={120} count={3} />
            ) : (
              <>
                <Card>
                  <CardBody>
                    <div className="nodata">
                      <img
                        src={require("../../assets/img/noData.svg").default}
                        alt="no data"
                      />
                      <p>There is no data to display</p>
                    </div>
                  </CardBody>
                </Card>
              </>
            )}
          </Row>
        </div>
      </div>

      {/* <Card className="cardDesign">
        <CardHeader>
          <CardTitle>{query?.categoryName}</CardTitle>
          
        </CardHeader>
        <CardBody>
          <Table>
            <thead>
              <tr>
                <th>Topic</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody></tbody>
          </Table>
        </CardBody>
      </Card> */}

      <CustomPagination
        data={topics}
        dataCount={topicCount}
        dataPayload={payload}
        onPageChange={_onPageChange}
        onSizeChange={_onSizeChange}
      />
    </>
  );
};

export default TrainingUserList;
