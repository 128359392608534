import React from "react";
import { Button } from "reactstrap";
import { capitalize, copyToClipboard } from "../helper-methods";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const LeadPersonalInfo = ({
  data,
  userType,
  affiliateType,
  _toggleLeadPersonalInfoModal,
}) => {
  const userData = useSelector((state) => state?.userCredential);
  const navigate = useNavigate();
  return (
    <div className="cardWrap personalInfo">
      <div className="cardHeader d-flex justify-content-between">
        Personal Info
        {(userType === "admin" || affiliateType === "leader") && (
          <div className="d-flex align-items-center">
            <Button
              className="editBtn"
              color="link"
              disabled={!data}
              onClick={() => _toggleLeadPersonalInfoModal(true, data)}
            >
              <i className="fas fa-pencil-alt" />
            </Button>
          </div>
        )}
      </div>
      <div className="cardBody">
        <h6 className="heading">
          {data?.companyName ? capitalize(data?.companyName) : "N/A"}
        </h6>
        <div className="contactInfo">
          <ul>
            <li>
              Referred By :{" "}
              {data?.isAddedByAdmin ? (
                "Admin"
              ) : data?._referBy?.name?.full ? (
                userType === "affiliate" ? (
                  <span>{capitalize(data?._referBy?.name?.full)}</span>
                ) : (
                  <span
                    className="themeColor cursorPointer"
                    onClick={() =>
                      navigate(`/${userType}/affiliate/${data?._referBy?._id}`)
                    }
                  >
                    {capitalize(data?._referBy?.name?.full)}
                  </span>
                )
              ) : (
                "N/A"
              )}
            </li>
            <li>
              W2 employees :{" "}
              {data?.leadQuestionResponse?.find(
                (each) =>
                  each.questionText ===
                  "How many W2 employees do you currently have on payroll?"
              )?.response ||
                data?.questionnaireEmployeeCount ||
                "N/A"}
            </li>

            {userData?.user?.userType?.toLowerCase() === "affiliate" &&
            (userData?.user?.affiliateType?.toLowerCase() === "regular" ||
              userData?.user?.affiliateType?.toLowerCase() ===
                "super") ? null : (
              <li>Organization Name : {data?.organizationName || "N/A"}</li>
            )}
          </ul>
          <ul className="right">
            <li>
              <i className="fas fa-user" />
              <span>
                {data?.name?.full ? capitalize(data?.name?.full) : "N/A"}
              </span>
            </li>
            <li>
              <i className="fas fa-phone-alt" />
              <span
                onClick={() =>
                  copyToClipboard(`${data?.countryCode || ""}${data?.phone}`)
                }
              >
                {data?.countryCode} {data?.phone || "N/A"}
              </span>
            </li>
            <li>
              <i className="far fa-envelope" />
              <span
                className="value"
                onClick={() => copyToClipboard(data.email)}
              >
                {data?.email || "N/A"}
              </span>
            </li>
            <li>
              <i className="fas fa-map-marker-alt" />
              <span>{data?.location || "N/A"}</span>
            </li>
          </ul>
        </div>

        {/* <div className="d-flex mb-2">
                  <p className="mb-0 fs-14">
                    Agreement : {data?.agreement?.status || "N/A"}
                  </p>
                  {data?.agreement?.url && (
                    <Button
                      color="link"
                      className="ms-2 px-1"
                      onClick={() => openUrlOnNewTab(data.agreement.url)}
                    >
                      View
                    </Button>
                  )}
                </div> */}

        {/* <div className="contact-details-list">
                  <i
                    className="fas fa-link fs-12"
                    style={{ width: 20, color: "#666" }}
                  />
                  <Button
                    color="link"
                    className="px-0"
                    disabled={generateTokenLoading}
                    onClick={() => _generateLeadToken(data?._id)}
                  >
                    {generateTokenLoading ? (
                      <i className="fa fa-spinner fa-spin mr-2" />
                    ) : null}{" "}
                    Document Link
                  </Button>
                </div> */}
      </div>
    </div>
  );
};

export default LeadPersonalInfo;
