import React from "react";
import { Button } from "reactstrap";

const ActionButtonsComponent = ({ data, deleteLoading, onDelete, onEdit }) => {
  return (
    <div className="action" style={{ width: 100 }}>
      <Button color="link" onClick={() => onEdit(data)}>
        <i className="fas fa-pencil-alt" />
      </Button>

      <Button
        color="link"
        className="ms-1"
        disabled={deleteLoading ? true : false}
        onClick={() => onDelete(data)}
      >
        {deleteLoading === data?._id ? (
          <i className="fa fa-spinner fa-spin text-danger" />
        ) : (
          <i className="fa fa-trash-alt text-danger" />
        )}
      </Button>
    </div>
  );
};

export default ActionButtonsComponent;
