import React, { useState } from "react";
import { useEffect } from "react";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { errorHandler, showToast } from "../../helper-methods";
import { createQuiz, updateQuiz } from "../../http/http-calls";
import SvgIcons from "../SvgIcons";

const AddQuizModal = ({ isOpen, data, toggle, onSuccess = () => {} }) => {
  const [formFields, setFormFields] = useState({
    question: "",
    options: ["", ""],
    correctOption: 1,
  });
  // eslint-disable-next-line no-unused-vars
  const [isDirty, setIsDirty] = useState({});
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const _setModalState = (data = null) => {
    setFormFields({
      question: data?.question || "",
      options: data?.options?.length
        ? data.options.map((each) => each.answerText || "")
        : ["", ""],
      correctOption: data?.correctOption || 1,
    });
    setIsDirty({});
    setLoading(false);
    setErrors({});
  };

  const _closeModal = () => {
    _setModalState();
    toggle();
  };

  const _validateFormFields = ({ newFormFields, newIsDirty }) => {
    return new Promise((resolve) => {
      const newErrors = {};
      let isFormValid = true;

      if (newFormFields) {
        Object.keys(newFormFields).forEach((key) => {
          if (newIsDirty[key]) {
            switch (key) {
              case "question": {
                if (newFormFields[key]?.trim().length) {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] = "*Required";
                  isFormValid = false;
                }
                break;
              }
              default:
            }
          }
        });

        newFormFields.options?.forEach((optionText, optionIndex) => {
          if (newIsDirty[`option_${optionIndex}`]) {
            if (optionText?.trim().length) {
              newErrors[`option_${optionIndex}`] = null;
              newIsDirty[`option_${optionIndex}`] = false;
            } else {
              newErrors[`option_${optionIndex}`] = "*Required";
              isFormValid = false;
            }
          }
        });
      }

      setErrors((prev) => ({
        ...prev,
        ...newErrors,
      }));

      setIsDirty((prev) => ({
        ...prev,
        ...newIsDirty,
      }));

      resolve(isFormValid);
    });
  };

  const _onChangeFormFields = (key, value) => {
    const newFormFields = { ...formFields };

    newFormFields[key] = value;

    setFormFields(newFormFields);

    if (errors[key]) {
      setErrors((prev) => ({ ...prev, [key]: null }));
    }
  };

  const _onChangeOptionText = (index, value) => {
    const newFormFields = { ...formFields };

    newFormFields.options[index] = value;

    setFormFields(newFormFields);

    if (errors[`option_${index}`]) {
      setErrors((prev) => ({ ...prev, [`option_${index}`]: null }));
    }
  };

  const _removeOption = (index) => {
    const newFormFields = { ...formFields };
    newFormFields.options.splice(index, 1);

    if (newFormFields.correctOption > newFormFields.options?.length) {
      newFormFields.correctOption = 1;
    }

    setFormFields(newFormFields);

    if (errors[`option_${index}`]) {
      setErrors((prev) => ({ ...prev, [`option_${index}`]: null }));
    }
  };

  const _addOption = () => {
    const newFormFields = { ...formFields };
    newFormFields.options.push("");
    setFormFields(newFormFields);
  };

  const _onBlurFormFields = (key) => {
    const newFormFields = { ...formFields };
    const newIsDirty = {
      [key]: true,
    };

    _validateFormFields({ newFormFields, newIsDirty });
  };

  const _onSaveQuiz = async () => {
    try {
      const newFormFields = { ...formFields };

      const newIsDirty = {};

      Object.keys(newFormFields).forEach((key) => {
        newIsDirty[key] = true;
      });

      newFormFields.options?.forEach((optionText, optionIndex) => {
        newIsDirty[`option_${optionIndex}`] = true;
      });

      const isFormValid = await _validateFormFields({
        newFormFields,
        newIsDirty,
      });

      if (!isFormValid) return;

      const payload = {
        question: formFields.question.trim(),
        options: [],
        correctOption: formFields?.correctOption || "",
      };

      formFields.options?.forEach((each, index) => {
        payload.options.push({ optionNo: index + 1, answerText: each.trim() });
      });

      setLoading(true);

      if (data?._id) {
        await updateQuiz({ id: data?._id, payload });
        showToast("Quiz has been updated", "success");
      } else {
        await createQuiz(payload);
        showToast("Quiz has been created", "success");
      }

      onSuccess();
      _closeModal();
    } catch (error) {
      setLoading(false);
      errorHandler(error);
    }
  };

  useEffect(() => {
    if (isOpen && data?._id) {
      _setModalState(data);
    }
  }, [isOpen, data]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        scrollable
        centered
        className="modal-lg message-template"
      >
        <ModalHeader toggle={() => _closeModal()}>
          {data ? "Edit" : "Add"} Quiz
        </ModalHeader>

        <ModalBody>
          <FormGroup>
            <Label>
              Question<sup>*</sup>
            </Label>
            <Input
              type="textarea"
              rows={5}
              placeholder="Enter question"
              value={formFields.question}
              onChange={(e) => _onChangeFormFields("question", e.target.value)}
              onBlur={() => _onBlurFormFields("question")}
              disabled={loading}
            />
            {errors.question ? (
              <div className="form-error">{errors.question}</div>
            ) : null}
          </FormGroup>

          {formFields.options?.map((optionText, optionIndex) => (
            <FormGroup key={`option_${optionIndex}`}>
              <Label>
                Option {optionIndex + 1}
                <sup>*</sup>
              </Label>
              <div className="quizQuestion">
                <Input
                  type="radio"
                  name="correctOption"
                  value={optionIndex + 1}
                  checked={formFields.correctOption === optionIndex + 1}
                  onChange={(e) =>
                    _onChangeFormFields("correctOption", optionIndex + 1)
                  }
                />

                <Input
                  type="textarea"
                  rows="2"
                  value={optionText}
                  onChange={(e) =>
                    _onChangeOptionText(optionIndex, e.target.value)
                  }
                  onBlur={() => _onBlurFormFields(`option_${optionIndex}`)}
                  disabled={loading}
                />
                {formFields.options?.length >= 3 ? (
                  <Button
                    color="link"
                    className="remove"
                    onClick={() => _removeOption(optionIndex)}
                  >
                    <SvgIcons type="delete" />
                  </Button>
                ) : null}
              </div>

              {errors[`option_${optionIndex}`] ? (
                <div className="form-error">
                  {errors[`option_${optionIndex}`]}
                </div>
              ) : null}
            </FormGroup>
          ))}

          {formFields.options?.length < 10 ? (
            <div className="text-center">
              <Button color="primary" onClick={() => _addOption()}>
                Add more Options
              </Button>
            </div>
          ) : null}
        </ModalBody>

        <ModalFooter>
          <Button color="link" onClick={_closeModal}>
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={loading}
            onClick={() => _onSaveQuiz()}
          >
            {loading ? <i className="fa fa-spinner fa-spin mr-2" /> : null}{" "}
            {data ? "Update" : "Add"}
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AddQuizModal;
