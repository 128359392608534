import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import {
  errorHandler,
  showToast,
  uploadFileOnServer,
} from "../../helper-methods";
import { addCategory, editCategory } from "../../http/http-calls";

const AddCategories = ({ isOpen, toggle, fetchAllCategories, editDetails }) => {
  const _closeModal = () => {
    toggle();
    setFormData({
      category: "",
      description: "",
      isMandatoryToSee: false,
      image: {},
    });
    setIsDirty({
      category: false,
      description: false,
    });
    setErrors({});
    setImg("");
  };

  const [formData, setFormData] = useState({
    category: "",
    description: "",
    isMandatoryToSee: false,
    image: {},
  });

  const [isDirty, setIsDirty] = useState({
    category: false,
    description: false,
  });

  const [prevData, setPrevData] = useState({
    category: "",
    description: "",
    isMandatoryToSee: false,
    image: {},
  });
  const [errors, setErrors] = useState({});
  const [flag, setFlag] = useState(false);
  const [img, setImg] = useState("");
  const [prevImg, setPrevImg] = useState("");

  const _validateForm = (data, dirties) => {
    const newErrors = { ...errors };

    Object.keys(dirties).forEach((each) => {
      if (each === "category" && dirties[each]) {
        if (!data?.category?.length) {
          newErrors.category = "*Required";
        } else if (
          data.category.trim().length < 2 ||
          data.category.length > 150
        ) {
          newErrors.category =
            "*Category should be greater than 2 characters and less than 150 characters";
        } else {
          delete newErrors[each];
          dirties[each] = false;
        }
      } else if (each === "description" && dirties[each]) {
        if (!data?.description?.length) {
          newErrors.description = "*Required";
        } else {
          delete newErrors[each];
          dirties[each] = false;
        }
      }
    });
    setIsDirty(dirties);
    setErrors(newErrors);
    return Object.keys(newErrors).length ? true : false;
  };

  const _handelOnChange = (field, event) => {
    const newFormData = { ...formData };
    const newIsDirty = { ...isDirty };

    if (field === "image") {
      newFormData[field] = event.target.files[0];
      setImg(URL.createObjectURL(event.target.files[0]));
    } else {
      if(field === "isMandatoryToSee"){
        newFormData[field] = event.target.checked;
      }else{
        newFormData[field] = event.target.value;
      }
    }
    newIsDirty[field] = true;
    setFormData(newFormData);
    setIsDirty(newIsDirty);
  };

  const _onBlurFormFields = (key) => {
    const newFormFields = { ...formData };
    const newIsDirty = {
      [key]: true,
    };
    _validateForm(newFormFields, newIsDirty);
  };

  const _categorySubmitHandler = async () => {
    const newIsDirty = {
      category: true,
      description: true,
    };

    const error = _validateForm(formData, newIsDirty);
    if (!error) {
      setFlag(true);
      if (editDetails) {
        if (formData?.image?.type) {
          let payload = [{ uploadData: formData.image }];
          try {
            const imageRes = await uploadFileOnServer(payload);

            delete formData?.image;

            let finalPayload = {
              ...formData,
              coverPic: imageRes[0].url,
            };

            try {
              await editCategory(editDetails?._id, finalPayload);

              fetchAllCategories();

              showToast("Category Updated Successfully", "success");
            } catch (error) {
              errorHandler(error);
            }
          } catch (error) {
            console.log({ error });
          }
        } else {
          if (img) {
            let payload = { ...formData, coverPic: img };

            delete payload?.image;

            try {
              await editCategory(editDetails?._id, payload);

              fetchAllCategories();
              showToast("Category Updated Successfully", "success");
            } catch (error) {
              errorHandler(error);
            }
          } else {
            let payload = { ...formData };

            delete payload?.image;

            try {
              await editCategory(editDetails?._id, payload);

              fetchAllCategories();
              showToast("Category Updated Successfully", "success");
            } catch (error) {
              console.log({ error });
              errorHandler(error);
            }
          }
        }
      } else {
        if (img) {
          let payload = [{ uploadData: formData.image }];
          try {
            const imageRes = await uploadFileOnServer(payload);

            delete formData?.image;

            let finalPayload = {
              ...formData,
              coverPic: imageRes[0].url,
            };

            try {
              await addCategory(finalPayload);

              fetchAllCategories();
              showToast("Category Created Successfully", "success");
            } catch (error) {
              errorHandler(error);
            }
          } catch (error) {
            console.log({ error });
          }
        } else {
          delete formData?.image;
          let finalPayload = {
            ...formData,
          };
          try {
            await addCategory(finalPayload);

            fetchAllCategories();
            showToast("Category Created Successfully", "success");
          } catch (error) {
            errorHandler(error);
          }
        }
      }
      _closeModal();
      setFlag(false);
    }
  };

  // const _clearImageData = (event) => {
  //   event.preventDefault();

  //   setFormData({
  //     ...formData,
  //     image: {},
  //   });
  //   setImg("");
  // };

  useEffect(() => {
    if (editDetails) {
      setFormData({
        category: editDetails?.category || "",
        description: editDetails?.description || "",
        isMandatoryToSee: editDetails?.isMandatoryToSee || false,
        image: {},
      });
      setPrevData({
        category: editDetails?.category || "",
        description: editDetails?.description || "",
        isMandatoryToSee: editDetails?.isMandatoryToSee || false,
        image: {},
      });
      if (editDetails?.coverPic) {
        setImg(editDetails?.coverPic);
        setPrevImg(editDetails?.coverPic);
      } else {
        setImg("");
        setPrevImg("");
      }
    }
  }, [editDetails]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        scrollable
        centered
        className="modal-lg message-template"
        backdrop="static"
      >
        <ModalHeader toggle={() => _closeModal()}>
          {editDetails ? "Edit" : "Add"} Categories
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>Category</Label>
            <Input
              placeholder="Enter"
              type="text"
              disabled={flag}
              value={formData?.category}
              onChange={(event) => _handelOnChange("category", event)}
              onBlur={() => _onBlurFormFields("category")}
            />
            {errors.category ? (
              <div className="form-error">{errors.category}</div>
            ) : null}
          </FormGroup>
          <FormGroup>
            <Label>Description</Label>
            <Input
              placeholder="Enter"
              type="textarea"
              rows="4"
              disabled={flag}
              value={formData?.description}
              onChange={(event) => _handelOnChange("description", event)}
              onBlur={() => _onBlurFormFields("description")}
            />
            {errors.description ? (
              <div className="form-error">{errors.description}</div>
            ) : null}
          </FormGroup>
          <FormGroup check inline>
            <Input
              placeholder="Enter"
              type="checkbox"
              disabled={flag}
              checked={formData?.isMandatoryToSee}
              onChange={(event) => _handelOnChange("isMandatoryToSee", event)}
            />
            <Label>Mandatory to watch</Label>
          </FormGroup>

          {/* <Label className="uploadFile">
            <Input
              type="file"
              accept="image/*"
              onChange={(event) => _handelOnChange("image", event)}
            />
            {!img ? (
              <SvgIcons type={"upload"} />
            ) : (
              <div className="uploadedFile">
                <img src={img} alt="file" />
                <div className="deleteIcon" onClick={(e) => _clearImageData(e)}>
                  <SvgIcons type={"remove"} />
                </div>
              </div>
            )}
          </Label> */}
        </ModalBody>
        <ModalFooter>
          <Button color="link" onClick={() => _closeModal()}>
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={
              flag || editDetails
                ? (JSON.stringify(prevData) === JSON.stringify(formData) &&
                    img === prevImg) ||
                  flag
                : null
            }
            onClick={() => _categorySubmitHandler()}
          >
            {flag ? <i className="fa fa-spinner fa-spin mr-2" /> : null}{" "}
            {editDetails ? "Update" : "Add"}
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AddCategories;
