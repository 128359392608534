import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Label,
  Input,
  Table,
  FormGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
  UncontrolledPopover,
  PopoverBody,
} from "reactstrap";
import "react-datetime/css/react-datetime.css";
import { useNavigate, useParams } from "react-router-dom";
import {
  sendEmailTextToUsers,
  getAllActivites,
  getAllTemplatesList,
} from "../../http/http-calls";
import {
  errorHandler,
  formatDate,
  formatDateAndTime,
  formatTime,
  getYesterdayDate,
  showToast,
} from "../../helper-methods";
import SearchableInput from "../../components/SearchableInput";
import TextEditor from "../../components/TextEditor";
import ReactDatetime from "react-datetime";
import { templateVariablesConfig } from "../../config/templateConfig";
import ReadMoreReadLess from "../../components/ReadMoreReadLess";

const NewMessagePage = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [formFields, setFormFields] = useState({
    category: "Email", // Email, Text
    template: "",
    subject: "",
    body: "",
    message: "",
    schedule: "Now", // Now, Later
    scheduleTime: "",
  });
  // eslint-disable-next-line no-unused-vars
  const [isDirty, setIsDirty] = useState({});
  const [errors, setErrors] = useState({});

  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(false);

  // const [adminUsers, setAdminUsers] = useState([]);
  const [templates, setTemplates] = useState([]);

  const filteredTemplates = useMemo(() => {
    return templates?.length
      ? templates.filter((each) => each.category === formFields.category)
      : [];
  }, [templates, formFields.category]);

  const _validateFormFields = ({ newFormFields, newIsDirty }) => {
    return new Promise((resolve) => {
      const newErrors = {};
      let isFormValid = true;

      if (newFormFields) {
        Object.keys(newFormFields).forEach((key) => {
          if (newIsDirty[key]) {
            switch (key) {
              case "subject": {
                if (
                  newFormFields[key]?.trim().length ||
                  formFields.category !== "Email"
                ) {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] = "*Required";
                  isFormValid = false;
                }
                break;
              }
              case "message": {
                if (
                  newFormFields[key]?.trim().length ||
                  formFields.category !== "Text"
                ) {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] = "*Required";
                  isFormValid = false;
                }
                break;
              }
              case "body": {
                if (
                  (newFormFields[key]?.trim().length &&
                    newFormFields[key] !== "<p><br></p>") ||
                  formFields.category !== "Email"
                ) {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] = "*Required";
                  isFormValid = false;
                }
                break;
              }
              case "scheduleTime": {
                if (formFields.schedule === "Later") {
                  if (newFormFields[key]?.trim().length) {
                    if (new Date() < new Date(newFormFields[key])) {
                      newErrors[key] = null;
                      newIsDirty[key] = false;
                    } else {
                      newErrors[key] =
                        "*Invalid date, it should be future date & time.";
                      isFormValid = false;
                    }
                  } else {
                    newErrors[key] = "*Required";
                    isFormValid = false;
                  }
                } else {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                }
                break;
              }
              default:
            }
          }
        });
      }

      setErrors((prev) => ({
        ...prev,
        ...newErrors,
      }));

      setIsDirty((prev) => ({
        ...prev,
        ...newIsDirty,
      }));

      resolve(isFormValid);
    });
  };

  const _onSelectVariable = (key, value) => {
    const newFormFields = { ...formFields };
    newFormFields[key] += value;
    setFormFields(newFormFields);

    const newIsDirty = { ...isDirty };
    newIsDirty[key] = true;
    _validateFormFields({ newFormFields, newIsDirty });
  };

  const _onChangeFormFields = (key, value) => {
    const newFormFields = { ...formFields };

    if (key === "scheduleTime") {
      newFormFields[key] = value?._d ? new Date(value).toISOString() : "";
      const newIsDirty = {
        [key]: true,
      };
      _validateFormFields({ newFormFields, newIsDirty });
    } else {
      newFormFields[key] = value;
    }

    if (key === "template" && value) {
      if (value.category === "Email") {
        newFormFields.subject = value.subject;
        newFormFields.body = value.body;
      } else {
        newFormFields.message = value.body;
      }
    }

    if (key === "category") {
      newFormFields.template = null;
      newFormFields.subject = "";
      newFormFields.body = "";
      newFormFields.message = "";
    }

    setFormFields(newFormFields);
  };

  const _onBlurFormFields = (key) => {
    const newFormFields = { ...formFields };
    const newIsDirty = {
      [key]: true,
    };
    _validateFormFields({ newFormFields, newIsDirty });
  };

  const _onSaveDetails = async () => {
    try {
      const newFormFields = { ...formFields };

      const newIsDirty = {
        subject: true,
        body: true,
        message: true,
        scheduleTime: true,
      };

      const isFormValid = await _validateFormFields({
        newFormFields,
        newIsDirty,
      });

      if (!isFormValid) return;

      setLoading(true);

      const payload = {
        to: [params?.id],
        userType: "Lead",
        messageType: formFields.category, // Email, Text
        templateId: formFields.template?.value || "",
      };

      if (formFields.category === "Email") {
        payload.subject = formFields.subject.trim();
        payload.body = formFields.body.trim();
      } else {
        payload.subject = "";
        payload.body = formFields.message.trim();
      }

      if (formFields.schedule === "Later") {
        payload.isSendNow = false;
        payload.scheduleTime = formFields.scheduleTime;
      } else {
        payload.isSendNow = true;
      }

      await sendEmailTextToUsers(payload);

      showToast(
        `${
          formFields.category === "Text" ? "Message" : formFields.category
        } has been sent`,
        "success"
      );
      navigate(-1);
    } catch (error) {
      setLoading(false);
      errorHandler(error);
    }
  };

  const _getAllTemplatesList = () => {
    getAllTemplatesList()
      .then((res) => {
        // setAdminUsers(
        //   res.adminUser?.length
        //     ? res.adminUser.map((each) => ({
        //         label: each.name?.full,
        //         value: each._id,
        //         ...each,
        //       }))
        //     : []
        // );
        setTemplates(
          res.template?.length
            ? res.template.map((each) => ({
                label: each.title,
                value: each._id,
                ...each,
              }))
            : []
        );
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  const _getAllActivites = () => {
    setLoading(true);

    const payload = {
      userId: params?.id,
      userType: "Lead", // enum=["Lead", "Affiliate"]
    };
    getAllActivites(payload)
      .then((res) => {
        setActivities(res.activities?.length ? res.activities : []);
        setLoading(false);
      })
      .catch((error) => {
        errorHandler(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    _getAllActivites();

    _getAllTemplatesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="pgTitleWrap justify-content-start">
        <Button color="link" className="backBtn" onClick={() => navigate(-1)}>
          <i className="fas fa-chevron-left" />
        </Button>

        <h2>Send Message {loading ? <Spinner /> : null}</h2>
      </div>

      <div className="tableInCardWrap">
        <div className="tableCardHeader">History</div>

        <div className="tableWrapper">
          <Table responsive>
            <thead>
              <tr>
                <th>Type</th>
                <th>Time</th>
                <th>Message</th>
                <th>By</th>
              </tr>
            </thead>
            <tbody>
              {activities?.length ? (
                activities.map((each) => (
                  <tr key={each?._id}>
                    <td>
                      {each.activityType === "SMS"
                        ? "Text"
                        : each.activityType || "N/A"}
                    </td>
                    <td>
                      {formatDate(each.createdAt)} <br />
                      {formatTime(each.createdAt)}
                    </td>
                    <td>
                      {each.message ? (
                        <>
                          <div
                            id={`newMessageText_${each._id}`}
                            className="themeColor cursorPointer"
                            style={{ width: 250 }}
                          >
                            <ReadMoreReadLess
                              text={
                                each.activityType === "Email"
                                  ? each.subject
                                  : each.message
                              }
                              count={80}
                              isHideMoreButton={true}
                            />
                          </div>
                          <UncontrolledPopover
                            target={`newMessageText_${each._id}`}
                            trigger="legacy"
                          >
                            {each.activityType === "Email" ? (
                              <PopoverBody>
                                <div>
                                  Subject:
                                  <p>{each.subject}</p>
                                </div>
                                <hr />
                                <div>
                                  Body:
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: each.message,
                                    }}
                                  />
                                </div>
                              </PopoverBody>
                            ) : (
                              <PopoverBody>{each.message}</PopoverBody>
                            )}
                          </UncontrolledPopover>
                        </>
                      ) : (
                        "N/A"
                      )}
                    </td>
                    <td className="text-capitalize">
                      {each._createdBy?.name?.full || "N/A"}
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="text-center">
                  <td colSpan="4">
                    {loading ? (
                      <Spinner />
                    ) : (
                      <div className="nodata">
                        <img
                          src={require("../../assets/img/noData.svg").default}
                          alt="no data"
                        />
                        <p>There is no data to display</p>
                      </div>
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>

      <div className="cardWrap">
        <div className="cardHeader">New Message</div>
        <div className="cardBody">
          <div className="d-flex">
            <FormGroup check>
              <Input
                id="category_Email"
                name="category"
                type="radio"
                checked={formFields.category === "Email"}
                value="Email"
                onChange={(e) =>
                  _onChangeFormFields("category", e.target.value)
                }
              />
              <Label check for="category_Email">
                Email
              </Label>
            </FormGroup>

            <FormGroup check className="ms-4">
              <Input
                id="category_Text"
                name="category"
                type="radio"
                checked={formFields.category === "Text"}
                value="Text"
                onChange={(e) =>
                  _onChangeFormFields("category", e.target.value)
                }
              />
              <Label check for="category_Text">
                Text Message
              </Label>
            </FormGroup>
          </div>

          <FormGroup className="mt-4">
            <Label>Template</Label>
            <SearchableInput
              options={filteredTemplates}
              value={formFields.template}
              onChange={(value) => _onChangeFormFields("template", value)}
              onBlur={() => _onBlurFormFields("template")}
            />
            {errors["template"] ? (
              <div className="form-error">{errors["template"]}</div>
            ) : null}
          </FormGroup>

          {/* <FormGroup className="ms-1 w-50">
              <Label>CC</Label>
              <SearchableInput
                options={adminUsers}
                value={formFields.cc}
                onChange={(value) => _onChangeFormFields("cc", value)}
                onBlur={() => _onBlurFormFields("cc")}
              />
              {errors["cc"] ? (
                <div className="form-error">{errors["cc"]}</div>
              ) : null}
            </FormGroup> */}

          {formFields.category === "Email" ? (
            <>
              <FormGroup>
                <div className="labelWithVariables">
                  <Label>Subject</Label>

                  <UncontrolledDropdown>
                    <DropdownToggle caret>Insert Variables</DropdownToggle>
                    <DropdownMenu end>
                      {React.Children.toArray(
                        templateVariablesConfig.map((each) => (
                          <DropdownItem
                            onClick={() =>
                              _onSelectVariable("subject", each.variable)
                            }
                          >
                            {each.variable}
                          </DropdownItem>
                        ))
                      )}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>

                <Input
                  type="text"
                  value={formFields.subject}
                  name="subject"
                  onChange={(e) =>
                    _onChangeFormFields("subject", e.target.value)
                  }
                  onBlur={() => _onBlurFormFields("subject")}
                  disabled={loading}
                />
                {errors["subject"] ? (
                  <div className="form-error">{errors["subject"]}</div>
                ) : null}
              </FormGroup>

              <FormGroup>
                <div className="labelWithVariables">
                  <Label>Body</Label>

                  <UncontrolledDropdown>
                    <DropdownToggle caret>Insert Variables</DropdownToggle>
                    <DropdownMenu end>
                      {React.Children.toArray(
                        templateVariablesConfig.map((each) => (
                          <DropdownItem
                            onClick={() =>
                              _onSelectVariable("body", each.variable)
                            }
                          >
                            {each.variable}
                          </DropdownItem>
                        ))
                      )}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
                <TextEditor
                  className="editorHeight"
                  content={formFields.body}
                  onChange={(value) => _onChangeFormFields("body", value)}
                  onBlur={() => _onBlurFormFields("body")}
                />
                {errors["body"] ? (
                  <div className="form-error">{errors["body"]}</div>
                ) : null}
              </FormGroup>
            </>
          ) : null}

          {formFields.category === "Text" ? (
            <FormGroup>
              <div className="labelWithVariables">
                <Label>Message</Label>

                <UncontrolledDropdown>
                  <DropdownToggle caret>Insert Variables</DropdownToggle>
                  <DropdownMenu end>
                    {React.Children.toArray(
                      templateVariablesConfig.map((each) => (
                        <DropdownItem
                          onClick={() =>
                            _onSelectVariable("message", each.variable)
                          }
                        >
                          {each.variable}
                        </DropdownItem>
                      ))
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>

              <Input
                type="textarea"
                row="3"
                value={formFields.message}
                name="message"
                onChange={(e) => _onChangeFormFields("message", e.target.value)}
                onBlur={() => _onBlurFormFields("message")}
                disabled={loading}
              />
              {errors["message"] ? (
                <div className="form-error">{errors["message"]}</div>
              ) : null}
            </FormGroup>
          ) : null}

          <Label className="mt-1">Schedule Time</Label>
          <div className="d-flex mt-1 mb-3">
            <FormGroup check>
              <Input
                id="schedule_Now"
                type="radio"
                name="schedule"
                checked={formFields.schedule === "Now"}
                value="Now"
                onChange={(e) =>
                  _onChangeFormFields("schedule", e.target.value)
                }
              />
              <Label check for="schedule_Now">
                Now
              </Label>
            </FormGroup>

            {/* if this option is selected, show a date/time selector below */}
            <FormGroup check className="ms-4">
              <Input
                id="schedule_Later"
                type="radio"
                name="schedule"
                checked={formFields.schedule === "Later"}
                value="Later"
                onChange={(e) =>
                  _onChangeFormFields("schedule", e.target.value)
                }
              />
              <Label check for="schedule_Later">
                Later
              </Label>
            </FormGroup>
          </div>

          {formFields.schedule === "Later" && (
            <>
              <FormGroup style={{ maxWidth: 300 }}>
                <Label>Date & Time</Label>
                <ReactDatetime
                  inputProps={{
                    className: "form-control",
                    placeholder: "Select Date & Time",
                    value: formatDateAndTime(formFields.scheduleTime),
                    // onBlur: _onChangeFormFields("scheduleTime"),
                  }}
                  // value={formFields.scheduleTime ? formFields.scheduleTime : ""}
                  onChange={(e) => _onChangeFormFields("scheduleTime", e)}
                  onBlur={() => _onChangeFormFields("scheduleTime")}
                  isValidDate={(current) => current.isAfter(getYesterdayDate())}
                  timeConstraints={{
                    minutes: {
                      step: 15,
                    },
                  }}
                  dateFormat={true}
                  timeFormat={true}
                />
                {errors["scheduleTime"] ? (
                  <div className="form-error">{errors["scheduleTime"]}</div>
                ) : null}
              </FormGroup>
            </>
          )}

          <Button
            color="primary"
            className="d-block mx-auto px-3"
            onClick={() => _onSaveDetails()}
            disabled={loading}
          >
            {loading ? <i className="fa fa-spinner fa-spin mr-2" /> : null} Send
          </Button>
        </div>
      </div>
    </>
  );
};

export default NewMessagePage;
