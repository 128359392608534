import React, { useCallback, useEffect, useState } from "react";
import { Button, Table } from "reactstrap";
import AddTopics from "../modals/AddTopics";
import {
  deleteTopic,
  editTopic,
  fetchAllCategories,
  fetchTopics,
  rearrangeTopics,
} from "../../http/http-calls";
import { debounce, errorHandler, showToast } from "../../helper-methods";
import CustomPagination from "../Table/CustomPagination";
import Swal from "sweetalert2";
import SkeletonLoading from "../SkeletonLoading";
import SvgIcons from "../SvgIcons";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import ActionButtonsComponent from "../ActionButtonsComponent";
import { categoryTopicStatus } from "../../config/statusChangeConfig";
import StatusChangeComponent from "../StatusChangeComponent";
import FilterTrainingVideo from "../FilterTrainingVideo";
import { topicConfig } from "../../config/helper-config";

// Create a drag handle component
const DragHandle = SortableHandle(() => (
  <span style={{ cursor: "grab" }}>
    <i
      className="fas fa-grip-vertical"
      style={{ marginRight: "8px", cursor: "grabbing" }}
    />
  </span>
));

const SortableContainerList = SortableContainer(({ children }) => {
  return <tbody className="w-full">{children}</tbody>;
});

const SortableRow = SortableElement(
  ({
    topic,
    topicIndex,
    _changeStatus,
    _toggleAddTopic,
    _deleteTemplateAlert,
    flag,
    deleteLoading,
    index,
  }) => (
    <tr key={index}>
      <td>
        <div className="d-flex align-items-center" style={{ width: 150 }}>
          <DragHandle /> {topic?.topicName || "N/A"}
        </div>
      </td>
      <td>
        <div style={{ width: 400 }}>
          {topic?._faqCategory?.category || "N/A"}
        </div>
      </td>
      <td>
        <StatusChangeComponent
          value={topic?.isActive}
          onChange={(event) => _changeStatus(event, topic?._id, topicIndex)}
          disabled={flag}
          options={categoryTopicStatus}
        />
      </td>
      <td>
        <ActionButtonsComponent
          data={topic}
          deleteLoading={deleteLoading}
          onDelete={_deleteTemplateAlert}
          onEdit={(topic) => _toggleAddTopic(true, topic)}
        />
      </td>
    </tr>
  )
);

// Create a sortable table container
const SortableTable = ({ items, loading, ...props }) => (
  <>
    {items?.length ? (
      <SortableContainerList useDragHandle={true} onSortEnd={props?.onSortEnd}>
        {items.map((topic, index) => (
          <SortableRow
            key={index}
            index={index}
            topicIndex={index}
            topic={topic}
            {...props}
          />
        ))}
      </SortableContainerList>
    ) : loading ? (
      <tbody>
        <SkeletonLoading type="table" height={20} row={5} col={4} />
      </tbody>
    ) : (
      <tbody>
        <tr className="text-center">
          <td colSpan="4">
            <div className="nodata">
              <img
                src={require("../../assets/img/noData.svg").default}
                alt="no data"
              />
              <p>There is no data to display</p>
            </div>
          </td>
        </tr>
      </tbody>
    )}
  </>
);

const TopicsTab = ({ activeTab, tabId }) => {
  // eslint-disable-next-line no-unused-vars
  const [showFilter, setShowFilter] = useState(false);

  const [addTopicModal, setAddTopicModal] = useState({
    isOpen: false,
    data: null,
  });

  const [payload, setPayload] = useState({
    pageNumber: 0,
    limit: 10,
    page: 1,
    search: "",
    status: "",
    categoryId: "",
  });
  const [topics, setTopics] = useState([]);
  const [topicCount, setTopicCount] = useState(0);
  const [flag, setFlag] = useState(false);
  const [categoryList, setCategoryList] = useState([]);

  const [deleteLoading, setDeleteLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const catListConfig = [
    ...topicConfig.slice(0, 1),
    {
      label: "Category",
      name: "categoryId",
      type: "select",
      defaultOption: "All",
      options: categoryList?.map((category) => ({
        value: category?._id,
        label: category?.category,
      })),
    },
    ...topicConfig.slice(1),
  ];

  const _toggleAddTopic = (isOpen = false, data = null) => {
    setAddTopicModal({ isOpen, data });
  };

  const _fetchAllTopics = async (newPayload) => {
    setLoading(true);

    if (newPayload) {
      try {
        const res = await fetchTopics(newPayload);

        setTopics(res?.faqTopics);
        setTopicCount(res?.totalCount);
      } catch (error) {
        errorHandler(error);
      }
    } else {
      try {
        const res = await fetchTopics(payload);

        setTopics(res?.faqTopics);
        setTopicCount(res?.totalCount);
      } catch (error) {
        errorHandler(error);
      }
    }
    setLoading(false);
  };

  const _onSizeChange = (limit = 10) => {
    const newDataPayload = { ...payload };
    newDataPayload["skip"] = 0;
    newDataPayload["pageNumber"] = 0;
    newDataPayload["page"] = 1;
    newDataPayload["limit"] = limit;
    setPayload(newDataPayload);
    _fetchAllTopics(newDataPayload);
  };

  const _onPageChange = (pageNumber = 0) => {
    const newDataPayload = { ...payload };
    newDataPayload["pageNumber"] = pageNumber;
    newDataPayload["page"] = pageNumber + 1;
    setPayload(newDataPayload);
    _fetchAllTopics(newDataPayload);
  };

  const _changeStatus = async (event, id, index) => {
    let oldReports = JSON.parse(JSON.stringify(topics));

    const value = event.target.value;
    topics[index].isActive = value;

    setTopics([...topics]);
    setFlag(true);

    try {
      await editTopic(id, {
        status: value === "true" ? true : false,
      });

      showToast("Status successfully changed", "success");
    } catch (error) {
      setTopics(oldReports);
      errorHandler(error);
    }
    setFlag(false);
  };

  const _checkHandler = async (data) => {
    _fetchAllTopics(data);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const optimizedFn = useCallback(debounce(_checkHandler), []);

  const _searchFilterHandler = async (field, event) => {
    let newSearchData = { ...payload };

    if (field === "status") {
      newSearchData[field] = event.target.value?.length
        ? event.target.value === "true"
          ? true
          : false
        : "";
    } else {
      newSearchData[field] = event.target.value;
    }

    newSearchData["pageNumber"] = 0;
    newSearchData["page"] = 1;

    setPayload(newSearchData);

    if (field === "search") {
      optimizedFn({ ...newSearchData, search: newSearchData.search.trim() });
    } else {
      _checkHandler(newSearchData);
    }
  };

  const _fetchCategoryNames = async () => {
    try {
      const res = await fetchAllCategories();

      setCategoryList(res?.faqCategories);
    } catch (error) {
      errorHandler(error);
    }
  };

  const _deleteTemplate = (id) => {
    // deleteCategory
    setDeleteLoading(id);

    const newData = [...topics];
    let newDataCount = topicCount;
    const findDataIndex = newData.findIndex((each) => each._id === id);
    if (findDataIndex > -1) {
      newData.splice(findDataIndex, 1);
      setTopics(newData);
      newDataCount--;
      setTopicCount(newDataCount);
    }

    deleteTopic(id)
      .then(async (res) => {
        Swal.fire("Deleted!", "Template has been deleted.", "success");
        if (topics.length === 0 || newDataCount <= 10) {
          _onPageChange();
        }
        setDeleteLoading(false);
      })
      .catch((error) => {
        errorHandler(error);
        setDeleteLoading(false);
        _fetchAllTopics();
      });
  };
  const _deleteTemplateAlert = (data) => {
    if (!data?._id) {
      errorHandler();
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete this (Title: ${data?.topicName}) Template.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#31c5c7",
      cancelButtonColor: "#F16667",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        _deleteTemplate(data._id);
      }
    });
  };

  const _clearFilters = () => {
    let newPayload = {
      pageNumber: 0,
      limit: 10,
      page: 1,
      search: "",
      status: "",
      categoryId: "",
    };
    setPayload(newPayload);
    _fetchAllTopics(newPayload);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    // Use the array's slice method to create a new array with the rearranged items
    const newTopics = [...topics];
    newTopics.splice(newIndex, 0, newTopics.splice(oldIndex, 1)[0]);

    let startingNumber = payload.pageNumber * 10; // Use orderNumber of the first item or default to

    let updatedArray = newTopics.map((item, index) => {
      return { ...item, orderNumber: startingNumber + index };
    });
    const neworderNumber = updatedArray[newIndex].orderNumber;
    const id = updatedArray[newIndex]._id;

    // apicall(oldorderNumber, neworderNumber)

    setTopics(updatedArray);
    let apiPayload = {
      newOrder: neworderNumber,
      topicId: id,
    };
    _reArrangeArray(apiPayload);
  };

  const _reArrangeArray = async (newPayload) => {
    try {
      await rearrangeTopics(newPayload);
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    if (activeTab === tabId) {
      _fetchAllTopics();
      _fetchCategoryNames();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, tabId]);

  return (
    <>
      <div
        className={`filterWrapper ${showFilter === true ? "showFilter" : ""}`}
      >
        <i className="fas fa-filter filterIcon" />

        <FilterTrainingVideo
          fields={catListConfig}
          payload={payload}
          onFilterChange={_searchFilterHandler}
        />
      </div>
      <div className="text-end">
        <Button
          className="h-auto"
          color="primary"
          onClick={() => _toggleAddTopic(true)}
        >
          Add Topic
        </Button>
        <Button
          color="link"
          className="btn-reset"
          onClick={() => _clearFilters()}
        >
          <SvgIcons type={"reset"} />
        </Button>
      </div>
      <div className="mt-4">
        <Table responsive>
          <thead>
            <tr>
              <th>Topic</th>
              <th>Category</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>

          <SortableTable
            items={topics}
            loading={loading}
            onSortEnd={onSortEnd}
            _changeStatus={_changeStatus}
            _toggleAddTopic={_toggleAddTopic}
            _deleteTemplateAlert={_deleteTemplateAlert}
            flag={flag}
            deleteLoading={deleteLoading}
          />
        </Table>

        {addTopicModal.isOpen && (
          <AddTopics
            isOpen={addTopicModal.isOpen}
            toggle={() => _toggleAddTopic()}
            fetchAllTopics={_fetchAllTopics}
            editDetails={addTopicModal?.data}
          />
        )}

        <CustomPagination
          data={topics}
          dataCount={topicCount}
          dataPayload={payload}
          onPageChange={_onPageChange}
          onSizeChange={_onSizeChange}
        />
      </div>
    </>
  );
};

export default TopicsTab;
