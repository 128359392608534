import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Form,
  Input,
  Label,
  FormGroup,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import SearchableInput from "../../components/SearchableInput";
import { APP_LOGO } from "../../config";
import { countryCodes } from "../../config/country-codes";
import { affiliateApplicationConfig } from "../../config/questionnaireConfig";
import { RegexConfig } from "../../config/RegexConfig";
import PublicFooter from "../../containers/Public/PublicFooter";
import {
  decodeToken,
  errorHandler,
  extractQueryParams,
  showToast,
  splitFullName,
} from "../../helper-methods";
import {
  addAffiliateApplication,
  getAffiliateList,
} from "../../http/http-calls";
import { hideLoader, showLoader } from "../../redux/actions/loader-data";
import {
  LoadCanvasTemplateNoReload,
  loadCaptchaEnginge,
  validateCaptcha,
} from "react-simple-captcha";
import { addUserCredential } from "../../redux/actions/user-credential";

const AffiliateApplication = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formFields, setFormFields] = useState({
    countryCode: "+1",
  });

  // eslint-disable-next-line no-unused-vars
  const [isDirty, setIsDirty] = useState({});
  const [errors, setErrors] = useState({});
  // const [isAcceptTerm, setIsAcceptTerm] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [showPassword, setShowPassword] = useState(false);

  const _getAffiliateList = (newFormFields) => {
    const { referCode } = extractQueryParams();

    getAffiliateList()
      .then((res) => {
        if (res?.user?.length) {
          const findUserByCode = res.user.find(
            (each) => each.referCode === referCode
          );
          if (referCode && findUserByCode) {
            newFormFields.referCode = {
              label: findUserByCode.name?.full,
              value: findUserByCode.referCode,
              _referBy: findUserByCode._id,
            };
            setFormFields(newFormFields);
          }
          setUsersList(
            res.user.map((each) => ({
              label: each.name?.full,
              value: each.referCode,
              _referBy: each._id,
            }))
          );
        }
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  const _validateFormFields = ({ newFormFields, newIsDirty }) => {
    return new Promise((resolve) => {
      const newErrors = {};
      let isFormValid = true;

      if (newFormFields) {
        Object.keys(newFormFields).forEach((key) => {
          if (newIsDirty[key]) {
            switch (key) {
              case "fullName": {
                if (newFormFields[key]?.trim().length) {
                  if (
                    RegexConfig.name.test(
                      String(newFormFields[key]).toLowerCase()
                    )
                  ) {
                    newErrors[key] = null;
                    newIsDirty[key] = false;
                  } else {
                    newErrors[key] = "*Invalid name";
                    isFormValid = false;
                  }
                } else {
                  newErrors[key] = "*Required";
                  isFormValid = false;
                }
                break;
              }
              case "referCode": {
                if (newFormFields[key]) {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                }
                break;
              }
              case "email": {
                if (newFormFields[key]?.trim().length) {
                  if (
                    RegexConfig.email.test(
                      String(newFormFields[key]).toLowerCase()
                    )
                  ) {
                    newErrors[key] = null;
                    newIsDirty[key] = false;
                  } else {
                    newErrors[key] = "*Invalid email";
                    isFormValid = false;
                  }
                } else {
                  newErrors[key] = "*Required";
                  isFormValid = false;
                }
                break;
              }
              case "phone": {
                if (newFormFields[key]?.trim().length) {
                  if (
                    RegexConfig.phone.test(
                      String(newFormFields[key]).toLowerCase()
                    )
                  ) {
                    newErrors[key] = null;
                    newIsDirty[key] = false;
                  } else {
                    newErrors[key] = "*Invalid phone number";
                    isFormValid = false;
                  }
                } else {
                  newErrors[key] = "*Required";
                  isFormValid = false;
                }
                break;
              }
              case "password": {
                if (newFormFields[key]?.length) {
                  if (
                    RegexConfig.password.test(
                      String(newFormFields[key]).toLowerCase()
                    )
                  ) {
                    newErrors[key] = null;
                    newIsDirty[key] = false;
                  } else {
                    newErrors[key] =
                      "*Minimum eight characters, at least one letter, one number and one special character";
                    isFormValid = false;
                  }
                } else {
                  newErrors[key] = "*Required";
                  isFormValid = false;
                }
                break;
              }
              case "Why_should_we_approve_your_application_to_become_an_affiliate?": {
                if (newFormFields[key]?.trim().length) {
                  if (newFormFields[key]?.trim().length <= 1500) {
                    newErrors[key] = null;
                    newIsDirty[key] = false;
                  } else {
                    newErrors[key] = "*Maximum character limit is 1500";
                    isFormValid = false;
                  }
                } else {
                  newErrors[key] = "*Required";
                  isFormValid = false;
                }
                break;
              }
              default: {
                if (newFormFields[key]?.trim().length) {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] = "*Required";
                  isFormValid = false;
                }
                break;
              }
            }
          }
        });
      }

      setErrors((prev) => ({
        ...prev,
        ...newErrors,
      }));

      setIsDirty((prev) => ({
        ...prev,
        ...newIsDirty,
      }));

      resolve(isFormValid);
    });
  };

  const _onChangeFormFields = (key, value) => {
    if (key === "phone" && (isNaN(value) || value.includes("."))) return;

    const newFormFields = { ...formFields };
    newFormFields[key] = value;
    setFormFields(newFormFields);

    if (errors?.[key]) {
      setErrors((prev) => ({ ...prev, [key]: null }));
    }
  };

  const _onBlurFormFields = (key) => {
    const newFormFields = { ...formFields };
    const newIsDirty = {
      [key]: true,
    };
    _validateFormFields({ newFormFields, newIsDirty });
  };

  const _completedAuthorization = (res = {}) => {
    dispatch(hideLoader());
    let userData;
    if (!res.user) {
      userData = decodeToken(res.token);
      res.user = userData;
    }
    dispatch(addUserCredential({ token: res.token, user: res.user }));
    navigate(`/${res.user?.userType?.toLowerCase()}/training-user`);
  };

  const _addAffiliateApplication = async (payload) => {
    try {
      dispatch(showLoader("Loading..."));

      const res = await addAffiliateApplication(payload);

      // Swal.fire(
      //   "Requested!",
      //   // `An ${APP_NAME} Account Executive will review your submission and contact you shortly to help you get started and answer any additional questions you may have.`,
      //   `Exciting news! Your account request is in, and awaiting review. A member of the team will reach out if we need anything further.`,
      //   "success"
      // );
      // navigate(`/docu-sign-accept-agreement/${res?.token}`)

      showToast("Congratulations, you have successfully logged in", "success");

      _completedAuthorization(res);
      dispatch(hideLoader());
    } catch (error) {
      errorHandler(error);
      dispatch(hideLoader());
    }
  };

  const _onSaveDetails = async (e) => {
    try {
      if (e) e.preventDefault();

      // if (!isAcceptTerm) {
      //   showToast("Please accept Terms and Conditions", "error");
      //   return;
      // }

      const newFormFields = { ...formFields };

      const newIsDirty = {
        captchaValue: true,
      };

      affiliateApplicationConfig.forEach((each) => {
        newIsDirty[each.key] = true;
      });

      const isFormValid = await _validateFormFields({
        newFormFields,
        newIsDirty,
      });

      if (!isFormValid) return;

      if (validateCaptcha(formFields.captchaValue) !== true) {
        showToast("Captcha Does Not Match", "error");
        return;
      }

      const { firstName, lastName } = splitFullName(formFields.fullName.trim());

      const payload = {
        name: {
          first: firstName,
          last: lastName,
        },
        email: formFields.email.trim(),
        phone: formFields.phone.trim(),
        countryCode: formFields.countryCode.trim(),
        referCode: formFields.referCode?.value,
        _referBy: formFields.referCode?._referBy,
        // _referBy: formFields._referBy?.value,
        responses: [], // { questionText: String, response: String }
        password: formFields.password,
      };

      affiliateApplicationConfig.forEach((each) => {
        if (each.isQuestion) {
          payload.responses.push({
            questionText: each.label,
            response: formFields[each.key],
          });
        }
      });

      _addAffiliateApplication(payload);
    } catch (error) {
      errorHandler(error);
    }
  };

  const _renderFormFields = () => {
    let questionCount = 1;

    return affiliateApplicationConfig.map((each) => {
      switch (each.type) {
        case "input": {
          if (each.key === "phone") {
            return (
              <div className="mb-3" key={each.key}>
                <Label>{each.label}</Label>
                <InputGroup>
                  <InputGroupText className="p-0 bg-transparent border-0 border-end">
                    <Input
                      type="select"
                      className="pl-0 bg-transparent pe-0 border-0"
                      value={formFields.countryCode || ""}
                      name="countryCode"
                      onChange={(e) =>
                        _onChangeFormFields("countryCode", e.target.value)
                      }
                      onBlur={() => _onBlurFormFields("countryCode")}
                    >
                      {countryCodes.map((each) => (
                        <option key={each.code} value={each.dial_code}>
                          {each.code} ({each.dial_code})
                        </option>
                      ))}
                    </Input>
                  </InputGroupText>
                  <Input
                    type="text"
                    value={formFields.phone || ""}
                    name="phone"
                    onChange={(e) =>
                      _onChangeFormFields("phone", e.target.value)
                    }
                    onBlur={() => _onBlurFormFields("phone")}
                  />
                </InputGroup>
                {errors["phone"] ? (
                  <div className="form-error">{errors["phone"]}</div>
                ) : null}
              </div>
            );
          }
          if (each.key === "password") {
            return (
              <div className="mb-3" key={each.key}>
                <Label>{each.label}</Label>
                <InputGroup>
                  <Input
                    type={showPassword ? "text" : "password"}
                    value={formFields[each.key] || ""}
                    name={each.key}
                    onChange={(e) =>
                      _onChangeFormFields(each.key, e.target.value)
                    }
                    onBlur={() => _onBlurFormFields(each.key)}
                  />
                  <InputGroupText
                    className="cursorPointer"
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                  >
                    <i
                      className={`far ${
                        showPassword ? "fa-eye" : "fa-eye-slash"
                      }`}
                    />
                  </InputGroupText>
                </InputGroup>
                {errors[each.key] ? (
                  <div className="form-error">{errors[each.key]}</div>
                ) : null}
              </div>
            );
          }
          return (
            <div className="mb-3" key={each.key}>
              <Label>{each.label}</Label>
              <Input
                type="text"
                value={formFields[each.key] || ""}
                name={each.key}
                onChange={(e) => _onChangeFormFields(each.key, e.target.value)}
                onBlur={() => _onBlurFormFields(each.key)}
              />
              {errors[each.key] ? (
                <div className="form-error">{errors[each.key]}</div>
              ) : null}
            </div>
          );
        }
        case "radio": {
          return (
            <div className="mb-3" key={each.key}>
              <Label>
                {each.isQuestion ? questionCount++ + "." : ""} {each.label}
              </Label>
              {each.options.map((option) => (
                <FormGroup check key={`${each.key}_${option}`}>
                  <Input
                    name={each.key}
                    id={`${each.key}_${option}`}
                    type="radio"
                    checked={formFields[each.key] === option ? true : false}
                    value={option}
                    onChange={(e) =>
                      _onChangeFormFields(each.key, e.target.value)
                    }
                    onBlur={() => _onBlurFormFields(each.key)}
                  />
                  <Label check for={`${each.key}_${option}`}>
                    {option}
                  </Label>
                </FormGroup>
              ))}
              {errors[each.key] ? (
                <div className="form-error">{errors[each.key]}</div>
              ) : null}
            </div>
          );
        }
        case "textarea": {
          return (
            <div className="mb-3" key={each.key}>
              <Label>{each.label}</Label>
              <Input
                type="textarea"
                row="4"
                value={formFields[each.key] || ""}
                name={each.key}
                onChange={(e) => _onChangeFormFields(each.key, e.target.value)}
                onBlur={() => _onBlurFormFields(each.key)}
              />
              {errors[each.key] ? (
                <div className="form-error">{errors[each.key]}</div>
              ) : null}
            </div>
          );
        }
        case "searchable_input": {
          if (each.key !== "referCode" || !formFields[each.key]) return null;
          return (
            <div className="mb-3" key={each.key}>
              <Label>Referred By</Label>
              <SearchableInput
                options={usersList}
                value={formFields[each.key] || ""}
                onChange={(value) => _onChangeFormFields(each.key, value)}
                onBlur={() => _onBlurFormFields(each.key)}
                disabled={true}
              />
              {errors[each.key] ? (
                <div className="form-error">{errors[each.key]}</div>
              ) : null}
            </div>
          );
        }
        default:
          return null;
      }
    });
  };

  useEffect(() => {
    const newFormFields = { ...formFields };

    affiliateApplicationConfig.forEach((each) => {
      newFormFields[each.key] = "";
    });
    newFormFields.captchaValue = "";
    setFormFields(newFormFields);

    _getAffiliateList(newFormFields);

    loadCaptchaEnginge(4, "grey");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="authPgWrapper affiliate-questionnaire">
        <div className="authFormWrap">
          <img
            src={APP_LOGO}
            alt="Project Logo"
            className="companyLogo"
            role="button"
            onClick={() => navigate("/")}
          />

          <div className="authForm">
            <h2 className="form-title">Affiliate Application</h2>

            {/* <div className="questionnaireVideo">
              <video
                poster="/assets/img/affiliate_intro_thumbnail.png"
                src={AFFILIATE_APPLICATION_INTRO_VIDEO}
                controls
                // poster={require("../../assets/img/questionnaire.jpg")}
                disablePictureInPicture
                controlsList="nodownload"
              />
            </div> */}

            <hr />

            <Form onSubmit={(e) => _onSaveDetails(e)}>
              {_renderFormFields()}

              <div className="mt-3">
                <div className="d-flex align-items-center">
                  <Input
                    type="text"
                    placeholder="Enter Captcha Value"
                    value={formFields?.captchaValue || ""}
                    name={"captchaValue"}
                    onChange={(e) =>
                      _onChangeFormFields("captchaValue", e.target.value)
                    }
                    onBlur={() => _onBlurFormFields("captchaValue")}
                  />

                  <LoadCanvasTemplateNoReload />
                </div>
                {errors?.captchaValue ? (
                  <div className="form-error">{errors?.captchaValue}</div>
                ) : null}
              </div>

              {/* <div className="form-check mt-3">
                <Input
                  id="isAcceptTerm"
                  type="checkbox"
                  role="button"
                  name="isAcceptTerm"
                  value="isAcceptTerm"
                  checked={isAcceptTerm}
                  onChange={(e) => setIsAcceptTerm(e.target.checked)}
                />
                <Label check for="isAcceptTerm" role="button">
                  I agree to the{" "}
                  <a
                    href={`${WEBSITE_URL}/terms`}
                    rel="noreferrer"
                    target="_blank"
                    className="me-1"
                  >
                    Terms of Service
                  </a>
                  &
                  <a
                    href={`${WEBSITE_URL}/privacy-policy`}
                    rel="noreferrer"
                    target="_blank"
                    className="ms-1"
                  >
                    Affiliate Policy
                  </a>
                  .
                </Label>
              </div> */}

              <Button type="submit" color="primary">
                Submit
              </Button>
            </Form>
          </div>
        </div>
      </div>

      <PublicFooter />
    </>
  );
};

export default AffiliateApplication;
