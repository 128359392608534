import React, { Fragment } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
} from "reactstrap";
import { capitalize, copyToClipboard } from "../helper-methods";
import { affiliateTypeConfig } from "../config/helper-config";
import ReferralCodeAndLinkList from "./ReferralCodeAndLinkList";

const AffiliateSecondaryLeader = ({
  data,
  _toggleAddUsersModal,
  openSecondaryAccordian,
  _toggleSecondaryAccordian,
}) => {
  return (
    <div className="cardWrap personalInfo">
      <div className="cardHeader d-flex justify-content-between">
        Secondary Leader
        <Button
          className="editBtn"
          color="link"
          onClick={() =>
            _toggleAddUsersModal(true, null, "Add Secondary Leader", data)
          }
        >
          <i className="fas fa-plus" />
        </Button>
      </div>
      <div className="cardBody">
        {data?._organization?._secondaryLeader?.length ? (
          data?._organization?._secondaryLeader?.map((each, index) => (
            <Fragment key={each?._id + index}>
              <Accordion
                open={openSecondaryAccordian}
                toggle={_toggleSecondaryAccordian}
                key={index}
              >
                <AccordionItem>
                  <AccordionHeader targetId={`${each?.id}`}>
                    {each?.name?.full || "N/A"}
                  </AccordionHeader>
                  <AccordionBody accordionId={`${each?.id}`}>
                    <div className="text-end">
                      <Button
                        color="link"
                        className="editBtn"
                        onClick={() =>
                          _toggleAddUsersModal(
                            true,
                            each,
                            "Update Secondary Leader",
                            null
                          )
                        }
                      >
                        <i className="fas fa-pencil-alt"></i>
                      </Button>
                    </div>
                    <div className="contactInfo">
                      <ul>
                        <li>
                          Referred By :{" "}
                          {each?.isAddedByAdmin
                            ? "Admin"
                            : each?._referBy?.name?.full
                            ? capitalize(each?._referBy?.name?.full)
                            : "N/A"}
                        </li>
                        <li>
                          Organization Name : {each?.organizationName || "N/A"}
                        </li>
                        <li>
                          Type :{" "}
                          {affiliateTypeConfig.find(
                            (e) => e.value === each?.affiliateType
                          )?.label || "N/A"}
                        </li>
                      </ul>
                      <ul className="right">
                        <li>
                          <i className="fas fa-phone-alt" />
                          <span
                            className="text-overflow"
                            onClick={() => copyToClipboard(each?.phone)}
                          >
                            {each?.countryCode} {each?.phone || "N/A"}
                          </span>
                        </li>
                        <li>
                          <i className="far fa-envelope" />
                          <span
                            className="value"
                            onClick={() => copyToClipboard(each?.email)}
                          >
                            {each?.email || "N/A"}
                          </span>
                        </li>
                        <li>
                          <i className="fas fa-map-marker-alt" />
                          <span>{each?.location || "N/A"}</span>
                        </li>

                        <ReferralCodeAndLinkList data={each} />
                      </ul>
                    </div>
                  </AccordionBody>
                </AccordionItem>
              </Accordion>
            </Fragment>
          ))
        ) : (
          <div className="nodata">
            <img
              src={require("../assets/img/noData.svg").default}
              alt="no data"
            />
            <p>There is no data to display</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default AffiliateSecondaryLeader;
