import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Table, Container, Alert } from "reactstrap";
import LeadDocuments from "../../components/LeadDocuments";
import { APP_LOGO } from "../../config";
import DashboardFooter from "../../containers/Dashboard/DashboardFooter";
import {
  decodeToken,
  errorHandler,
  extractQueryParams,
  openUrlOnNewTab,
} from "../../helper-methods";
import {
  getAllLeadInfoDocuments,
  getLeadInfo,
  updateLeadInfoDocuments,
} from "../../http/http-calls";
import { hideLoader, showLoader } from "../../redux/actions/loader-data";

const LeadDocumentUpload = () => {
  const dispatch = useDispatch();

  const [data, setData] = useState(null);
  const [documents, setDocuments] = useState([]);

  const _getLeadDetails = () => {
    return Promise.all([
      new Promise(async (resolve, reject) => {
        try {
          const res = await getLeadInfo({ isTempToken: true });
          setData(res.lead);
          resolve(res);
        } catch (error) {
          reject(error);
        }
      }),
      new Promise(async (resolve, reject) => {
        try {
          const res = await getAllLeadInfoDocuments({ isTempToken: true });
          setDocuments(res.documents);
          resolve(res);
        } catch (error) {
          reject(error);
        }
      }),
    ]);
  };

  const _updateLeadInfoDocuments = ({ id, payload }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await updateLeadInfoDocuments({
          id,
          payload: { ...payload, isTempToken: true },
        });
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  };

  const _checkAuthUser = async () => {
    try {
      dispatch(showLoader("Loading..."));

      const { token } = extractQueryParams();

      const decodedToken = decodeToken(token);
      console.log({ decodedToken });

      localStorage.temp_token = token;

      await _getLeadDetails();

      dispatch(hideLoader());
    } catch (error) {
      if (error?.message?.includes("Invalid token specified")) {
        errorHandler({ reason: "Unauthorized" });
      } else {
        errorHandler(error);
      }
      delete localStorage.temp_token;
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    _checkAuthUser();

    return () => {
      delete localStorage.temp_token;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="mainWrapper">
        <div className="projectHeader navbar">
          <div className="navbar-brand px-3">
            <img src={APP_LOGO} alt="Logo" />
          </div>
        </div>
        <div className="innerWrapper">
          {data ? (
            <Container>
              <div className="pgTitleWrap">
                <h2>Client Document Upload</h2>
              </div>

              <Alert color="success">
                Please upload your documents here. 100% encrypted and secure.
              </Alert>

              <div className="cardWrap mt-4">
                <div className="cardHeader d-flex justify-content-between">
                  Personal Info
                </div>
                <div className="cardBody  d-flex justify-content-between">
                  <div className="basic-details">
                    <h6 className="fs-18 mb-2">{data?.companyName || "N/A"}</h6>
                    <p className="fs-14 mb-2">
                      Referred By : {data?._referBy?.name?.full || "N/A"}
                    </p>
                    <div className="d-flex">
                      <p className="mb-0 fs-14">
                        Agreement : {data?.agreement?.status || "N/A"}
                      </p>

                      {data?.agreement?.url && (
                        <Button
                          color="link"
                          className="ms-2 px-1"
                          onClick={() => openUrlOnNewTab(data.agreement.url)}
                        >
                          View
                        </Button>
                      )}
                    </div>
                  </div>

                  <div className="contact-details">
                    <div className="mb-2">
                      <i
                        className="fas fa-user me-1 fs-12"
                        style={{ width: 20, color: "#666" }}
                      />
                      {data?.name?.full || "N/A"}
                    </div>
                    <div className="mb-2">
                      <i
                        className="fas fa-phone-alt me-1 fs-12"
                        style={{ width: 20, color: "#666" }}
                      />
                      {data?.countryCode} {data?.phone || "N/A"}
                    </div>
                    <div className="mb-2">
                      <i
                        className="far fa-envelope me-1 fs-12"
                        style={{ width: 20, color: "#666" }}
                      />
                      {data?.email || "N/A"}
                    </div>
                    <div>
                      <i
                        className="fas fa-map-marker-alt fs-12"
                        style={{ width: 20, color: "#666" }}
                      />
                      {data?.location || "N/A"}
                    </div>
                  </div>
                </div>
              </div>

              <div className="tableInCardWrap lead-documents">
                <div className="tableCardHeader">Documents</div>
                <div className="tableWrapper">
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th className="text-end">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <LeadDocuments
                        upload
                        documents={documents}
                        getAllDocuments={() => _getLeadDetails()}
                        updateLeadInfoDocuments={({ id, payload }) =>
                          _updateLeadInfoDocuments({ id, payload })
                        }
                      />
                    </tbody>
                  </Table>
                </div>
              </div>

              <div className="cardWrap">
                <img
                  src={require("../../assets/img/Data_security_28.webp")}
                  alt=""
                  width="100%"
                  style={{ maxHeight: "500px", objectFit: "contain" }}
                />
              </div>
            </Container>
          ) : (
            <p className="text-danger text-center">Unauthorized</p>
          )}
        </div>
      </div>

      <DashboardFooter />
    </>
  );
};

export default LeadDocumentUpload;
