import { store } from "../redux/store";

export const getToken = (isTempToken = false) => {
  return new Promise((resolve, reject) => {
    let token = null;

    const { userCredential } = store.getState();

    if (userCredential?.token) {
      token = userCredential.token;
    }
    if (isTempToken && localStorage?.temp_token) {
      token = localStorage.temp_token;
    }

    resolve(token);
  });
};
