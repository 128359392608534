import React, { useState } from "react";
import ReactCreatableSelect from "react-select/creatable";

const CreatableInput = ({
  options,
  value,
  onChange,
  onBlur,
  disabled = false,
}) => {
  const [inputValue, setInputValue] = useState("");

  const _onChange = (obj) => {
    if (onChange) {
      if (typeof obj?.value === "string") {
        onChange({
          label: obj?.label,
          value: { min: +obj?.label, max: +obj?.label },
        });
      } else {
        onChange(obj);
      }
    }
  };

  const _onInputChange = (inputValue) => {
    if (isNaN(inputValue)) {
      return;
    }

    setInputValue(inputValue);
  };

  const _onBlur = () => {
    if (onBlur) onBlur();
  };

  return (
    <ReactCreatableSelect
      options={options}
      value={value}
      onChange={_onChange}
      inputValue={inputValue}
      onInputChange={_onInputChange}
      onBlur={_onBlur}
      isDisabled={disabled}
      placeholder="Select..."
      isClearable
      className="react-select-container"
      classNamePrefix="react-select"
      formatCreateLabel={(userInput) => `Search for ${userInput}`}
    />
  );
};

export default CreatableInput;
