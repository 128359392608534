import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Button, Table } from "reactstrap";
import AddCategories from "../modals/AddCategories";
import {
  deleteCategory,
  editCategory,
  fetchCategories,
  rearrangeCategories,
} from "../../http/http-calls";
import {
  convertLinksToAnchorTags,
  debounce,
  errorHandler,
  escapeHTML,
  showToast,
} from "../../helper-methods";
import CustomPagination from "../Table/CustomPagination";
import Swal from "sweetalert2";
import SkeletonLoading from "../SkeletonLoading";
import SvgIcons from "../SvgIcons";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import ActionButtonsComponent from "../ActionButtonsComponent";
import StatusChangeComponent from "../StatusChangeComponent";
import { categoryTopicStatus } from "../../config/statusChangeConfig";
import FilterTrainingVideo from "../FilterTrainingVideo";
import { categorieConfig } from "../../config/helper-config";

// Create a drag handle component
const DragHandle = SortableHandle(() => (
  <span style={{ cursor: "grab" }}>
    <i
      className="fas fa-grip-vertical"
      style={{ marginRight: "8px", cursor: "grabbing" }}
    />
  </span>
));

const SortableContainerList = SortableContainer(({ children }) => {
  return <div className="w-full">{children}</div>;
});

const SortableRow = SortableElement(
  ({
    category,
    catIndex,
    _changeStatus,
    _toggleAddTemplateModal,
    _deleteTemplateAlert,
    flag,
    deleteLoading,
    index,
  }) => (
    <tr key={index}>
      <td>
        <div className="d-flex align-items-center" style={{ width: 150 }}>
          <DragHandle /> {category?.category || "N/A"}
        </div>
      </td>
      <td>
        {/* <div style={{ width: 300 }}>{category?.description || "N/A"}</div> */}
        <div
          style={{ whiteSpace: "pre-wrap" }}
          dangerouslySetInnerHTML={{
            __html: convertLinksToAnchorTags(escapeHTML(category.description)),
          }}
        ></div>
      </td>
      <td>{category?.isMandatoryToSee === true ? "Yes" : "No"}</td>
      <td>
        <StatusChangeComponent
          value={category?.isActive}
          onChange={(event) => _changeStatus(event, category?._id, catIndex)}
          disabled={flag}
          options={categoryTopicStatus}
        />
      </td>
      <td>
        <ActionButtonsComponent
          data={category}
          deleteLoading={deleteLoading}
          onDelete={_deleteTemplateAlert}
          onEdit={(category) => _toggleAddTemplateModal(true, category)}
        />
      </td>
    </tr>
  )
);

// Create a sortable table container
const SortableTable = ({ items, loading, ...props }) => (
  <>
    {items?.length ? (
      <>
        <tbody>
          {items.map((category, index) => (
            <SortableRow
              key={index}
              index={index}
              catIndex={index}
              category={category}
              {...props}
            />
          ))}
        </tbody>
      </>
    ) : loading ? (
      <tbody>
        <SkeletonLoading type="table" height={20} row={5} col={5} />
      </tbody>
    ) : (
      <tbody>
        <tr className="text-center">
          <td colSpan="4">
            <div className="nodata">
              <img
                src={require("../../assets/img/noData.svg").default}
                alt="no data"
              />
              <p>There is no data to display</p>
            </div>
          </td>
        </tr>
      </tbody>
    )}
  </>
);

const CategoriesTab = ({ activeTab, tabId }) => {
  const [addTemplateModal, setAddTemplateModal] = useState({
    isOpen: false,
    data: null,
  });
  const [payload, setPayload] = useState({
    pageNumber: 0,
    limit: 10,
    page: 1,
    search: "",
    status: "",
  });

  const _toggleAddTemplateModal = (isOpen = false, data = null) => {
    setAddTemplateModal({ isOpen, data });
  };
  // eslint-disable-next-line no-unused-vars
  const [showFilter, setShowFilter] = useState(false);

  const [categories, setCategories] = useState([]);
  const [categoryCount, setCategoryCount] = useState(0);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [flag, setFlag] = useState(false);
  const [loading, setLoading] = useState(false);

  const _fetchAllCategories = async (newPayload) => {
    setLoading(true);
    if (newPayload) {
      try {
        const res = await fetchCategories(newPayload);

        setCategories(res?.faqCategories);
        setCategoryCount(res?.totalCount);
      } catch (error) {
        console.log({ error });
      }
    } else {
      try {
        const res = await fetchCategories(payload);

        setCategories(res?.faqCategories);
        setCategoryCount(res?.totalCount);
      } catch (error) {
        console.log({ error });
      }
    }
    setLoading(false);
  };

  const _changeStatus = async (event, id, index) => {
    let oldReports = JSON.parse(JSON.stringify(categories));

    const value = event.target.value;
    categories[index].isActive = value;
    setCategories([...categories]);
    setFlag(true);
    try {
      await editCategory(id, {
        status: value === "true" ? true : false,
      });

      showToast("Status successfully changed", "success");
    } catch (error) {
      setCategories(oldReports);
      showToast(error.reason, "error");
    }
    setFlag(false);
  };

  const _checkHandler = async (data) => {
    _fetchAllCategories(data);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const optimizedFn = useCallback(debounce(_checkHandler), []);

  const _searchFilterHandler = async (field, event) => {
    let newSearchData = { ...payload };

    if (field === "status") {
      newSearchData[field] = event.target.value?.length
        ? event.target.value === "true"
          ? true
          : false
        : "";
    } else {
      newSearchData[field] = event.target.value;
    }
    newSearchData["pageNumber"] = 0;
    newSearchData["page"] = 1;

    setPayload(newSearchData);

    optimizedFn({ ...newSearchData, search: newSearchData.search.trim() });
  };

  const _onSizeChange = (limit = 10) => {
    const newDataPayload = { ...payload };
    newDataPayload["skip"] = 0;
    newDataPayload["pageNumber"] = 0;
    newDataPayload["page"] = 1;
    newDataPayload["limit"] = limit;
    setPayload(newDataPayload);
    _fetchAllCategories(newDataPayload);
  };

  const _onPageChange = (pageNumber = 0) => {
    const newDataPayload = { ...payload };
    newDataPayload["pageNumber"] = pageNumber;
    newDataPayload["page"] = pageNumber + 1;
    setPayload(newDataPayload);
    _fetchAllCategories(newDataPayload);
  };

  const _deleteTemplate = (id) => {
    // deleteCategory
    setDeleteLoading(id);

    const newData = [...categories];
    let newDataCount = categoryCount;
    const findDataIndex = newData.findIndex((each) => each._id === id);
    if (findDataIndex > -1) {
      newData.splice(findDataIndex, 1);
      setCategories(newData);
      newDataCount--;
      setCategoryCount(newDataCount);
    }

    deleteCategory(id)
      .then(async (res) => {
        Swal.fire("Deleted!", "Template has been deleted.", "success");
        if (categories.length === 0 || newDataCount <= 10) {
          _onPageChange();
        }
        setDeleteLoading(false);
      })
      .catch((error) => {
        errorHandler(error);
        setDeleteLoading(false);
        _fetchAllCategories();
      });
  };
  const _deleteTemplateAlert = (data) => {
    if (!data?._id) {
      errorHandler();
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete this (Title: ${data?.category}) Template.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#31c5c7",
      cancelButtonColor: "#F16667",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        _deleteTemplate(data._id);
      }
    });
  };

  const _clearFilters = () => {
    let newPayload = {
      pageNumber: 0,
      limit: 10,
      page: 1,
      search: "",
      status: "",
    };
    setPayload(newPayload);
    _fetchAllCategories(newPayload);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    // Use the array's slice method to create a new array with the rearranged items
    const newCategories = [...categories];
    newCategories.splice(newIndex, 0, newCategories.splice(oldIndex, 1)[0]);

    let startingNumber = payload.pageNumber * 10; // Use orderNumber of the first item or default to

    let updatedArray = newCategories.map((item, index) => {
      return { ...item, orderNumber: startingNumber + index };
    });
    const neworderNumber = updatedArray[newIndex].orderNumber;
    const id = updatedArray[newIndex]._id;

    setCategories(updatedArray);
    let apiPayload = {
      newOrder: neworderNumber,
      categoryId: id,
    };
    _reArrangeArray(apiPayload);
  };

  const _reArrangeArray = async (newPayload) => {
    try {
      await rearrangeCategories(newPayload);
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    if (activeTab === tabId) {
      _fetchAllCategories();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, tabId]);

  return (
    <>
      <div
        className={`filterWrapper ${showFilter === true ? "showFilter" : ""}`}
      >
        <i className="fas fa-filter filterIcon" />

        <FilterTrainingVideo
          fields={categorieConfig}
          payload={payload}
          onFilterChange={_searchFilterHandler}
        />
      </div>
      <div className="text-end">
        <Button
          className="h-auto"
          color="primary"
          onClick={() => _toggleAddTemplateModal(true)}
        >
          Add Category
        </Button>
        <Button
          color="link"
          className="btn-reset"
          onClick={() => _clearFilters()}
        >
          <SvgIcons type={"reset"} />
        </Button>
      </div>
      <div className="mt-4">
        <SortableContainerList useDragHandle={true} onSortEnd={onSortEnd}>
          <Table responsive>
            <thead>
              <tr>
                <th>Category</th>
                <th>Description</th>
                <th>Mandatory</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>

            <SortableTable
              items={categories}
              loading={loading}
              onSortEnd={onSortEnd}
              _changeStatus={_changeStatus}
              _toggleAddTemplateModal={_toggleAddTemplateModal}
              _deleteTemplateAlert={_deleteTemplateAlert}
              flag={flag}
              deleteLoading={deleteLoading}
            />
          </Table>
        </SortableContainerList>

        <CustomPagination
          data={categories}
          dataCount={categoryCount}
          dataPayload={payload}
          onPageChange={_onPageChange}
          onSizeChange={_onSizeChange}
        />
      </div>

      {addTemplateModal.isOpen && (
        <AddCategories
          isOpen={addTemplateModal.isOpen}
          toggle={() => _toggleAddTemplateModal()}
          fetchAllCategories={_fetchAllCategories}
          editDetails={addTemplateModal?.data}
        />
      )}
    </>
  );
};

export default CategoriesTab;
