import React from "react";
import { APP_NAME, WEBSITE_URL } from "../../config";

const PublicFooter = () => {
  return (
    <div className="projectFooter lh-1">
      <div className="d-flex justify-content-between align-items-center">
        <p>© 2023 {APP_NAME} LLC</p>
      </div>

      <ul className="d-flex align-itmes-center list-unstyled mb-0">
        <li>
          <a
            href={`${WEBSITE_URL}/terms`}
            rel="noreferrer"
            target="_blank"
            className="ms-2 text-primary"
          >
            Terms
          </a>
        </li>
        <li className="ms-2 text-primary">|</li>
        <li>
          <a
            href={`${WEBSITE_URL}/privacy-policy`}
            rel="noreferrer"
            target="_blank"
            className="ms-2 text-primary"
          >
            Privacy Policy
          </a>
        </li>
        <li className="ms-2 text-primary">|</li>
        <li>
          <a
            href={`${WEBSITE_URL}/general-disclaimer`}
            rel="noreferrer"
            target="_blank"
            className="ms-2 text-primary"
          >
            Legal Disclaimer
          </a>
        </li>
        {/* <li className="ms-2 text-primary">|</li>
        <li className="ms-2">
          <div className="poweredBy">
            <p>Powered By</p>
            <a
              href="https://www.logic-square.com"
              target="_blank"
              rel="noreferrer"
              className="ms-2 lsLink"
            >
              Logic Square
            </a>
          </div>
        </li> */}
      </ul>
    </div>
  );
};

export default PublicFooter;
