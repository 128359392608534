import React, { Fragment } from "react";

const QuestionnaireCard = ({ data }) => (
  <div className="cardWrap questionnaire">
    <div className="cardHeader">
      <div>
        Questionnaire{" "}
        <img
          src="../assets/img/questionnaire.jpg"
          alt=""
          width="18x"
          className="ms-1"
        />
      </div>
    </div>
    <div className="cardBody quesAnsWrap">
      {data?.responses?.map((each, index) => (
        <Fragment key={`responses_${index}`}>
          <h5>
            {index + 1}. {each.questionText}
          </h5>
          <p>{each.response}</p>
        </Fragment>
      ))}
    </div>
  </div>
);

export default QuestionnaireCard;
