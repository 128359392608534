import React from "react";
import CustomPagination from "./Table/CustomPagination";
import SkeletonLoading from "./SkeletonLoading";
import { formatCurrencyValue, formatDate } from "../helper-methods";
import { Table } from "reactstrap";

const AffiliatePaymentTable = ({
  paymentDetails,
  loading,
  totalAmount,
  paymentCount,
  payload,
  _onResourcePageChange,
  _onResourceSizeChange,
}) => (
  <div className="tableInCardWrap">
    <div className="tableCardHeader">
      <h3>Payment Log</h3>

      <h6>
        Total Amount
        <span className="text-success">{formatCurrencyValue(totalAmount)}</span>
      </h6>
    </div>

    <div className="tableWrapper">
      <Table responsive>
        <thead>
          <tr>
            <th>Payment Date</th>
            <th>Payment Amount</th>
          </tr>
        </thead>
        <tbody>
          {paymentDetails?.length ? (
            paymentDetails.map((each) => (
              <tr key={each?._id}>
                <td>{formatDate(each.paymentDate)}</td>
                <td>{formatCurrencyValue(each.amount) || "N/A"}</td>
              </tr>
            ))
          ) : loading ? (
            <SkeletonLoading type="table" height={20} row={5} col={2} />
          ) : (
            <tr className="text-center">
              <td colSpan="2">
                <div className="nodata">
                  <img
                    src={require("../assets/img/noData.svg").default}
                    alt="no data"
                  />
                  <p>There is no data to display</p>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
    <CustomPagination
      data={paymentDetails}
      dataCount={paymentCount}
      dataPayload={payload}
      onPageChange={_onResourcePageChange}
      onSizeChange={_onResourceSizeChange}
    />
  </div>
);

export default AffiliatePaymentTable;
