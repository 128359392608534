export const dateConfig = [
  { label: "Last 7 Days", value: "last7days" },
  { label: "Last 4 Weeks", value: "last4weeks" },
  { label: "Last Month", value: "lastmonth" },
  { label: "Last 3 Months", value: "last3months" },
  { label: "Last 12 Months", value: "last12months" },
  { label: "Last Year", value: "lastyear" },
];

export const referredLeadsConfig = [
  { label: "0", value: { min: 0, max: 0 } },
  { label: "1-5", value: { min: 1, max: 5 } },
  { label: "6-10", value: { min: 6, max: 10 } },
  { label: "11-50", value: { min: 11, max: 50 } },
  { label: "50+", value: { min: 50 } },
];

export const refereAffiliateConfig = [
  { label: "0", value: { min: 0, max: 0 } },
  { label: "1-5", value: { min: 1, max: 5 } },
  { label: "6-10", value: { min: 6, max: 10 } },
  { label: "11-50", value: { min: 11, max: 50 } },
  { label: "50+", value: { min: 50 } },
];

export const docStatusConfig = [
  { label: "Pending", value: "Pending" },
  { label: "Requested", value: "Requested" },
  { label: "Submitted", value: "Submitted" },
  { label: "Partial", value: "Partial" },
  { label: "Completed", value: "Completed" },
];

export const qualifiedEmployeesConfig = [
  { label: "0-100", value: { min: 0, max: 100 } },
  { label: "101-250", value: { min: 101, max: 250 } },
  { label: "251-500", value: { min: 251, max: 500 } },
  { label: "501-1,000", value: { min: 501, max: 1000 } },
  { label: "1,000+", value: { min: 1000 } },
];

export const leadStatusConfig = [
  { label: "In review", value: "In review" },
  { label: "Canceled", value: "Canceled" },
  {
    label: "Scheduling a Call",
    value: "Scheduling a Call",
    description:
      "Employer has scheduled a call, and will receive a presentation from an IHIA team member.",
  },
  {
    label: "Presented",
    value: "Presented",
    description:
      "Employer has seen the presentation, and has been sent the census template.",
  },
  {
    label: "Following Up",
    value: "Following Up",
    description:
      "Employer is being followed up with, and the IHIA team is pushing them to fill out the census. Please follow up with the employer, and offer assistance.",
  },
  { label: "Not Qualified", value: "Not Qualified" },
  { label: "Census Requested", value: "Census Requested" },
  {
    label: "Proforma Requested",
    value: "Proforma Requested",
    description:
      "Employer has submitted the census, and the proforma (savings calculation) is being generated.",
  },
  {
    label: "Proforma Sent",
    value: "Proforma Sent",
    description:
      "The IHIA team has sent the proforma (savings calculation) to the employer, with a link to schedule a “review call.” Please follow up with the employer, and encourage them to book the call.",
  },
  {
    label: "Proforma Review with Lead",
    value: "Proforma Review with Lead",
    description:
      "The IHIA team has reviewed the proforma (savings calculation) with the employer. Please follow up with the employer, and get a gauge on their excitement level / readiness to move forward.",
  },
  {
    label: "Awaiting Verbal Confirmation",
    value: "Awaiting Verbal Confirmation",
    description:
      "The IHIA team is awaiting a verbal confirmation as to whether or not the employer wants to move forward. Please follow up with the employer, and give them a friendly push.",
  },
  { label: "Contract Sent", value: "Contract Sent" },
  { label: "Contract Signed", value: "Contract Signed" },
  {
    label: "Pre-implementation form sent",
    value: "Pre-implementation form sent",
    description:
      "The IHIA team has received verbal confirmation that “YES” the employer wants to proceed, so the Pre-Implementation form has been sent over. Please follow up with the employer, and congratulate them on their decision / offer assistance.",
  },
  {
    label: "Waiting for Docusign",
    value: "Waiting for Docusign",
    description:
      "The Pre-Implementation forms are completed, and the IHIA team has sent a DocuSign agreement. Please follow up with the employer, and push them to sign the agreement.",
  },
  {
    label: "Pending implementation",
    value: "Pending implementation",
    description:
      "The DocuSign agreement has been completed, and a payroll coordinator will reach out to help with the implementation phase.",
  },
  {
    label: "Full Census Assistance",
    value: "Full Census Assistance",
    description:
      "Payroll coordinator is working with the employer to get a full census / paycodes.",
  },
  {
    label: "Ready for TPA",
    value: "Ready for TPA",
    description:
      "Payroll coordinator has received the full census / paycodes, and a meeting with TPA is being scheduled.",
  },
  {
    label: "TPA Implementing",
    value: "TPA Implementing",
    description:
      "TPA has had an initial meeting with the group, and the program is being implemented. This process should take 3-4 weeks to complete, and then the employer will be LIVE!",
  },
  {
    label: "Active",
    value: "Active",
    description:
      "The IM4Health program is LIVE, and you will start receiving residuals soon.",
  },
];

export const documentTemplateStatusConfig = [
  { label: "Signed", value: "Signed" },
  { label: "Pending", value: "Pending" },
];

export const affiliateTypeConfig = [
  { label: "Regular Affiliate", value: "Regular" },
  { label: "Super Affiliate", value: "Super" },
  { label: "Organization Leader", value: "Leader" },
];

export const dashboardCardConfig = [
  {
    label: "Total Affiliates",
    value: "totalAffiliates",
    imgSrc: require("../assets/img/affiliate.png"),
  },
  {
    label: "Total Employers",
    value: "totalEmployer",
    imgSrc: require("../assets/img/employer.png"),
  },
  {
    label: "Total Estimated Employees",
    value: "totalEstimatedEmployee",
    imgSrc: require("../assets/img/employer.png"),
  },
  {
    label: "Total Qualified Employees",
    value: "totalQualifiedEmployees",
    imgSrc: require("../assets/img/employee.png"),
  },
];

export const categorieConfig = [
  {
    label: "Search",
    name: "search",
    type: "text",
    placeholder: "Search",
  },
  {
    label: "Status",
    name: "status",
    type: "select",
    defaultOption: "All",
    options: [
      { value: "true", label: "Active" },
      { value: "false", label: "Inactive" },
    ],
  },
];

export const topicConfig = [
  {
    label: "Search",
    name: "search",
    type: "text",
    placeholder: "Search",
  },
  {
    label: "Status",
    name: "status",
    type: "select",
    defaultOption: "All",
    options: [
      { value: "true", label: "Active" },
      { value: "false", label: "Inactive" },
    ],
  },
];

export const alphabetArray = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

export const videoResolutionsConfig = {
  threeTwoZero: 320,
  fiveFourZero: 540,
  sevenTwoZero: 720,
  oneZeroEightZero: 1080,
};

export const bitrateConfig = {
  fiveFourZero: 200000,
  oneZeroEightZero: 400000,
  sevenTwoZero: 800000,
  threeTwoZero: 1500000,
};
