import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  UncontrolledPopover,
  PopoverBody,
  Spinner,
  Input,
} from "reactstrap";
import { errorHandler, formatDate, formatTime } from "../../helper-methods";
import { getAllActivites } from "../../http/http-calls";
import ReadMoreReadLess from "../ReadMoreReadLess";

const TemplateLogModal = ({ isOpen, data, toggle }) => {
  const navigate = useNavigate();

  const userCredential = useSelector((state) => state?.userCredential);

  const userType = useMemo(
    () => userCredential?.user?.userType?.toLowerCase(),
    [userCredential]
  );

  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const _closeModal = () => {
    toggle();
  };

  const _getAllActivites = () => {
    setLoading(true);

    const payload = {
      templateId: data?._id,
    };

    getAllActivites(payload)
      .then((res) => {
        setActivities(res.activities?.length ? res.activities : []);
        setLoading(false);
      })
      .catch((error) => {
        errorHandler(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (isOpen && data?._id) {
      _getAllActivites();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, data]);

  const filteredActivities = useMemo(() => {
    if (search?.trim().length) {
      const serachValue = search?.trim().toLowerCase();
      return activities.filter((each) => {
        return (
          each?.message?.toLowerCase()?.includes(serachValue) ||
          each?.subject?.toLowerCase()?.includes(serachValue) ||
          each?.description?.toLowerCase()?.includes(serachValue) ||
          each._lead?.name?.full?.toLowerCase()?.includes(serachValue) ||
          each._affiliate?.name?.full?.toLowerCase()?.includes(serachValue) ||
          each._createdBy?.name?.full?.toLowerCase()?.includes(serachValue)
        );
      });
    }
    return activities;
  }, [activities, search]);

  return (
    <Modal
      isOpen={isOpen}
      size="xl"
      toggle={() => _closeModal()}
      scrollable
      centered
      className="modal-lg message-template"
      backdrop="static"
    >
      {/* the modal header name will be dynamic. Either "Email Template Log" or "Message Template Log"*/}
      <ModalHeader toggle={() => _closeModal()}>
        {data?.category === "Text" ? "Message" : data?.category || ""} Template
        Log
      </ModalHeader>
      <ModalBody>
        <div>
          <Input
            type="text"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="tableWrapper mb-0 shadow-none">
          <Table responsive>
            <thead>
              <tr>
                <th>Type</th>
                <th>Time</th>
                <th>Message</th>
                <th>By</th>
                <th>To</th>
              </tr>
            </thead>
            <tbody>
              {filteredActivities?.length ? (
                filteredActivities.map((each) => (
                  <tr key={each?._id}>
                    <td>
                      {each.activityType === "SMS"
                        ? "Text"
                        : each.activityType || "N/A"}
                    </td>
                    <td>
                      <div style={{ width: 100 }}>
                        {formatDate(each.createdAt)} <br />
                        {formatTime(each.createdAt)}
                      </div>
                    </td>
                    <td>
                      {each.message ? (
                        <>
                          <div
                            id={`messageTemplate_${each._id}`}
                            className="logTd"
                          >
                            <ReadMoreReadLess
                              text={
                                each.activityType === "Email"
                                  ? each.subject
                                  : each.message
                              }
                              count={80}
                              isHideMoreButton={true}
                            />
                          </div>
                          <UncontrolledPopover
                            target={`messageTemplate_${each._id}`}
                            trigger="legacy"
                          >
                            {each.activityType === "Email" ? (
                              <PopoverBody>
                                <div>
                                  Subject:
                                  <p>{each.subject}</p>
                                </div>
                                <hr />
                                <div>
                                  Body:
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: each.message,
                                    }}
                                  />
                                </div>
                              </PopoverBody>
                            ) : (
                              <PopoverBody>{each.message}</PopoverBody>
                            )}
                          </UncontrolledPopover>
                        </>
                      ) : (
                        "N/A"
                      )}
                    </td>
                    <td>
                      <div style={{ width: 100 }}>
                        {each._createdBy?.name?.full || "N/A"}
                      </div>
                    </td>
                    <td>
                      {each._lead?.name?.full ? (
                        <div
                          style={{ width: 200 }}
                          className="themeColor text-decoration-underline cursorPointer"
                          onClick={() =>
                            navigate(`/${userType}/client/${each._lead._id}`)
                          }
                          title={each._lead.email}
                        >
                          {each._lead?.name?.full}
                        </div>
                      ) : each._affiliate?.name?.full ? (
                        <div
                          className="themeColor text-decoration-underline cursorPointer"
                          onClick={() =>
                            navigate(
                              `/${userType}/affiliate/${each._affiliate._id}`
                            )
                          }
                          title={each._affiliate.email}
                        >
                          {each._affiliate?.name?.full}
                        </div>
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="text-center">
                  <td colSpan="5">
                    {loading ? (
                      <Spinner />
                    ) : (
                      <div className="nodata">
                        <img
                          src={require("../../assets/img/noData.svg").default}
                          alt="no data"
                        />
                        <p>There is no data to display</p>
                      </div>
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="link" className="closeBtn" onClick={() => _closeModal()}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default TemplateLogModal;
