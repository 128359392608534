import React from "react";
import {
  capitalize,
  copyToClipboard,
  formatCurrencyValue,
  formatDate,
} from "../helper-methods";
import { Table, UncontrolledTooltip } from "reactstrap";
import { affiliateTypeConfig } from "../config/helper-config";

const AffiliateReferredAffiliates = ({
  affiliateRefer,
  _calculateTotalAffiliatesPayout,
  _calculateTotalClientsPayout,
  _calculateTotalCommission,
  _toggleAffiliatesClientsModal,
}) => {
  return (
    <div className="tableInCardWrap">
      <div className="tableCardHeader">
        <h3>
          Referred Affiliates{" "}
          <img
            src={`/assets/img/people.png`}
            alt=""
            width="18"
            className="ms-1"
          />
        </h3>
        <h6>
          Estimated Earnings
          <span className="text-success">
            {formatCurrencyValue(
              _calculateTotalAffiliatesPayout(
                affiliateRefer,
                "affiliatesReferComission"
              )
            )}
          </span>
        </h6>
      </div>

      <div className="tableWrapper">
        <Table responsive>
          <thead>
            <tr>
              <th>Name</th>
              <th>Date</th>
              <th>Type</th>
              <th>Estimated Commissions</th>
              <th>Leader 1 Comm.</th>
              <th>Leader 2 Comm.</th>
              <th>Level 1 Comm.</th>
              <th>Level 2 Comm.</th>
            </tr>
          </thead>
          <tbody>
            {affiliateRefer?.length ? (
              affiliateRefer.map((each) => (
                <tr key={each?._id}>
                  <td className="text-capitalize">
                    {each?._affiliate?.referredClients?.length ? (
                      <div
                        className="themeColor cursorPointer"
                        onClick={() =>
                          _toggleAffiliatesClientsModal(
                            true,
                            each?._affiliate?.referredClients
                          )
                        }
                      >
                        {capitalize(each?._affiliate?.name?.full)}
                      </div>
                    ) : (
                      capitalize(each?._affiliate?.name?.full) || "N/A"
                    )}

                    <div
                      className="d-flex text-dark cursorPointer"
                      style={{ marginTop: 7 }}
                    >
                      {each._affiliate?.phone && (
                        <>
                          <i
                            className="fas fa-phone-alt fs-12"
                            id={`affiliate_details_page_user_phone_${each._id}`}
                            onClick={() =>
                              copyToClipboard(
                                `${each._affiliate?.countryCode || ""}${
                                  each._affiliate?.phone
                                }`
                              )
                            }
                          />
                          <UncontrolledTooltip
                            target={`affiliate_details_page_user_phone_${each._id}`}
                            placement="bottom"
                          >
                            {each._affiliate?.countryCode}{" "}
                            {each._affiliate?.phone}
                          </UncontrolledTooltip>
                        </>
                      )}
                      {each._affiliate?.email && (
                        <>
                          <i
                            className="far fa-envelope mx-3"
                            id={`affiliate_details_page_user_email_${each._id}`}
                            onClick={() =>
                              copyToClipboard(each._affiliate?.email)
                            }
                          />
                          <UncontrolledTooltip
                            target={`affiliate_details_page_user_email_${each._id}`}
                            placement="bottom"
                          >
                            {each._affiliate?.email}
                          </UncontrolledTooltip>
                        </>
                      )}
                    </div>
                  </td>
                  <td>{formatDate(each?._affiliate?.createdAt)}</td>
                  <td>
                    {affiliateTypeConfig.find(
                      (obj) => obj.value === each?._affiliate?.affiliateType
                    )?.label || "N/A"}
                  </td>
                  <td>
                    {formatCurrencyValue(
                      _calculateTotalClientsPayout(
                        each?._affiliate?.referredClients,
                        "affiliatesReferComission"
                      )
                    )}
                  </td>
                  <td>
                    {formatCurrencyValue(
                      _calculateTotalCommission(
                        each?._affiliate?.referredClients,
                        "firstLeaderComission"
                      )
                    )}{" "}
                    (PEPM)
                  </td>
                  <td>
                    {formatCurrencyValue(
                      _calculateTotalCommission(
                        each?._affiliate?.referredClients,
                        "secondLeaderComission"
                      )
                    )}{" "}
                    (PEPM)
                  </td>
                  <td>
                    {formatCurrencyValue(
                      _calculateTotalCommission(
                        each?._affiliate?.referredClients,
                        "leadReferComission"
                      )
                    )}{" "}
                    (PEPM)
                  </td>
                  <td>
                    {formatCurrencyValue(
                      _calculateTotalCommission(
                        each?._affiliate?.referredClients,
                        "affiliatesReferComission"
                      )
                    )}{" "}
                    (PEPM)
                  </td>
                </tr>
              ))
            ) : (
              <tr className="text-center">
                <td colSpan="8">
                  <div className="nodata">
                    <img
                      src={require("../assets/img/noData.svg").default}
                      alt="no data"
                    />
                    <p>There is no data to display</p>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default AffiliateReferredAffiliates;
