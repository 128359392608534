import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Spinner,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
} from "reactstrap";
import {
  errorHandler,
  formatDateAndTime,
  showToast,
} from "../../helper-methods";
import {
  createLeadNotes,
  deleteLeadNotes,
  getAllLeadNotes,
  updateLeadNotes,
} from "../../http/http-calls";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

const LeadNotesModal = ({ isOpen, data, toggle, onSuccess }) => {
  const userCredential = useSelector((state) => state?.userCredential);

  const userType = useMemo(
    () => userCredential?.user?.userType?.toLowerCase(),
    [userCredential]
  );

  const [notes, setNotes] = useState([]);
  const [noteText, setNoteText] = useState("");
  const [notesId, setNotesId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const _getAllLeadNotes = () => {
    setLoading(true);

    getAllLeadNotes(data?._client?._id || data?._id)
      .then((res) => {
        setNotes(res.notes);
        setLoading(false);
      })
      .catch((error) => {
        errorHandler(error);
        setLoading(false);
      });
  };

  const _closeModal = () => {
    toggle();
    setNotes([]);
    setNoteText("");
    setNotesId(null);
    setLoading(false);
    setDeleteLoading(false);
  };

  const _toggleEditNotes = (note) => {
    if (note?._id) {
      setNoteText(note.noteText);
      setNotesId(note._id);
    } else {
      setNoteText("");
      setNotesId(null);
    }
  };

  const _onSubmitNotes = async (e) => {
    try {
      if (e) e.preventDefault();

      if (!noteText?.trim().length) {
        showToast("Please enter notes", "error");
        return;
      }

      setLoading(true);

      if (notesId) {
        const newNotes = [...notes];
        const findNotesIndex = newNotes.findIndex(
          (each) => each._id === notesId
        );

        if (findNotesIndex < 0) {
          showToast("Notes not found", "error");
          setLoading(false);
          return;
        }

        const { note } = await updateLeadNotes({
          id: notesId,
          payload: { noteText: noteText.trim() },
        });

        newNotes[findNotesIndex] = { ...note };
        setNotes(newNotes);

        showToast("Notes has been updated", "success");
      } else {
        const { note } = await createLeadNotes({
          noteText: noteText.trim(),
          _lead: data?._client?._id || data?._id,
        });

        const newNotes = [...notes];
        newNotes.unshift({ ...note });
        setNotes(newNotes);

        showToast("Notes has been created", "success");

        onSuccess();
      }

      setTimeout(() => {
        _toggleEditNotes();
      }, 0);

      setLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  const _onKeyDownNotes = (e) => {
    if (
      e &&
      e.key &&
      e.keyCode &&
      e.key === "Enter" &&
      e.keyCode === 13 &&
      !e.shiftKey
    )
      _onSubmitNotes();
  };

  const _deleteLeadNotes = (id) => {
    if (id === notesId) {
      _toggleEditNotes();
    }

    setDeleteLoading(id);

    const newNotes = [...notes];
    const findDataIndex = newNotes.findIndex((each) => each._id === id);

    if (findDataIndex < 0) {
      showToast("Notes not found", "error");
      setDeleteLoading(false);
      return;
    }

    newNotes.splice(findDataIndex, 1);
    setNotes(newNotes);

    deleteLeadNotes(id)
      .then(async (res) => {
        Swal.fire("Deleted!", "Comment has been deleted.", "success");

        onSuccess();

        setDeleteLoading(false);
      })
      .catch((error) => {
        errorHandler(error);
        setDeleteLoading(false);
        _getAllLeadNotes();
      });
  };

  const _deleteLeadNotesAlert = (data) => {
    if (!data?._client?._id && !data?._id) {
      errorHandler();
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete this comment.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#31c5c7",
      cancelButtonColor: "#F16667",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        _deleteLeadNotes(data?._client?._id || data?._id);
      }
    });
  };

  useEffect(() => {
    if (isOpen && data?._id) {
      _getAllLeadNotes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, data]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => _closeModal()}
      scrollable
      centered
      className="notes"
      backdrop="static"
    >
      <ModalHeader toggle={() => _closeModal()}>Notes</ModalHeader>
      <ModalBody>
        <div className="notes-add">
          <Input
            type="textarea"
            disabled={loading}
            value={noteText}
            onChange={(e) => setNoteText(e.target.value)}
            onKeyDown={(e) => _onKeyDownNotes(e)}
          />

          <div className="d-flex justify-content-end mt-2">
            {notesId ? (
              <Button
                color="link"
                size="sm"
                className="me-2 text-muted"
                onClick={() => _toggleEditNotes()}
              >
                Cancel
              </Button>
            ) : null}
            <Button
              color="primary"
              size="sm"
              disabled={loading}
              onClick={(e) => _onSubmitNotes(e)}
            >
              {loading ? <i className="fa fa-spinner fa-spin mr-2" /> : null}{" "}
              {notesId ? "Update" : "Add"}
            </Button>
          </div>
        </div>

        {notes?.length ? (
          notes.map((each) => (
            <Card key={each._id} className="notesCard">
              <CardHeader>
                <CardTitle>
                  <span className="title">
                    {each.createdBy?.name?.full || "N/A"}
                  </span>
                  {" - "}
                  <span>{formatDateAndTime(each.createdAt)}</span>
                </CardTitle>

                {userType === "admin" ||
                userCredential?.user?._id === each?.createdBy?._id ? (
                  <>
                    <div className="action">
                      <Button
                        color="link"
                        size="sm"
                        onClick={() => _toggleEditNotes(each)}
                      >
                        <i className="fas fa-pencil-alt text-primary" />
                      </Button>
                      <Button
                        color="link"
                        size="sm"
                        disabled={deleteLoading ? true : false}
                        onClick={() => _deleteLeadNotesAlert(each)}
                      >
                        {deleteLoading === "123" ? (
                          <i className="fa fa-spinner fa-spin text-danger" />
                        ) : (
                          <i className="fa fa-trash-alt text-danger" />
                        )}
                      </Button>
                    </div>
                  </>
                ) : null}
              </CardHeader>
              <CardBody>
                <p className="">{each.noteText}</p>
              </CardBody>
            </Card>
          ))
        ) : loading ? (
          <div className="text-center">
            <Spinner />
          </div>
        ) : // "There is no data to display."
        null}
      </ModalBody>
    </Modal>
  );
};

export default LeadNotesModal;
