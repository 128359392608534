import React, { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

import CategoriesTab from "../../components/Training/CategoriesTab";
import TopicsTab from "../../components/Training/TopicsTab";
import AditionalResourcesTab from "../../components/Training/AditionalResourcesTab";

const Training = () => {
  const [activeTab, setActiveTab] = useState("Categories");

  const _toggleTab = (newTab = "Categories") => {
    if (activeTab !== newTab) {
      setActiveTab(newTab);
    }
  };
  return (
    <>
      <div className="pgTitleWrap">
        <h2>Training</h2>
      </div>

      <div className="cardWrap main_content_wrapper">
        <Nav pills className="mobileTabs">
          <NavItem>
            <NavLink
              className={activeTab === "Categories" ? "active" : ""}
              onClick={() => _toggleTab("Categories")}
            >
              Categories
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "Topic" ? "active" : ""}
              onClick={() => _toggleTab("Topic")}
            >
              Topic
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "AdditionalResources" ? "active" : ""}
              onClick={() => _toggleTab("AdditionalResources")}
            >
              Additional Resources
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="Categories">
            <CategoriesTab activeTab={activeTab} tabId="Categories" />
          </TabPane>

          <TabPane tabId="Topic">
            <TopicsTab activeTab={activeTab} tabId="Topic" />
          </TabPane>

          <TabPane tabId="AdditionalResources">
            <AditionalResourcesTab
              activeTab={activeTab}
              tabId="AdditionalResources"
            />
          </TabPane>
        </TabContent>
      </div>
    </>
  );
};

export default Training;
