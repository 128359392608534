import React, { Component } from "react";
import { Button, Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import {
  convertLinksToAnchorTags,
  deepClone,
  errorHandler,
  escapeHTML,
  openUrlOnNewTab,
} from "../../helper-methods";
import { getTopicById, maintainWatchlist } from "../../http/http-calls";
import SkeletonLoading from "../../components/SkeletonLoading";
import SvgIcons from "../../components/SvgIcons";
import CustomVideoPlayer from "../../components/custom/CustomVideoPlayer";
import { withRouter } from "../../hooks/withRouter";
// import LessonVideoPlayer from "../../components/LessonVideoPlayer";

class TrainingUserDetail extends Component {
  state = {
    topicDetails: null,
    runningLessonData: null,
    isCompletedLesson: false,
    loading: false,
  };

  _updateLessonDuration = async (payload) => {
    try {
      const { runningLessonData } = this.state;

      await maintainWatchlist({ id: runningLessonData?._id, payload });
    } catch (error) {
      errorHandler(error);
    }
  };

  _onProgress = (videoRef) => {
    try {
      const { topicDetails } = deepClone(this.state);

      if (!topicDetails || topicDetails?.isCompleted) return;

      const watchedTill = Number(videoRef?.current?.currentTime?.toFixed(0));
      const wholeDuration = Number(videoRef?.current?.duration?.toFixed(0));

      if (watchedTill && wholeDuration) {
        const payload = {
          watchedTill,
        };

        if (topicDetails) {
          if (!topicDetails.isStarted && watchedTill <= 5) {
            topicDetails.isStarted = true;

            // api payload updated for lesson started
            payload.isStarted = true;
          }

          const remainingTimeInSeconds = wholeDuration - watchedTill;

          if (
            topicDetails.isStarted &&
            !topicDetails.isCompleted &&
            remainingTimeInSeconds <= 5
          ) {
            // api payload updated for lesson completed
            payload.isCompleted = true;

            topicDetails.isCompleted = true;

            this.setState({ isCompletedLesson: true });
          }

          topicDetails.watchedTill = watchedTill;

          this.setState({ topicDetails });
        }

        // api hit for track lesson duration
        this._updateLessonDuration(payload);
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  _topicDetails = async () => {
    try {
      this.setState({ loading: true });

      const res = await getTopicById(this.props?.params?.id);

      const topicDetails = res?.faqTopic;

      if (res.onGoingTopic?.length) {
        const findTracking = res.onGoingTopic.find(
          (subEach) => subEach._topic === res?.faqTopic?._id
        );
        if (findTracking) {
          topicDetails.isStarted = findTracking.isStarted;
          topicDetails.isCompleted = findTracking.isCompleted;
          topicDetails.watchedTill = findTracking.watchedTill;
        }
      }

      this.setState({
        topicDetails,
        isCompletedLesson: !res?.faqTopic?._faqCategory?.isMandatoryToSee,
        runningLessonData: {
          _id: res?.faqTopic?._id,
          title: res?.faqTopic?.topicName,
          videoUrl: res?.faqTopic?.videoFiles?.[0]?.url,
          duration: res?.faqTopic?.videoFiles?.[0]?.duration,
          poster: res?.faqTopic?.imageFiles?.[0]?.url,
          isStarted: topicDetails?.isStarted || false,
          isCompleted: topicDetails?.isCompleted || false,
          watchedTill: topicDetails?.watchedTill || 0,
        },
        loading: false,
      });
    } catch (error) {
      errorHandler(error);
      this.setState({ loading: false });
    }
  };

  componentDidMount = () => {
    this._topicDetails();
  };

  render() {
    const { topicDetails, runningLessonData, isCompletedLesson, loading } =
      this.state;

    return (
      <>
        <div className="pgTitleWrap">
          <h2>
            <Button
              color="link"
              className="backBtn"
              onClick={() =>
                this.props?.navigate &&
                this.props.navigate(
                  `/affiliate/training-list/${topicDetails?._faqCategory?._id}?categoryName=${topicDetails?._faqCategory?.category}&description=${topicDetails?._faqCategory?.description}`
                )
              }
            >
              <i className="fas fa-chevron-left" />
            </Button>
            Training Details
          </h2>
        </div>

        <Card className="cardDesign">
          {topicDetails ? (
            <>
              <CardHeader>
                <CardTitle className="d-flex">
                  {topicDetails?.topicName}{" "}
                  {topicDetails?.isCompleted ? <SvgIcons type="check" /> : null}
                </CardTitle>
              </CardHeader>
              <CardBody className="traningContent">
                <div className="topicVideo">
                  {/* <video
                  src={topicDetails?.videoFiles?.[0]?.url}
                  poster={topicDetails?.imageFiles?.[0]?.url}
                /> */}

                  {/* <LessonVideoPlayer
                  runningLessonVideoRef={runningLessonVideoRef}
                  runningLessonData={runningLessonData}
                  onProgress={_onProgress}
                /> */}

                  <CustomVideoPlayer
                    runningLessonData={runningLessonData}
                    isCompletedLesson={isCompletedLesson}
                    fullScreenMode
                    controls
                    onProgress={this._onProgress}
                  />
                </div>

                {/* <h1>{topicDetails?.topicName}</h1> */}

                <div
                  style={{ whiteSpace: "pre-wrap" }}
                  dangerouslySetInnerHTML={{
                    __html: convertLinksToAnchorTags(
                      escapeHTML(topicDetails?.content)
                    ),
                  }}
                />

                {topicDetails?.pdfFiles?.[0]?.url && (
                  <Button
                    color="link"
                    className="remove p-0"
                    onClick={() =>
                      openUrlOnNewTab(topicDetails?.pdfFiles?.[0]?.url, true)
                    }
                  >
                    <SvgIcons type={"pdf"} />
                    {/* {topicDetails?.pdfFiles?.[0]?.title || "PDF Notes"} */}
                    <span className="pr-2">PDF Notes</span>
                  </Button>
                )}
              </CardBody>
            </>
          ) : (
            <>
              {loading ? (
                <>
                  <CardHeader>
                    <CardTitle>
                      <SkeletonLoading type="box" height={30} />
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <SkeletonLoading type="box" height={400} />
                  </CardBody>
                </>
              ) : (
                <>
                  <div className="nodata">
                    <img
                      src={require("../../assets/img/noData.svg").default}
                      alt="no data"
                    />
                    <p>There is no data to display</p>
                  </div>
                </>
              )}
            </>
          )}
        </Card>
      </>
    );
  }
}

export default withRouter(TrainingUserDetail);
