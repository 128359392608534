import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Toaster } from "react-hot-toast";

import FullPageLoader from "./containers/FullPageLoader";
import ErrorNotFound from "./components/ErrorNotFound";
import ProtectedRoute from "./components/routes/ProtectedRoute";
import PublicRoute from "./components/routes/PublicRoute";
import DashboardLayout from "./containers/Dashboard/DashboardLayout";
import LoginPage from "./pages/public-pages/LoginPage";
import ForgotPasswordPage from "./pages/public-pages/ForgotPasswordPage";
import AffiliateApplication from "./pages/public-pages/AffiliateApplication";
import Questionnaire from "./pages/public-pages/Questionnaire";
import LeadDocumentUpload from "./pages/public-pages/LeadDocumentUpload";
import { useSelector } from "react-redux";
import DocuSignSuccessPage from "./pages/public-pages/DocuSignSuccessPage";
import DocuSignAcceptAgreementPage from "./pages/public-pages/DocuSignAcceptAgreementPage";
import QuestionnaireNextStep from "./pages/public-pages/QuestionnaireNextStep";
import useOneSignal from "./hooks/useOneSignal";

const App = () => {
  const userCredential = useSelector((state) => state?.userCredential);
  useOneSignal();
  return (
    <Router>
      <>
        <FullPageLoader />
        <Toaster
          position="bottom-right"
          reverseOrder={false}
          toastOptions={{
            style: {
              color: "#666",
              fontSize: 14,
              letterSpacing: 0.3,
              // add the font-family to be used in the project here
              fontFamily: "Poppins",
            },
          }}
        />
        <Routes>
          <Route
            exact
            path="/docu-sign-accept-agreement/:token"
            element={<DocuSignAcceptAgreementPage />}
          />
          <Route
            exact
            path="/agreementsigned/:id"
            element={<DocuSignSuccessPage />}
          />
          <Route
            exact
            path="/client-document-upload"
            element={<LeadDocumentUpload />}
          />
          <Route
            exact
            path="/affiliate-application"
            element={<AffiliateApplication />}
          />
          <Route exact path="/questionnaire" element={<Questionnaire />} />
          <Route
            exact
            path="/questionnaire-next-step"
            element={<QuestionnaireNextStep />}
          />

          <Route
            path=""
            element={
              <PublicRoute
                redirectRoute={`/${userCredential?.user?.userType?.toLowerCase()}`}
              />
            }
          >
            <Route exact path="/login" element={<LoginPage />} />
            <Route
              exact
              path="/forgot-password"
              element={<ForgotPasswordPage />}
            />

            <Route index element={<Navigate replace to="/login" />} />
          </Route>

          <Route path="" element={<ProtectedRoute redirectRoute={"/login"} />}>
            <Route path={`/*`} element={<DashboardLayout />} />
          </Route>

          <Route path="*" element={<Navigate replace to="/" />} />

          <Route element={<ErrorNotFound />} />
        </Routes>
      </>
    </Router>
  );
};

export default App;
