import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { updateUserData } from "../../redux/actions/user-credential";
import { notificationsRead } from "../../http/http-calls";
import { errorHandler } from "../../helper-methods";
import moment from "moment";

const NotificationViewModal = ({ isOpen, toggle, data, pageName }) => {
  const dispatch = useDispatch();

  const userCredential = useSelector((state) => state?.userCredential);

  const _closeModal = () => {
    toggle();
    _readNotification();
  };

  const [currentIndex, setCurrentIndex] = useState(0);

  const _readNotification = async () => {
    try {
      await notificationsRead({
        userType: userCredential?.user?.userType?.toLowerCase(),
      });
      dispatch(
        updateUserData({...userCredential, user: userCredential?.user, notificationCount: 0, })
      );
    } catch (error) {
      errorHandler(error);
    }
  };

  const _handleNextClick = () => {
    if (currentIndex < data.length - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    } else {
      _closeModal();
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        scrollable
        centered
        className="modal-lg message-template"
        backdrop="static"
      >
        <ModalHeader
          toggle={() =>
            pageName === "notification" ? toggle() : _closeModal()
          }
        >
          {" "}
          View Notification
        </ModalHeader>

        <ModalBody>
          <div className="notificationContent">
            {data[currentIndex]?.title ? (
              <h2>{data[currentIndex]?.title}</h2>
            ) : null}

            <p>
              {data[currentIndex]?.description ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: data[currentIndex]?.description,
                  }}
                />
              ) : (
                "N/A"
              )}
            </p>
            <span>
              {data[currentIndex]?.createdAt
                ? moment(data[currentIndex]?.createdAt)?.format(
                    "MMM Do YYYY h:mm a"
                  )
                : "N/A"}
            </span>
            {data[currentIndex]?.attachments?.length ? (
              <div className="uploadBody">
                <div className="UploadContent">
                  {data[currentIndex]?.attachments?.map((file, index) =>
                    file?.url ? (
                      <div className="uploadItem" key={index}>
                        <img src={file?.url} alt="file" />
                      </div>
                    ) : null
                  )}
                </div>
              </div>
            ) : null}
          </div>
        </ModalBody>

        {pageName === "notification" ? null : (
          <ModalFooter>
            <Button color="secondary" onClick={() => _handleNextClick()}>
              Ok
            </Button>
          </ModalFooter>
        )}
      </Modal>
    </>
  );
};

export default NotificationViewModal;
