import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  FormGroup,
  Label,
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { APP_NAME } from "../../config";
import {
  errorHandler,
  formatDateMonthYear,
  showToast,
} from "../../helper-methods";
import { getLeadById, updateLead } from "../../http/http-calls";
import ReactDatetime from "react-datetime";

const EditEarningsModal = ({ isOpen, data, toggle, onSuccess }) => {
  const ModalBodyRef = useRef();

  const [formFields, setFormFields] = useState({
    qualifiedEmployees: "",
    qualifiedDate: "",
    companyComission: "",
    leadReferComission: "",
    affiliatesReferComission: "",
    firstLeaderComission: "",
    secondLeaderComission: "",
  });
  // eslint-disable-next-line no-unused-vars
  const [isDirty, setIsDirty] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const _validateFormFields = ({ newFormFields, newIsDirty }) => {
    return new Promise((resolve) => {
      const newErrors = {};
      let isFormValid = true;

      if (newFormFields) {
        Object.keys(newFormFields).forEach((key) => {
          if (newIsDirty[key]) {
            switch (key) {
              case "qualifiedEmployees":
              case "companyComission":
              case "leadReferComission":
              case "affiliatesReferComission":
              case "firstLeaderComission":
              case "secondLeaderComission": {
                if (newFormFields[key]?.trim().length) {
                  if (
                    isNaN(newFormFields[key]) ||
                    +newFormFields[key] < 0 ||
                    +newFormFields[key] > 99
                  ) {
                    newErrors[key] = "*Please enter a number from 0 to 99";
                    isFormValid = false;
                  } else {
                    newErrors[key] = null;
                    newIsDirty[key] = false;
                  }
                } else {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                }
                break;
              }
              default:
            }
          }
        });
      }

      setErrors((prev) => ({
        ...prev,
        ...newErrors,
      }));

      setIsDirty((prev) => ({
        ...prev,
        ...newIsDirty,
      }));

      resolve(isFormValid);
    });
  };

  const _onChangeFormFields = (key, value) => {
    if (
      (key === "companyComission" ||
        key === "leadReferComission" ||
        key === "affiliatesReferComission" ||
        key === "firstLeaderComission" ||
        key === "secondLeaderComission") &&
      (isNaN(value) ||
        (value.includes(".") && value.split(".")[1].length > 2) ||
        value.length > 10 ||
        Number(value) < 0)
    )
      return;
    if (
      key === "qualifiedEmployees" &&
      (isNaN(value) ||
        value.includes(".") ||
        value.length > 10 ||
        Number(value) < 0)
    )
      return;

    const newFormFields = { ...formFields };
    if (key === "qualifiedDate") {
      newFormFields[key] = value?._d ? new Date(value).toISOString() : "";
      const newIsDirty = {
        [key]: true,
      };
      _validateFormFields({ newFormFields, newIsDirty });
    } else {
      newFormFields[key] = value;
    }
    setFormFields(newFormFields);
  };

  const _onBlurFormFields = (key) => {
    const newFormFields = { ...formFields };
    const newIsDirty = {
      [key]: true,
    };
    _validateFormFields({ newFormFields, newIsDirty });
  };

  const _setModalState = (data = null) => {
    setFormFields({
      qualifiedEmployees:
        data?.qualifiedEmployees || data?.qualifiedEmployees === 0
          ? data.qualifiedEmployees.toString()
          : "",
      qualifiedDate: data?.qualifiedDate || "",
      companyComission:
        data?.estimateEarning?.companyComission ||
        data?.estimateEarning?.companyComission === 0
          ? data.estimateEarning.companyComission.toString()
          : "",
      leadReferComission:
        data?.estimateEarning?.leadReferComission?.comission ||
        data?.estimateEarning?.leadReferComission?.comission === 0
          ? data?.estimateEarning?.leadReferComission?.comission.toString()
          : data?.estimateEarning?.leadReferComission?._affiliate?._id
          ? ""
          : "0",
      affiliatesReferComission:
        (data?.estimateEarning?.affiliatesReferComission?.comission ||
          data?.estimateEarning?.affiliatesReferComission?.comission === 0) &&
        data?.estimateEarning?.affiliatesReferComission?._affiliate?._id
          ? data?.estimateEarning?.affiliatesReferComission?.comission.toString()
          : data?.estimateEarning?.affiliatesReferComission?._affiliate?._id
          ? ""
          : "0",
      firstLeaderComission:
        data?.estimateEarning?.firstLeaderComission?.comission ||
        data?.estimateEarning?.firstLeaderComission?.comission === 0
          ? data?.estimateEarning?.firstLeaderComission?.comission.toString()
          : data?.estimateEarning?.firstLeaderComission?._affiliate?._id
          ? ""
          : "0",
      secondLeaderComission:
        (data?.estimateEarning?.secondLeaderComission?.comission ||
          data?.estimateEarning?.secondLeaderComission?.comission === 0) &&
        data?.estimateEarning?.secondLeaderComission?._affiliate?._id
          ? data?.estimateEarning?.secondLeaderComission?.comission.toString()
          : data?.estimateEarning?.secondLeaderComission?._affiliate?._id
          ? ""
          : "0",
    });
    setIsDirty({});
    setErrors({});
    setLoading(false);
  };

  const _closeModal = () => {
    _setModalState();
    toggle();
  };

  const _updateLead = ({ id, payload }) => {
    updateLead({ id, payload })
      .then((res) => {
        showToast("Data has been updated", "success");
        onSuccess();
        _closeModal();
      })
      .catch((error) => {
        errorHandler(error);
        setLoading(false);
      });
  };

  const _onSaveDetails = async () => {
    try {
      const newFormFields = { ...formFields };

      const newIsDirty = {};

      Object.keys(newFormFields).forEach((key) => {
        newIsDirty[key] = true;
      });

      const isFormValid = await _validateFormFields({
        newFormFields,
        newIsDirty,
      });

      if (!isFormValid) return;

      setLoading(true);

      const payload = {
        qualifiedEmployees: +newFormFields.qualifiedEmployees,
        companyComission: +newFormFields.companyComission,
        leadReferComission: +newFormFields.leadReferComission,
        affiliatesReferComission: +newFormFields.affiliatesReferComission,
        firstLeaderComission: +newFormFields.firstLeaderComission,
        secondLeaderComission: +newFormFields.secondLeaderComission,
      };

      if (newFormFields.qualifiedDate) {
        payload.qualifiedDate = newFormFields.qualifiedDate;
      }

      if (data?._id) {
        _updateLead({ id: data._id, payload });
      }
    } catch (error) {
      setLoading(false);
      errorHandler(error);
    }
  };

  const _getLeadById = async (id) => {
    try {
      setLoading(true);

      const res = await getLeadById(id);

      _setModalState(res.lead);
      setLoading(false);
    } catch (error) {
      errorHandler(error);
      _closeModal();
    }
  };

  useEffect(() => {
    if (isOpen && data?._id) _getLeadById(data._id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, data]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => _closeModal()}
      scrollable
      centered
      backdrop="static"
    >
      <ModalHeader toggle={() => _closeModal()}>
        {data?._id ? "Update" : "Add"} Earnings
      </ModalHeader>

      <div className="modal-body" ref={ModalBodyRef}>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Qualified Employees</Label>
              <InputGroup>
                <Input
                  type="text"
                  value={formFields.qualifiedEmployees}
                  name="qualifiedEmployees"
                  onChange={(e) =>
                    _onChangeFormFields("qualifiedEmployees", e.target.value)
                  }
                  onBlur={() => _onBlurFormFields("qualifiedEmployees")}
                  disabled={loading}
                />
              </InputGroup>
              {errors["qualifiedEmployees"] ? (
                <div className="form-error">{errors["qualifiedEmployees"]}</div>
              ) : null}
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label>Qualified Month</Label>
              <ReactDatetime
                inputProps={{
                  className: "form-control right",
                  placeholder: "Select qualified month",
                  value: formatDateMonthYear(formFields.qualifiedDate),
                  disabled: loading || data?.qualifiedDate,
                  
                }}
                value={
                  formFields.qualifiedDate
                    ? new Date(formFields.qualifiedDate)
                    : ""
                }
                onChange={(e) => _onChangeFormFields("qualifiedDate", e)}
                onOpen={() => {
                  setTimeout(() => {
                    ModalBodyRef.current.scrollTop =
                      ModalBodyRef.current.scrollHeight;
                  }, 0);
                }}
                // dateFormat={true}
                timeFormat={false}
                dateFormat="MMMM yyyy"
              />
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label>{APP_NAME} Service Fee</Label>

              <InputGroup>
                <InputGroupText>$</InputGroupText>
                <Input
                  type="text"
                  value={formFields.companyComission}
                  name="companyComission"
                  onChange={(e) =>
                    _onChangeFormFields("companyComission", e.target.value)
                  }
                  onBlur={() => _onBlurFormFields("companyComission")}
                  disabled={loading}
                />
                <InputGroupText>PEPM</InputGroupText>
              </InputGroup>
              {errors["companyComission"] ? (
                <div className="form-error">{errors["companyComission"]}</div>
              ) : null}
            </FormGroup>
          </Col>

          {data?.estimateEarning?.leadReferComission?._affiliate?._id ? (
            <Col md={6}>
              <FormGroup>
                <Label>
                  Affiliate Level 1{" "}
                  {data?.estimateEarning?.leadReferComission?._affiliate?.name
                    ?.full
                    ? `(${data?.estimateEarning?.leadReferComission?._affiliate?.name?.full?.trim()})`
                    : null}
                </Label>
                <InputGroup>
                  <InputGroupText>$</InputGroupText>
                  <Input
                    type="text"
                    value={formFields.leadReferComission}
                    name="leadReferComission"
                    onChange={(e) =>
                      _onChangeFormFields("leadReferComission", e.target.value)
                    }
                    onBlur={() => _onBlurFormFields("leadReferComission")}
                    disabled={loading}
                  />
                  <InputGroupText>PEPM</InputGroupText>
                </InputGroup>
                {errors["leadReferComission"] ? (
                  <div className="form-error">
                    {errors["leadReferComission"]}
                  </div>
                ) : null}
              </FormGroup>
            </Col>
          ) : null}

          {data?.estimateEarning?.affiliatesReferComission?._affiliate?._id ? (
            <Col md={6}>
              <FormGroup>
                <Label>
                  Affiliate Level 2{" "}
                  {data?.estimateEarning?.affiliatesReferComission?._affiliate
                    ?.name?.full
                    ? `(${data?.estimateEarning?.affiliatesReferComission?._affiliate?.name?.full?.trim()})`
                    : null}
                </Label>
                <InputGroup>
                  <InputGroupText>$</InputGroupText>
                  <Input
                    type="text"
                    value={formFields.affiliatesReferComission}
                    name="affiliatesReferComission"
                    onChange={(e) =>
                      _onChangeFormFields(
                        "affiliatesReferComission",
                        e.target.value
                      )
                    }
                    onBlur={() => _onBlurFormFields("affiliatesReferComission")}
                    disabled={
                      loading ||
                      !data?.estimateEarning?.affiliatesReferComission
                        ?._affiliate?._id
                    }
                  />
                  <InputGroupText>PEPM</InputGroupText>
                </InputGroup>
                {errors["affiliatesReferComission"] ? (
                  <div className="form-error">
                    {errors["affiliatesReferComission"]}
                  </div>
                ) : null}
              </FormGroup>
            </Col>
          ) : null}

          {data?.estimateEarning?.firstLeaderComission?._affiliate?._id ? (
            <Col md={6}>
              <FormGroup>
                <Label>
                  Primary Leader{" "}
                  {data?.estimateEarning?.firstLeaderComission?._affiliate?.name
                    ?.full
                    ? `(${data?.estimateEarning?.firstLeaderComission?._affiliate?.name?.full?.trim()})`
                    : null}
                </Label>
                <InputGroup>
                  <InputGroupText>$</InputGroupText>
                  <Input
                    type="text"
                    value={formFields.firstLeaderComission}
                    name="firstLeaderComission"
                    onChange={(e) =>
                      _onChangeFormFields(
                        "firstLeaderComission",
                        e.target.value
                      )
                    }
                    onBlur={() => _onBlurFormFields("firstLeaderComission")}
                    disabled={
                      loading ||
                      !data?.estimateEarning?.firstLeaderComission?._affiliate
                        ?._id
                    }
                  />
                  <InputGroupText>PEPM</InputGroupText>
                </InputGroup>
                {errors["firstLeaderComission"] ? (
                  <div className="form-error">
                    {errors["firstLeaderComission"]}
                  </div>
                ) : null}
              </FormGroup>
            </Col>
          ) : null}

          {data?.estimateEarning?.secondLeaderComission?._affiliate?._id ? (
            <Col md={6}>
              <FormGroup>
                <Label>
                  Secondary Leader{" "}
                  {data?.estimateEarning?.secondLeaderComission?._affiliate
                    ?.name?.full
                    ? `(${data?.estimateEarning?.secondLeaderComission?._affiliate?.name?.full?.trim()})`
                    : null}
                </Label>
                <InputGroup>
                  <InputGroupText>$</InputGroupText>
                  <Input
                    type="text"
                    value={formFields.secondLeaderComission}
                    name="secondLeaderComission"
                    onChange={(e) =>
                      _onChangeFormFields(
                        "secondLeaderComission",
                        e.target.value
                      )
                    }
                    onBlur={() => _onBlurFormFields("secondLeaderComission")}
                    disabled={
                      loading ||
                      !data?.estimateEarning?.secondLeaderComission?._affiliate
                        ?._id
                    }
                  />
                  <InputGroupText>PEPM</InputGroupText>
                </InputGroup>
                {errors["secondLeaderComission"] ? (
                  <div className="form-error">
                    {errors["secondLeaderComission"]}
                  </div>
                ) : null}
              </FormGroup>
            </Col>
          ) : null}
        </Row>
      </div>

      <ModalFooter>
        <Button color="link" className="closeBtn" onClick={() => _closeModal()}>
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={() => _onSaveDetails()}
          disabled={loading}
        >
          {loading ? <i className="fa fa-spinner fa-spin mr-2" /> : null}{" "}
          {data?._id ? "Update" : "Add"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditEarningsModal;
