import React, { useEffect, useRef, useState } from "react";
import { Button, Input, Label, ListGroup, Collapse } from "reactstrap";
import { updateDocument } from "../http/http-calls";
import Swal from "sweetalert2";
import { errorHandler, showToast, uploadFileOnServer } from "../helper-methods";

const LeadDocuments = ({
  upload,
  documents,
  getAllDocuments,
  updateLeadInfoDocuments,
}) => {
  const commentsInterval = useRef({ current: {} });

  const [leadDocuments, setLeadDocuments] = useState([]);

  const [collapseIsOpen, setCollapseIsOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [loadingDocuments, setLoadingDocuments] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);

  const _toggleCollapseIsOpen = (isOpen = false) => {
    if (isOpen !== collapseIsOpen) setCollapseIsOpen(isOpen);
    else setCollapseIsOpen(false);
  };

  const _deleteDocument = (id) => {
    setDeleteLoading(id);

    const newLeadDocuments = [...leadDocuments];
    const findDataIndex = newLeadDocuments.findIndex((each) => each._id === id);
    if (findDataIndex > -1) {
      newLeadDocuments.splice(findDataIndex, 1);
      setLeadDocuments(newLeadDocuments);
    }

    updateDocument({ id, payload: { isDelete: true } })
      .then(async (res) => {
        Swal.fire("Deleted!", "Folder has been deleted.", "success");

        setDeleteLoading(false);
      })
      .catch((error) => {
        errorHandler(error);
        setDeleteLoading(false);
        getAllDocuments();
      });
  };

  const _deleteDocumentAlert = (data) => {
    if (!data?._id) {
      errorHandler();
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete ${data?.docName} Folder.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#31c5c7",
      cancelButtonColor: "#F16667",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        _deleteDocument(data._id);
      }
    });
  };

  const _validStatusAlert = (data, status) => {
    if (!data?._id || !status) {
      errorHandler();
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: `You want to ${status} ${data?.docName} Documents.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#31c5c7",
      cancelButtonColor: "#F16667",
      confirmButtonText: `Yes, ${status} it!`,
    }).then(async (result) => {
      try {
        if (result.isConfirmed) {
          setStatusLoading(`${status}_${data._id}`);
          const payload = {};
          if (status === "approve") payload.verify = true;
          if (status === "reject") payload.isReject = true;
          await updateDocument({ id: data._id, payload });

          const newLeadDocuments = [...leadDocuments];
          const findData = newLeadDocuments.find(
            (each) => each._id === data._id
          );
          if (findData) {
            if (status === "approve") findData.verify = true;
            if (status === "reject") findData.isReject = true;
            setLeadDocuments(newLeadDocuments);
          }

          setStatusLoading(false);
        }
      } catch (error) {
        errorHandler(error);
        setStatusLoading(false);
      }
    });
  };

  const _onChangeFile = async ({ event, id, index = null }) => {
    try {
      if (event?.target?.files?.length) {
        const uploadedFiles = [];

        for (let file of event.target.files) {
          const fileType = file.type.split("/")[0];

          if (fileType === "image" || file.type.includes("pdf")) {
            const previewBlob = URL.createObjectURL(file);
            uploadedFiles.push({
              uploadData: file,
              previewBlob,
              type: fileType === "application" ? "pdf" : fileType,
            });
          } else {
            showToast("Only Image & PDF file is allowed", "error");
            continue;
          }
        }

        setLoadingDocuments(index !== null ? `update_${id}_${index}` : id);

        const response = await uploadFileOnServer(uploadedFiles);

        const payload = {
          url: [],
        };

        const findDoc = leadDocuments.find((each) => each._id === id);
        if (findDoc?.url?.length) {
          payload.url = findDoc.url;
        }

        if (response?.length) {
          if (index !== null) {
            payload.url[index].link = response[0].url;
            payload.url[index].fileName = response[0].name;
            payload.url[index].fileType = response[0].contentType;
          } else {
            for (let each of response) {
              if (each?.url) {
                payload.url.push({
                  link: each.url,
                  fileName: each.name,
                  fileType: each.contentType,
                });
              }
            }
          }

          if (payload.url?.length) {
            await updateLeadInfoDocuments({ id, payload });
            await getAllDocuments();

            showToast("Document has been uploaded", "success");
          }
        }

        setLoadingDocuments(false);
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const _onChangeDocComments = (data, eachFile, index, value) => {
    try {
      if (commentsInterval.current?.[eachFile._id])
        clearTimeout(commentsInterval.current[eachFile._id]);

      const newLeadDocuments = [...leadDocuments];
      const findData = newLeadDocuments.find((each) => each._id === data._id);
      if (findData) {
        findData.url[index].comments = value;
        setLeadDocuments(newLeadDocuments);
      }

      commentsInterval.current[eachFile._id] = setTimeout(async () => {
        try {
          await updateDocument({
            id: data._id,
            payload: { url: findData.url },
          });

          showToast("Comments has been updated", "success");
        } catch (error) {
          errorHandler(error);
        }
      }, 1000);
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    setLeadDocuments(documents);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents]);

  return (
    <>
      {leadDocuments?.length ? (
        leadDocuments.map((each) => (
          <tr key={each._id}>
            {upload ? (
              <>
                <td>
                  <b className="folderName">
                    {each.docName || "N/A"}
                    {each.verify ? (
                      <span className="text-success ms-1">(Verified)</span>
                    ) : each.isReject ? (
                      <span className="text-danger ms-1">(Rejected)</span>
                    ) : null}
                  </b>

                  <Collapse
                    isOpen={collapseIsOpen === each._id}
                    className="customAccordionBody mb-0"
                  >
                    <ListGroup>
                      {each.url?.length ? (
                        each.url?.map((eachFile, index) => (
                          <li key={`files_${eachFile._id}`}>
                            <a
                              href={eachFile.link}
                              target="_blank"
                              rel="noreferrer"
                              download
                              className="fileName"
                            >
                              {eachFile.fileName}
                            </a>
                            {eachFile.comments && (
                              <Input
                                type="textarea"
                                placeholder="comment"
                                value={eachFile.comments}
                                readOnly
                              />
                            )}

                            {!each.verify && (
                              <div>
                                <Label
                                  for={`update_file_link_${eachFile._id}`}
                                  className="text-muted fs-12  mb-0 lh-1"
                                  role="button"
                                >
                                  {loadingDocuments ===
                                  `update_${each._id}_${index}` ? (
                                    <i className="fa fa-spinner fa-spin" />
                                  ) : null}{" "}
                                  Update
                                </Label>
                                <Input
                                  id={`update_file_link_${eachFile._id}`}
                                  type="file"
                                  className="d-none"
                                  accept="image/x-png,image/jpeg,.pdf"
                                  value=""
                                  disabled={
                                    loadingDocuments ===
                                    `update_${each._id}_${index}`
                                      ? true
                                      : false
                                  }
                                  onChange={(event) =>
                                    _onChangeFile({
                                      id: each._id,
                                      event,
                                      index,
                                    })
                                  }
                                />
                              </div>
                            )}
                          </li>
                        ))
                      ) : (
                        <div className="nodata">
                          <img
                            src={require("../assets/img/noData.svg").default}
                            alt="no data"
                          />
                          <p>There is no data to display</p>
                        </div>
                      )}
                    </ListGroup>
                  </Collapse>
                </td>

                <td className="text-end align-top">
                  <div className="d-flex align-items-center justify-content-end">
                    {!each.verify && (
                      <div>
                        <Label
                          for={`Upload_documents_${each._id}`}
                          className="text-primary mb-0 lh-1"
                          role="button"
                        >
                          {loadingDocuments === each._id ? (
                            <i className="fa fa-spinner fa-spin" />
                          ) : null}{" "}
                          Upload
                        </Label>
                        <Input
                          id={`Upload_documents_${each._id}`}
                          type="file"
                          className="d-none"
                          accept="image/x-png,image/jpeg,.pdf"
                          value=""
                          multiple
                          disabled={
                            loadingDocuments === each._id ? true : false
                          }
                          onChange={(event) =>
                            _onChangeFile({
                              id: each._id,
                              event,
                            })
                          }
                        />
                      </div>
                    )}
                    <Button
                      color="link"
                      className="ms-1"
                      onClick={() => _toggleCollapseIsOpen(each._id)}
                    >
                      <i
                        className={`fa fa-chevron-${
                          collapseIsOpen === each._id ? "up" : "down"
                        } fs-16`}
                      />
                    </Button>
                  </div>
                </td>
              </>
            ) : (
              <>
                <td>
                  <b className="folderName">
                    {each.docName || "N/A"}
                    {each.verify ? (
                      <span className="text-success ms-1">(Verified)</span>
                    ) : each.isReject ? (
                      <span className="text-danger ms-1">(Rejected)</span>
                    ) : null}
                  </b>

                  <Collapse
                    isOpen={collapseIsOpen === each._id}
                    className="customAccordionBody mb-0"
                  >
                    <ListGroup>
                      {each.url?.length
                        ? each.url?.map((eachFile, index) => (
                            <li key={`files_${eachFile._id}`}>
                              <a
                                href={eachFile.link}
                                target="_blank"
                                rel="noreferrer"
                                download
                                className="fileName"
                              >
                                {eachFile.fileName}
                              </a>
                              <Input
                                type="textarea"
                                placeholder="comment"
                                value={eachFile.comments || ""}
                                onChange={(e) =>
                                  _onChangeDocComments(
                                    each,
                                    eachFile,
                                    index,
                                    e.target.value
                                  )
                                }
                              />
                            </li>
                          ))
                        : "There is no data to display."}
                    </ListGroup>
                  </Collapse>
                </td>
                <td className="text-end align-top">
                  {each.url?.length ? (
                    <>
                      {each.verify === false && (
                        <Button
                          className="ms-1 text-success"
                          onClick={() => _validStatusAlert(each, "approve")}
                          disabled={statusLoading ? true : false}
                        >
                          {statusLoading === `approve_${each._id}` ? (
                            <i className="fa fa-spinner fa-spin text-danger" />
                          ) : null}{" "}
                          Approve
                        </Button>
                      )}

                      {each.verify === false && each.isReject === false && (
                        <Button
                          className="ms-1 text-danger"
                          onClick={() => _validStatusAlert(each, "reject")}
                          disabled={statusLoading ? true : false}
                        >
                          {statusLoading === `reject_${each._id}` ? (
                            <i className="fa fa-spinner fa-spin text-danger" />
                          ) : null}{" "}
                          Reject
                        </Button>
                      )}
                    </>
                  ) : null}

                  <Button
                    color="link"
                    className="ms-1"
                    title="Remove"
                    disabled={deleteLoading ? true : false}
                    onClick={() => _deleteDocumentAlert(each)}
                  >
                    {deleteLoading === each._id ? (
                      <i className="fa fa-spinner fa-spin text-danger" />
                    ) : (
                      <i className="fa fa-trash" />
                    )}
                  </Button>

                  <Button
                    color="link"
                    className="ms-1"
                    onClick={() => _toggleCollapseIsOpen(each._id)}
                  >
                    <i
                      className={`fa fa-chevron-${
                        collapseIsOpen === each._id ? "up" : "down"
                      } fs-16`}
                    />
                  </Button>
                </td>
              </>
            )}
          </tr>
        ))
      ) : (
        <tr className="text-center">
          <td colSpan="2">There is no data to display.</td>
        </tr>
      )}
    </>
  );
};

export default LeadDocuments;
