import React from "react";
import { openUrlOnNewTab } from "../../helper-methods";
import SvgIcons from "../SvgIcons";

const FileDisplaySection = ({ formData, deleteAfile, onLoadedMetadata }) => (
  <div className="uploadBody">
    {formData?.imageFiles?.length ? (
      <>
        <div className="UploadContent">
        <h4>Thumbnail</h4>
          {formData?.imageFiles?.map(
            (each, index) =>
              (each?.url || each?.previewBlob) && (
                <div className="uploadItem" key={index}>
                  <img
                    className="cursorPointer"
                    src={each?.url || each?.previewBlob}
                    alt="thumbnail"
                    onClick={() =>
                      openUrlOnNewTab(each?.url || each?.previewBlob, true)
                    }
                  />
                  <div
                    className="deleteIcon"
                    onClick={() => deleteAfile("imageFiles", index)}
                  >
                    <SvgIcons type={"remove"} />
                  </div>
                  {/* <span>{each?.name || each?.title}</span> */}
                </div>
              )
          )}
        </div>
      </>
    ) : null}

    {formData?.videoFiles?.length ? (
      <>
        <div className="UploadContent">
        <h4>Video</h4>
          {formData?.videoFiles?.map(
            (each, index) =>
              (each?.url || each?.previewBlob) && (
                <div className="uploadItem" key={index}>
                  <video
                    className="cursorPointer"
                    src={each?.url || each?.previewBlob}
                    width={"100%"}
                    height={"100%"}
                    onClick={() =>
                      openUrlOnNewTab(each?.url || each?.previewBlob, true)
                    }
                    onLoadedMetadata={(event) =>
                      onLoadedMetadata(event.target.duration, index)
                    }
                  />
                  <div
                    className="deleteIcon"
                    onClick={() => deleteAfile("videoFiles", index)}
                  >
                    <SvgIcons type={"remove"} />
                  </div>
                  {/* <span>{each?.name || each?.title}</span> */}
                </div>
              )
          )}
        </div>
      </>
    ) : null}

    {formData?.pdfFiles?.length ? (
      <>
        <div className="UploadContent">
        <h4>PDF Notes</h4>
          {formData?.pdfFiles?.map(
            (each, index) =>
              (each?.url || each?.previewBlob) && (
                <div className="uploadItem" key={index}>
                  <img
                    className="cursorPointer"
                    src={require("../../assets/img/pdf.png")}
                    alt="final"
                    onClick={() =>
                      openUrlOnNewTab(each?.url || each?.previewBlob, true)
                    }
                  />
                  <div
                    className="deleteIcon"
                    onClick={() => deleteAfile("pdfFiles", index)}
                  >
                    <SvgIcons type={"remove"} />
                  </div>
                  {/* <span>{each?.name || each?.title}</span> */}
                </div>
              )
          )}
        </div>
      </>
    ) : null}
  </div>
);

export default FileDisplaySection;
