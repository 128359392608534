import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import SvgIcons from "../SvgIcons";
import AddResources from "../modals/AddResources";
import {
  copyToClipboard,
  errorHandler,
  openUrlOnNewTab,
  showToast,
} from "../../helper-methods";
import { editResource, fetchresources } from "../../http/http-calls";
import CustomPagination from "../Table/CustomPagination";
import SkeletonLoading from "../SkeletonLoading";
import StatusChangeComponent from "../StatusChangeComponent";

const AditionalResourcesTab = ({ activeTab, tabId }) => {
  const [addResources, setAddResources] = useState({
    isOpen: false,
    data: null,
  });

  const [payload, setPayload] = useState({
    pageNumber: 0,
    limit: 10,
    page: 1,
  });
  const [resouces, setResouces] = useState([]);
  const [resouceCount, setResourceCount] = useState(0);
  const [flag, setFlag] = useState(false);
  const [loading, setLoading] = useState(false);

  const _toggleAddResources = (isOpen = false, data = null) => {
    setAddResources({ isOpen, data });
  };

  const _fetchAllResources = async (newPayload) => {
    setLoading(true);

    if (newPayload) {
      try {
        const res = await fetchresources(newPayload);

        setResouces(res?.faqMiscellaneous);
        setResourceCount(res?.totalCount);
      } catch (error) {
        errorHandler(error);
      }
    } else {
      try {
        const res = await fetchresources(payload);

        setResouces(res?.faqMiscellaneous);
        setResourceCount(res?.totalCount);
      } catch (error) {
        errorHandler(error);
      }
    }
    setLoading(false);
  };

  const _onSizeChange = (limit = 10) => {
    const newDataPayload = { ...payload };
    newDataPayload["skip"] = 0;
    newDataPayload["pageNumber"] = 0;
    newDataPayload["page"] = 1;
    newDataPayload["limit"] = limit;
    setPayload(newDataPayload);
    _fetchAllResources(newDataPayload);
  };

  const _onPageChange = (pageNumber = 0) => {
    const newDataPayload = { ...payload };
    newDataPayload["limit"] = pageNumber * newDataPayload["limit"];
    newDataPayload["pageNumber"] = pageNumber;
    newDataPayload["page"] = pageNumber + 1;
    setPayload(newDataPayload);
    _fetchAllResources(newDataPayload);
  };

  const _changeStatus = async (event, id, index) => {
    let oldReports = JSON.parse(JSON.stringify(resouces));

    const value = event.target.checked;
    resouces[index].isActive = value;

    setResouces([...resouces]);
    setFlag(true);
    try {
      await editResource(id, { status: value });

      showToast("Status successfully changed", "success");
    } catch (error) {
      setResouces(oldReports);
      errorHandler(error);
    }
    setFlag(false);
  };

  useEffect(() => {
    if (activeTab === tabId) {
      _fetchAllResources();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, tabId]);

  return (
    <>
      <div className="text-end">
        <Button
          className="h-auto"
          color="primary"
          onClick={() => _toggleAddResources(true)}
        >
          Add Resources
        </Button>
      </div>

      <Row>
        {resouces?.length ? (
          resouces?.map((resource, index) => (
            <Col md={4} key={index}>
              <Card className="additionalCard">
                <CardHeader>
                  <FormGroup switch>
                    <StatusChangeComponent
                      value={resource?.isActive}
                      onChange={(event) =>
                        _changeStatus(event, resource?._id, index)
                      }
                      disabled={flag}
                      type="switch"
                    />
                    <Label check>{resource?.title}</Label>
                  </FormGroup>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <InputGroup>
                      <Input value={resource?.url} disabled />
                      <Button
                        color="link"
                        className="mr-2"
                        onClick={() => copyToClipboard(resource?.url)}
                      >
                        <SvgIcons type={"clone"} />
                      </Button>
                    </InputGroup>
                  </FormGroup>
                  <div className="d-flex align-items-center justify-content-center">
                    <Button
                      color="primary"
                      onClick={() => _toggleAddResources(true, resource)}
                    >
                      Update
                    </Button>
                    <Button
                      color="link"
                      className="ms-3"
                      onClick={() => openUrlOnNewTab(resource?.url)}
                    >
                      Open
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))
        ) : loading ? (
          <SkeletonLoading type="card" count={3} />
        ) : (
          <div className="nodata">
            <p>There is no data to display</p>
          </div>
        )}
      </Row>

      <CustomPagination
        data={resouces}
        dataCount={resouceCount}
        dataPayload={payload}
        onPageChange={_onPageChange}
        onSizeChange={_onSizeChange}
      />

      {addResources?.isOpen && (
        <AddResources
          isOpen={addResources.isOpen}
          toggle={() => _toggleAddResources()}
          fetchAllResources={_fetchAllResources}
          editDetails={addResources.data}
        />
      )}
    </>
  );
};

export default AditionalResourcesTab;
