import React, { useMemo, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import ProtectedRoute from "../../components/routes/ProtectedRoute";
import LeadsPage from "../../pages/protected-pages/LeadsPage";
import LeadDetailsPage from "../../pages/protected-pages/LeadDetailsPage";
import MediaQueueViewer from "../MediaQueueViewer";
import DashboardFooter from "./DashboardFooter";
import DashboardHeader from "./DashboardHeader";
import DashboardSidebar from "./DashboardSidebar";
import AffiliatesPage from "../../pages/protected-pages/AffiliatesPage";
import AffiliateDetailsPage from "../../pages/protected-pages/AffiliateDetailsPage";
import MessageTemplatesPage from "../../pages/protected-pages/MessageTemplatesPage";
import NewMessagePage from "../../pages/protected-pages/NewMessagePage";
import AgreementDocuments from "../../pages/protected-pages/AgreementDocuments";
import { useSelector } from "react-redux";
import AffiliateHomePage from "../../pages/protected-pages/AffiliateHomePage";
import Dashboard from "../../pages/protected-pages/Dashboard";
import Training from "../../pages/protected-pages/Training";
import TrainingUser from "../../pages/protected-pages/TrainingUser";

import TrainingUserList from "../../pages/protected-pages/TrainingUserList";
import TrainingUserDetail from "../../pages/protected-pages/TrainingUserDetail";
import Notification from "../../pages/protected-pages/Notification";
import QuizBankPage from "../../pages/protected-pages/QuizBankPage";

const DashboardLayout = () => {
  const userCredential = useSelector((state) => state?.userCredential);

  const userType = useMemo(
    () => userCredential?.user?.userType?.toLowerCase(),
    [userCredential]
  );

  const affiliateType = useMemo(
    () => userCredential?.user?.affiliateType?.toLowerCase(),
    [userCredential]
  );

  const [isShow, setIsShow] = useState(false);

  return (
    <>
      <div className="mainWrapper">
        <DashboardSidebar isShow={isShow} setIsShow={setIsShow} />
        <DashboardHeader isShow={isShow} setIsShow={setIsShow} />
        <div className="innerWrapper">
          <Routes>
            <Route
              path={`${userType}/*`}
              element={<ProtectedRoute redirectRoute={"/login"} />}
            >
              <Route exact path={`dashboard`} element={<Dashboard />} />
              <Route exact path={`client/:id`} element={<LeadDetailsPage />} />
              {/* <Route exact path={`notification`} element={<Notification />} /> */}

              {userType === "affiliate" ? (
                <>
                  <Route exact path={`home`} element={<AffiliateHomePage />} />
                  <Route
                    exact
                    path={`training-user`}
                    element={<TrainingUser />}
                  />
                  <Route
                    exact
                    path={`training-list/:id`}
                    element={<TrainingUserList />}
                  />
                  <Route
                    exact
                    path={`training-detail/:id`}
                    element={<TrainingUserDetail />}
                  />
                  <Route
                    exact
                    path={`notification`}
                    element={<Notification />}
                  />
                </>
              ) : null}

              {userType === "admin" ? (
                <Route exact path={`quiz-bank`} element={<QuizBankPage />} />
              ) : null}

              {userType === "admin" ||
              (userType === "affiliate" && affiliateType === "leader") ? (
                <>
                  <Route exact path={`clients`} element={<LeadsPage />} />
                  <Route
                    exact
                    path={`affiliates`}
                    element={<AffiliatesPage />}
                  />

                  <Route
                    exact
                    path={`affiliate/:id`}
                    element={<AffiliateDetailsPage />}
                  />
                  <Route
                    exact
                    path={`message-templates`}
                    element={<MessageTemplatesPage />}
                  />
                  <Route
                    exact
                    path={`message/:id`}
                    element={<NewMessagePage />}
                  />

                  <Route
                    exact
                    path={`agreement-documents`}
                    element={<AgreementDocuments />}
                  />

                  <Route exact path={`training`} element={<Training />} />
                </>
              ) : null}

              {userType === "affiliate" ? (
                <>
                  <Route
                    index
                    element={<Navigate replace to={`/${userType}/${userCredential?.showHeader ? "home" : "training-user"}`} />}
                  />

                  <Route
                    path="*"
                    element={<Navigate replace to={`/${userType}/${userCredential?.showHeader ? "home" : "training-user"}`} />}
                  />
                </>
              ) : (
                <>
                  <Route
                    index
                    element={<Navigate replace to={`/${userType}/clients`} />}
                  />

                  <Route
                    path="*"
                    element={<Navigate replace to={`/${userType}/clients`} />}
                  />
                </>
              )}
            </Route>

            <Route
              path="*"
              element={
                <Navigate
                  replace
                  to={`/${userCredential?.user?.userType?.toLowerCase()}`}
                />
              }
            />
          </Routes>

          <MediaQueueViewer />
        </div>

        <DashboardFooter />
      </div>
    </>
  );
};

export default DashboardLayout;
