import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Form,
  Input,
  Label,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { RegexConfig } from "../../config/RegexConfig";
import PublicFooter from "../../containers/Public/PublicFooter";
import { addUserCredential } from "../../redux/actions/user-credential";
import { hideLoader, showLoader } from "../../redux/actions/loader-data";
import {
  decodeToken,
  errorHandler,
  openUrlInSameTab,
} from "../../helper-methods";
import { addDeviceId, login } from "../../http/http-calls";
import { APP_LOGO } from "../../config";

const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [credentials, setCredentials] = useState({
    handle: "",
    password: "",
  });
  const [isDirty, setIsDirty] = useState({
    handle: false,
    password: false,
  });
  const [errors, setErrors] = useState({});

  const _validateFormFields = (newCredentials, newIsDirty) => {
    return new Promise((resolve) => {
      const newErrors = { ...errors };
      let isFormValid = true;

      Object.keys(newCredentials).forEach((key) => {
        if (newIsDirty[key]) {
          switch (key) {
            case "handle": {
              if (newCredentials[key]?.trim().length) {
                if (
                  RegexConfig.email.test(
                    String(newCredentials[key]).toLowerCase()
                  )
                ) {
                  delete newErrors[key];
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] = "*Invalid email";
                  isFormValid = false;
                }
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "password": {
              if (newCredentials[key]?.length) {
                delete newErrors[key];
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            default:
          }
        }
      });

      setErrors(newErrors);
      setIsDirty(newIsDirty);

      resolve(isFormValid);
    });
  };

  const _onChangeFormFields = (key, value) => {
    const newCredentials = { ...credentials };
    const newIsDirty = { ...isDirty };
    newCredentials[key] = value;
    newIsDirty[key] = true;
    setCredentials(newCredentials);
    setIsDirty(newIsDirty);
    _validateFormFields(newCredentials, newIsDirty);
  };

  const _completedAuthorization = (res = {}) => {
    dispatch(hideLoader());
    let userData;
    if (!res.user) {
      userData = decodeToken(res.token);
      res.user = userData;
    }
    if (
      !res?.user?.isVerified &&
      (res?.user?.isAddedByForm || res?.user?.isAddedThroughMondayCrm)
    ) {
      if (
        res?.user?.userType === "Affiliate" ||
        res?.user?.affiliateType === "Leader"
      ) {
        dispatch(
          addUserCredential({
            token: res.token,
            user: res.user,
            showHeader: false,
          })
        );
        navigate(`/${res.user?.userType?.toLowerCase()}/training-user`);
      } else {
        dispatch(
          addUserCredential({
            token: res.token,
            user: res.user,
            showHeader: true,
          })
        );
        navigate(`/${res.user?.userType?.toLowerCase()}/clients`);
      }
    } else {
      if (
        res?.user?.userType === "Affiliate" &&
        res?.user?.affiliateType !== "Leader"
      ) {
        if (res?.user?.stageOfSign === "noSigned" && res?.user?.isVerifiedForDocuSign && res?.user?.isVerified) {
          openUrlInSameTab(`/docu-sign-accept-agreement/${res?.token}`);
        } else {
          if (res?.user?.stageOfSign === "combineAchw9Signed") {
            dispatch(
              addUserCredential({
                token: res.token,
                user: res.user,
                showHeader: res.user.isVerified || res?.user?.completedAllTopic,
              })
            );
            navigate(`/${res.user?.userType?.toLowerCase()}/training-user`);
          } else {
            if(res?.user?.isVerifiedForDocuSign && res?.user?.isVerified){
              localStorage.temp_token = res?.token;
              openUrlInSameTab(
                `/agreementsigned/${res?.user?._id}?event=signing_complete`
              );
            }else{
              dispatch(
                addUserCredential({
                  token: res.token,
                  user: res.user,
                  showHeader: res.user.isVerified || res?.user?.completedAllTopic,
                })
              );
              navigate(`/${res.user?.userType?.toLowerCase()}/training-user`);
            }
          }
        }
      } else if (
        res?.user?.affiliateType === "Leader" &&
        res?.user?.stageOfSign !== "combineAchw9Signed" && res?.user?.isVerifiedForDocuSign
      ) {
        localStorage.temp_token = res?.token;
        openUrlInSameTab(
          `/agreementsigned/${res?.user?._id}?event=signing_complete`
        );
      } else {
        if (res?.user?.affiliateType === "Leader") {
          dispatch(
            addUserCredential({
              token: res.token,
              user: res.user,
              showHeader: true,
            })
          );
          navigate(`/${res.user?.userType?.toLowerCase()}/clients`);
        } else {
          dispatch(
            addUserCredential({
              token: res.token,
              user: res.user,
              showHeader: false,
            })
          );
          navigate(`/${res.user?.userType?.toLowerCase()}/training-user`);
        }
      }
    }
  };

  const waitForDeviceId = async () => {
    return new Promise((resolve) => {
      const checkDeviceId = () => {
        const deviceIdData = JSON.parse(localStorage.getItem("users"));
        const deviceId = deviceIdData?.[deviceIdData.length - 1]?.deviceId;

        if (deviceId) {
          // Resolve the promise when deviceId is available
          resolve(deviceId);
        } else {
          // Retry after a delay
          setTimeout(checkDeviceId, 500); // Adjust the delay as needed
        }
      };

      // Start checking for deviceId
      checkDeviceId();
    });
  };

  const _onSubmitLoginForm = async (e) => {
    try {
      if (e) e.preventDefault();

      const newIsDirty = {
        handle: true,
        password: true,
      };

      const isFormValid = await _validateFormFields(credentials, newIsDirty);

      if (!isFormValid) {
        return;
      }

      dispatch(showLoader("Login..."));

      const payload = {
        ...credentials,
      };

      const res = await login(payload);

      _completedAuthorization(res);

      if (window.location.origin !== "http://localhost:3000") {
        const deviceId = await waitForDeviceId();
        if (deviceId) {
          await addDeviceId({ deviceId: deviceId });
        }
      }
    } catch (error) {
      dispatch(hideLoader());
      errorHandler(error);
    }
  };

  useEffect(() => {
    if (localStorage?.temp_token) {
      delete localStorage.temp_token;
    }
  }, []);

  return (
    <>
      <div className="authPgWrapper">
        <div className="authFormWrap">
          <img
            src={APP_LOGO}
            alt="Project Logo"
            className="companyLogo"
            role="button"
            onClick={() => navigate("/")}
          />

          <div className="authForm">
            <h2>Login</h2>

            <Form onSubmit={(e) => _onSubmitLoginForm(e)}>
              <div className="mb-3">
                <Label>Email</Label>
                <InputGroup>
                  <Input
                    type="text"
                    placeholder="Enter your email"
                    autoComplete="off"
                    name="handle"
                    value={credentials.handle}
                    onChange={(e) =>
                      _onChangeFormFields("handle", e.target.value)
                    }
                  />
                  <InputGroupText>
                    <i className="far fa-envelope" />
                  </InputGroupText>
                </InputGroup>
                {errors["handle"] ? (
                  <div className="form-error">{errors["handle"]}</div>
                ) : null}
              </div>

              <div className="mb-1">
                <Label>Password</Label>
                <InputGroup>
                  <Input
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter your password"
                    autoComplete="off"
                    name="password"
                    value={credentials.password}
                    onChange={(e) =>
                      _onChangeFormFields("password", e.target.value)
                    }
                  />
                  <InputGroupText
                    className="cursorPointer"
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                  >
                    <i
                      className={`far ${
                        showPassword ? "fa-eye" : "fa-eye-slash"
                      }`}
                    />
                  </InputGroupText>
                </InputGroup>
                {errors["password"] ? (
                  <div className="form-error">{errors["password"]}</div>
                ) : null}
              </div>

              <Link to="/forgot-password" className="forgotPassword">
                Forgot Password?
              </Link>

              <Button type="submit" className="submitBtn" color="primary">
                Login
              </Button>
            </Form>
          </div>
        </div>
      </div>

      <PublicFooter />
    </>
  );
};

export default LoginPage;
