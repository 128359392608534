import React, { useEffect, useMemo, useState } from "react";
import { Button, Row, Col, Label, Input, Badge } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import {
  getAllActivites,
  getPayments,
  getUserById,
  updateUser,
  // sendAgreementAffiliate,
} from "../../http/http-calls";
import { errorHandler, showToast } from "../../helper-methods";
import { useDispatch, useSelector } from "react-redux";
import { showLoader, hideLoader } from "../../redux/actions/loader-data";
import AddUsersModal from "../../components/modals/AddUsersModal";
import AgreementModal from "../../components/modals/AgreementModal";
import ResetUserPasswordModal from "../../components/modals/ResetUserPasswordModal";
import AffiliatesClientsModal from "../../components/modals/AffiliatesClientsModal";
import LeadNotesModal from "../../components/modals/LeadNotesModal";
import { affiliateTypeConfig } from "../../config/helper-config";
import AffiliatePaymentTable from "../../components/AffiliatePaymentTable";
import AffiliateMessagesTable from "../../components/AffiliateMessagesTable";
import AffiliatePersonalInfo from "../../components/AffiliatePersonalInfo";
import QuestionnaireCard from "../../components/QuestionnaireCard";
import AffiliateSecondaryLeader from "../../components/AffiliateSecondaryLeader";
import AffiliateReferredAffiliates from "../../components/AffiliateReferredAffiliates";
import AffiliateReferredClients from "../../components/AffiliateReferredClients";
import SvgIcons from "../../components/SvgIcons";
import ResubmitSignatureComponent from "../../components/ResubmitSignatureComponent";

const AffiliateDetailsPage = () => {
  const navigate = useNavigate();

  const params = useParams();

  const dispatch = useDispatch();

  const userCredential = useSelector((state) => state?.userCredential);

  const userType = useMemo(
    () => userCredential?.user?.userType?.toLowerCase(),
    [userCredential]
  );

  const [data, setData] = useState(null);
  const [leadRefer, setLeadRefer] = useState([]);
  const [affiliateRefer, setAffiliateRefer] = useState([]);
  // const [sendAgreementLoading, setSendAgreementLoading] = useState(false);
  const [affiliateTypeLoading, setAffiliateTypeLoading] = useState(false);
  const [activities, setActivities] = useState([]);

  const [addUsersModal, setAddUsersModal] = useState({
    isOpen: false,
    data: null,
    heading: null,
    primaryLeaderData: null,
  });
  const [resetUserPasswordModal, setResetUserPasswordModal] = useState({
    isOpen: false,
  });
  const [affiliatesClientsModal, setAffiliatesClientsModal] = useState({
    isOpen: false,
    data: null,
  });

  const [agreementModal, setAgreementModal] = useState({
    isOpen: false,
    data: null,
    templateType: null,
  });

  const [leadNotesModal, setLeadNotesModal] = useState({
    isOpen: false,
    data: null,
  });

  const [openSecondaryAccordian, setOpenSecondaryAccordian] = useState("");
  const [payload, setPayload] = useState({
    pageNumber: 0,
    limit: 10,
    page: 1,
  });
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [paymentCount, setPaymentCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const _toggleAddUsersModal = (
    isOpen = false,
    data = null,
    heading = null,
    primaryLeaderData = null
  ) => {
    setAddUsersModal({ isOpen, data, heading, primaryLeaderData });
  };

  const _toggleAffiliatesClientsModal = (isOpen = false, data = null) => {
    setAffiliatesClientsModal({ isOpen, data });
  };

  const _toggleResetUserPasswordModal = (isOpen = false, data = null) => {
    setResetUserPasswordModal({ isOpen, data });
  };

  const _toggleLeadNotesModal = (isOpen = false, data = null) => {
    setLeadNotesModal({ isOpen, data });
  };

  const _toggleAgreementModal = (
    isOpen = false,
    data = null,
    templateType = null
  ) => {
    setAgreementModal({ isOpen, data, templateType });
  };

  const _toggleSecondaryAccordian = (id = "") => {
    if (openSecondaryAccordian === id) {
      setOpenSecondaryAccordian("");
    } else {
      setOpenSecondaryAccordian(id);
    }
  };

  // const _sendAgreementAffiliate = async (id) => {
  //   try {
  //     setSendAgreementLoading(id);
  //     await sendAgreementAffiliate(id);
  //     setSendAgreementLoading(false);

  //     _getUserById();
  //   } catch (error) {
  //     errorHandler(error);
  //     setSendAgreementLoading(false);
  //   }
  // };

  const _getAllActivites = async () => {
    try {
      const payload = {
        userId: params?.id,
        userType: "Affiliate", // enum=["Lead", "Affiliate"]
      };

      const res = await getAllActivites(payload);

      setActivities(res.activities?.length ? res.activities : []);
    } catch (error) {
      errorHandler(error);
    }
  };

  const _getUserById = () => {
    dispatch(showLoader("Fetching..."));
    getUserById(params?.id)
      .then((res) => {
        setData(res.user);
        setLeadRefer(res.user?.referredClients);
        setAffiliateRefer(res.user?.referredAffiliate);
        dispatch(hideLoader());
      })
      .catch((error) => {
        errorHandler(error);
        dispatch(hideLoader());
        navigate(-1);
      });
  };

  const _fetchPaymentDetails = async (newPayload) => {
    setLoading(true);
    try {
      const res = await getPayments(
        params?.id,
        newPayload ? newPayload : payload
      );
      setPaymentDetails(res?.paymentLogs);
      setTotalAmount(res?.totalAmount);
      setPaymentCount(res?.totalLogs);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const _onChangeAffiliateType = async (user, status) => {
    try {
      if (!user?._id) {
        errorHandler({ reason: "User data not found" });
        return;
      }

      setAffiliateTypeLoading(true);

      const payload = {
        affiliateType: status,
      };

      const newData = { ...data };
      newData.affiliateType = payload.affiliateType;
      setData(newData);

      const res = await updateUser({ id: user._id, payload });

      if (res?.user?.affiliateType) {
        newData.affiliateType = res.user.affiliateType;
        setData(newData);
      }

      setAffiliateTypeLoading(false);
      showToast("Status has been updated", "success");
    } catch (error) {
      errorHandler(error);
      setAffiliateTypeLoading(false);
      _getUserById();
    }
  };

  const _checkLeaderTypeCommission = (each) => {
    switch (data?.leaderType) {
      case "Primary": {
        return (
          (each?._client?.estimateEarning?.firstLeaderComission?.comission ||
            0) * (each?._client?.qualifiedEmployees || 0)
        );
      }
      case "Secondary": {
        return (
          (each?._client?.estimateEarning?.secondLeaderComission?.comission ||
            0) * (each?._client?.qualifiedEmployees || 0)
        );
      }
      default: {
        return (
          (each?._client?.estimateEarning?.leadReferComission?.comission || 0) *
          (each?._client?.qualifiedEmployees || 0)
        );
      }
    }
  };

  const _calculateTotalAffiliatesPayout = (array = [], key = "") => {
    key =
      data?.leaderType === "Primary"
        ? "firstLeaderComission"
        : data?.leaderType === "Secondary"
        ? "secondLeaderComission"
        : key;

    if (!array?.length || !key) return 0;

    return array.reduce((acc, each) => {
      if (each?._affiliate?.referredClients?.length)
        acc += _calculateTotalClientsPayout(
          each?._affiliate?.referredClients,
          key
        );

      return acc;
    }, 0);
  };

  const _calculateTotalClientsPayout = (array = [], key = "") => {
    key =
      data?.leaderType === "Primary"
        ? "firstLeaderComission"
        : data?.leaderType === "Secondary"
        ? "secondLeaderComission"
        : key;

    if (!array?.length || !key) return 0;

    return array.reduce((acc, each) => {
      if (
        each?._client?.estimateEarning?.[key]?.comission &&
        +each?._client?.estimateEarning?.[key]?.comission
      )
        acc +=
          +each?._client?.estimateEarning?.[key]?.comission *
          +each?._client?.qualifiedEmployees;

      return acc;
    }, 0);
  };

  const _calculateTotalCommission = (array = [], key = "") => {
    if (!array?.length || !key) return 0;

    return array.reduce((acc, each) => {
      if (
        each?._client?.estimateEarning?.[key]?.comission &&
        +each?._client?.estimateEarning?.[key]?.comission
      )
        acc += +each?._client?.estimateEarning?.[key]?.comission;

      return acc;
    }, 0);
  };

  const _onResourceSizeChange = (limit = 10) => {
    const newDataPayload = { ...payload };
    newDataPayload["skip"] = 0;
    newDataPayload["pageNumber"] = 0;
    newDataPayload["page"] = 1;
    newDataPayload["limit"] = limit;
    setPayload(newDataPayload);
    _fetchPaymentDetails(newDataPayload);
  };

  const _onResourcePageChange = (pageNumber = 0) => {
    const newDataPayload = { ...payload };
    newDataPayload["pageNumber"] = pageNumber;
    newDataPayload["page"] = pageNumber + 1;
    setPayload(newDataPayload);
    _fetchPaymentDetails(newDataPayload);
  };

  useEffect(() => {
    _getAllActivites();
    _getUserById();
    _fetchPaymentDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id]);

  return (
    <>
      <div className="pgTitleWrap align-items-center">
        <div className="d-flex align-items-center">
          <Button color="link" className="backBtn" onClick={() => navigate(-1)}>
            <i className="fas fa-chevron-left" />
          </Button>
          <h2>Affiliate Details</h2>
        </div>

        <div>
          <Label>Type</Label>
          <Input
            type="select"
            style={{ width: 150, height: "auto" }}
            disabled={affiliateTypeLoading ? true : false}
            value={data?.affiliateType}
            onChange={(e) => _onChangeAffiliateType(data, e.target.value)}
          >
            {affiliateTypeConfig.map((each) => (
              <option
                key={each.value}
                value={each.value}
                disabled={each.value === "Leader"}
              >
                {each.label}
              </option>
            ))}
          </Input>
        </div>

        {/* <div className="d-flex align-items-end">
          {data?.agreement?.status === "Pending" ? ( // enum: ["Signed", "Pending"]
            <Button
              color="primary"
              className="text-nowrap"
              onClick={() =>
                _toggleAgreementModal(
                  true,
                  data,
                  "Affiliate Agreement Reminder"
                )
              }
            >
              Send Agreement Reminder
            </Button>
          ) : data?.agreement?.status === "Not Sent" ? (
            <Button
              color="primary"
              className="text-nowrap"
              disabled={sendAgreementLoading === data?._id}
              onClick={() => _sendAgreementAffiliate(data?._id)}
            >
              {sendAgreementLoading === data?._id ? (
                <i className="fa fa-spinner fa-spin mr-2" />
              ) : null}{" "}
              Send Agreement
            </Button>
          ) : null}
        </div> */}
      </div>
      <Row>
        <Col lg={"6"}>
          <AffiliatePersonalInfo
            data={data}
            setData={setData}
            getUserById={_getUserById}
            _toggleAddUsersModal={_toggleAddUsersModal}
            _toggleResetUserPasswordModal={_toggleResetUserPasswordModal}
            userType={userType}
          />

          {data?.hasClearedQuiz || data?.failedInQuiz ? (
            userCredential?.user?.affiliateType === "Leader" ? null : (
              <div className="cardWrap quizTestCard">
                <div className="cardHeader">Quiz</div>
                <div className="cardBody">
                  <>
                    {data?.hasClearedQuiz ? (
                      <>
                        <SvgIcons type={"success"} id="success" />
                        <h3>Quiz Score</h3>

                        <div className="percentage">
                          <span>{data?.quizPercentageScored}%</span>
                          <Badge color="success">Pass</Badge>
                        </div>
                      </>
                    ) : (
                      <>
                        {data?.failedInQuiz ? (
                          <>
                            <SvgIcons type={"filed"} id="filed" />
                            <h3>Quiz Score</h3>
                            <div className="percentage">
                              <span>{data?.quizPercentageScored}%</span>{" "}
                              <Badge color="danger">Fail</Badge>
                            </div>
                            {data?.failedInQuiz < 2 ? (
                              <div>1 Attempt left</div>
                            ) : null}
                          </>
                        ) : null}
                      </>
                    )}
                  </>
                </div>
              </div>
            )
          ) : null}
        </Col>

        <Col lg="6">
          {data?.responses?.length ? <QuestionnaireCard data={data} /> : null}
        </Col>

        {data?.leaderType === "Primary" ? (
          <Col lg="6">
            <AffiliateSecondaryLeader
              data={data}
              _toggleAddUsersModal={_toggleAddUsersModal}
              openSecondaryAccordian={openSecondaryAccordian}
              _toggleSecondaryAccordian={_toggleSecondaryAccordian}
            />
          </Col>
        ) : null}

        {data?.achSignedPdf || data?.agreementSignedPdf || data?.w9SignedPdf ? (
          <Col lg="6">
            <ResubmitSignatureComponent data={data} pageName="details" />
          </Col>
        ) : null}

        <Col md={12}>
          <AffiliateReferredClients
            leadRefer={leadRefer}
            _checkLeaderTypeCommission={_checkLeaderTypeCommission}
            _toggleLeadNotesModal={_toggleLeadNotesModal}
            _calculateTotalClientsPayout={_calculateTotalClientsPayout}
            userType={userType}
          />
        </Col>

        <Col md={12}>
          <AffiliateReferredAffiliates
            affiliateRefer={affiliateRefer}
            _calculateTotalAffiliatesPayout={_calculateTotalAffiliatesPayout}
            _calculateTotalClientsPayout={_calculateTotalClientsPayout}
            _calculateTotalCommission={_calculateTotalCommission}
            _toggleAffiliatesClientsModal={_toggleAffiliatesClientsModal}
          />
        </Col>

        <Col lg={12}>
          <AffiliateMessagesTable activities={activities} />
        </Col>

        <Col lg={12}>
          <AffiliatePaymentTable
            paymentDetails={paymentDetails}
            loading={loading}
            totalAmount={totalAmount}
            paymentCount={paymentCount}
            payload={payload}
            _onResourcePageChange={_onResourcePageChange}
            _onResourceSizeChange={_onResourceSizeChange}
          />
        </Col>
      </Row>

      <AddUsersModal
        isOpen={addUsersModal.isOpen}
        data={addUsersModal.data}
        heading={addUsersModal.heading}
        primaryLeaderData={addUsersModal.primaryLeaderData}
        toggle={() => _toggleAddUsersModal()}
        onSuccess={() => _getUserById()}
      />

      <AgreementModal
        isOpen={agreementModal.isOpen}
        data={agreementModal.data}
        templateType={agreementModal.templateType}
        toggle={() => _toggleAgreementModal()}
        onSuccess={() => _getUserById()}
      />

      <ResetUserPasswordModal
        isOpen={resetUserPasswordModal.isOpen}
        data={resetUserPasswordModal.data}
        toggle={() => _toggleResetUserPasswordModal()}
      />

      <AffiliatesClientsModal
        isOpen={affiliatesClientsModal.isOpen}
        data={affiliatesClientsModal.data}
        toggle={() => _toggleAffiliatesClientsModal()}
      />

      <LeadNotesModal
        isOpen={leadNotesModal.isOpen}
        data={leadNotesModal.data}
        toggle={() => _toggleLeadNotesModal()}
        onSuccess={() => _getUserById()}
      />
    </>
  );
};

export default AffiliateDetailsPage;
