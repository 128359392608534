import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { countryCodes } from "../../config/country-codes";
import { RegexConfig } from "../../config/RegexConfig";
import {
  copyToClipboard,
  errorHandler,
  showToast,
  splitFullName,
} from "../../helper-methods";
import {
  addLead,
  getAffiliateList,
  getLeadById,
  updateLead,
} from "../../http/http-calls";
import SearchableInput from "../SearchableInput";

const LeadPersonalInfoModal = ({ isOpen, data, toggle, onSuccess }) => {
  const [formFields, setFormFields] = useState({
    fullName: "",
    email: "",
    phone: "",
    countryCode: "+1",
    _referBy: "",
    companyName: "",
    location: "",
    organizationName: "",
    questionnaireEmployeeCount: "",
    referCode: "",
  });
  // eslint-disable-next-line no-unused-vars
  const [isDirty, setIsDirty] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [usersList, setUsersList] = useState([]);

  const _getAffiliateList = async () => {
    try {
      const res = await getAffiliateList();

      setUsersList(
        res?.user?.length
          ? res.user
              .filter((each) => each._id !== data?._id)
              .map((each) => ({
                ...each,
                label: each.name?.full,
                value: each._id,
              }))
          : []
      );
    } catch (error) {
      errorHandler(error);
    }
  };

  const _validateFormFields = ({ newFormFields, newIsDirty }) => {
    return new Promise((resolve) => {
      const newErrors = {};
      let isFormValid = true;

      if (newFormFields) {
        Object.keys(newFormFields).forEach((key) => {
          if (newIsDirty[key]) {
            switch (key) {
              // case "_referBy":
              case "fullName": {
                if (newFormFields[key]?.trim().length) {
                  if (
                    newFormFields[key]?.trim().length >= 2 &&
                    newFormFields[key]?.trim().length <= 100
                  ) {
                    if (
                      RegexConfig.name.test(
                        String(newFormFields[key]).toLowerCase()
                      )
                    ) {
                      newErrors[key] = null;
                      newIsDirty[key] = false;
                    } else {
                      newErrors[key] = "*Invalid name";
                      isFormValid = false;
                    }
                  } else {
                    newErrors[key] =
                      "*Please enter between 2 and 100 characters.";
                    isFormValid = false;
                  }
                } else {
                  newErrors[key] = "*Required";
                  isFormValid = false;
                }
                break;
              }
              case "companyName": {
                if (newFormFields[key]?.trim().length) {
                  if (
                    RegexConfig.alphanumericMultiWord.test(
                      String(newFormFields[key]).toLowerCase()
                    ) &&
                    newFormFields[key].length >= 2
                  ) {
                    newErrors[key] = null;
                    newIsDirty[key] = false;
                  } else {
                    newErrors[key] =
                      "*Invalid name,  it should be atleast 2 alphanumeric value";
                    isFormValid = false;
                  }
                } else {
                  newErrors[key] = "*Required";
                  isFormValid = false;
                }
                break;
              }
              case "email": {
                if (newFormFields[key]?.trim().length) {
                  if (
                    RegexConfig.email.test(
                      String(newFormFields[key]).toLowerCase()
                    )
                  ) {
                    newErrors[key] = null;
                    newIsDirty[key] = false;
                  } else {
                    newErrors[key] = "*Invalid email";
                    isFormValid = false;
                  }
                } else {
                  newErrors[key] = "*Required";
                  isFormValid = false;
                }
                break;
              }
              case "phone": {
                if (newFormFields[key]?.trim().length) {
                  if (
                    RegexConfig.phone.test(
                      String(newFormFields[key]).toLowerCase()
                    )
                  ) {
                    newErrors[key] = null;
                    newIsDirty[key] = false;
                  } else {
                    newErrors[key] = "*Invalid phone number";
                    isFormValid = false;
                  }
                } else {
                  newErrors[key] = "*Required";
                  isFormValid = false;
                }
                break;
              }
              default:
            }
          }
        });
      }

      setErrors((prev) => ({
        ...prev,
        ...newErrors,
      }));

      setIsDirty((prev) => ({
        ...prev,
        ...newIsDirty,
      }));

      resolve(isFormValid);
    });
  };

  const _onChangeFormFields = (key, value) => {
    if (
      (key === "phone" || key === "questionnaireEmployeeCount") &&
      (isNaN(value) || value.includes(".") || Number(value) < 0)
    )
      return;

    const newFormFields = { ...formFields };
    newFormFields[key] = value;

    if (key === "_referBy") {
      newFormFields.referCode = value?.referCode || "";
      newFormFields.organizationName = value?.organizationName || "";
    }

    setFormFields(newFormFields);
  };

  const _onBlurFormFields = (key) => {
    const newFormFields = { ...formFields };
    const newIsDirty = {
      [key]: true,
    };
    _validateFormFields({ newFormFields, newIsDirty });
  };

  const _setModalState = (data = null) => {
    setFormFields({
      fullName: data?.name?.full || "",
      email: data?.email || "",
      phone: data?.phone || "",
      countryCode: data?.countryCode || "+1",
      _referBy:
        data?._referBy?._id && data?._referBy?.name?.full
          ? { label: data._referBy.name.full, value: data._referBy._id }
          : "",
      companyName: data?.companyName || "",
      location: data?.location || "",
      questionnaireEmployeeCount:
        data?.questionnaireEmployeeCount ||
        data?.questionnaireEmployeeCount === 0
          ? data?.questionnaireEmployeeCount?.toString()
          : "",
      organizationName: data?.organizationName || "",
      referCode: data?.referCode || "",
    });
    setIsDirty({});
    setErrors({});
    setLoading(false);
  };

  const _closeModal = () => {
    _setModalState();
    toggle();
  };

  const _updateLead = ({ id, payload }) => {
    updateLead({ id, payload })
      .then((res) => {
        showToast("Data has been updated", "success");
        onSuccess();
        _closeModal();
      })
      .catch((error) => {
        errorHandler(error);
        setLoading(false);
      });
  };

  const _addLead = (payload) => {
    addLead(payload)
      .then((res) => {
        showToast("Data has been added", "success");
        onSuccess();
        _closeModal();
      })
      .catch((error) => {
        errorHandler(error);
        setLoading(false);
      });
  };

  const _onSaveDetails = async () => {
    try {
      const newFormFields = { ...formFields };

      const newIsDirty = {
        fullName: true,
        email: true,
        phone: true,
        companyName: true,
      };

      const isFormValid = await _validateFormFields({
        newFormFields,
        newIsDirty,
      });

      if (!isFormValid) return;

      setLoading(true);

      const { firstName, lastName } = splitFullName(formFields.fullName.trim());

      const payload = {
        name: {
          first: firstName,
          last: lastName,
        },
        email: formFields.email.trim(),
        phone: formFields.phone.trim(),
        countryCode: formFields.countryCode.trim(),
        _referBy: formFields._referBy?.value || "",
        companyName: formFields.companyName.trim(),
        location: formFields.location.trim(),
        organizationName: formFields?.organizationName,
        questionnaireEmployeeCount: formFields?.questionnaireEmployeeCount,
        referCode: formFields?.referCode,
      };

      if (formFields._referBy?._organization) {
        payload.organizationName = formFields._referBy?.organizationName;
        payload.organizationId = formFields._referBy?._organization;
      }

      if (data?._id) {
        delete payload?.referCode;
        _updateLead({ id: data._id, payload });
      } else {
        delete payload?._referBy;
        _addLead(payload);
      }
    } catch (error) {
      setLoading(false);
      errorHandler(error);
    }
  };

  const _getLeadById = (id) => {
    setLoading(true);
    getLeadById(id)
      .then((res) => {
        _setModalState(res.lead);
        setLoading(false);
      })
      .catch((error) => {
        errorHandler(error);
        _closeModal();
      });
  };

  useEffect(() => {
    if (isOpen && data?._id) _getLeadById(data._id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, data]);

  useEffect(() => {
    if (isOpen) _getAffiliateList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => _closeModal()}
      scrollable
      centered
      backdrop="static"
    >
      <ModalHeader toggle={() => _closeModal()}>
        {data?._id ? "Update" : "Add"} Client
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Client Name</Label>
              <Input
                type="text"
                value={formFields.fullName}
                name="fullName"
                onChange={(e) =>
                  _onChangeFormFields("fullName", e.target.value)
                }
                onBlur={() => _onBlurFormFields("fullName")}
                disabled={loading}
              />
              {errors["fullName"] ? (
                <div className="form-error">{errors["fullName"]}</div>
              ) : null}
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Email</Label>
              <InputGroup>
                <Input
                  type="text"
                  value={formFields.email}
                  name="email"
                  onChange={(e) => _onChangeFormFields("email", e.target.value)}
                  onBlur={() => _onBlurFormFields("email")}
                  disabled={loading ? true : false}
                />
                {data?._id && data?.email ? (
                  <InputGroupText
                    className="cursorPointer"
                    onClick={() => copyToClipboard(data?.email)}
                  >
                    <img
                      src={require("../../assets/img/copy.png")}
                      alt=""
                      width="14x"
                      className="cursorPointer ms-1"
                    />
                  </InputGroupText>
                ) : null}
              </InputGroup>
              {errors["email"] ? (
                <div className="form-error">{errors["email"]}</div>
              ) : null}
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Phone Number</Label>
              <InputGroup>
                <InputGroupText className="p-0 bg-transparent border-0 border-end">
                  <Input
                    type="select"
                    className="pl-0 bg-transparent pe-0 border-0"
                    value={formFields.countryCode}
                    name="countryCode"
                    onChange={(e) =>
                      _onChangeFormFields("countryCode", e.target.value)
                    }
                    onBlur={() => _onBlurFormFields("countryCode")}
                    disabled={loading}
                  >
                    {countryCodes.map((each) => (
                      <option key={each.code} value={each.dial_code}>
                        {each.code} ({each.dial_code})
                      </option>
                    ))}
                  </Input>
                </InputGroupText>
                <Input
                  type="text"
                  value={formFields.phone}
                  name="phone"
                  onChange={(e) => _onChangeFormFields("phone", e.target.value)}
                  onBlur={() => _onBlurFormFields("phone")}
                  disabled={loading}
                />
              </InputGroup>
              {errors["phone"] ? (
                <div className="form-error">{errors["phone"]}</div>
              ) : null}
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label>Referred By</Label>
              <SearchableInput
                options={usersList}
                value={formFields._referBy}
                onChange={(value) => _onChangeFormFields("_referBy", value)}
                disabled={loading}
              />
              {errors["_referBy"] ? (
                <div className="form-error">{errors["_referBy"]}</div>
              ) : null}
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Company Name</Label>
              <Input
                type="text"
                value={formFields.companyName}
                name="companyName"
                onChange={(e) =>
                  _onChangeFormFields("companyName", e.target.value)
                }
                onBlur={() => _onBlurFormFields("companyName")}
                disabled={loading}
              />
              {errors["companyName"] ? (
                <div className="form-error">{errors["companyName"]}</div>
              ) : null}
            </FormGroup>
          </Col>
          {Object.keys(formFields._referBy)?.length ? (
            <Col md={6}>
              <FormGroup>
                <Label>Organization Name</Label>
                <Input
                  type="text"
                  name="organizationName"
                  value={formFields.organizationName}
                  //need to disabled all time not needed onChange
                  disabled={true}
                />
              </FormGroup>
            </Col>
          ) : null}
          <Col md={data?.questionnaireEmployeeCount ? 12 : 6}>
            <FormGroup>
              <Label>Location</Label>
              <Input
                type="text"
                value={formFields.location}
                name="location"
                onChange={(e) =>
                  _onChangeFormFields("location", e.target.value)
                }
                onBlur={() => _onBlurFormFields("location")}
                disabled={loading}
              />
              {errors["location"] ? (
                <div className="form-error">{errors["location"]}</div>
              ) : null}
            </FormGroup>
          </Col>
          {!data?.questionnaireEmployeeCount && (
            <Col md={6}>
              <FormGroup>
                <Label>Estimated Employees</Label>
                <Input
                  type="text"
                  value={formFields.questionnaireEmployeeCount}
                  name="questionnaireEmployeeCount"
                  onChange={(e) =>
                    _onChangeFormFields(
                      "questionnaireEmployeeCount",
                      e.target.value
                    )
                  }
                  onBlur={() => _onBlurFormFields("questionnaireEmployeeCount")}
                  disabled={loading}
                />
                {errors["questionnaireEmployeeCount"] ? (
                  <div className="form-error">
                    {errors["questionnaireEmployeeCount"]}
                  </div>
                ) : null}
              </FormGroup>
            </Col>
          )}
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="link" className="closeBtn" onClick={() => _closeModal()}>
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={() => _onSaveDetails()}
          disabled={loading}
        >
          {loading ? <i className="fa fa-spinner fa-spin mr-2" /> : null}{" "}
          {data?._id ? "Update" : "Add"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default LeadPersonalInfoModal;
