import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { countryCodes } from "../../config/country-codes";
import { affiliateTypeConfig } from "../../config/helper-config";
import { RegexConfig } from "../../config/RegexConfig";
import {
  copyToClipboard,
  errorHandler,
  showToast,
  splitFullName,
} from "../../helper-methods";
import {
  createUser,
  getAffiliateList,
  getUserById,
  updateUser,
} from "../../http/http-calls";
import SearchableInput from "../SearchableInput";

const AddUsersModal = ({
  isOpen,
  data,
  toggle,
  onSuccess,
  isUpdateUser = false,
  heading = "",
  primaryLeaderData = null,
}) => {
  const userCredential = useSelector((state) => state?.userCredential);

  const userType = useMemo(
    () => userCredential?.user?.userType?.toLowerCase(),
    [userCredential]
  );

  const affiliateType = useMemo(
    () => userCredential?.user?.affiliateType?.toLowerCase(),
    [userCredential]
  );

  const [formFields, setFormFields] = useState({
    fullName: "",
    email: "",
    phone: "",
    countryCode: "+1",
    _referBy: "",
    referCode: "",
    location: "",
    affiliateType: "",
    organizationName: "",
    commissionLevel1: "",
    commissionLevel2: "",
  });
  // eslint-disable-next-line no-unused-vars
  const [isDirty, setIsDirty] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [usersList, setUsersList] = useState([]);

  const _getAffiliateList = async () => {
    try {
      const res = await getAffiliateList();

      const users = res?.user?.length
        ? res.user
            .filter((each) => each._id !== data?._id)
            .map((each) => ({
              ...each,
              label: each.name?.full,
              value: each._id,
            }))
        : [];

      setUsersList(users);
    } catch (error) {
      errorHandler(error);
    }
  };

  const _validateFormFields = ({ newFormFields, newIsDirty }) => {
    return new Promise((resolve) => {
      const newErrors = {};
      let isFormValid = true;

      if (newFormFields) {
        Object.keys(newFormFields).forEach((key) => {
          if (newIsDirty[key]) {
            switch (key) {
              // case "_referBy":
              case "fullName": {
                if (newFormFields[key]?.trim().length) {
                  if (
                    newFormFields[key]?.trim().length >= 2 &&
                    newFormFields[key]?.trim().length <= 100
                  ) {
                    if (
                      RegexConfig.name.test(
                        String(newFormFields[key]).toLowerCase()
                      )
                    ) {
                      newErrors[key] = null;
                      newIsDirty[key] = false;
                    } else {
                      newErrors[key] = "*Invalid name";
                      isFormValid = false;
                    }
                  } else {
                    newErrors[key] =
                      "*Please enter between 2 and 100 characters.";
                    isFormValid = false;
                  }
                } else {
                  newErrors[key] = "*Required";
                  isFormValid = false;
                }
                break;
              }
              case "email": {
                if (newFormFields[key]?.trim().length) {
                  if (
                    RegexConfig.email.test(
                      String(newFormFields[key]).toLowerCase()
                    )
                  ) {
                    newErrors[key] = null;
                    newIsDirty[key] = false;
                  } else {
                    newErrors[key] = "*Invalid email";
                    isFormValid = false;
                  }
                } else {
                  newErrors[key] = "*Required";
                  isFormValid = false;
                }
                break;
              }
              case "phone": {
                if (newFormFields[key]?.trim().length) {
                  if (
                    RegexConfig.phone.test(
                      String(newFormFields[key]).toLowerCase()
                    )
                  ) {
                    newErrors[key] = null;
                    newIsDirty[key] = false;
                  } else {
                    newErrors[key] = "*Invalid phone number";
                    isFormValid = false;
                  }
                } else {
                  newErrors[key] = "*Required";
                  isFormValid = false;
                }
                break;
              }
              case "referCode": {
                if (newFormFields[key]?.trim().length) {
                  if (
                    newFormFields[key]?.trim().length >= 2 &&
                    newFormFields[key]?.trim().length <= 25 &&
                    RegexConfig.alphanumeric.test(
                      String(newFormFields[key]).toLowerCase()
                    )
                  ) {
                    newErrors[key] = null;
                    newIsDirty[key] = false;
                  } else {
                    newErrors[key] =
                      "*Invalid code, it should be between 2 to 25 alphanumeric value";
                    isFormValid = false;
                  }
                } else {
                  newErrors[key] = "*Required";
                  isFormValid = false;
                }
                break;
              }
              case "affiliateType": {
                if (newFormFields[key]?.length) {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] = "*Required";
                  isFormValid = false;
                }
                break;
              }
              case "organizationName": {
                if (newFormFields.affiliateType === "Leader") {
                  if (newFormFields[key]?.length) {
                    newErrors[key] = null;
                    newIsDirty[key] = false;
                  } else {
                    newErrors[key] = "*Required";
                    isFormValid = false;
                  }
                } else {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                }
                break;
              }
              case "commissionLevel1": {
                if (
                  userType === "admin" ||
                  (userType === "affiliate" &&
                    affiliateType === "leader" &&
                    userCredential?.user?.id !== data?.id)
                ) {
                  if (newFormFields[key]?.trim().length) {
                    if (isNaN(newFormFields[key]?.trim())) {
                      newErrors[key] = "*Must be a number";
                      isFormValid = false;
                    } else {
                      newErrors[key] = null;
                      newIsDirty[key] = false;
                    }
                  } else {
                    newErrors[key] = "*Required";
                    isFormValid = false;
                  }
                } else {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                }
                break;
              }
              case "commissionLevel2": {
                if (
                  userType === "admin" ||
                  (userType === "affiliate" &&
                    affiliateType === "leader" &&
                    userCredential?.user?.id !== data?.id)
                ) {
                  if (newFormFields.affiliateType !== "Leader") {
                    if (newFormFields[key]?.trim().length) {
                      if (isNaN(newFormFields[key]?.trim())) {
                        newErrors[key] = "*Must be a number";
                        isFormValid = false;
                      } else {
                        newErrors[key] = null;
                        newIsDirty[key] = false;
                      }
                    } else {
                      newErrors[key] = "*Required";
                      isFormValid = false;
                    }
                  } else {
                    newErrors[key] = null;
                    newIsDirty[key] = false;
                  }
                } else {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                }
                break;
              }
              default:
            }
          }
        });
      }

      setErrors((prev) => ({
        ...prev,
        ...newErrors,
      }));

      setIsDirty((prev) => ({
        ...prev,
        ...newIsDirty,
      }));

      resolve(isFormValid);
    });
  };

  const _onChangeFormFields = (key, value) => {
    if (
      key === "phone" &&
      (isNaN(value) || value.includes(".") || Number(value) < 0)
    )
      return;
    if (
      (key === "commissionLevel1" || key === "commissionLevel2") &&
      (isNaN(value) ||
        (value.includes(".") && value.split(".")[1].length > 2) ||
        value.length > 16 ||
        Number(value) < 0)
    )
      return;

    const newFormFields = { ...formFields };
    if (key === "referCode") {
      value = value.toLowerCase();
    }
    newFormFields[key] = value;

    if (key === "affiliateType") {
      if (value === "Leader") {
        newFormFields._referBy = "";
        newFormFields.organizationName = "";

        newFormFields.commissionLevel1 = heading?.includes("Secondary Leader")
          ? "13"
          : "18";
        newFormFields.commissionLevel2 = "";
      } else {
        if (!newFormFields._referBy) {
          newFormFields.organizationName = "";
        }
        if (value === "Regular") {
          newFormFields.commissionLevel1 = "5";
          newFormFields.commissionLevel2 = "2";
        }
        if (value === "Super") {
          newFormFields.commissionLevel1 = "10";
          newFormFields.commissionLevel2 = "5";
        }
      }
    }

    if (key === "_referBy") {
      newFormFields.organizationName = value?.organizationName || "";
    }

    setFormFields(newFormFields);

    if (key === "affiliateType") {
      const newIsDirty = { ...isDirty };

      newIsDirty[key] = true;

      if (key === "affiliateType") {
        if (errors?._referBy) {
          newIsDirty._referBy = true;
        }
        if (errors?.organizationName) {
          newIsDirty.organizationName = true;
        }
      }

      _validateFormFields({ newFormFields, newIsDirty });
    }
  };

  const _onBlurFormFields = (key) => {
    const newFormFields = { ...formFields };
    const newIsDirty = {
      [key]: true,
    };

    if (key === "affiliateType") {
      newIsDirty.organizationName = true;
      newIsDirty._referBy = true;
    }

    _validateFormFields({ newFormFields, newIsDirty });
  };

  const _setModalState = (data = null) => {
    setFormFields({
      fullName: data?.name?.full || "",
      email: data?.email || "",
      phone: data?.phone || "",
      countryCode: data?.countryCode || "+1",
      _referBy:
        data?._referBy?._id && data?._referBy?.name?.full
          ? { label: data._referBy.name.full, value: data._referBy._id }
          : "",
      referCode: data?.referCode || "",
      location: data?.location || "",
      affiliateType: data?.affiliateType || "",
      organizationName: data?.organizationName || "",
      commissionLevel1:
        data?.commissionLevel1 || data?.commissionLevel1 === 0
          ? data?.commissionLevel1?.toString()
          : "",
      commissionLevel2:
        data?.commissionLevel2 || data?.commissionLevel2 === 0
          ? data?.commissionLevel2?.toString()
          : "",
    });
    setIsDirty({});
    setErrors({});
    setLoading(false);
  };

  const _setPrimaryLeaderData = (data = null) => {
    setFormFields({
      fullName: "",
      email: "",
      phone: "",
      countryCode: "+1",
      _referBy: "",
      referCode: "",
      location: "",
      affiliateType: data?.affiliateType || "",
      organizationName: data?.organizationName || "",
      commissionLevel1: "13",
      commissionLevel2: "",
    });
    setIsDirty({});
    setErrors({});
    setLoading(false);
  };

  const _closeModal = () => {
    _setModalState();
    toggle();
  };

  const _createUser = async (payload) => {
    try {
      await createUser(payload);

      showToast("Affiliate has been added", "success");
      onSuccess();
      _closeModal();
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  const _updateUser = async ({ id, payload }) => {
    try {
      await updateUser({ id, payload });

      showToast("Affiliate has been updated", "success");
      onSuccess();
      _closeModal();
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  const _onSaveDetails = async () => {
    try {
      const newFormFields = { ...formFields };

      const newIsDirty = {};

      Object.keys(newFormFields).forEach((key) => {
        newIsDirty[key] = true;
      });

      const isFormValid = await _validateFormFields({
        newFormFields,
        newIsDirty,
      });

      if (!isFormValid) return;

      setLoading(true);

      const { firstName, lastName } = splitFullName(formFields.fullName.trim());

      const payload = {
        name: {
          first: firstName,
          last: lastName,
        },
        email: formFields.email.trim(),
        phone: formFields.phone.trim(),
        countryCode: formFields.countryCode.trim(),

        referCode: formFields.referCode.trim(),
        location: formFields.location.trim(),

        organizationName: formFields?.organizationName || "",
        affiliateType: formFields?.affiliateType || "",

        commissionLevel1: formFields?.commissionLevel1 || "",
      };

      if (formFields.affiliateType !== "Leader") {
        if (formFields._referBy?.value) {
          payload._referBy = formFields._referBy?.value;
        }
        if (formFields._referBy?._organization) {
          payload.organizationId = formFields._referBy?._organization;
        }

        payload.commissionLevel2 = formFields?.commissionLevel2 || "";
      }

      if (primaryLeaderData?._organization?._id) {
        // payload._referBy = primaryLeaderData?._id;
        payload.organizationId = primaryLeaderData?._organization?._id;
      }

      if (!primaryLeaderData && (data?._id || isUpdateUser)) {
        _updateUser({ id: data?._id, payload });
      } else {
        _createUser(payload);
      }
    } catch (error) {
      setLoading(false);
      errorHandler(error);
    }
  };

  const _getUserById = async (id, isGetPrimaryLeaderData = false) => {
    try {
      setLoading(true);

      const res = await getUserById(id);

      if (isGetPrimaryLeaderData) {
        _setPrimaryLeaderData(res.user);
      } else {
        _setModalState(res.user);
      }

      setLoading(false);
    } catch (error) {
      errorHandler(error);
      _closeModal();
    }
  };

  useEffect(() => {
    if (isOpen && data?._id) _getUserById(data._id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, data]);

  useEffect(() => {
    if (isOpen && primaryLeaderData?._id)
      _getUserById(primaryLeaderData._id, true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, primaryLeaderData]);

  useEffect(() => {
    if (isOpen) {
      _getAffiliateList();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => _closeModal()}
      scrollable
      centered
      backdrop="static"
    >
      <ModalHeader toggle={() => _closeModal()}>
        {heading ? heading : <>{data?._id ? "Update" : "Add"} Affiliate</>}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Name</Label>
              <Input
                type="text"
                value={formFields.fullName}
                name="fullName"
                onChange={(e) =>
                  _onChangeFormFields("fullName", e.target.value)
                }
                onBlur={() => _onBlurFormFields("fullName")}
                disabled={loading}
              />
              {errors["fullName"] ? (
                <div className="form-error">{errors["fullName"]}</div>
              ) : null}
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Email</Label>
              <InputGroup>
                <Input
                  type="text"
                  value={formFields.email}
                  name="email"
                  onChange={(e) => _onChangeFormFields("email", e.target.value)}
                  onBlur={() => _onBlurFormFields("email")}
                  disabled={loading ? true : false}
                />
                {data?._id && data?.email ? (
                  <InputGroupText
                    className="cursorPointer"
                    onClick={() => copyToClipboard(data?.email)}
                  >
                    <img
                      src={require("../../assets/img/copy.png")}
                      alt=""
                      width="14x"
                      className="cursorPointer ms-1"
                    />
                  </InputGroupText>
                ) : null}
              </InputGroup>
              {errors["email"] ? (
                <div className="form-error">{errors["email"]}</div>
              ) : null}
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Phone Number</Label>
              <InputGroup>
                <InputGroupText className="p-0 bg-transparent border-0 border-end">
                  <Input
                    type="select"
                    className="pl-0 bg-transparent pe-0 border-0"
                    value={formFields.countryCode}
                    name="countryCode"
                    onChange={(e) =>
                      _onChangeFormFields("countryCode", e.target.value)
                    }
                    onBlur={() => _onBlurFormFields("countryCode")}
                    disabled={loading}
                    style={{ width: 100 }}
                  >
                    {countryCodes.map((each) => (
                      <option key={each.code} value={each.dial_code}>
                        {each.code} ({each.dial_code})
                      </option>
                    ))}
                  </Input>
                </InputGroupText>
                <Input
                  type="text"
                  value={formFields.phone}
                  name="phone"
                  onChange={(e) => _onChangeFormFields("phone", e.target.value)}
                  onBlur={() => _onBlurFormFields("phone")}
                  disabled={loading}
                />
              </InputGroup>
              {errors["phone"] ? (
                <div className="form-error">{errors["phone"]}</div>
              ) : null}
            </FormGroup>
          </Col>

          {formFields.affiliateType !== "Leader" ? (
            <Col md={6}>
              <FormGroup>
                <Label>Referred By</Label>
                <SearchableInput
                  options={usersList}
                  value={formFields._referBy}
                  onChange={(value) => _onChangeFormFields("_referBy", value)}
                  disabled={
                    loading ||
                    isUpdateUser ||
                    formFields.affiliateType === "Leader"
                  }
                />
                {errors["_referBy"] ? (
                  <div className="form-error">{errors["_referBy"]}</div>
                ) : null}
              </FormGroup>
            </Col>
          ) : null}

          {userType === "admin" ||
          (userType === "affiliate" && affiliateType === "leader") ? (
            <>
              <Col md={6}>
                <FormGroup>
                  <Label>Type</Label>
                  <Input
                    type="select"
                    value={formFields.affiliateType}
                    name="affiliateType"
                    onChange={(e) =>
                      _onChangeFormFields("affiliateType", e.target.value)
                    }
                    // onBlur={() => _onBlurFormFields("affiliateType")}
                    disabled={loading || isUpdateUser}
                  >
                    <option value="">Select</option>
                    {affiliateTypeConfig.map((each) => (
                      <option
                        key={each.value}
                        value={each.value}
                        disabled={data?._id && each.value === "Leader"}
                      >
                        {each.label}
                      </option>
                    ))}
                  </Input>
                  {errors["affiliateType"] ? (
                    <div className="form-error">{errors["affiliateType"]}</div>
                  ) : null}
                </FormGroup>
              </Col>

              <Col md={12}>
                <FormGroup>
                  <Label>Organization Name</Label>
                  <Input
                    type="text"
                    value={formFields.organizationName}
                    name="organizationName"
                    onChange={(e) =>
                      _onChangeFormFields("organizationName", e.target.value)
                    }
                    onBlur={() => _onBlurFormFields("organizationName")}
                    disabled={
                      loading ||
                      isUpdateUser ||
                      formFields.affiliateType !== "Leader"
                    }
                  />
                  {errors["organizationName"] ? (
                    <div className="form-error">
                      {errors["organizationName"]}
                    </div>
                  ) : null}
                </FormGroup>
              </Col>

              <Col md={12}>
                <FormGroup>
                  <Label>
                    {formFields.affiliateType === "Leader"
                      ? heading?.includes("Secondary Leader")
                        ? `Secondary Leader Commission`
                        : "Organization Commission"
                      : "Commission Level 1"}
                  </Label>
                  <InputGroup>
                    <InputGroupText>$</InputGroupText>
                    <Input
                      type="text"
                      value={formFields.commissionLevel1}
                      name="commissionLevel1"
                      onChange={(e) =>
                        _onChangeFormFields("commissionLevel1", e.target.value)
                      }
                      onBlur={() => _onBlurFormFields("commissionLevel1")}
                      disabled={
                        loading ||
                        (userType === "affiliate" &&
                          userCredential?.user?.id === data?.id)
                      }
                    />
                    <InputGroupText>PEPM</InputGroupText>
                  </InputGroup>
                  {errors?.commissionLevel1 ? (
                    <div className="form-error">{errors?.commissionLevel1}</div>
                  ) : null}
                </FormGroup>
              </Col>

              {formFields.affiliateType !== "Leader" ? (
                <Col md={6}>
                  <FormGroup>
                    <Label>Commission Level 2</Label>
                    <InputGroup>
                      <InputGroupText>$</InputGroupText>
                      <Input
                        type="text"
                        value={formFields.commissionLevel2}
                        name="commissionLevel2"
                        onChange={(e) =>
                          _onChangeFormFields(
                            "commissionLevel2",
                            e.target.value
                          )
                        }
                        onBlur={() => _onBlurFormFields("commissionLevel2")}
                        disabled={
                          loading ||
                          (userType === "affiliate" &&
                            userCredential?.user?.id === data?.id)
                        }
                      />
                      <InputGroupText>PEPM</InputGroupText>
                    </InputGroup>
                    {errors?.commissionLevel2 ? (
                      <div className="form-error">
                        {errors?.commissionLevel2}
                      </div>
                    ) : null}
                  </FormGroup>
                </Col>
              ) : null}
            </>
          ) : null}

          <Col md={6}>
            <FormGroup>
              <Label>Referral Code</Label>
              <Input
                type="text"
                value={formFields.referCode}
                name="referCode"
                onChange={(e) =>
                  _onChangeFormFields("referCode", e.target.value)
                }
                onBlur={() => _onBlurFormFields("referCode")}
                disabled={loading}
              />
              {errors["referCode"] ? (
                <div className="form-error">{errors["referCode"]}</div>
              ) : null}
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Location</Label>
              <Input
                type="text"
                value={formFields.location}
                name="location"
                onChange={(e) =>
                  _onChangeFormFields("location", e.target.value)
                }
                onBlur={() => _onBlurFormFields("location")}
                disabled={loading}
              />
              {errors["location"] ? (
                <div className="form-error">{errors["location"]}</div>
              ) : null}
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="link" className="closeBtn" onClick={() => _closeModal()}>
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={() => _onSaveDetails()}
          disabled={loading}
        >
          {loading ? <i className="fa fa-spinner fa-spin mr-2" /> : null}{" "}
          {data?._id ? "Update" : "Add"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddUsersModal;
