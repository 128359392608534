import React, { useState } from "react";
import { Button } from "reactstrap";

const ReadMoreReadLess = ({
  text,
  count = 80,
  className,
  isHideMoreButton = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  if (!text) return <></>;

  if (text.length <= count)
    return <p className={`${className} mb-0 w-auto d-inline-block`}>{text}</p>;

  return (
    <p className={`${className} mb-0 pe-2`}>
      {isOpen ? text : text.slice(0, count) + "....."}
      {!isHideMoreButton ? (
        <Button
          color="link"
          className="ms-2 p-0 h-auto d-inline"
          onClick={() => setIsOpen((prev) => !prev)}
        >
          {isOpen ? "Less" : "More"}
        </Button>
      ) : null}
    </p>
  );
};

export default ReadMoreReadLess;
