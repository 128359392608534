export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const FRONTEND_BASE_URL = process.env.REACT_APP_FRONTEND_BASE_URL;

export const BUSINESS_APPLICATION_URL =
  process.env.REACT_APP_BUSINESS_APPLICATION_URL;

export const WEBSITE_URL = process.env.REACT_APP_WEBSITE_URL;

export const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_API_KEY;

export const CLIENT_INTRO_VIDEO = process.env.REACT_APP_CLIENT_INTRO_VIDEO;

export const AFFILIATE_APPLICATION_INTRO_VIDEO =
  process.env.REACT_APP_AFFILIATE_APPLICATION_INTRO_VIDEO;

export const AWS_IMAGE_BUCKET_NAME =
  process.env.REACT_APP_AWS_IMAGE_BUCKET_NAME;

export const DEFAULT_PROFILE_PICTURE =
  require("../assets/img/default-profile.svg").default;

export const REQUEST_INVITE_VIDEO = process.env.REACT_APP_REQUEST_INVITE_VIDEO;

export const REQUEST_INVITE_THUMBNAIL =
  process.env.REACT_APP_REQUEST_INVITE_THUMBNAIL;

export const APP_LOGO = process.env.REACT_APP_LOGO;

export const APP_NAME = process.env.REACT_APP_NAME;

export const APP_THEME_HEX_COLOR = process.env.REACT_APP_THEME_HEX_COLOR;
