import React, { Component } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
class TextEditor extends Component {
  modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
    ],
  };

  formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
  ];

  _handleChange = (value) => {
    this.props.onChange(value);
  };

  _handleBlur = () => {
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  };

  render() {
    return (
      <div>
        <ReactQuill
          value={this.props.content || ""}
          name="renterRefundPolicy"
          onChange={(value) => this._handleChange(value)}
          onBlur={() => this._handleBlur()}
          className="editor"
          modules={this.modules}
          formats={this.formats}
          placeholder="Enter your message here.."
          preserveWhitespace={true}
        />
      </div>
    );
  }
}

export default TextEditor;
