import {
  ADD_USER_CREDENTIAL,
  UPDATE_USER_DATA,
  CLEAR_USER_CREDENTIAL,
} from "../actions/";

const initialState = {
  token: null,
  user: null,
  notificationCount: 0,
  showHeader: true
};

export const userCredentialReducer = (state = initialState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case ADD_USER_CREDENTIAL: {
      newState = action.payload.credential;
      break;
    }
    case UPDATE_USER_DATA: {
      newState["user"] = action.payload.user;
      newState["notificationCount"] = action.payload.notificationCount;
      newState["showHeader"] = action.payload.showHeader
      break;
    }
    case CLEAR_USER_CREDENTIAL: {
      newState = initialState;
      break;
    }
    default:
  }
  return newState;
};
