import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import SvgIcons from "./SvgIcons";

const tabsConfig = [
  // "Affiliate Application",
  // "Agreement",
  "Training Videos",
  "Quiz",
  "Pending verification",
  "Register Docs",
];

const CustomTrainingSteps = ({ activeTabIndex }) => {
  return (
    <>
      <div className="personalWebsiteSetps">
        <Nav pills>
          {tabsConfig.map((each, index) => (
            <NavItem key={`${each}_${index}`}>
              <NavLink className={+activeTabIndex - 1 >= index ? "active" : ""}>
                <div
                  className={`stepCount ${
                    +activeTabIndex - 1 > index ? "success" : null
                  }`}
                >
                  {+activeTabIndex - 1 > index ? (
                    <>
                      <SvgIcons type="check" />
                    </>
                  ) : (
                    index + 1
                  )}
                </div>
                <span className="stepTitle">{each}</span>
              </NavLink>
            </NavItem>
          ))}
        </Nav>

        {/* <TabContent activeTab={activeTab}>
          <TabPane tabId="1"></TabPane>
          <TabPane tabId="2"></TabPane>
          <TabPane tabId="3"></TabPane>
          <TabPane tabId="4"></TabPane>
          <TabPane tabId="5"></TabPane>
        </TabContent>

        <div className="d-flex align-items-center justify-content-center">
          {Number(activeTab) !== 1 ? (
            <Button
              color="primary"
              outline
              onClick={() => _moveToPrevStep(activeTab)}
            >
              Previous
            </Button>
          ) : null}
          <Button color="primary">Next</Button>
        </div> */}
      </div>
    </>
  );
};

export default CustomTrainingSteps;
