import React, { useState } from "react";
import { Button, FormGroup, Input, Label, Modal, ModalBody } from "reactstrap";
import { submitTermsAndCondition } from "../../http/http-calls";
import { errorHandler } from "../../helper-methods";
import { WEBSITE_URL } from "../../config";

const AccepctAggrimentModal = ({ isOpen, toggle, affiliateType }) => {
  const [isAgree, setIsAgree] = useState(false);
  const [loading, setLoading] = useState(false);

  const _onSaveHandler = async () => {
    setLoading(true);
    toggle();
    let payload = { isAcceptForSignature: isAgree };
    try {
      await submitTermsAndCondition({
        payload: { ...payload, isTempToken: true },
      });
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        scrollable
        centered
        className="modal-lg message-template"
        backdrop="static"
      >
        {/* <ModalHeader>
          Welcome! Kindly ensure completion of the following steps:
        </ModalHeader> */}
        <ModalBody>
          {affiliateType === "Leader" ? (
            <>
              <div className="completionStep">
                <h4>Welcome! Please agree the following terms </h4>
                {/* Thank you for your cooperation. */}
                <FormGroup className="mt-4">
                  <Input
                    id="I_have_read_and_understood"
                    type="checkbox"
                    value={isAgree}
                    onChange={(e) => setIsAgree(e.target.checked)}
                  />{" "}
                  <Label for="I_have_read_and_understood">
                    I agree to the{" "}
                    <a
                      href={`${WEBSITE_URL}/terms`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Terms of Service
                    </a>
                    ,{" "}
                    <a
                      href={`${WEBSITE_URL}/privacy-policy`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Affiliate Policy
                    </a>{" "}
                    and{" "}
                    <a
                      href={`${WEBSITE_URL}/affiliate-associate-agreement`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Affiliate Agreement
                    </a>
                    .{" "}
                  </Label>
                </FormGroup>
              </div>
              <div className="text-center">
                <Button
                  color="primary"
                  disabled={!isAgree}
                  onClick={() => _onSaveHandler()}
                >
                  {loading ? (
                    <i className="fa fa-spinner fa-spin mr-2" />
                  ) : null}{" "}
                  Submit
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className="completionStep">
                <h4>Welcome! Please agree the following terms </h4>
                {/* <ul>
                  {accepctAggrimentQuestions?.map((question, index) => (
                    <li key={index}>{question}</li>
                  ))}
                </ul> */}
                {/* Thank you for your cooperation. */}
                <FormGroup className="mt-4">
                  <Input
                    id="I_have_read_and_understood"
                    type="checkbox"
                    value={isAgree}
                    onChange={(e) => setIsAgree(e.target.checked)}
                  />{" "}
                  <Label for="I_have_read_and_understood">
                    I agree to the{" "}
                    <a
                      href={`${WEBSITE_URL}/terms`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Terms of Service
                    </a>
                    ,{" "}
                    <a
                      href={`${WEBSITE_URL}/privacy-policy`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Affiliate Policy
                    </a>{" "}
                    and{" "}
                    <a
                      href={`${WEBSITE_URL}/affiliate-associate-agreement`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Affiliate Agreement
                    </a>
                    .{" "}
                  </Label>
                </FormGroup>
              </div>
              <div className="text-center">
                <Button
                  color="primary"
                  disabled={!isAgree}
                  onClick={() => _onSaveHandler()}
                >
                  {loading ? (
                    <i className="fa fa-spinner fa-spin mr-2" />
                  ) : null}{" "}
                  Submit
                </Button>
              </div>
            </>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default AccepctAggrimentModal;
