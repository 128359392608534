import React from "react";
import { BUSINESS_APPLICATION_URL, FRONTEND_BASE_URL } from "../config";
import { copyToClipboard } from "../helper-methods";

const ReferralCodeAndLinkList = ({ data }) => {
  if (!data?.referCode) {
    return <></>;
  }

  return (
    <>
      <li>
        <img src={require("../assets/img/refer.png")} alt="icon" />
        <span
          title={data?.referCode}
          onClick={() => copyToClipboard(data?.referCode)}
        >
          {data?.referCode}
        </span>
      </li>

      <li>
        {data?.isVerified ? (
          <>
            <img src={require("../assets/img/refer.png")} alt="icon" />
            <span
              title={`Share this link with business owners / decision makers`}
              onClick={() =>
                copyToClipboard(
                  `${BUSINESS_APPLICATION_URL}?referCode=${data?.referCode}`
                )
              }
              className="text-primary text-decoration-underline cursorPointer"
            >
              Business Application
              <img
                src={require("../assets/img/copy.png")}
                alt=""
                width="14x"
                className="cursorPointer ms-1"
              />
            </span>
          </>
        ) : null}
      </li>

      <li>
        {data?.isVerified ? (
          <>
            <img src={require("../assets/img/refer.png")} alt="icon" />
            <span
              title={`Share this link with affiliates to receive an override commission`}
              onClick={() =>
                copyToClipboard(
                  `${FRONTEND_BASE_URL}/affiliate-application?referCode=${data?.referCode}`
                )
              }
              className="text-primary text-decoration-underline cursorPointer"
            >
              Affiliate Application
              <img
                src={require("../assets/img/copy.png")}
                alt=""
                width="14x"
                className="cursorPointer ms-1"
              />
            </span>
          </>
        ) : null}
      </li>
    </>
  );
};

export default ReferralCodeAndLinkList;
