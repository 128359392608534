import {
  makeGetRequest,
  makePostRequest,
  makePutRequest,
  makeDeleteRequest,
  // uploadFile,
  // uploadFileMultiPart
} from "./http-service";
import { BASE_URL } from "../config/index";

export const login = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/login`, false, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const forgotPassword = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/forgotpassword`, false, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const requestInvitation = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/signup`, false, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const addDeviceId = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/add/device`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const removeDeviceId = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/remove/device`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

/**
 * @param {string} platform - google or facebook
 * @param {object} payload - {accessToken: google or facefook response token}
 * @returns
 */
export const socialLogin = (platform, payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/${platform}/signup`, false, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

/**
 * used for check availability of phone or email or username
 *
 * @param {string} payload - phone or email or username
 * @returns
 */
export const checkAvailability = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/unique`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getLoggedInUserDetail = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/user`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getAllTemplates = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/templates`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getTemplateById = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/template/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const createTemplate = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/template`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const updateTemplate = ({ id, payload }) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/template/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const deleteTemplate = (id) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(`${BASE_URL}/template/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getAffiliateList = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/affiliateList`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const createUser = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/user`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getAllUsers = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/users`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const updateUserVerification = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/change/verification/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const updateUser = ({ id, payload }) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/user/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getUserById = (id, payload) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/user/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getPayments = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/user/paymentLogs/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const deleteUserById = (id) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(`${BASE_URL}/user/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const sendEmailTextToUsers = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/email`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const sendNotification = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/sendnotifications`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getLeadById = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/lead/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const deleteLeadById = (id) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(`${BASE_URL}/lead/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const createLead = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/lead`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getAllLeads = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/leads`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const addLead = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/admin/create/lead`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const updateLead = ({ id, payload }) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/lead/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getAllActivites = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/activities`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const addAffiliateApplication = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/addUserByForm`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getAllTemplatesList = () => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/templateList`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getAllLeadRefer = (id) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/get/leadrefer/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getAllAffiliateRefer = (id) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/affiliate/refer/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getAllLeadNotes = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/notes/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const createLeadNotes = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/note`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const updateLeadNotes = ({ id, payload }) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/note/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const deleteLeadNotes = (id) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(`${BASE_URL}/note/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getAllDocuments = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/documents/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getAllDocumentById = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/documents/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const createDocument = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/documents`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const updateDocument = ({ id, payload }) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/documents/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getLeadInfo = (params) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/leaddetails`, true, params)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const updateLeadInfoDocuments = ({ id, payload }) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/leadupdatedocuments/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getAllLeadInfoDocuments = (params) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/leaddocuments`, true, params)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getAffiliateInfo = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/getAffiliate`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getAllAgreementDetail = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/getAgreementDetail`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const docuSignCheckStatus = ({ id, payload }) => {
  console.log("payload", payload);
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/checkStatus/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const docuSignAcceptAgreement = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/agreement-signature`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const docuSignAchSignature = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/ach-signature`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const w9SignAchSignature = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/w9-signature`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const docuSignAchandW9Signature = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/combinesign`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const resubmitSignature = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/resubmitdocumemts`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const submitTermsAndCondition = ({ payload }) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/accept-for-Sign`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

/**
 *
 * @param {Object} payload
 * @param {String} payload.templateType `enum = ["Client Agreement", "Documents", "Affiliate Agreement", "Affiliate Agreement Reminder", "Documents Reminder", "Client Agreement Reminder", "Generic"]`.
 * @returns
 */
export const getDefaultTemplate = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/defaulttemplate`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const sendDefaultTemplate = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/sendEmailfordefault`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const sendEmailPayout = (id) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/emailpayout/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const sendAgreementAffiliate = (id) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/sendAgreement/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const generateLeadToken = (id) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/generateleadtoken/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const resetUserPassword = ({ id, payload }) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/generate/password/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const updateLeadMeetBooking = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/meetings/booked`, false, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getOrganizationsList = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/organizations`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getTreeData = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/tree`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getAffiliateLeaderTreeData = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/leader/tree`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getAffiliateUserTreeData = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/usertree`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

//Training

export const fetchCategories = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/faq-categories`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const addCategory = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/add/faq-category`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const editCategory = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/faq-category/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const deleteCategory = (id) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(`${BASE_URL}/faq-category/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const rearrangeCategories = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/rearrange/category`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

//resource
export const fetchresources = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/faq-miscellaneous`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const addResource = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/add/faq-miscellaneous`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const editResource = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/faq-miscellaneous/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

//topic
export const fetchTopics = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/faq-topics`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getTopicById = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/faq-topic/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const addTopic = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/add/faq-topic`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const editTopic = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/faq-topic/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const deleteTopic = (id) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(`${BASE_URL}/faq-topic/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const fetchAllCategories = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/category/list`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getTopicFromCategoryId = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/category/faq-topics`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const rearrangeTopics = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/rearrange/topics`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

//notification
export const fetchAllNotifications = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/fetch/notifications`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const notificationsRead = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/read/notifications`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getAllQuiz = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/admin/quizzes`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const createQuiz = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/admin/quiz`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const updateQuiz = ({ id, payload }) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/admin/quiz/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const deleteQuiz = (id) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(`${BASE_URL}/admin/quiz/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getQuizTestForUser = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/affiliate/get/quiz`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const submitQuizAnswer = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/affiliate/takes/quiz`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

/**
 *
 * @param {object} data
 * @param {string} data.id
 * @param {object} data.payload
 * @param {number} data.payload.watchedTill
 * @param {boolean} data.payload.isCompleted
 * @param {boolean} data.payload.isStarted
 * @returns
 */
export const maintainWatchlist = ({ id, payload }) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/update/watchlist/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};
