import React from "react";
import { Button, Table } from "reactstrap";
import SvgIcons from "./SvgIcons";
import { resubmitSignature } from "../http/http-calls";
import {
  decodeToken,
  errorHandler,
  handlePdfDownload,
  openUrlInSameTab,
} from "../helper-methods";

const ResubmitSignatureComponent = ({ data, pageName }) => {
  const _resubmitSignatureHandler = async (type) => {
    try {
      const res = await resubmitSignature({ reSubmitDocument: type });
      let userData = decodeToken(res.payloadToken);

      if (userData?.checkReSubmitDcoument === "Agreement") {
        openUrlInSameTab(`/docu-sign-accept-agreement/${res?.payloadToken}`);
      } else {
        localStorage.temp_token = res?.payloadToken;
        openUrlInSameTab(
          `/agreementsigned/${userData?._id}?event=signing_complete`
        );
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const _downloadAllHandler = () => {
    const pdfName = ["agreementSignedPdf", "achSignedPdf", "w9SignedPdf"];

    pdfName.forEach((each) => {
      if (data?.[each]?.length) {
        handlePdfDownload(data?.[each], each);
      }
    });
  };

  const _combinedPdfDownloadHandler = () => {
    const pdfName = ["achSignedPdf", "w9SignedPdf"];

    pdfName.forEach((each) => {
      if (data?.[each]?.length) {
        handlePdfDownload(data?.[each], each);
      }
    });
  }



  return (
    <div className="cardWrap docsPdf">
      <div className="cardHeader">
        Docs
        <Button
          className="ms-auto"
          color="link"
          onClick={() => _downloadAllHandler()}
        >
          Download All
        </Button>
      </div>
      <div className="cardBody ">
        <Table responsive>
          <thead>
            <tr>
              <th>Pdf Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {/* <tr>
              <td>
                <SvgIcons type={"pdf"} />
                ACH
              </td>
              <td>
                {pageName === "details" ? null : (
                 data?.achSignedPdf?.length? 
                  <Button
                    color="link"
                    onClick={() => _resubmitSignatureHandler("Ach")}
                  >
                    Resubmit
                  </Button> : null
                )}
                {data?.achSignedPdf?.length ? (
                  <Button
                    className="remove"
                    color="link"
                    onClick={() =>
                      handlePdfDownload(data?.achSignedPdf, "AchSignedPdf")
                    }
                  >
                    Download
                  </Button>
                ) : null}
              </td>
            </tr> */}
            {data?.agreementSignedPdf?.length ? (
              <tr>
                <td>
                  <SvgIcons type={"pdf"} />
                  Agreement
                </td>
                <td>
                  {pageName === "details" ? null : data?.agreementSignedPdf
                      ?.length ? (
                    <Button
                      color="link"
                      onClick={() => _resubmitSignatureHandler("Agreement")}
                    >
                      Resubmit
                    </Button>
                  ) : null}
                  {data?.agreementSignedPdf?.length ? (
                    <Button
                      className="remove"
                      color="link"
                      onClick={() =>
                        handlePdfDownload(
                          data?.agreementSignedPdf,
                          "agreementSignedPdf"
                        )
                      }
                    >
                      Download
                    </Button>
                  ) : null}
                </td>
              </tr>
            ) : null}
            {(data?.agreementSignedPdf?.length && data?.w9SignedPdf?.length) ? (
              <tr>
                <td>
                  <SvgIcons type={"pdf"} />
                  ACH and W-9
                </td>
                <td>
                  {pageName === "details" ? null : (data?.agreementSignedPdf?.length && data?.w9SignedPdf?.length) ? (
                    <Button
                      color="link"
                      onClick={() =>
                        _resubmitSignatureHandler("combineAchw9Signed")
                      }
                    >
                      Resubmit
                    </Button>
                  ) : null}
                  {(data?.agreementSignedPdf?.length && data?.w9SignedPdf?.length) ? (
                    <Button
                      className="remove"
                      color="link"
                      onClick={() =>
                        // handlePdfDownload(
                        //   data?.combineAchw9SignedPdf,
                        //   "combineAchw9SignedPdf"
                        // )
                        _combinedPdfDownloadHandler()
                      }
                    >
                      Download
                    </Button>
                  ) : null}
                </td>
              </tr>
            ) : null}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default ResubmitSignatureComponent;
