import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { RegexConfig } from "../../config/RegexConfig";
import { errorHandler, showToast } from "../../helper-methods";
import { addResource, editResource } from "../../http/http-calls";

const AddResources = ({ isOpen, toggle, fetchAllResources, editDetails }) => {
  const _closeModal = () => {
    toggle();
    setFormData({
      title: "",
      url: "",
    });
    setIsDirty({
      title: false,
      url: false,
    });
    setErrors({});
  };

  const [formData, setFormData] = useState({
    title: "",
    url: "",
  });

  const [isDirty, setIsDirty] = useState({
    title: false,
    url: false,
  });

  const [prevData, setPrevData] = useState({
    title: "",
    url: "",
  });
  const [errors, setErrors] = useState({});
  const [flag, setFlag] = useState(false);

  const _validateForm = (data, dirties) => {
    const newErrors = { ...errors };

    Object.keys(dirties).forEach((each) => {
      if (each === "title" && dirties[each]) {
        if (!data?.title?.length) {
          newErrors.title = "*Required";
        } else if (data.title.trim().length < 2 || data.title.length > 150) {
          newErrors.title =
            "*Title should be greater than 2 characters and less than 150 characters";
        } else {
          delete newErrors[each];
          dirties[each] = false;
        }
      } else if (each === "url" && dirties[each]) {
        if (!data?.url?.length) {
          newErrors.url = "*Required";
        } else if (
          data?.url.trim().length &&
          !new RegExp(RegexConfig.url).test(data?.url)
        ) {
          newErrors.url = "*Invalid Url";
        } else {
          delete newErrors[each];
          dirties[each] = false;
        }
      }
    });
    setIsDirty(dirties);
    setErrors(newErrors);
    return Object.keys(newErrors).length ? true : false;
  };

  const _handelOnChange = (field, event) => {
    const newFormData = { ...formData };
    const newIsDirty = { ...isDirty };

    newFormData[field] = event.target.value;

    newIsDirty[field] = true;
    setFormData(newFormData);
    setIsDirty(newIsDirty);
  };

  const _onBlurFormFields = (key) => {
    const newFormFields = { ...formData };
    const newIsDirty = {
      [key]: true,
    };
    _validateForm(newFormFields, newIsDirty);
  };

  const _categorySubmitHandler = async () => {
    const newIsDirty = {
      title: true,
      url: true,
    };

    const error = _validateForm(formData, newIsDirty);

    if (!error) {
      setFlag(true);
      if (editDetails) {
        try {
          await editResource(editDetails?._id, formData);

          fetchAllResources();
          showToast("Resource Updated Successfully", "success");
        } catch (error) {
          errorHandler(error);
        }
      } else {
        try {
          await addResource(formData);

          fetchAllResources();
          showToast("Resource Added Successfully", "success");
        } catch (error) {
          errorHandler(error);
        }
      }
      _closeModal();
      setFlag(false);
    }
  };

  useEffect(() => {
    if (editDetails) {
      setFormData({
        title: editDetails?.title || "",
        url: editDetails?.url || "",
      });
      setPrevData({
        title: editDetails?.title || "",
        url: editDetails?.url || "",
      });
    }
  }, [editDetails]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        scrollable
        centered
        className="modal-lg message-template"
        backdrop="static"
      >
        <ModalHeader toggle={() => _closeModal()}>
          {editDetails ? "Edit" : "Add"} Resources
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>Title</Label>
            <Input
              placeholder="Enter"
              value={formData?.title}
              disabled={flag}
              onChange={(event) => _handelOnChange("title", event)}
              onBlur={() => _onBlurFormFields("title")}
            />
            {errors.title ? (
              <div className="form-error">{errors.title}</div>
            ) : null}
          </FormGroup>
          <FormGroup>
            <Label>URL</Label>
            <Input
              type="url"
              value={formData?.url}
              disabled={flag}
              onChange={(event) => _handelOnChange("url", event)}
              onBlur={() => _onBlurFormFields("url")}
            />
            {errors.url ? <div className="form-error">{errors.url}</div> : null}
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="link" onClick={toggle}>
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={
              flag || editDetails
                ? JSON.stringify(prevData) === JSON.stringify(formData) || flag
                : flag
            }
            onClick={() => _categorySubmitHandler()}
          >
            {flag ? <i className="fa fa-spinner fa-spin mr-2" /> : null}{" "}
            {editDetails ? "Update" : "Add"}
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AddResources;
