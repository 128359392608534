import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import {
  deepClone,
  errorHandler,
  showToast,
  uploadFileOnServer,
} from "../../helper-methods";
import { addTopic, editTopic, fetchAllCategories } from "../../http/http-calls";
import SearchableInput from "../SearchableInput";
import FileDisplaySection from "../TopicComponent/FileDisplaySection";
import FileUploadSection from "../TopicComponent/FileUploadSection";

const AddTopics = ({ isOpen, toggle, fetchAllTopics, editDetails }) => {
  const [formData, setFormData] = useState({
    topicName: "",
    category: "",
    description: "",
    videoFiles: [],
    imageFiles: [],
    pdfFiles: [],
  });

  const [isDirty, setIsDirty] = useState({
    topicName: false,
    category: false,
    description: false,
  });

  const [prevData, setPrevData] = useState({
    topicName: "",
    category: "",
    description: "",
    videoFiles: [],
    imageFiles: [],
    pdfFiles: [],
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [categoryList, setCategoryList] = useState([]);

  const _closeModal = () => {
    toggle();
    setFormData({
      topicName: "",
      category: "",
      description: "",
      videoFiles: [],
      imageFiles: [],
      pdfFiles: [],
    });
    setIsDirty({});
    setPrevData({
      topicName: "",
      category: "",
      description: "",
      videoFiles: [],
      imageFiles: [],
      pdfFiles: [],
    });
    setErrors({});
    setLoading(false);
  };

  const _validateForm = (data, dirties) => {
    const newErrors = { ...errors };

    Object.keys(dirties).forEach((each) => {
      if (each === "topicName" && dirties[each]) {
        if (!data?.topicName?.length) {
          newErrors.topicName = "*Required";
        } else if (
          data.topicName.trim().length < 2 ||
          data.topicName.length > 150
        ) {
          newErrors.topicName =
            "*TopicName should be greater than 2 characters and less than 150 characters";
        } else {
          delete newErrors[each];
          dirties[each] = false;
        }
      } else if (each === "category" && dirties[each]) {
        if (!data?.category?.length) {
          newErrors.category = "*Required";
        } else {
          delete newErrors[each];
          dirties[each] = false;
        }
      } else if (each === "description" && dirties[each]) {
        if (!data?.description?.length) {
          newErrors.description = "*Required";
        } else {
          delete newErrors[each];
          dirties[each] = false;
        }
      }
    });
    setIsDirty(dirties);
    setErrors(newErrors);
    return Object.keys(newErrors).length ? true : false;
  };

  const _handelOnChange = (field, event) => {
    const newFormData = { ...formData };
    const newIsDirty = { ...isDirty };
    if (
      field === "videoFiles" ||
      field === "imageFiles" ||
      field === "pdfFiles"
    ) {
      if (!event?.target?.files?.length) return;

      const uploadData = event?.target?.files[0];
      let fileType = uploadData.type.split("/")[0];

      if (field === "imageFiles" && fileType !== "image") {
        showToast("Only Image file is allowed", "error");
        return;
      }
      if (field === "videoFiles" && fileType !== "video") {
        showToast("Only Video file is allowed", "error");
        return;
      }
      if (field === "pdfFiles") {
        fileType = uploadData.type.split("/")[1];

        if (fileType !== "pdf") {
          showToast("Only PDF file is allowed", "error");
          return;
        }
      }

      newFormData[field] = [
        {
          uploadData,
          previewBlob: URL.createObjectURL(uploadData),
          fileType,
          name: uploadData.name,
          forKeyName: field,
        },
      ];
    } else {
      if (field === "category") {
        newFormData[field] = event?.value;
      } else {
        newFormData[field] = event.target.value;
      }
    }

    newIsDirty[field] = true;
    setFormData(newFormData);
    setIsDirty(newIsDirty);
  };

  const _onBlurFormFields = (key) => {
    const newFormFields = { ...formData };
    const newIsDirty = {
      [key]: true,
    };
    _validateForm(newFormFields, newIsDirty);
  };

  const _topicSubmitHandler = async () => {
    try {
      const newIsDirty = {
        topicName: true,
        category: true,
        description: true,
      };

      const error = _validateForm(formData, newIsDirty);

      if (error) {
        return;
      }

      if (!formData?.videoFiles?.length) {
        showToast("Please Upload Content", "error");
        return;
      }

      setLoading(true);

      const payload = { ...formData };

      try {
        const uploadedData = [
          ...formData?.imageFiles,
          ...formData?.videoFiles,
          ...formData?.pdfFiles,
        ]?.filter((each) => each.uploadData);

        if (uploadedData?.length) {
          const res = await uploadFileOnServer(uploadedData);

          res.forEach((each) => {
            payload[each.forKeyName] = [
              {
                url: each.url,
                docType: each.contentType,
                title: each.name,
                duration: each.duration,
              },
            ];
          });
        }
      } catch (error) {
        console.log({ error });
      }

      if (editDetails) {
        await editTopic(editDetails?._id, payload);

        showToast("Topic Updated SuccessFully", "success");
      } else {
        await addTopic(payload);

        showToast("Topic Created SuccessFully", "success");
      }

      fetchAllTopics();
      _closeModal();
      setLoading(false);
    } catch (error) {
      errorHandler(error);
    }
  };

  const _onLoadedMetadata = (duration, index) => {
    const newFormData = deepClone(formData);
    newFormData.videoFiles[index].duration = duration;
    setFormData(newFormData);
  };

  const _deleteAfile = (field, index) => {
    const newFormData = deepClone(formData);
    newFormData[field].splice(index, 1);
    setFormData(newFormData);
  };

  const _fetchCategoryNames = async () => {
    try {
      const res = await fetchAllCategories();

      setCategoryList(
        res?.faqCategories?.map((each) => ({
          label: each?.category,
          value: each?._id,
        }))
      );
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    _fetchCategoryNames();
  }, []);

  useEffect(() => {
    if (editDetails) {
      setFormData({
        topicName: editDetails?.topicName || "",
        category: editDetails?._faqCategory?._id || "",
        description: editDetails?.content || "",
        videoFiles: editDetails?.videoFiles || [],
        imageFiles: editDetails?.imageFiles || [],
        pdfFiles: editDetails?.pdfFiles || [],
      });
      setPrevData({
        topicName: editDetails?.topicName || "",
        category: editDetails?._faqCategory?._id || "",
        description: editDetails?.content || "",
        videoFiles: editDetails?.videoFiles || [],
        imageFiles: editDetails?.imageFiles || [],
        pdfFiles: editDetails?.pdfFiles || [],
      });
    }
  }, [editDetails]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        scrollable
        centered
        className="modal-lg message-template"
        backdrop="static"
      >
        <ModalHeader toggle={() => _closeModal()}>
          {editDetails ? "Edit" : "Add"} Topics
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>Topic</Label>
            <Input
              placeholder="Enter"
              type="text"
              disabled={loading}
              value={formData?.topicName}
              onChange={(event) => _handelOnChange("topicName", event)}
              onBlur={() => _onBlurFormFields("topicName")}
            />
            {errors.topicName ? (
              <div className="form-error">{errors.topicName}</div>
            ) : null}
          </FormGroup>
          <FormGroup>
            <Label>Category</Label>

            <SearchableInput
              options={categoryList}
              value={
                categoryList?.filter(
                  (data) => data?.value === formData?.category
                )[0]
              }
              disabled={loading}
              onBlur={() => _onBlurFormFields("category")}
              onChange={(value) => _handelOnChange("category", value)}
            />

            {errors.category ? (
              <div className="form-error">{errors.category}</div>
            ) : null}
          </FormGroup>
          <FormGroup>
            <Label>Content</Label>
            <Input
              placeholder="Enter"
              type="textarea"
              rows="4"
              disabled={loading}
              value={formData?.description}
              onChange={(event) => _handelOnChange("description", event)}
              onBlur={() => _onBlurFormFields("description")}
            />
            {errors.description ? (
              <div className="form-error">{errors.description}</div>
            ) : null}
          </FormGroup>

          <Label>Upload Content</Label>
          <div className="topicUploadWrapper">
            <FileUploadSection
              disabled={loading}
              _handelOnChange={_handelOnChange}
            />
            <FileDisplaySection
              formData={formData}
              deleteAfile={_deleteAfile}
              onLoadedMetadata={_onLoadedMetadata}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="link" onClick={toggle}>
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={
              editDetails
                ? loading ||
                  JSON.stringify(prevData) === JSON.stringify(formData)
                : loading
            }
            onClick={() => _topicSubmitHandler()}
          >
            {loading ? <i className="fa fa-spinner fa-spin mr-2" /> : null}{" "}
            {editDetails ? "Update" : "Add"}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AddTopics;
