import React from "react";
import {
  capitalize,
  copyToClipboard,
  formatCurrencyValue,
  formatDate,
  formatDateMonthYear,
  getLeadsProjectStatusLabel,
} from "../helper-methods";
import { Button, Table, UncontrolledTooltip } from "reactstrap";
import { useNavigate } from "react-router-dom";

const AffiliateReferredClients = ({
  leadRefer,
  _checkLeaderTypeCommission,
  _toggleLeadNotesModal,
  _calculateTotalClientsPayout,
  userType,
}) => {
  const navigate = useNavigate();
  return (
    <div className="tableInCardWrap">
      <div className="tableCardHeader">
        <h3>
          Referred Clients{" "}
          <img
            src={`/assets/img/referred_clients.png`}
            alt=""
            width="16"
            className="ms-1"
          />
        </h3>

        <h6>
          Estimated Earnings
          <span className="text-success">
            {formatCurrencyValue(
              _calculateTotalClientsPayout(leadRefer, "leadReferComission")
            )}
          </span>
        </h6>
      </div>

      <div className="tableWrapper">
        <Table responsive>
          <thead>
            <tr>
              <th>Name</th>
              <th>Company Name</th>
              <th>Date</th>
              <th>Number of Employees</th>
              <th>Qualified Month</th>
              <th>Estimated Commissions</th>
              <th>Leader 1 Comm.</th>
              <th>Leader 2 Comm.</th>
              <th>Level 1 Comm.</th>
              <th>Level 2 Comm.</th>
              <th>Notes</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {leadRefer?.length ? (
              leadRefer.map((each) => (
                <tr key={each?._id}>
                  <td className="text-capitalize">
                    {each?._client?.name?.full ? (
                      <div
                        className="themeColor cursorPointer"
                        onClick={() =>
                          navigate(`/${userType}/client/${each?._client?._id}`)
                        }
                      >
                        {capitalize(each?._client?.name?.full)}
                      </div>
                    ) : (
                      "N/A"
                    )}

                    <div
                      className="d-flex text-dark cursorPointer"
                      style={{ marginTop: 7 }}
                    >
                      {each?._client?.phone && (
                        <>
                          <i
                            className="fas fa-phone-alt fs-12"
                            id={`user_phone_${each?._client?._id}`}
                            onClick={() =>
                              copyToClipboard(
                                `${each?._client?.countryCode}${each?._client?.phone}`
                              )
                            }
                          />
                          <UncontrolledTooltip
                            target={`user_phone_${each?._client?._id}`}
                            placement="bottom"
                          >
                            {each?._client?.countryCode} {each?._client?.phone}
                          </UncontrolledTooltip>
                        </>
                      )}
                      {each?._client?.email && (
                        <>
                          <i
                            className="far fa-envelope mx-3"
                            id={`user_email_${each?._client?._id}`}
                            onClick={() =>
                              copyToClipboard(each?._client?.email)
                            }
                          />
                          <UncontrolledTooltip
                            target={`user_email_${each?._client?._id}`}
                            placement="bottom"
                          >
                            {each?._client?.email}
                          </UncontrolledTooltip>
                        </>
                      )}
                    </div>
                  </td>
                  <td>{each?._client?.companyName || "N/A"}</td>
                  <td>{formatDate(each?._client?.createdAt) || "N/A"}</td>
                  <td>{each?._client?.qualifiedEmployees}</td>
                  <td>
                    {formatDateMonthYear(each?._client?.qualifiedDate) || "N/A"}
                  </td>
                  <td>
                    {formatCurrencyValue(_checkLeaderTypeCommission(each))}
                  </td>
                  <td>
                    {formatCurrencyValue(
                      each?._client?.estimateEarning?.firstLeaderComission
                        ?.comission
                    )}{" "}
                    (PEPM)
                  </td>
                  <td>
                    {formatCurrencyValue(
                      each?._client?.estimateEarning?.secondLeaderComission
                        ?.comission
                    )}{" "}
                    (PEPM)
                  </td>
                  <td>
                    {formatCurrencyValue(
                      each?._client?.estimateEarning?.leadReferComission
                        ?.comission
                    )}{" "}
                    (PEPM)
                  </td>
                  <td>
                    {formatCurrencyValue(
                      each?._client?.estimateEarning?.affiliatesReferComission
                        ?.comission
                    )}{" "}
                    (PEPM)
                  </td>
                  <td>
                    <Button
                      color="link"
                      className="me-1"
                      onClick={() => _toggleLeadNotesModal(true, each)}
                    >
                      <i
                        className="far fa-sticky-note me-1"
                        style={{ verticalAlign: -0.5 }}
                      />
                      {each._client?.notesCount || 0}
                    </Button>
                  </td>
                  <td>
                    {getLeadsProjectStatusLabel(
                      each?._client?.leadsProjectStatus
                    ) || "N/A"}
                  </td>
                </tr>
              ))
            ) : (
              <tr className="text-center">
                <td colSpan="12">
                  <div className="nodata">
                    <img
                      src={require("../assets/img/noData.svg").default}
                      alt="no data"
                    />
                    <p>There is no data to display</p>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default AffiliateReferredClients;
