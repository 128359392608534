import React, { useMemo } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Spinner,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";
import CustomDateRangePicker from "../../components/CustomDateRangePicker";
import ErrorBoundary from "../../components/ErrorBoundary";
import OrgChartComponent from "../../components/OrgChartComponent";
import SkeletonLoading from "../../components/SkeletonLoading";
import SvgIcons from "../../components/SvgIcons";
import {
  dashboardCardConfig,
  refereAffiliateConfig,
  referredLeadsConfig,
} from "../../config/helper-config";
import {
  errorHandler,
  formatNumberInShort,
  openUrlInSameTab,
} from "../../helper-methods";
import {
  getAffiliateInfo,
  getAffiliateLeaderTreeData,
  getAffiliateUserTreeData,
  getTreeData,
} from "../../http/http-calls";
import { updateUserData } from "../../redux/actions/user-credential";

const Dashboard = () => {
  const userCredential = useSelector((state) => state?.userCredential);
  const dispatch = useDispatch();

  const userType = useMemo(
    () => userCredential?.user?.userType?.toLowerCase(),
    [userCredential]
  );

  const affiliateType = useMemo(
    () => userCredential?.user?.affiliateType?.toLowerCase(),
    [userCredential]
  );

  const customDateRangePickerRef = useRef({ current: null });

  const [treeData, setTreeData] = useState(null);
  const [totalCount, setTotalCount] = useState({
    totalAffiliates: 0,
    totalEmployer: 0,
    totalEstimatedEmployee: 0,
    totalQualifiedEmployees: 0,
  });
  const [loading, setLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filters, setFilters] = useState({
    search: "",
    startDate: null,
    endDate: null,
    referLeadCount: "",
    refereAffiliateCount: "",
  });
  const [isErrorInOrgChart, setIsErrorInOrgChart] = useState(false);

  const _onChangeFilters = (key, value) => {
    const newFilters = { ...filters };
    newFilters[key] = value;
    setFilters(newFilters);
  };

  const _onDatesChange = ({ startDate, endDate }) => {
    const newFilters = { ...filters };
    newFilters.startDate = startDate;
    newFilters.endDate = endDate;
    setFilters(newFilters);

    // if ((!startDate && !endDate) || (startDate && endDate)) {
    //   _onFiltersUpdated(newFilters);
    // }
  };

  const _clearFilters = () => {
    if (customDateRangePickerRef?.current?.resetState) {
      customDateRangePickerRef.current.resetState();
    }

    const newFilters = { ...filters };
    Object.keys(newFilters).forEach((key) => {
      newFilters[key] = "";
    });
    setFilters(newFilters);
  };

  const _getAffiliateInfo = async () => {
    try {
      const res = await getAffiliateInfo();

      dispatch(
        updateUserData({
          user: userCredential?.user,
          notificationCount: userCredential?.notificationCount,
          showHeader: res.user?.isVerified || res?.user?.completedAllTopic,
        })
      );

      if (
        res?.user?.isVerified &&
        // res?.user?.isAddedByForm &&
        res?.user?.isAcceptForSignature &&
        res?.user?.affiliateType !== "Leader" &&
        res?.user?.isVerifiedForDocuSign
      ) {
        if (res?.user?.stageOfSign === "noSigned") {
          openUrlInSameTab(
            `/docu-sign-accept-agreement/${userCredential?.token}`
          );
        } else if (res?.user?.stageOfSign !== "combineAchw9Signed") {
          localStorage.temp_token = userCredential?.token;
          openUrlInSameTab(
            `/agreementsigned/${userCredential?.user?._id}?event=signing_complete`
          );
        }
      } else {
        if (
          res?.user?.isVerified &&
          res?.user?.stageOfSign !== "combineAchw9Signed" &&
          res?.user?.affiliateType === "Leader" &&
          res?.user?.isVerifiedForDocuSign
        ) {
          localStorage.temp_token = userCredential?.token;
          openUrlInSameTab(
            `/agreementsigned/${userCredential?.user?._id}?event=signing_complete`
          );
        }
      }
      if (res?.user?.isVerified && !res?.user?.isAddedByForm) {
        dispatch(
          updateUserData({
            user: userCredential?.user,
            notificationCount: userCredential?.notificationCount,
            showHeader: true,
          })
        );
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const _getTreeData = async () => {
    try {
      setLoading(true);

      let res;

      if (userType === "affiliate") {
        if (affiliateType === "leader") {
          res = await getAffiliateLeaderTreeData();
        } else {
          res = await getAffiliateUserTreeData();
        }
      } else {
        res = await getTreeData();
      }

      setTreeData(res?.genealogyTrees);
      setTotalCount({
        totalAffiliates: res?.totalAffiliates || 0,
        totalEmployer: res?.totalEmployer || 0,
        totalEstimatedEmployee: res?.totalEstimatedEmployee || 0,
        totalQualifiedEmployees: res?.totalQualifiedEmployees || 0,
      });

      setLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    _getTreeData();
    _getAffiliateInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="pgTitleWrap">
        <h2>Dashboard {loading ? <Spinner /> : null}</h2>

        {!isErrorInOrgChart ? (
          <>
            <div className="right">
              <div
                className="filterBarIcon"
                onClick={() => setShowFilter(!showFilter)}
              >
                <SvgIcons type={"filter"} />
              </div>
              <Button
                color="link"
                className="btn-reset"
                onClick={() => _clearFilters()}
              >
                <SvgIcons type={"reset"} />
              </Button>
            </div>
          </>
        ) : null}
      </div>

      {!isErrorInOrgChart ? (
        <>
          <div
            className={`filterWrapper ${
              showFilter === true ? "showFilter" : ""
            }`}
          >
            <i className="fas fa-filter filterIcon" />

            <div className="filterForm">
              <div className="formGroup searchBar">
                <Label>Search</Label>
                <InputGroup className="searchTable">
                  <Input
                    type="text"
                    name="search"
                    placeholder="Search by name"
                    autoComplete="off"
                    value={filters.search}
                    disabled={loading}
                    onChange={(e) => _onChangeFilters("search", e.target.value)}
                  />
                  <InputGroupText>
                    {filters.search ? (
                      <i
                        className="fa fa-times"
                        onClick={() => _onChangeFilters("search", "")}
                      />
                    ) : (
                      <i className="fa fa-search" />
                    )}
                  </InputGroupText>
                </InputGroup>
              </div>

              <div className="formGroup">
                <Label>Added Date</Label>

                <CustomDateRangePicker
                  ref={customDateRangePickerRef}
                  startDate={filters?.startDate}
                  endDate={filters?.endDate}
                  startDateId={"startDateId_LeadPageFilter"}
                  endDateId={"endDateId_LeadPageFilter"}
                  onDatesChange={_onDatesChange}
                  disabled={loading}
                />
              </div>

              <div className="formGroup">
                <Label>Referred Clients</Label>
                <Input
                  type="select"
                  value={filters.referLeadCount}
                  name="referLeadCount"
                  disabled={loading}
                  onChange={(e) =>
                    _onChangeFilters("referLeadCount", e.target.value)
                  }
                >
                  <option value="">All</option>
                  {referredLeadsConfig.map((each, index) => (
                    <option
                      key={`referLeadCount_${index}`}
                      value={JSON.stringify(each.value)}
                    >
                      {each.label}
                    </option>
                  ))}
                </Input>
              </div>

              <div className="formGroup">
                <Label>Referred Affiliates</Label>
                <Input
                  type="select"
                  value={filters.refereAffiliateCount}
                  disabled={loading}
                  name="refereAffiliateCount"
                  onChange={(e) =>
                    _onChangeFilters("refereAffiliateCount", e.target.value)
                  }
                >
                  <option value="">All</option>
                  {refereAffiliateConfig.map((each, index) => (
                    <option
                      key={`refereAffiliateCount_${index}`}
                      value={JSON.stringify(each.value)}
                    >
                      {each.label}
                    </option>
                  ))}
                </Input>
              </div>
            </div>
          </div>
        </>
      ) : null}

      <Row>
        <Col xl="9" lg="8" md="12">
          <div className="cardWrap">
            {treeData && !isErrorInOrgChart ? (
              <ErrorBoundary>
                <OrgChartComponent
                  data={treeData}
                  filters={filters}
                  reset={_clearFilters}
                  setIsErrorInOrgChart={setIsErrorInOrgChart}
                />
              </ErrorBoundary>
            ) : loading ? (
              <SkeletonLoading type="box" height={500} />
            ) : (
              <div className="p-4 text-center">
                There is no data to display.
              </div>
            )}
          </div>
        </Col>

        <Col xl="3" lg="4" md="12">
          <div className="dashboard-analytics">
            {dashboardCardConfig?.map((each) => (
              <Card
                className="analyticsCard"
                key={`dashboardCardConfig_${each.value}`}
              >
                <CardBody>
                  <div className="analyticsWrap">
                    <div className="amountNumber">
                      {loading ? (
                        <SkeletonLoading
                          width={Math.floor(Math.random() * 50 + 50)}
                          type="box"
                        />
                      ) : (
                        formatNumberInShort(totalCount?.[each.value])
                      )}
                    </div>
                    <h2>{each.label}</h2>
                  </div>

                  <div className="bgIcon one">
                    <img src={each.imgSrc} alt="user" width="40px" />
                  </div>
                </CardBody>
              </Card>
            ))}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
