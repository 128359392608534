import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";
import {
  capitalize,
  formatDate,
  getLeadsProjectStatusLabel,
} from "../../helper-methods";

const AffiliatesClientsModal = ({
  isOpen,
  data,
  isHideClientName = false,
  toggle,
}) => {
  const navigate = useNavigate();

  const userCredential = useSelector((state) => state?.userCredential);

  const userType = useMemo(
    () => userCredential?.user?.userType?.toLowerCase(),
    [userCredential]
  );

  const _closeModal = () => {
    toggle();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => _closeModal()}
      scrollable
      centered
      backdrop={false}
    >
      <ModalHeader toggle={() => _closeModal()}>Referred Clients</ModalHeader>
      <ModalBody>
        <div className="tableWrapper mb-0 shadow-none">
          <Table responsive>
            <thead>
              <tr>
                <th>Name</th>
                <th>Qualified Employees</th>
                <th>Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {data?.length ? (
                data.map((each) => (
                  <tr key={each?._id}>
                    <td className="text-capitalize">
                      {isHideClientName ? (
                        "XXXXX"
                      ) : each?._client?.name?.full ? (
                        <div
                          className="themeColor cursorPointer"
                          onClick={() =>
                            navigate(
                              `/${userType}/client/${each?._client?._id}`
                            )
                          }
                        >
                          {capitalize(each?._client?.name?.full)}
                        </div>
                      ) : (
                        "N/A"
                      )}
                    </td>
                    <td>{each._client?.qualifiedEmployees}</td>
                    <td>{formatDate(each?._client?.createdAt) || "N/A"}</td>
                    <td>
                      {getLeadsProjectStatusLabel(
                        each?._client?.leadsProjectStatus
                      ) || "N/A"}
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="text-center">
                  <td colSpan="3">
                    <div className="nodata">
                      <img
                        src={require("../../assets/img/noData.svg").default}
                        alt="no data"
                      />
                      <p>There is no data to display</p>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="link" className="closeBtn" onClick={() => _closeModal()}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AffiliatesClientsModal;
