import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import PublicFooter from "../../containers/Public/PublicFooter";
import {
  decodeToken,
  errorHandler,
  extractQueryParams,
  openUrlInSameTab,
} from "../../helper-methods";
import {
  // docuSignAchSignature,
  docuSignAchandW9Signature,
  docuSignCheckStatus,
  getUserById,
  // w9SignAchSignature,
} from "../../http/http-calls";
import { hideLoader, showLoader } from "../../redux/actions/loader-data";
import { Button } from "reactstrap";
import { addUserCredential } from "../../redux/actions/user-credential";
import AccepctAggrimentModal from "../../components/modals/AccepctAggrimentModal";

const DocuSignSuccessPage = () => {
  const params = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [isError, setIsError] = useState(false);
  // const [stageOfSign, setStageOfSign] = useState("");
  // const [stageOfResubmit, setstageOfResubmit] = useState("");
  const [agreementModal, setAgreementModal] = useState(false);
  const [affiliateType, setAffiliateType] = useState("");

  const _docuSignCheckStatus = async () => {
    try {
      const { id } = params;
      let { event } = extractQueryParams();
      dispatch(showLoader("Loading..."));

      const res = await docuSignCheckStatus({ id, payload: { event: event } });
      localStorage.temp_token = res?.token;
      const decodedToken = decodeToken(res?.token);
      // setStageOfSign(decodedToken?.stageOfSign);
      // setstageOfResubmit(decodeToken?.checkReSubmitDcoument);
      if (decodedToken?.stageOfSign === "combineAchw9Signed") {
        let userData;
        if (!res.user) {
          userData = decodeToken(res.token);
          res.user = userData;
        }
        dispatch(
          addUserCredential({
            token: res.token,
            user: res.user,
            showHeader: true,
          })
        );
        navigate(`/${res.user?.userType?.toLowerCase()}/training-user`);
      } else {
        if (decodedToken?.affiliateType === "Leader") {
          await _getUserData(res?.token);
        }
      }

      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      errorHandler(error);
      setIsError(true);
    }
  };

  const _combinedSignatureSubmitHandler = async () => {
    try {
      dispatch(showLoader("Loading..."));
      const res = await docuSignAchandW9Signature({ isTempToken: true });
      dispatch(hideLoader());
      delete localStorage.temp_token;
      openUrlInSameTab(res?.basicUrl?.redirectUrl);
    } catch (error) {
      dispatch(hideLoader());
      errorHandler(error);
    }
  };

  // const _w9SignatureSubmitHandler = async () => {
  //   try {
  //     dispatch(showLoader("Loading..."));
  //     const res = await w9SignAchSignature({ isTempToken: true });
  //     dispatch(hideLoader());
  //     delete localStorage.temp_token;
  //     openUrlInSameTab(res?.basicUrl?.redirectUrl);
  //   } catch (error) {
  //     dispatch(hideLoader());
  //     errorHandler(error);
  //   }
  // };

  const _getUserData = async (token) => {
    const decodedToken = decodeToken(token);

    try {
      const res = await getUserById(decodedToken?._id, { isTempToken: true });
      if (
        !res?.user?.isAcceptForSignature &&
        res?.user?.affiliateType === "Leader" &&
        res?.user?.isVerified
      ) {
        setAffiliateType(res?.user?.affiliateType);
        setAgreementModal(true);
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("temp_token");
    if (token) {
      // const decodedToken = decodeToken(token);
      // setStageOfSign(decodedToken?.stageOfSign);
      // setstageOfResubmit(decodedToken?.checkReSubmitDcoument);
      _getUserData(token);
    } else {
      _docuSignCheckStatus();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="docSignStatus">
        {isError ? (
          <p className="text-danger">Unauthorized</p>
        ) : (
          <div>
            <h1 className="title">
              {/* <img
                src={require("../../assets/img/checked.png")}
                alt=""
              /> */}
              Please Click This Button To Fill the ACH and W9{" "}
              {/* {stageOfSign === "agreementSigned" || stageOfResubmit === "Ach"
                ? "ACH"
                : stageOfSign === "achSigned" || stageOfResubmit === "w9"
                ? "W9"
                : null} */}{" "}
              Form!
            </h1>
            {/* <p>Your document has been successfully signed and submitted.</p> */}
            <p>
              Thank you for choosing DocuSign for your electronic signature
              needs.
            </p>
            {/* {stageOfSign === "agreementSigned" || stageOfResubmit === "Ach" ? (
              <Button
                color="primary"
                className="mt-3"
                onClick={() => _achSignatureSubmitHandler()}
              >
                {" "}
                Proceed for ACH form
              </Button>
            ) : stageOfSign === "achSigned" || stageOfResubmit === "w9" ? (
              <Button
                color="primary"
                className="mt-3"
                onClick={() => _w9SignatureSubmitHandler()}
              >
                {" "}
                Proceed for W9 form
              </Button>
            ) : null} */}

            <Button
              color="primary"
              className="mt-3"
              onClick={() => _combinedSignatureSubmitHandler()}
            >
              {" "}
              Proceed for ACH and W9 form
            </Button>

            <AccepctAggrimentModal
              isOpen={agreementModal}
              toggle={() => setAgreementModal(false)}
              affiliateType={affiliateType}
            />
          </div>
        )}
      </div>

      <PublicFooter />
    </>
  );
};

export default DocuSignSuccessPage;
