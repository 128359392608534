import React, { useState } from "react";
import { Button, Input } from "reactstrap";
import {
  capitalize,
  copyToClipboard,
  errorHandler,
  formatCurrencyValue,
  showToast,
} from "../helper-methods";
import { useNavigate } from "react-router-dom";
import ReferralCodeAndLinkList from "./ReferralCodeAndLinkList";
import { updateUser } from "../http/http-calls";

const AffiliatePersonalInfo = ({
  data,
  setData = () => {},
  getUserById = () => {},
  _toggleAddUsersModal,
  _toggleResetUserPasswordModal,
  userType,
}) => {
  const navigate = useNavigate();

  const [verificationLoading, setVerificationLoading] = useState(false);

  const _onChangeVerification = async (user, status) => {
    try {
      if (!user?._id) {
        errorHandler({ reason: "User data not found" });
        return;
      }

      setVerificationLoading(true);

      const payload = {
        isVerified: status === "verified" ? true : false,
      };

      const newData = { ...data };
      newData.isVerified = payload.isVerified;
      setData(newData);

      // await updateUserVerification(user._id);
      await updateUser({ id: user._id, payload });

      setVerificationLoading(false);

      showToast("Verification has been updated", "success");
    } catch (error) {
      errorHandler(error);
      setVerificationLoading(false);
      getUserById();
    }
  };

  return (
    <div className="cardWrap personalInfo">
      <div className="cardHeader d-flex justify-content-between">
        Personal Info
        <Button
          className="editBtn"
          color="link"
          onClick={() => _toggleAddUsersModal(true, data)}
        >
          <i className="fas fa-pencil-alt" />
        </Button>
      </div>
      <div className="cardBody">
        <h6 className="heading">
          {data?.name?.full ? capitalize(data?.name?.full) : "N/A"}
        </h6>
        <div className="contactInfo">
          <ul>
            <li>
              Verification :{" "}
              <div className="ms-1">
                {userType === "admin" ? (
                  <Input
                    style={{ width: 150 }}
                    type="select"
                    disabled={verificationLoading ? true : false}
                    value={data?.isVerified ? "verified" : "notVerified"}
                    onChange={(e) =>
                      _onChangeVerification(data, e.target.value)
                    }
                  >
                    <option value="verified">Verified</option>
                    <option value="notVerified">Not Verified</option>
                  </Input>
                ) : (
                  <>{data?.isVerified ? "Verified" : "Not Verified"}</>
                )}
              </div>
            </li>
            <li>
              Referred By :{" "}
              {data?.isAddedByAdmin ? (
                "Admin"
              ) : data?._referBy?.name?.full ? (
                <span
                  className="ms-1 themeColor cursorPointer"
                  onClick={() =>
                    navigate(`/${userType}/affiliate/${data?._referBy?._id}`)
                  }
                >
                  {capitalize(data?._referBy?.name?.full)}
                </span>
              ) : (
                "N/A"
              )}
            </li>
            {data?.affiliateType === "Leader" ? (
              <>
                {data?._organization?._primaryLeader?.id === data?.id ? (
                  <>
                    {/* not needed to show here */}
                    {/* {data?._organization?._secondaryLeader?.name
                    ?.full && (
                    <li>
                      Secondary Leader :{" "}
                      {
                        data?._organization?._secondaryLeader?.name
                          ?.full
                      }
                    </li>
                  )} */}
                  </>
                ) : (
                  <>
                    <li>
                      Primary Leader :{" "}
                      {data?._organization?._primaryLeader?.name?.full || "N/A"}
                    </li>
                  </>
                )}
              </>
            ) : (
              <>
                <li>
                  Primary Leader :{" "}
                  {data?._organization?._primaryLeader?.name?.full || "N/A"}
                </li>
                {data?._organization?._secondaryLeader?.length ? (
                  <li>
                    Secondary Leaders:{" "}
                    {Array.from(
                      new Set(
                        data?._organization?._secondaryLeader.map(
                          (data) => data?.name?.full
                        )
                      )
                    ).join(", ")}
                  </li>
                ) : null}
              </>
            )}
            <li>
              {data?.affiliateType === "Leader"
                ? "Organization Commission"
                : "PEPM Commission"}{" "}
              : {formatCurrencyValue(data?.commissionLevel1 || 0)}
            </li>
            {data?.affiliateType !== "Leader" ? (
              <li>
                Override : {formatCurrencyValue(data?.commissionLevel2 || 0)}
              </li>
            ) : null}
            <li>Organization Name : {data?.organizationName || "N/A"}</li>
            <li>
              <i
                className="fas fa-link fs-12"
                style={{ width: 20, color: "#666" }}
              />
              <Button
                color="link"
                className="px-0"
                onClick={() => _toggleResetUserPasswordModal(true, data)}
              >
                Reset Password
              </Button>
            </li>
            {/* <li>
              <img
                className="telegramIcon"
                src={require("../assets/img/telegram.png")}
                alt="icon"
                onClick={() => window.open("https://t.me/+97lWkZe6IfJhOWQx")}
              />{" "}
              <span
                className="text-primary cursorPointer"
                onClick={() => window.open("https://t.me/+97lWkZe6IfJhOWQx")}
              >
                Join Community
              </span>
            </li> */}
          </ul>
          <ul className="right">
            <li>
              <i className="fas fa-phone-alt" />
              <span
                onClick={() =>
                  copyToClipboard(`${data?.countryCode || ""}${data?.phone}`)
                }
              >
                {data?.countryCode} {data?.phone || "N/A"}
              </span>
            </li>
            <li>
              <i className="far fa-envelope" />
              <span
                className="value"
                onClick={() => copyToClipboard(data.email)}
              >
                {data?.email || "N/A"}
              </span>
            </li>
            <li>
              <i className="fas fa-map-marker-alt" />
              <span>{data?.location || "N/A"}</span>
            </li>

            <ReferralCodeAndLinkList data={data} />
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AffiliatePersonalInfo;
