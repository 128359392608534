import React, { useMemo, useState } from "react";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {
  errorHandler,
  formatNumberInShort,
  showToast,
} from "../../helper-methods";
import { getQuizTestForUser, submitQuizAnswer } from "../../http/http-calls";
import CustomTooltip from "../custom/CustomTooltip";
import SvgIcons from "../SvgIcons";

// eslint-disable-next-line
const QuizTestModal = ({ isOpen, data, toggle, onSuccess = () => {} }) => {
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(1);
  const [selectedOption, setSelectedOption] = useState(0);
  const [currentPercentageScored, setCurrentPercentageScored] = useState(0);
  const [hasPassed, setHasPassed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isReadAndUnderstood, setIsReadAndUnderstood] = useState(false);

  const isQuizCompleted = useMemo(() => {
    return questions?.length && questions?.length < currentQuestionIndex;
  }, [questions, currentQuestionIndex]);

  const currentQuestion = useMemo(() => {
    return questions?.length && questions?.[currentQuestionIndex - 1];
  }, [questions, currentQuestionIndex]);

  const _resetModalState = () => {
    setQuestions([]);
    setCurrentQuestionIndex(1);
    setSelectedOption(0);
    setCurrentPercentageScored(0);
    setHasPassed(false);
    setLoading(false);
    setIsReadAndUnderstood(false);
  };

  const _closeModal = () => {
    if (isQuizCompleted) {
      onSuccess();
    }
    _resetModalState();
    toggle();
  };

  const _submitQuizAnswer = async () => {
    try {
      if (isQuizCompleted) return;

      if (!selectedOption) {
        showToast("Please select option to proceed", "error");
        return;
      }

      setLoading(true);

      const payload = {
        questionId: currentQuestion?._id,
        optionNo: selectedOption,
        totalQuestion: questions?.length,
      };

      if (currentQuestionIndex === 1) {
        payload.isQuizStart = true;
      }
      if (currentQuestionIndex === questions?.length) {
        payload.isQuizDone = true;
      }

      const res = await submitQuizAnswer(payload);

      setCurrentQuestionIndex((prev) => ++prev);
      setSelectedOption(0);
      setCurrentPercentageScored(res.currentPercentageScored);
      setHasPassed(res.hasPassed);

      setLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  const _getQuizTestForUser = async () => {
    try {
      setLoading(true);

      const res = await getQuizTestForUser();
      setQuestions(res.questions);

      setLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        scrollable
        centered
        className="modal-lg message-template"
        backdrop="static"
      >
        <ModalHeader>Qualification Quiz</ModalHeader>

        <ModalBody>
          {isQuizCompleted ? (
            <>
              {hasPassed ? (
                <div className="beforeWarning">
                  <SvgIcons type={"success"} id="success" />
                  <p>
                    Congrats on scoring{" "}
                    {formatNumberInShort(currentPercentageScored)}% on the quiz!
                    Your account is now pending admin approval for promotion and
                    is in "Pending" status until authorized. Thanks for your
                    patience during this process, and well done on passing! We
                    recommend completing all the unlocked training material as
                    you wait for promotional approval.
                  </p>
                </div>
              ) : (
                <div className="beforeWarning">
                  <SvgIcons type={"success"} id="success" />
                  <p className="my-1">
                    Thank you for submitting the test. Unfortunately, your
                    performance fell below the passing grade, resulting in a
                    score of {formatNumberInShort(currentPercentageScored)}%.
                  </p>
                  <p className="my-1">
                    {data?.failedInQuiz < 1 ? (
                      <b>
                        You have one final attempt remaining. Return to the home
                        page to seize this chance and retry the quiz.
                      </b>
                    ) : null}
                  </p>
                </div>
              )}
            </>
          ) : (
            <>
              {questions?.length && currentQuestion ? (
                <>
                  <div className="mb-2">
                    Questions ({currentQuestionIndex} / {questions?.length})
                  </div>

                  <p>{currentQuestion?.question}</p>

                  {currentQuestion?.options?.map((option, optionIndex) => (
                    <div
                      className="QuestionQuizWrap"
                      key={currentQuestion?._id + optionIndex}
                    >
                      <Input
                        type="radio"
                        name="rightOption"
                        value={optionIndex + 1}
                        id={currentQuestion?._id + optionIndex}
                        checked={selectedOption === optionIndex + 1}
                        onChange={() => setSelectedOption(optionIndex + 1)}
                      />
                      <Label for={currentQuestion?._id + optionIndex}>
                        {option?.answerText}
                      </Label>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <div className="beforeWarning">
                    <SvgIcons type="warning" id="warning" />
                    <p>Instruction:</p>
                    <p>
                      {/* Click 'Start quiz' to begin. Don't leave, reload, or close
                      the tab/window to avoid test failure. Stay until the end
                      for accurate results. */}
                      You're all set to start your quiz! To kick things off,
                      please click on the "Start Quiz" button. Remember, once
                      you've begun, it's important to stay on the quiz page.
                      Avoid leaving, opening new browser tabs, or closing the
                      quiz window, as this could lead to errors or failure. Take
                      your time answering the multiple-choice questions. Good
                      luck! When you pass, your account will be reviewed by an
                      administrator for approval.
                    </p>
                    {data?.failedInQuiz ? (
                      <p>Please note that this is your final attempt.</p>
                    ) : null}
                    <p>Thank you!</p>

                    <FormGroup>
                      <Input
                        id="I_have_read_and_understood"
                        type="checkbox"
                        checked={isReadAndUnderstood}
                        onChange={(e) =>
                          setIsReadAndUnderstood(e.target.checked)
                        }
                      />{" "}
                      <Label for="I_have_read_and_understood">
                        I have read and understood.{" "}
                        <sup
                          className="cursorPointer"
                          id="I_have_read_and_understood_Tooltip"
                        >
                          <i className="fa fa-info-circle" />
                        </sup>
                      </Label>
                    </FormGroup>
                    <CustomTooltip
                      target={"I_have_read_and_understood_Tooltip"}
                      text="Attention: Before proceeding, please check the box to confirm that you have read and understood the warning"
                    />
                  </div>
                </>
              )}
            </>
          )}
        </ModalBody>

        <ModalFooter>
          {isQuizCompleted ? (
            <>
              <Button color="link" onClick={() => _closeModal()}>
                Close
              </Button>
            </>
          ) : (
            <>
              {questions?.length ? (
                <>
                  <Button
                    color="primary"
                    disabled={loading}
                    onClick={() => _submitQuizAnswer()}
                  >
                    {loading ? (
                      <i className="fa fa-spinner fa-spin mr-2" />
                    ) : null}{" "}
                    {currentQuestionIndex === questions.length
                      ? "Submit"
                      : "Next"}
                  </Button>
                </>
              ) : (
                <>
                  <Button color="link" onClick={() => _closeModal()}>
                    Do it later
                  </Button>
                  <Button
                    color="primary"
                    onClick={_getQuizTestForUser}
                    disabled={loading || isReadAndUnderstood === false}
                  >
                    {loading ? (
                      <i className="fa fa-spinner fa-spin mr-2" />
                    ) : null}{" "}
                    Start Quiz
                  </Button>
                </>
              )}
            </>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default QuizTestModal;
