import React from "react";
import { Button, PopoverBody, Table, UncontrolledPopover } from "reactstrap";
import { formatDate, formatTime } from "../helper-methods";
import ReadMoreReadLess from "./ReadMoreReadLess";
import { useNavigate } from "react-router-dom";

const LeadMessagesTable = ({ activities, userType, data }) => {
  const navigate = useNavigate();
  return (
    <div className="tableInCardWrap">
      <div className="tableCardHeader">
        Messages
        <Button onClick={() => navigate(`/${userType}/message/${data?._id}`)}>
          Send Message
        </Button>
      </div>

      <div className="tableWrapper">
        <Table responsive>
          <thead>
            <tr>
              <th>Type</th>
              <th>Time</th>

              <th>Message</th>
              <th>By</th>
            </tr>
          </thead>
          <tbody>
            {activities?.length ? (
              activities.map((each) => (
                <tr key={each?._id}>
                  <td>{each.activityType || "N/A"}</td>
                  <td>
                    {formatDate(each.createdAt)} <br />
                    {formatTime(each.createdAt)}
                  </td>
                  <td>
                    {each.message ? (
                      <>
                        <div
                          id={`newMessageText_${each._id}`}
                          className="themeColor cursorPointer w-auto d-inline-block pe-3"
                        >
                          <ReadMoreReadLess
                            text={
                              each.activityType === "Email"
                                ? each.subject
                                : each.message
                            }
                            count={80}
                            isHideMoreButton={true}
                          />
                        </div>
                        <UncontrolledPopover
                          target={`newMessageText_${each._id}`}
                          trigger="legacy"
                        >
                          {each.activityType === "Email" ? (
                            <PopoverBody>
                              <div>
                                Subject:
                                <p>{each.subject}</p>
                              </div>
                              <hr />
                              <div>
                                Body:
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: each.message,
                                  }}
                                />
                              </div>
                            </PopoverBody>
                          ) : (
                            <PopoverBody>{each.message}</PopoverBody>
                          )}
                        </UncontrolledPopover>
                      </>
                    ) : (
                      "N/A"
                    )}
                  </td>
                  <td>{each._createdBy?.name?.full || "N/A"}</td>
                </tr>
              ))
            ) : (
              <tr className="text-center">
                <td colSpan="4">
                  <div className="nodata">
                    <img
                      src={require("../assets/img/noData.svg").default}
                      alt="no data"
                    />
                    <p>There is no data to display</p>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default LeadMessagesTable;
