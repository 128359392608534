import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Form,
  Input,
  Label,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { APP_LOGO } from "../../config";
import { RegexConfig } from "../../config/RegexConfig";
import PublicFooter from "../../containers/Public/PublicFooter";
import { errorHandler, showToast } from "../../helper-methods";
import { forgotPassword } from "../../http/http-calls";
import { hideLoader, showLoader } from "../../redux/actions/loader-data";

const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [credentials, setCredentials] = useState({
    handle: "",
  });
  const [isDirty, setIsDirty] = useState({
    handle: false,
  });
  const [errors, setErrors] = useState({});

  const _validateFormFields = (newCredentials, newIsDirty) => {
    return new Promise((resolve) => {
      const newErrors = { ...errors };
      let isFormValid = true;

      Object.keys(newCredentials).forEach((key) => {
        if (newIsDirty[key]) {
          switch (key) {
            case "handle": {
              if (newCredentials[key]?.trim().length) {
                if (
                  RegexConfig.email.test(
                    String(newCredentials[key]).toLowerCase()
                  )
                ) {
                  delete newErrors[key];
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] = "*Invalid email";
                  isFormValid = false;
                }
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            default:
          }
        }
      });

      setErrors(newErrors);
      setIsDirty(newIsDirty);

      resolve(isFormValid);
    });
  };

  const _onChangeFormFields = (key, value) => {
    const newCredentials = { ...credentials };
    const newIsDirty = { ...isDirty };
    newCredentials[key] = value;
    newIsDirty[key] = true;
    setCredentials(newCredentials);
    setIsDirty(newIsDirty);
    _validateFormFields(newCredentials, newIsDirty);
  };

  const _onSubmitForm = async (event) => {
    if (event) event.preventDefault();

    const newIsDirty = {
      handle: true,
    };

    const isFormValid = await _validateFormFields(credentials, newIsDirty);

    if (isFormValid) {
      dispatch(showLoader("Loading..."));

      forgotPassword(credentials)
        .then((res) => {
          dispatch(hideLoader());
          showToast(
            "We have emailed the reset password instructions.",
            "success"
          );
          navigate("/login");
        })
        .catch((error) => {
          dispatch(hideLoader());
          errorHandler(error);
        });
    }
  };

  return (
    <>
      <div className="authPgWrapper">
        <div className="authFormWrap">
          <img
            src={APP_LOGO}
            alt="Project Logo"
            className="companyLogo"
            role="button"
            onClick={() => navigate("/")}
          />

          <div className="authForm">
            <h2>Forgot Password?</h2>

            <Form onSubmit={(e) => _onSubmitForm(e)}>
              <div className="mb-3">
                <Label>Email</Label>
                <InputGroup>
                  <Input
                    type="text"
                    placeholder="Enter your email"
                    autoComplete="off"
                    name="handle"
                    value={credentials.handle}
                    onChange={(e) =>
                      _onChangeFormFields("handle", e.target.value)
                    }
                  />
                  <InputGroupText>
                    <i className="far fa-envelope" />
                  </InputGroupText>
                </InputGroup>
                {errors["handle"] ? (
                  <div className="form-error">{errors["handle"]}</div>
                ) : null}
              </div>

              <Button type="submit" className="submitBtn" color="primary">
                Reset Password
              </Button>
            </Form>

            <div className="text-center mt-4">
              Back to <Link to="/login">Login</Link>
            </div>
          </div>
        </div>
      </div>

      <PublicFooter />
    </>
  );
};

export default ForgotPasswordPage;
