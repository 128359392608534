import React, { useEffect, useState } from "react";
import moment from "moment";
import { errorHandler } from "../../helper-methods";
import {
  fetchAllNotifications,
  notificationsRead,
} from "../../http/http-calls";
import { useDispatch, useSelector } from "react-redux";
import SkeletonLoading from "../../components/SkeletonLoading";
import { updateUserData } from "../../redux/actions/user-credential";
import CustomPagination from "../../components/Table/CustomPagination";
import NotificationViewModal from "../../components/modals/NotificationViewModal";

const Notification = () => {
  const dispatch = useDispatch();

  const userCredential = useSelector((state) => state?.userCredential);

  const userType = useSelector((state) =>
    state?.userCredential?.user?.userType?.toLowerCase()
  );

  const [payload, setPayload] = useState({
    pageNumber: 0,
    limit: 10,
    page: 1,
    userType: userType,
  });
  const [notifications, setNotiofications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const [notificationViewModal, setNotificationViewModal] = useState({
    isOpen: false,
    data: null,
  });

  const _toggleNotificationModal = (isOpen = false, data = null) => {
    setNotificationViewModal({ isOpen, data });
  };

  const _fetchNotifications = async (newPayload) => {
    setLoading(true);

    try {
      const res = await fetchAllNotifications(
        newPayload ? newPayload : payload
      );
      setNotiofications(res?.notifications);
      setNotificationCount(res?.totalCount);
      _readNotification();
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const _readNotification = async () => {
    try {
      await notificationsRead({ userType: userType });
      dispatch(
        updateUserData({
          ...userCredential,
          user: userCredential?.user,
          notificationCount: 0,
        })
      );
    } catch (error) {
      errorHandler(error);
    }
  };

  const _onSizeChange = (limit = 10) => {
    const newDataPayload = { ...payload };
    newDataPayload["skip"] = 0;
    newDataPayload["pageNumber"] = 0;
    newDataPayload["page"] = 1;
    newDataPayload["limit"] = limit;
    setPayload(newDataPayload);
    _fetchNotifications(newDataPayload);
  };

  const _onPageChange = (pageNumber = 0) => {
    const newDataPayload = { ...payload };
    newDataPayload["pageNumber"] = pageNumber;
    newDataPayload["page"] = pageNumber + 1;
    setPayload(newDataPayload);
    _fetchNotifications(newDataPayload);
  };

  useEffect(() => {
    _fetchNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="InnerWrapper">
        <div className="pgTitleWrap">
          <h2>Notification</h2>
        </div>

        <div className="notificationList">
          <ul>
            {notifications?.length ? (
              notifications?.map((notification, index) => (
                <li
                  className={!notification?.isRead ? "newMessage" : ""}
                  key={index}
                  onClick={() => _toggleNotificationModal(true, [notification])}
                >
                  <p>
                    {notification?.description ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: notification?.description,
                        }}
                      />
                    ) : (
                      "N/A"
                    )}
                  </p>
                  <div className="nofiticationHeader">
                    <span>
                      {notification?.createdAt
                        ? moment(notification?.createdAt)?.format(
                            "MMM Do YYYY h:mm a"
                          )
                        : "N/A"}
                    </span>
                  </div>
                </li>
              ))
            ) : loading ? (
              <SkeletonLoading type="notificationItem" count={6} />
            ) : (
              <div className="nodata">
                <img
                  src={require("../../assets/img/noData.svg").default}
                  alt="no data"
                />
                <p>There is no data to display</p>
              </div>
            )}
          </ul>
        </div>
        <CustomPagination
          data={notifications}
          dataCount={notificationCount}
          dataPayload={payload}
          onPageChange={_onPageChange}
          onSizeChange={_onSizeChange}
        />
        {notificationViewModal.isOpen && notificationViewModal?.data && (
          <NotificationViewModal
            isOpen={notificationViewModal.isOpen}
            toggle={() => _toggleNotificationModal()}
            data={notificationViewModal?.data}
            pageName="notification"
          />
        )}
      </div>
    </>
  );
};

export default Notification;
